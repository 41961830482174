// helper.js
import moment from 'moment';

/**
 * Method for converting date object into string date format
 * @param {Object} dateObject - the date object
 * @param {String} format - format the date object into specific
 * @returns {String}
 */
export const toStringDate = (dateObject, format) => {
  if (typeof dateObject === 'undefined' || dateObject === '') return dateObject;

  return moment(dateObject).format(format);
};

/**
 * Method for converting value (number) object into currency format
 * @param {Float} number - the number
 * @param {String|null}| currencyType - https://nidhinkumar.medium.com/react-js-number-format-and-styling-a1a6e211e629
 *
 */
export const numberFormatCurrency = (number, currencyType = '') => {
  if (currencyType === null) return Intl.NumberFormat().format(number);

  if (currencyType === '')
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  return new Intl.NumberFormat('en-ph', {
    style: 'currency',
    currency: currencyType,
    maximumSignificantDigits: 2,
    minimumSignificantDigits: 2,
  }).format(number);
};

/**
 * Method for force download a file from ERP site
 * @param {String} url
 *
 * @returns {String}
 */
export const forceDownloadUrl = (url) => {
  return (
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_END_POINT +
    '/file-system/download?link=' +
    url
  );
};

/**
 * Generate a random unique ID
 *
 * @returns {String}
 */
export const generateUUID = () => {
  //Timestamp
  var d = new Date().getTime();

  //Time in microseconds since page-load or 0 if unsupported
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const toFixed = (value, decimal = 100) => {
  let tmp = parseFloat(value) || 0;
  return Math.round(tmp * decimal) / decimal;
};

export const noRoundoffNumberFormat = (value) => {
  let num = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
  return num.toLocaleString(navigator.language, { minimumFractionDigits: 2 });
};

/**
 * get Column Letter from Number
 *
 * @returns {String}
 */
export const getColumnNameFromNumber = (num = 0) => {
  let numeric = (num - 1) % 26;
  let letter = String.fromCharCode(65 + numeric);
  let num2 = parseInt((num - 1) / 26);

  if (num2 > 0) {
    return getColumnNameFromNumber(num2) + letter;
  } else {
    return letter;
  }
};

/**
 * navigate thru object keys
 *
 * @returns {any}
 */
export const navigateObject = (obj, currentKey, direction) => {
  return Object.values(obj)[Object.keys(obj).indexOf(currentKey) + direction];
};

/**
 * Add Hyphen or Dash on Every 3 Length of a string
 */
export const addDashAsIdentifier = (value) => {
  let valueArr = value.match(/\d{3}(?=\d{2,3})|\d+/g);
  let emptyVal = value;

  if (valueArr === null) {
    return emptyVal;
  } else {
    return valueArr.join('-');
  }
};

/**
 * accept only integer
 */

// export const acceptOnlyInteger = (value, dataSet) => {
//   const validation = /^[0-9\b]+$/;

//   if (value === "" || validation.test(value)) {
//     return dataSet;
//   }
// };

/**
 * check if environment is prod
 *
 * @returns {Boolean}
 */
export const isProd = () => {
  const url = new URL(window.location.origin);
  return url?.hostname === 'bkclouderp.com' && url?.port === '';
};

/**
 * get the extension of a file from a url
 *
 * @returns {String}
 */
export const getUrlExtension = (url = '') => {
  return url.split('.').pop();
};

/**
 * get the extension of a file from a url
 *
 * @returns {Boolean}
 */
export const isDateLessOrEqualCurrentDate = (date = '') => {
  if (!moment(date).isValid()) return false;

  return moment(date).isSameOrBefore();
};
