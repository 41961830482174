import { numberFormatCurrency } from '../../../utils/helper';

export const tableColumns = [
  {
    field: 'rr_no',
    headerName: 'RR No',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    sortable: false,
    filterable: false,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'product_code',
    headerName: 'Product Code',
    sortable: false,
    filterable: false,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'batch',
    headerName: 'Batch',
    minWidth: 150,
    flex: 1,
  },
  // {
  //   field: 'quantity_ordered',
  //   headerName: 'Ordered Quantity',
  //   type: 'number',
  //   minWidth: 150,
  //   flex: 1,
  // },
  {
    field: 'received_quantity',
    headerName: 'Received Quantity',
    type: 'number',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'unit_price',
    headerName: 'Unit Price',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.unit_price);
    },
  },
  {
    field: 'unit_vat',
    headerName: 'Unit Vat',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(
        parseInt(row.vat_exemption) === 0
          ? (parseInt(row.unit_price) / 1.12) * 0.12
          : (parseInt(row.unit_price) / 1.12) * 0,
      );
    },
  },
  {
    field: 'unit_vat_inclusive',
    headerName: 'Total Vat Inclusive',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.unit_vat_inclusive);
    },
  },
  {
    field: 'total_vat',
    headerName: 'Total Vat',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.total_vat);
    },
  },
  {
    field: 'unit_vat_exclusive',
    headerName: 'Total Vat Exclusive',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.unit_vat_exclusive);
    },
  },
  {
    field: 'free_goods',
    headerName: 'Free Goods',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.free_goods);
    },
  },
  {
    field: 'diluted_cost',
    headerName: 'Diluted Cost',
    minWidth: 150,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.diluted_cost);
    },
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry Date',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'manufacturing_date',
    headerName: 'Manufacturing Date',
    minWidth: 150,
    flex: 1,
  },
];
