import { useState } from "react";
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";

import { formDefault } from "./const";

const FormModal = ({ toggle, closeModal, onSubmit, loading }) => {
  const [form, setForm] = useState(formDefault);

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Modal
      show={toggle}
      onHide={closeModal}
      hidin
      backdrop="static"
      size="md"
      centered
      onExited={() => setForm(formDefault)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Check Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            Notes / Remarks <span class="req">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="note"
            value={form.note}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            Signature <span class="req d-none">*</span>
          </Form.Label>
          <Form.Control
            type="file"
            name="signature"
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.files })
            }
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => onSubmit(form)}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Save
        </Button>

        <Button
          variant="danger"
          onClick={closeModal}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
