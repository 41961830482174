export const revisionHistoryColumn = [
  {
    field: "activity",
    headerName: "Activity",
    flex: 0,
    minWidth: 180,
  },
  {
    field: "user_id",
    headerName: "User",
    flex: 0,
    minWidth: 180,
  },
  {
    field: "date_time",
    headerName: "Date & Time",
    flex: 0.5,
    minWidth: 180,
  },
  {
    field: "reference_code",
    headerName: "Reference",
    flex: 0.5,
    minWidth: 180,
  },
  {
    field: "module_name",
    headerName: "Module",
    flex: 0,
    minWidth: 180,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    flex: 1.2,
    minWidth: 180,
  },
];
