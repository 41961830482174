import React from "react";
import styled from "styled-components";

const StickyFooter = styled.div`
  position: fixed;
  padding: 10px 0px 0px 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #232e3c;
`;
const Footer = () => {
  return (
    <div>
      <footer className="footer bg-header">
        <div className="container">
          <div className="row text-center align-items-center flex-row-reverse">
            <div className="col-lg-auto ml-lg-auto">
              <ul className="list-inline list-inline-dots mb-0">
                <li className="list-inline-item">
                  <a href="/#" className="link-secondary">
                    User's Manual
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/#" className="link-secondary">
                    FAQ
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/#" target="_blank" className="link-secondary">
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-auto mt-3 mt-lg-0">
              Copyright © 2021
              <a href="/#" className="link-secondary">
                Bell-Kenz Pharma, Inc.
              </a>
              . All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
