import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { hasPermission } from '../PermissionsGate';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { menu } from '../../redux/reducers';
import { generateUUID } from '../../utils/helper';

const NavbarMenu = ({ items }) => {
  const { pathname } = useLocation();
  const { activeMenu } = useSelector((state) => state.menu);

  // this is a hack for removeing show on subchild menu
  $(function () {
    $('.dropdown-menu.show').removeClass('show');
  });
  // end of the hach

  const renderMainMenu = (items) => {
    return items.map((item) => {
      if (item?.hideMenu) return null;

      if (!hasPermission(item.permission)) return null;

      let classes = 'nav-item';

      if (activeMenu.indexOf(item.id) !== -1) {
        classes += ' active';
      }

      if (item?.child) classes += ' dropdown';

      return (
        <li key={item.id} className={classes} id={item.id}>
          <a
            className="nav-link dropdown-toggle"
            href={'/#'}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="nav-link-icon d-md-none d-lg-inline-block">
              {item.icon}
            </span>

            <span className="nav-link-title">{item.parent}</span>
          </a>

          {renderChildMenu(item.child, 0)}
        </li>
      );
    });
  };

  const renderChildMenu = (children, depth = 0) => {
    // check if there is child or none
    let classDropdownMenu = !depth ? 'dropdown-menu' : 'submenu dropdown-menu';

    return (
      <ul className={classDropdownMenu}>
        {children.map((item) => {
          if (item?.hideMenu) return null;

          if (!hasPermission(item.permission)) return null;

          // check if there is  property props on item
          let props = {};

          if (item.hasOwnProperty('props')) {
            props = item.props({ pathname });
          }

          let classes = 'dropdown-item';

          if (activeMenu.indexOf(item.id) !== -1) {
            classes += ' active';
          }

          if (item?.child) {
            classes += ' dropdown-item-has-submenu';
          }

          if (item?.indent) {
            classes += ' dropdown-item-indent';
          }

          if (item?.type) {
            switch (item?.type) {
              case 'title':
                classes += ' font-weight-bold';
                return (
                  <li key={item.id} className="dropdown-list-item-title">
                    <span className={'dropdown-title'}>{item.name}</span>
                  </li>
                );

              case 'divider':
                return (
                  <div key={generateUUID()} className="dropdown-divider"></div>
                );

              default:
                return null;
            }
          }

          const url = item?.url ?? '/#';

          return (
            <li key={item.id}>
              {item?.child ? (
                <span className={classes}>{item.name}</span>
              ) : (
                <NavLink className={classes} to={url} {...props}>
                  {item.name}
                </NavLink>
              )}

              {item?.child ? renderChildMenu(item?.child, depth + 1) : null}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div id="nav-bk" className="navbar-expand-md">
      <nav className="navbar navbar-light bg-light">
        <ul className="navbar-nav">
          <li className="nav-item d-none d-sm-block">
            <NavLink
              className="nav-link"
              to="/dashboard"
              title="Document Management"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-house-door"
                viewBox="0 0 22 22"
              >
                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"></path>
              </svg>
              <span className="badge bg-red"></span>
            </NavLink>
          </li>
          {renderMainMenu(items)}
        </ul>
      </nav>
    </div>
  );
};

export default NavbarMenu;
