import React, { useState, useEffect, Fragment } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomerTransactionModal from '../Srpf/CustomerTransactionModal';
import CustomerDeclineModal from '../Srpf/CustomerDeclineModal';
import PrimaryDataTable from '../PrimaryDataTable';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hasPermission, PermissionsGate } from '../PermissionsGate';
import { transactionDetails, onProcessColumn, tab_location } from './const';
import CustomerReturnToMakerModal from '../Srpf/CustomerReturnToMakerModal';

import {
  getSRPFApproval,
  declined,
  getSRPF,
  approveSRPF,
  srpfExport,
  splitBatch,
} from '../../api/srpf';
import ExportModal from './ExportModal';

const ForApproval = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [approvalSRPFData, setApprovalSRPFData] = useState([]);
  const [approvalSRPFDetails, setApprovedSRPFDetails] =
    useState(transactionDetails);
  const [modalShowDec, setModalShowDec] = useState(false);
  const [showExport, setShowExport] = useState({
    date_from: '',
    date_to: '',
    srpf_type: '',
    show: false,
    status: 3,
  });
  const [decline, setReturn] = useState({
    srpf_no: '',
    reason: '',
  });
  const [pageLoader, setPageLoader] = useState(false);

  const [returnToMaker, setReturnToMaker] = useState({
    srpf_no: '',
    reason: '',
  });

  useEffect(() => {
    loadApprovalData();
  }, []);

  const loadApprovalData = () => {
    setLoading(true);
    getSRPFApproval()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          const viewSrpfPermission = hasPermission(['view_srpf']);
          setApprovalSRPFData(
            result.map((row) => ({ ...row, viewSrpfPermission })),
          );
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleShowTransactionModal = (id) => {
    getSRPF({
      srpf_id: id,
    })
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          setApprovedSRPFDetails({
            ...approvalSRPFDetails,
            ...{ ...result, documents_new: [] },
          });
          setShowTransaction(true);
        }
      })
      .catch((err) => {});
  };

  const handleTabLocation = (key) => tab_location[key];

  const handleCloseTransactionModal = () => setShowTransaction(false);

  const handleApproveTransaction = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to approve this transaction?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setPageLoader(true);
        toast.loading('Processing request please wait');

        approveSRPF({
          srpf_id: id,
          documents_new: approvalSRPFDetails.documents_new,
        })
          .then((res) => {
            let { code, result } = res.data.response;

            if (code === '00000') {
              Swal.fire({
                icon: 'success',
                title: 'Submitted successfully!',
                text: res.data.response.result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Something went wrong!',
                text: Array.isArray(result) ? result.join('<br />') : result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }

            toast.dismiss();
            setPageLoader(false);
          })
          .catch((err) => {});
      }
    });
  };

  const handleSplitBatching = (srpf_no) => {
    Swal.fire({
      title: 'Return To Maker',
      text: 'Are you sure you want to return this transaction to maker?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalShow(true);
        setReturn({ ...decline, srpf_no: srpf_no });
        setReturnToMaker({ ...returnToMaker, srpf_no: srpf_no });
      }
    });
  };

  const handleDeclinedTransaction = (srpf_no) => {
    Swal.fire({
      title: 'Decline Transaction',
      text: 'Are you sure you want to decline this transaction?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3545',
    }).then((result) => {
      if (result.isConfirmed) {
        setReturn({ ...decline, srpf_no: srpf_no });
        setModalShowDec(true);
      }
    });
  };

  const handleConfirmReturn = () => {
    declined(decline)
      .then((res) => {
        if (res.data.response.code === '00000') {
          Swal.fire({
            icon: 'success',
            title: 'Submitted successfully!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops Something went Wrong!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            // if (result.isConfirmed) {
            //   window.location.reload();
            // }
          });
        }
      })
      .catch((err) => {});
  };

  const handleChangeReason = (e) =>
    setReturn({ ...decline, reason: e.target.value });

  const handleViewExportModal = () =>
    setShowExport({ ...showExport, show: true });

  const handleExport = (e) => {
    toast.loading('Exporting Data...');
    srpfExport(showExport)
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          window.open(result, '_blank').focus();
          toast.dismiss();
        }
      })
      .catch((err) => {
        toast.loading(
          'Something went wrong while exporting, Please try again.',
        );
      });
  };

  const handleChangeReasonToReturnToMaker = (e) =>
    setReturnToMaker({ ...returnToMaker, reason: e.target.value });

  const handleConfirmReturnToMaker = () => {
    console.log('retrunT', returnToMaker);
    splitBatch(returnToMaker)
      .then((res) => {
        if (res.data.response.code === '00000') {
          Swal.fire({
            icon: 'success',
            title: 'Submitted successfully!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops Something went Wrong!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            // if (result.isConfirmed) {
            //   window.location.reload();
            // }
          });
        }
      })
      .catch((err) => {});
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
          >
            <Tabs
              className="default-tab"
              activeKey="approval"
              onSelect={(k, event) => {
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab eventKey="add_new" title="Add New"></Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab eventKey="onprocess" title="On-Process SRPF"></Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="approval"
                  title="For Approval"
                >
                  <div className="mt-2 mb-2">
                    <div className="text-right">
                      <Button
                        type="button"
                        variant="info"
                        onClick={handleViewExportModal}
                        className="mb-2"
                      >
                        Export
                      </Button>
                    </div>
                    <PrimaryDataTable
                      loading={loading}
                      rows={approvalSRPFData}
                      columns={onProcessColumn({
                        handleShowTransactionModal,
                      })}
                      getRowId={(row) => row.srpf_approval_id}
                      columnVisibilityModel={{
                        reason: false,
                      }}
                      getCellClassName={({ row }) =>
                        row.reject_reason !== null ||
                        row.reject_reason !== undefined
                          ? row.reject_reason.length > 0
                            ? 'bg-green-lt'
                            : ''
                          : ''
                      }
                    />
                  </div>
                </Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="approved" title="Approved SRPF"></Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab eventKey="declined" title="Declined SRPF"></Tab>
              )}
              {hasPermission(['srpf_returned_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab eventKey="not_yet_received" title="Not yet Received"></Tab>
              )}
              {/* {hasPermission(["srpf_declined_list"]) && (
                <Tab eventKey="declined" title="Approved RTS(WAREHOUSE)"></Tab>
              )} */}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>

      <CustomerTransactionModal
        srpfDetails={approvalSRPFDetails}
        showTransactionModal={showTransaction}
        handleCloseTransactionModal={handleCloseTransactionModal}
        showApprovalButton={false}
        showApprovedButton={true}
        showDeclinedButton={true}
        showSplitButton={true}
        handleApprovalTransaction={false}
        handleApproveTransaction={handleApproveTransaction}
        handleDeclinedTransaction={handleDeclinedTransaction}
        handleSplitBatching={handleSplitBatching}
        pageLoader={pageLoader}
      />
      {/*  */}
      <CustomerDeclineModal
        show={modalShowDec}
        setModalShowDec={setModalShowDec}
        handleConfirmReturn={handleConfirmReturn}
        handleChange={handleChangeReason}
      />
      {/*  */}
      <ExportModal
        show={showExport}
        setModalShow={() =>
          setShowExport((prevState) => ({ ...prevState, show: false }))
        }
        handleChange={(e) =>
          setShowExport((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
        }
        handleConfirmReturn={handleExport}
      />

      <CustomerReturnToMakerModal
        show={modalShow}
        setModalShow={setModalShow}
        handleConfirmReturn={handleConfirmReturnToMaker}
        handleChange={handleChangeReasonToReturnToMaker}
      />
    </Fragment>
  );
};

export default ForApproval;
