import { lazy } from "react";

const VendorDatabaseScreen = lazy(() =>
  import("../components/Vendor/Screens/VendorDatabaseScreen")
);

const StockVendors = lazy(() =>
  import("../components/Vendor/pages/StockVendors")
);

const vendorRoute = {
  public: [],
  auth: [
    {
      name: "vendors_list",
      path: "/vsp/:id",
      component: VendorDatabaseScreen,
    },
    {
      name: "payee_list",
      path: "/vsp/:id",
      component: VendorDatabaseScreen,
    },
    {
      name: "supplier_list",
      path: "/vsp/:id",
      component: VendorDatabaseScreen,
    },
    {
      name: "vendor_info",
      path: "/vendors/:vendor_id/:category",
      component: StockVendors,
    },
  ],
};

export default vendorRoute;
