import { useState, useEffect, useRef, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { getPurchaseLogs } from '../../../api/product';
import { toast } from 'react-toastify';
import PrimaryDataTable from '../../PrimaryDataTable';
import { tableColumns } from './const';
import AddLog from './AddLog';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';

const Purchase = ({ product, visible, productCode }) => {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
    totalAmount: 0,
  });
  const [tableParams, setTableParams] = useState({});

  const loadPurchases = async (params) => {
    const res = await getPurchaseLogs(params);
    const { code, result, total_rows } = res.data.response;

    if (code === '00000') {
      setTableData({
        ...tableData,
        loading: false,
        data: result,
        totalRows: total_rows,
      });
    } else {
      toast.error(Array.isArray(result) ? result.join('<br />') : result);
      return;
    }
  };

  useEffect(() => {
    let newParams = { ...tableParams, product_id: product?.product_id };
    setTableParams(newParams);
    loadPurchases(newParams);
  }, [product]);

  const handleTableChange = (params) => {
    let newParams = { ...tableParams, ...params };
    setTableParams(newParams);
    loadPurchases(newParams);
  };

  const handleFilterModelChange = (filterModel) => {
    const newParams = { ...tableParams, filter: filterModel };
    setTableParams(newParams);
    loadPurchases(newParams);
  };

  const renderTable = () => {
    return (
      <PrimaryDataTable
        rows={tableData.data}
        columns={tableColumns}
        getRowId={(row) => row.log_id}
        disableColumnSelector
        mode="server"
        loading={tableData.loading}
        rowCount={tableData.totalRows}
        onChange={handleTableChange}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
      />
    );
  };

  const render = () => {
    return (
      <div>
        <Fragment>
          <div className="container">
            <div className="text-right">
              <Button
                variant="success"
                className="btn-add-create text-right mb-2"
                onClick={() => setShowModal(true)}
              >
                Create Log
              </Button>
            </div>
            {renderTable()}
          </div>
        </Fragment>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          dialogClassName="modal-md"
          keyboard={false}
          backdrop="static"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Create Log
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddLog
              product={product}
              productCode={productCode}
              visible={visible}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  return render();
};

export default Purchase;
