import { useEffect, useState, Fragment } from "react";
import { Alert, Badge } from "react-bootstrap";
import { Server } from "react-bootstrap-icons";

const HeaderEnvironmentLabel = () => {
  const [showLabel, setShowLabel] = useState(true);

  useEffect(() => {
    document.getElementById("app").classList.add("header-label-wrapper");
  });

  const handleOnClose = () => {
    setShowLabel(false);
    document.getElementById("app").classList.add("hlw-hide");
  };

  const handleOnShow = () => {
    setShowLabel(true);
    document.getElementById("app").classList.remove("hlw-hide");
  };

  const _renderLabel = () => {
    let origin = window.location.origin;

    const url = new URL(origin);

    let hideHeader = false;

    var tmp = { variant: "danger", label: "Development" };

    switch (url?.port) {
      case "8084":
        tmp = { variant: "info", label: "Staging 2" };
        break;
      case "8083":
        tmp = { variant: "secondary", label: "Staging" };
        break;
      case "42763":
        tmp = { variant: "warning", label: "QA" };
        break;
      case "3000":
      case "5000":
        tmp = { variant: "danger", label: "Development" };
        break;
      default:
        hideHeader = true;
        tmp = { variant: "success", label: "Production" };
        break;
    }

    if (hideHeader) {
      setTimeout(
        () =>
          document
            .getElementById("app")
            .classList.remove("header-label-wrapper"),
        350
      );

      return null;
    }

    return (
      <Fragment>
        <Alert
          dismissible
          variant={tmp.variant}
          className="mb-0 position-fixed rounded-0 w-100 top-0 header-label"
          style={{ zIndex: 2 }}
          onClose={handleOnClose}
        >
          <Server /> Server: <strong>{tmp.label}</strong>
        </Alert>
        <Badge
          bg={tmp.variant}
          className="header-label-badge"
          onClick={handleOnShow}
        >
          {tmp.label}
        </Badge>
      </Fragment>
    );
  };

  return _renderLabel();
};

export default HeaderEnvironmentLabel;
