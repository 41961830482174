import { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

import "./styles.css";

import { getNotificationList } from "../../api/notification";
import { notificationBellIcon } from "./const";
import PrimaryLoader from "../PrimaryLoader";

const NavbarNotification = () => {
  const listInnerRef = useRef();
  const { profileData } = useSelector(({ auth }) => auth);

  const id = profileData.employee_no;

  const [notificationData, setNotificationData] = useState([]);
  const [counter, setCounter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState({});
  const [pageSize, setPageSize] = useState(0);

  const [form, setForm] = useState({
    page_size: 10, //limit
    order_by: "created_at",
    direction: "asc",
    search: "",
  });

  useEffect(() => {
    loadNotificationList(form, 0);
  }, []);

  const loadNotificationList = async (formData, pageSize) => {
    let no_of_pages = pageSize * formData.page_size;
    setLoading(true);
    setPageSize((prevPageSize) => prevPageSize + 1);
    try {
      await new Promise((r) => setTimeout(r, 1000));

      let res = await getNotificationList(
        { ...formData, page: no_of_pages },
        id
      );

      const { code, result, total_rows } = res.data.response;

      setLoading(false);
      if (code !== "00000") {
        return;
      }

      setNotificationData([...notificationData, ...result]);
      setCounter(total_rows);
      setTotal(total_rows);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onScroll = (e) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const total_pages = total / form.page_size;
      // const bottom =
      //   e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        if (pageSize < Math.ceil(total_pages)) {
          loadNotificationList(form, pageSize);
        } else {
          return;
        }
      }
    }
  };

  const renderIcon = () => {
    return (
      <div className="nav-item dropdown d-none d-md-flex me-3">
        <a
          href="/#"
          className="nav-link px-0"
          data-bs-toggle="dropdown"
          tabIndex="-1"
          aria-label="Show notifications"
        >
          {notificationBellIcon}
          <span className="badge bg-red">{!counter ? null : counter}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow navbar-notification">
          <div
            className="nn-content"
            onScroll={(e) => onScroll(e)}
            ref={listInnerRef}
          >
            {notificationData.length === 0 ? (
              <Typography className="text-center text-secondary">
                No Notification
              </Typography>
            ) : (
              notificationData.map((data, key) => renderBody(data, key))
            )}
          </div>
          {loading && (
            <div className="position-relative p-4">
              <PrimaryLoader visible={true} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBody = (data, key) => {
    return (
      <a
        href="/#"
        id="data"
        className="dropdown-item notification-item"
        key={key}
      >
        {data.description}
        <Typography className="nn-timestamp">
          {moment(data.created_at).startOf("hour").fromNow()}
        </Typography>
      </a>
    );
  };
  return renderIcon();
};

export default NavbarNotification;
