const MoveToNewWebsiteScreen = () => {
  document.body.classList.add("under-construction");

  setTimeout(() => {
    window.location.href = "https://bkclouderp.com/";
  }, 5000);

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "90vh" }}
    >
      <div className="uc-box d-flex align-items-center justify-content-center">
        <div>
          <img src="/Stacked Version.png" alt="Bell Kenz" />
          <div className="uc-title">
            ERP has been moved to{" "}
            <a
              href="https://bkclouderp.com/"
              rel="noreferrer"
              target={"_blank"}
            >
              https://bkclouderp.com/
            </a>
            . <div>You'll be redirect in (5) seconds.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveToNewWebsiteScreen;
