import { Fragment, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import PrimaryLoader from '../../../PrimaryLoader';

import {
  getMainOfficeBranches,
  getUnassociateBranches,
  getMainBranches,
  createBranch,
  removeBranch,
  createBranchesConfigShow,
  createBranchesConfig,
  updateBranchesConfig,
} from '../../../../api/customerBranches';

const radios = [
  { name: 'Main Office', value: 1, id: 'main_branch' },
  { name: 'Branch', value: 0, id: 'branch' },
];

const customerTypeOption = [
  { value: 'main_branch', label: 'Main Office' },
  { value: 'branch', label: 'Branch' },
];

const CustomerBranches = ({ customerDetails }) => {
  const [branchType, setBranchType] = useState('');
  const [customerType, setCustomerTYpe] = useState('');
  const [selectCustomer, setSelectCustomer] = useState();
  const [customerOption, setCustomerOption] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [customerConfig, setCustomerConfig] = useState({
    so: 0,
    shipping: 0,
    billing: 0,
    billing_account: 0,
    soa_main: 0,
    soa_branch: 0,
  });

  useEffect(() => {}, [customerConfig]);

  useEffect(() => {
    loadBranchesConfig();
  }, [customerDetails]);

  const handleBranchType = (type) => {
    if (type === 'main_branch') {
      loadUnassociateBranches();
    }
    if (type === 'branch') {
      loadMainBranches();
    }
    // Load Branch Data
    loadBranchesData(type);
  };

  const fireBranchesConfig = (config) => {
    if (customerDetails.customer_code) {
      setPageLoader(true);
      createBranchesConfig({
        customer_code: customerDetails.customer_code,
        customer_type: branchType,
        so: config.so,
        shipping: config.shipping,
        billing: config.billing,
        billing_account: config.billing_account,
        soa_main: config.soa_main,
        soa_branch: config.soa_branch,
      })
        .then(() => {
          loadBranchesConfig();
        })
        .catch(() => {});
    }
  };

  const loadBranchesConfig = () => {
    if (customerDetails.customer_code) {
      // setBranchType("");
      setCustomerTYpe('');
      createBranchesConfigShow({
        customer_code: customerDetails.customer_code,
      })
        .then((res) => {
          let { code, result } = res.data.response;
          if (code === '00000' && result !== null) {
            setCustomerConfig({
              ...customerConfig,
              so:
                parseInt(result.so) === (result.type === 'main_branch' ? 1 : 0)
                  ? 1
                  : 0,
              shipping:
                parseInt(result.shipping) ===
                (result.type === 'main_branch' ? 1 : 0)
                  ? 1
                  : 0,
              billing:
                parseInt(result.billing) ===
                (result.type === 'main_branch' ? 1 : 0)
                  ? 1
                  : 0,
              billing_account:
                parseInt(result.billing_account) ===
                (result.type === 'main_branch' ? 1 : 0)
                  ? 1
                  : 0,
              soa_main: parseInt(result.soa_main),
              soa_branch: parseInt(result.soa_branch),
            });
            setBranchType(result.type);
            setCustomerTYpe(
              customerTypeOption.find((row) => row.value === result.type),
            );
            handleBranchType(result.type);
          } else {
            // Create if not exists
            setCustomerConfig({
              type: 'main_branch',
              so: 0,
              shipping: 0,
              billing: 0,
              billing_account: 0,
              soa: 0,
              soa_main: 0,
              soa_branch: 0,
            });
          }
        })
        .finally(() => {
          setPageLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const loadBranchesData = (type) => {
    setLoader(true);
    setBranchesData([]);
    if (type) {
      getMainOfficeBranches({
        customer_code: customerDetails.customer_code,
        branch_type: type,
      }).then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          setLoader(false);
          setBranchesData(result);
        }
      });
    } else {
      setLoader(false);
    }
  };

  // Main Branch Options
  const loadMainBranches = () => {
    setSelectCustomer(null);
    setCustomerOption([]);
    getMainBranches().then((res) => {
      let { code, result } = res.data.response;
      if (code === '00000') {
        setCustomerOption(
          result.map((value) => {
            return {
              value: value.customer_code,
              label: value.customer_name,
            };
          }),
        );
      }
    });
  };

  // Branch only options
  const loadUnassociateBranches = () => {
    setSelectCustomer(null);
    setCustomerOption([]);
    getUnassociateBranches().then((res) => {
      let { code, result } = res.data.response;
      if (code === '00000') {
        setCustomerOption(
          result.map((value) => {
            return {
              value: value.customer_code,
              label: value.customer_name,
            };
          }),
        );
      }
    });
  };

  const handleAddBranchButton = () => {
    if (selectCustomer !== null) {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to add branch?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoader(true);
          createBranch({
            branch_type: branchType,
            main_customer_code: customerDetails.customer_code,
            customer_branches: [
              { ...customerConfig, branch_customer_code: selectCustomer.value },
            ],
          }).then((res) => {
            let { result } = res.data.response;

            Swal.fire({
              icon: 'success',
              title: result,
            });

            setLoader(false);
            handleBranchType(branchType);
          });
        }
      });
    }
  };

  const handleChangeCustomerType = (e) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to change Customer Type?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        updateBranchesConfig({
          customer_code: customerDetails.customer_code,
          customer_type: e.value,
          so: 1,
          shipping: 1,
          billing: 1,
          soa: 1,
          soa_main: e.value === 'main_branch' ? 1 : 0,
          soa_branch: e.value === 'branch' ? 1 : 0,
        })
          .then(() => {
            //
            setBranchType(e.value);
            loadBranchesConfig();
          })
          .finally(() => {
            // Remove Associated Branch
            if (e.value === 'main_branch') {
              removeBranch({
                customer_code: customerDetails.customer_code,
                customer_type: 'main_branch',
              })
                .then((res) => {
                  console.log(res.data.response);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch(() => {});
      } else {
        setCustomerTYpe(
          customerTypeOption.find((row) => row.value === branchType),
        );
      }
    });
  };

  const handleChangeCustomerBranch = (e) => {
    setSelectCustomer(e);
  };

  const handleChangeSetting = (row) => {
    // setCustomerConfig(row);
    fireBranchesConfig(row);
  };

  const handleChangeSingleCheckbox = (row) => {
    let soaReport = {
      ...customerConfig,
      soa_main: row.name === 'soa_main' ? 1 : 0,
      soa_branch: row.name === 'soa_branch' ? 1 : 0,
    };
    setCustomerConfig(soaReport);
    fireBranchesConfig(soaReport);
  };

  const handleRemoveRow = (e) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to remove?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        removeBranch(e).then((res) => {
          handleBranchType(branchType);
        });
      }
    });
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Customer Branches</legend>
            <div className="col-md-12">
              <div className="row pl-2 pr-2 pt-4">
                <PrimaryLoader visible={pageLoader} />
                <Col lg="7">
                  <Row>
                    <Col>
                      <Form>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextEmail"
                        >
                          <Form.Label column sm="3">
                            Customer Type:
                          </Form.Label>
                          <Col sm="9">
                            <Select
                              name="customer_code"
                              options={customerTypeOption}
                              placeholder="- Select Customer Type -"
                              value={customerType || null}
                              isSearchable={false}
                              onChange={(e) => {
                                setCustomerTYpe(e);
                                handleChangeCustomerType(e);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {branchType !== '' && (
                        <div className="mt-2">
                          <div className="row">
                            <div className="col-sm-8 mb-3">
                              <Select
                                name="customer_code"
                                options={customerOption}
                                placeholder="- Select Option -"
                                value={selectCustomer}
                                isClearable={true}
                                isDisabled={
                                  branchType === 'branch' &&
                                  branchesData.length > 0
                                }
                                onChange={(e) => {
                                  handleChangeCustomerBranch(e);
                                }}
                              />
                            </div>
                            <div className="col-sm-4">
                              <Button
                                variant="success"
                                className="btn-block"
                                id="button-addon2"
                                disabled={
                                  branchType === 'branch' &&
                                  branchesData.length > 0
                                }
                                onClick={() => {
                                  handleAddBranchButton();
                                }}
                              >
                                {branchType === 'main_branch'
                                  ? 'Add Branch'
                                  : 'Add Main Office'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-2 mb-3">
                        <table className="table table-border border">
                          <thead>
                            <tr>
                              <th>Customer Code</th>
                              <th>Customer Name</th>
                              <th>Branch</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {branchesData.length > 0 ? (
                              branchesData.map((value) => (
                                <tr>
                                  <td>
                                    <Link
                                      to={`/view/customer/${
                                        branchType === 'branch'
                                          ? value.main_customer.customer_code
                                          : value.customer.customer_code
                                      }`}
                                    >
                                      {branchType === 'branch'
                                        ? value.main_customer.customer_code
                                        : value.customer.customer_code}
                                    </Link>
                                  </td>
                                  <td>
                                    {branchType === 'branch'
                                      ? value.main_customer.customer_name
                                      : value.customer.customer_name}
                                  </td>
                                  <td>
                                    {branchType === 'branch'
                                      ? 'Main Branch'
                                      : 'Branch'}
                                  </td>
                                  <td className="text-center">
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleRemoveRow({
                                          customer_type:
                                            value?.customer_config?.type,
                                          customer_code:
                                            value?.customer_config?.type ===
                                            'main_branch'
                                              ? value.main_customer_code
                                              : value.branch_customer_code,
                                        });
                                      }}
                                    >
                                      <Trash />
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={4}>
                                  {loader ? 'Loading Data...' : 'No Data'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <Form.Text muted className="text-right">
                          Removing Main Office will remove this customer's
                          association to all branches
                        </Form.Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="5">
                  <div>
                    <Form.Label>
                      Sales, Delivery, Payment & Report Settings
                    </Form.Label>
                  </div>
                  <div>
                    <table className="border" style={{ width: '100%' }}>
                      <tr className="border">
                        {/* Removed Test */}
                        <td className="pl-2 pt-0 pb-0" style={{ width: '40%' }}>
                          Sales Ordering
                        </td>
                        <td
                          className="p-2 border text-center"
                          style={{ verticalAlign: 'middle' }}
                        >
                          <ButtonGroup className="mt-2">
                            <ToggleButton
                              size="sm"
                              key={`radio-1-0`}
                              id={`radio-1-0`}
                              type="radio"
                              variant="outline-success"
                              name="salesOrdering"
                              value={1}
                              checked={parseInt(customerConfig.so) === 1}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  so: e.target.value,
                                });
                              }}
                            >
                              Main Branch
                            </ToggleButton>
                            <ToggleButton
                              size="sm"
                              key={`radio-1-1`}
                              id={`radio-1-1`}
                              type="radio"
                              variant="outline-success"
                              name="salesOrdering"
                              value={0}
                              checked={parseInt(customerConfig.so) === 0}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  so: e.target.value,
                                });
                              }}
                            >
                              Branch
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="pl-2">Shipping</td>
                        <td className="p-2 border text-center">
                          <ButtonGroup className="mt-2">
                            <ToggleButton
                              size="sm"
                              key={`radio-2-0`}
                              id={`radio-2-0`}
                              type="radio"
                              variant="outline-success"
                              name="shipping"
                              value={1}
                              checked={parseInt(customerConfig.shipping) === 1}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  shipping: e.target.value,
                                });
                              }}
                            >
                              Main Branch
                            </ToggleButton>
                            <ToggleButton
                              size="sm"
                              key={`radio-2-1`}
                              id={`radio-2-1`}
                              type="radio"
                              variant="outline-success"
                              name="shipping"
                              value={0}
                              checked={parseInt(customerConfig.shipping) === 0}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  shipping: e.target.value,
                                });
                              }}
                            >
                              Branch
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="pl-2">Billing Address</td>
                        <td className="p-2 border text-center">
                          <ButtonGroup className="mt-2">
                            <ToggleButton
                              size="sm"
                              key={`radio-3-0`}
                              id={`radio-3-0`}
                              type="radio"
                              variant="outline-success"
                              name="billing"
                              value={1}
                              checked={parseInt(customerConfig.billing) === 1}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  billing: e.target.value,
                                });
                              }}
                            >
                              Main Branch
                            </ToggleButton>
                            <ToggleButton
                              size="sm"
                              key={`radio-3-1`}
                              id={`radio-3-1`}
                              type="radio"
                              variant="outline-success"
                              name="billing"
                              value={0}
                              checked={parseInt(customerConfig.billing) === 0}
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  billing: e.target.value,
                                });
                              }}
                            >
                              Branch
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="pl-2">Billing Account Name</td>
                        <td className="p-2 border text-center">
                          <ButtonGroup className="mt-2">
                            <ToggleButton
                              size="sm"
                              key={`radio-4-0`}
                              id={`radio-4-0`}
                              type="radio"
                              variant="outline-success"
                              name="billing_account"
                              value={1}
                              checked={
                                parseInt(customerConfig.billing_account) === 1
                              }
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  billing_account: e.target.value,
                                });
                              }}
                            >
                              Main Branch
                            </ToggleButton>
                            <ToggleButton
                              size="sm"
                              key={`radio-4-1`}
                              id={`radio-4-1`}
                              type="radio"
                              variant="outline-success"
                              name="billing_account"
                              value={0}
                              checked={
                                parseInt(customerConfig.billing_account) === 0
                              }
                              disabled={branchType === ''}
                              onChange={(e) => {
                                handleChangeSetting({
                                  ...customerConfig,
                                  billing_account: e.target.value,
                                });
                              }}
                            >
                              Branch
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="pl-2">SOA/Reports</td>
                        <td className="p-2 border text-center">
                          <Form.Check
                            inline
                            id="soa_main"
                            key="soa_main"
                            value="soa_main"
                            label="(Main Office)"
                            checked={
                              customerConfig.soa_main === 1 &&
                              branchType === 'main_branch'
                            }
                            disabled={
                              branchType !== 'main_branch' || branchType === ''
                            }
                            onChange={(e) => {
                              if (customerConfig.soa_main !== 1) {
                                handleChangeSingleCheckbox({
                                  name: 'soa_main',
                                });
                              }
                            }}
                            name="soa_option"
                            type="checkbox"
                          />
                          {/*  */}
                          <Form.Check
                            inline
                            id="soa_branch"
                            key="soa_branch"
                            value="soa_branch"
                            label="(Per Branch)"
                            checked={customerConfig.soa_branch === 1}
                            disabled={branchType === ''}
                            onChange={(e) => {
                              if (customerConfig.soa_branch !== 1) {
                                handleChangeSingleCheckbox({
                                  name: 'soa_branch',
                                });
                              }
                            }}
                            name="soa_option"
                            type="checkbox"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerBranches;
