import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getZoneType } from "../../../../api/warehouse";
import ZoneTypeScreen from "../Screens/ZoneTypeScreen";

const ZoneType = () => {
  const [zoneType, setZoneType] = useState([]);
  const [zoneFormModal, setZoneFormModal] = useState({
    data: null,
    toggle: false,
  });

  useEffect(() => {
    loadZoneType();
  }, []);

  const loadZoneType = async () => {
    try {
      let res = await getZoneType();

      const { code, result } = res.data.response;
      if (code === "00000") {
        setZoneType(result);
      }
    } catch (error) {
      toast.error("Error Loading of Data");
    }
  };

  const onCallBack = () => {
    loadZoneType();
    setZoneFormModal({
      data: null,
      toggle: false,
    });
  };

  const handleCloseFormModal = () => {
    setZoneFormModal({
      data: null,
      toggle: false,
    });
  };

  const handleEditZone = (data) => {
    setZoneFormModal({
      data: data,
      toggle: true,
    });
  };

  return (
    <Fragment>
      <ZoneTypeScreen
        zoneType={zoneType} //LISTING OF DATA
        zoneFormModalData={zoneFormModal.data} //MODAL DATA
        toggle={zoneFormModal.toggle} //IF TRUE OR FALSE
        handleCloseFormModal={handleCloseFormModal} // CLOSING MODAL
        handleEditZone={handleEditZone} //PASSING DATA FROM ROW
        onCallBack={onCallBack} // RELOAD THE DATA WITH CLOSING MODAL
        setZoneFormModal={setZoneFormModal} // TRIGGER TO ADD FORM
      />
    </Fragment>
  );
};

export default ZoneType;
