import { useState } from "react";
import {
  Button,
  Modal,
  Alert,
  Table,
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
import { numberFormatCurrency } from "../../../../utils/helper";

const CheckDetailModal = ({
  toggle,
  closeModal,
  data,
  type = "outstanding_balance",
}) => {
  const [total, setTotal] = useState(0);

  const handleOnEntered = () => {
    let totalAmount = 0;

    if (data.length) {
      data.forEach(({ check_amount }) => {
        totalAmount += parseFloat(check_amount) || 0;
      });
    }

    setTotal(totalAmount);
  };

  const renderTable = () => {
    if (type === "customer_center_outstanding_balance") {
      return (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check Date</th>
              <th>Account Name</th>
              <th>Check No.</th>
              <th>Bank</th>
              <th>Status</th>
              <th className="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr>
                  <td>{row.date}</td>
                  <td>{row.account_name}</td>
                  <td>{row.check_no}</td>
                  <td>
                    {[row.bank_name, row.bank_branch]
                      .filter((a) => a)
                      .join(" - ")}
                  </td>
                  <td>{row.status}</td>
                  <td className="text-right">
                    {numberFormatCurrency(row.check_amount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Check Date</th>
            <th>PR/CR Number</th>
            <th>PR/CR Date</th>
            <th>DR/SI Number</th>
            <th>DR/SI Date</th>
            <th>Account Name</th>
            <th>Check No.</th>
            <th>Bank</th>
            {type === "transaction_history" ? <th>Payment Type</th> : null}
            <th>Status</th>
            <th className="text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              <tr>
                <td>{row.date}</td>
                <td>
                  {row.pr_no} <br /> {row.cr_no}
                </td>
                <td>{row.pr_cr_date}</td>
                <td>
                  {row.si_no.length > 0
                    ? row.si_no.map((si) => {
                        return (
                          <span>
                            {si.si_number} <br />
                          </span>
                        );
                      })
                    : ""}{" "}
                </td>
                <td>{row.si_date}</td>
                <td>{row.account_name}</td>
                <td>{row.check_no}</td>
                <td>
                  {[row.bank_name, row.bank_branch]
                    .filter((a) => a)
                    .join(" - ")}
                </td>
                {type === "transaction_history" ? (
                  <td>{row.payment_type.toUpperCase()}</td>
                ) : null}
                <td>{row.status}</td>
                <td className="text-right">
                  {numberFormatCurrency(row.check_amount)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderContent = () => {
    if (!data.length) return <Alert variant="warning">No Data.</Alert>;

    return (
      <div>
        {renderTable()}
        <Row>
          <Col></Col>
          <Col></Col>
          <Col lg="5">
            <Form.Group as={Row} className={"mb-4"}>
              <Form.Label column md="4">
                Total
              </Form.Label>
              <Col md="8">
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>

                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    name="total"
                    value={numberFormatCurrency(total, null)}
                    readOnly={true}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Modal
      show={toggle}
      onHide={closeModal}
      hidin
      dialogClassName="modal-width"
      keyboard={false}
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      onEntered={handleOnEntered}
    >
      <Modal.Header closeButton>
        <Modal.Title>Check Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={closeModal}
          style={{ width: "100px" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckDetailModal;
