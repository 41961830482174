import { Fragment, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useQuery, useQueryClient } from 'react-query';

import { toast } from 'react-toastify';

import { getWarehouses } from '../../../api/warehouse';
import { getWarehouseFloors } from '../../../api/warehouseZoneAssignment';
import {
  postUpdateWarehouseZone,
  postCreateWarehouseZone,
} from '../../../api/warehouseZone';
import Swal from 'sweetalert2';

const formDefault = {
  warehouse_id: '',
  floor_id: '',
  zone_name: '',
  color_code: '#000',
  zone_id: '',
  is_active: 1,
  zone_type: 0,
};
const WarehouseZoneFormModal = ({
  toggle,
  defaultData = {},
  onClose,
  onSubmit,
  onCallback = {},
}) => {
  const queryClient = useQueryClient();
  //const [warehouseDropdown, setWarehouseDropdown] = useState([]);
  //const [floorDropdown, setFloorDropdown] = useState([]);
  const [fieldReadOnly, setFieldReadOnly] = useState(false);

  const [form, setForm] = useState(formDefault);

  // const warehouseQuery = useQuery('warehouses1', getWarehouses, {
  //   onSuccess: (res) => {
  //     let { result } = res.data.response;

  //     setWarehouseDropdown(
  //       result.map((row) => ({
  //         value: row.warehouse_id,
  //         label: row.warehouse_name,
  //       })),
  //     );
  //   },
  //   onError: () => toast.error('Unable to get warehouses'),
  // });

  const { data: warehouseDropdown, isFetching: isWarehouseDropdownFetching } =
    useQuery({
      queryKey: ['warehouses1'],
      queryFn: async () => {
        const res = await getWarehouses();

        const { code, result } = res.data.response;

        if (code !== '00000') {
          throw new Error('Unable to get customer type');
        }

        return result.map(({ warehouse_id, warehouse_name }) => ({
          value: warehouse_id,
          label: warehouse_name,
        }));
      },
      placeholderData: [],
    });

  const { data: floorDropdown, isFetching: isFloorDropdownFetching } = useQuery(
    {
      queryKey: ['floors1', form.warehouse_id],
      queryFn: async () => {
        const res = await getWarehouseFloors(form.warehouse_id);

        const { code, result } = res.data.response;

        if (code !== '00000') {
          throw new Error('Unable to get customer type');
        }

        return result.map((row) => ({
          value: row.warehouse_pallet_id,
          label: row.floor,
          row: parseInt(row.row) || 0,
          shelf: parseInt(row.shelf) || 0,
          column: row.column,
          pallet_count: parseInt(row.pallet_count) || 0,
          is_active: parseInt(row.is_active) || 0,
        }));
      },
      enabled: form.warehouse_id !== '' ? true : false,
      placeholderData: [],
    },
  );

  // const floorQuery = useQuery(
  //   ['floors', form.warehouse_id],
  //   () => getWarehouseFloors(form.warehouse_id),
  //   {
  //     onSuccess: (res) => {
  //       let { result } = res.data.response;

  //       setFloorDropdown(
  //         result.map((row) => ({
  //           value: row.warehouse_pallet_id,
  //           label: row.floor,
  //           row: parseInt(row.row) || 0,
  //           shelf: parseInt(row.shelf) || 0,
  //           column: row.column,
  //           pallet_count: parseInt(row.pallet_count) || 0,
  //           is_active: parseInt(row.is_active) || 0,
  //         })),
  //       );
  //     },
  //     onError: () => toast.error('Unable to get floors'),
  //     enabled: form.warehouse_id !== '' ? true : false,
  //   },
  // );

  const handleOnEnter = () => {
    if (defaultData) {
      setFieldReadOnly(true);

      setForm({
        ...form,
        warehouse_id: defaultData?.warehouse_id,
        floor_id: defaultData?.floor_id,
        zone_name: defaultData?.zone_name || '',
        zone_id: defaultData?.zone_id || '',
        color_code: defaultData.color_code || '#000',
      });
    }
  };

  const handleSelect = (name, e) => {
    let tmpValue = e ? e.value : '';

    switch (name) {
      case 'warehouse_id':
        setForm({ ...form, [name]: tmpValue, floor_id: '' });
        // setFloorDropdown([]);
        break;
      default:
        setForm({ ...form, [name]: tmpValue });
        break;
    }
  };

  const handleOnSubmit = async () => {
    if (form.zone_id) {
      handleSubmitUpdate();
    } else {
      handleSubmitCreate();
    }
  };

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const handleClose = () => {
    setForm(formDefault);
    onClose();
  };

  const handleSubmitCreate = async () => {
    let { isConfirmed } = await Swal.fire({
      title: 'Warehouse Zone',
      text: 'Are you sure you want to create this zone?',
      icon: 'warning',
      showCancelButton: true,
    });

    if (isConfirmed) {
      const res = await postCreateWarehouseZone(form);

      let { code, result } = res.data.response;

      Swal.fire({
        title: 'Warehouse Zone',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code === '00003' ? 'success' : 'error',
      });

      if (code === '00003') {
        onCallback();
      }
    }
  };

  const handleSubmitUpdate = async () => {
    let { isConfirmed } = await Swal.fire({
      title: 'Warehouse Zone',
      text: 'Are you sure you want to update this zone?',
      icon: 'warning',
      showCancelButton: true,
    });

    if (isConfirmed) {
      const res = await postUpdateWarehouseZone(form);

      let { code, result } = res.data.response;

      Swal.fire({
        title: 'Warehouse Zone',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code === '00004' ? 'success' : 'error',
      });

      if (code === '00004') {
        onCallback();
      }
    }
  };

  return (
    <Modal
      show={toggle}
      onHide={handleClose}
      onEnter={handleOnEnter}
      aria-labelledby="Warehouse Zone Form"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Warehouse Zone Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            Warehouse <span className="req">*</span>
          </Form.Label>
          <Select
            options={warehouseDropdown}
            name="warehouse_id"
            placeholder="- Select Warehouse -"
            value={
              form.warehouse_id
                ? warehouseDropdown.find(
                    (row) => row.value === form.warehouse_id,
                  )
                : ''
            }
            onChange={(data) => handleSelect('warehouse_id', data)}
            isDisabled={fieldReadOnly}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Floor <span className="req">*</span>
          </Form.Label>
          <Select
            options={floorDropdown}
            name="floor_id"
            placeholder="- Select Floor -"
            value={
              form.floor_id
                ? floorDropdown.find((row) => row.value === form.floor_id)
                : ''
            }
            onChange={(data) => handleSelect('floor_id', data)}
            isDisabled={fieldReadOnly}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Zone Name <span className="req">*</span>
          </Form.Label>
          <input
            type="text"
            className="form-control"
            placeholder="Zone Name"
            name="zone_name"
            value={form.zone_name}
            onChange={handleChange}
            required={true}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Color Code <span className="req">*</span>
          </Form.Label>
          <input
            type="color"
            className="form-control"
            value={form.color_code}
            name="color_code"
            onChange={handleChange}
            required={true}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="success" onClick={handleOnSubmit}>
          Save
        </Button>
        <Button type="button" variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarehouseZoneFormModal;
