import { useEffect, useState } from "react";

import PrimaryDataTable from "../../../../components/PrimaryDataTable";

// api
import { getCustomerSummaryCheckDetails } from "../../../../api/collections";

// data and consts
import { checkDetailsColumns } from "./const";

const CheckDetails = ({ customer_code }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => loadData(customer_code), [customer_code]);

  const loadData = (customerCode) => {
    if (customerCode === "") return;

    setLoading(true);
    setData([]);

    getCustomerSummaryCheckDetails(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;

      if (code === "00000")
        setData(
          result.map((row, index) => ({
            ...row,
            id: customerCode + "_" + index,
          }))
        );
    });
  };

  return (
    <PrimaryDataTable
      key={customer_code}
      rows={data}
      columns={checkDetailsColumns}
      disableColumnSelector={true}
      disableSelectionOnClick
      hasGridToolbar
      loading={loading}
    />
  );
};

export default CheckDetails;
