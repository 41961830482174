import { useState, useEffect, useRef, Fragment } from "react";
import {
  Alert,
  Button,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import {
  ExclamationTriangleFill,
  PlusCircleFill,
  Receipt,
} from "react-bootstrap-icons";
import { registerAllModules } from "handsontable/registry";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";

import "../../../assets/css/pages/product-forecast.css";
import { getProductForecast } from "../../../api/product";

import PrimaryLoader from "../../PrimaryLoader";

import ForecastTable from "./ForecastTable";
import ForecastFormModal, { useForecastFormModal } from "./ForecastFormModal";
import PurchaseRequestFormModal, {
  usePurchaseRequestFormModal,
} from "./PurchaseRequestFormModal";

import PoTrailLogsModal, { usePoTrailLogsModal } from "./PoTrailLogsModal";

import useForecast from "./useForecast";
import { navigateObject, toFixed } from "../../../utils/helper";

registerAllModules();

const year = parseInt(moment().format("YYYY"));
const defaultYearDropdown = [{ label: year, value: year }];

const defaultSelectYears = [{ label: year, value: year }];

const Forecast = ({ product, productCode, visible, otherData }) => {
  const [forecastData, hotData, setRawForecastData] = useForecast();

  const [togglePrevForecast, setTogglePrevForecast] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [
    forecastFormModal,
    setForecastFormModalClose,
    setForecastFormModalMinimize,
    setForecastFormModalToggle,
  ] = useForecastFormModal();

  const [
    purchaseRequestFormModal,
    setPurchaseRequestFormModalClose,
    setPurchaseRequestFormModalMinimize,
    setPurchaseRequestFormModalToggle,
  ] = usePurchaseRequestFormModal();

  const [trailLogsModal, setPoTrailLogsModalClose, setPoTrailLogsModalToggle] =
    usePoTrailLogsModal();

  const [selectedYears, setSelectedYears] = useState(defaultSelectYears);
  const [vendorData, setVendorData] = useState(null);
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const [yearDropdown, setYearDropdown] = useState(defaultYearDropdown);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fcRef = useRef(null);

  useEffect(() => {
    if (
      visible &&
      !_.isEmpty(product) &&
      product?.product_code !== selectedProduct?.product_code
    ) {
      setHeight(fcRef.current.offsetHeight);
      setWidth(fcRef.current.offsetWidth);

      loadData(selectedYears);
      setSelectedProductCode(productCode);
      setPurchaseRequestFormModalClose();
      setForecastFormModalClose();
    } else {
      // if (!visible && hotData.data.length) {
      //   setLoading(true);
      // } else if (visible && hotData.data.length) {
      //   setLoading(false);
      // }
    }

    return () => {
      setPurchaseRequestFormModalClose();
      setForecastFormModalClose();
    };
  }, [visible, product, productCode]);

  const notifyForecast = () => {
    if (!_.isEmpty(hotData?.parseData?.current_inventory) && !hasError) {
      // check if current year month exist in the parseData
      const currentYearMonth = moment().format("MMM_YYYY").toLowerCase();

      if (!_.has(hotData?.parseData?.current_inventory, currentYearMonth)) {
        return null;
      }

      const leadTime = parseInt(product.supplier_lead_time) || 0;

      // get how many months from lead time
      const futureYearMonth = moment()
        .add(leadTime, "days")
        .format("YYYY-MM-DD");
      const months = moment(futureYearMonth).diff(moment(), "months") + 1;

      let flag = false;
      for (let i = 1; i <= months; i++) {
        let data = navigateObject(
          hotData?.parseData?.current_inventory,
          currentYearMonth,
          i
        );

        data = parseFloat(data) || 0;

        if (data < 0) {
          flag = true;
          break;
        }
      }

      if (flag) {
        return (
          <Alert variant="danger">
            <ExclamationTriangleFill style={{ marginTop: "-5px" }} /> Product
            inventory is running low in incoming months. Please create purchase
            request.
          </Alert>
        );
      }
    }

    return null;
  };

  const loadData = async (sYears) => {
    try {
      setLoading(true);
      setHasError(false);

      //let { code, result } = testData;
      //setRawForecastData({ result: result.forecasting, product });

      let years = [year];
      if (sYears.length) {
        years = sYears.map((row) => row.value);
      }

      let res = await getProductForecast({
        year: years.sort(),
        product_code: productCode,
      });

      let { code, result } = res.data;

      if (code !== "00000") {
        throw Object.assign(new Error("Unable to load forecast data"), {
          code: 402,
        });
      }

      setRawForecastData({
        result: result.forecasting,
        incoming_po_editable: [],
        product,
      });
      setSelectedProduct(product);

      setVendorData({
        ...result.product.product_vendor,
        product_payments: {
          percent_down_payment: toFixed(
            result.product.product_payments.percent_down_payment
          ),
          percent_intransit: toFixed(
            result.product.product_payments.percent_intransit
          ),
          percent_upon_delivery: toFixed(
            result.product.product_payments.percent_upon_delivery
          ),
          pdc_payment: result.product.product_payments.pdc_payment.map(
            (row) => {
              return { ...row, percent_amount: toFixed(row.percent_amount) };
            }
          ),
        },
      });

      setYearDropdown(
        result.available_years.map((row) => ({ value: row, label: row }))
      );

      setRawData(result); // store raw data

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setHasError(true);
      toast.error(error.message);
    }
  };

  const handleChangeYear = (data) => {
    setSelectedYears(data);
    loadData(data);
  };

  const handleForecastFormSubmitted = (data) => {
    if (data.success) {
      // close modal
      setForecastFormModalClose();
      // reload page
      loadData(selectedYears);
    }
  };

  const handlePurchaseRequestFormModalToggle = () => {
    setPurchaseRequestFormModalToggle({
      product: product,
      vendor: vendorData,
      otherData,
    });
  };

  const handlePurchaseRequestFormSubmitted = (data) => {
    if (data.success) {
      setPurchaseRequestFormModalClose();
      loadData(selectedYears);
    }
  };

  const handlePurchaseRequestFormModalMinimize = () => {
    setPurchaseRequestFormModalMinimize({
      product: product,
      vendor: vendorData,
      otherData,
    });
  };

  const handleTogglePrevForecast = () =>
    setTogglePrevForecast(!togglePrevForecast);

  const handleTableOnChange = (changes) => {
    setRawForecastData({
      result: rawData.forecasting,
      ...changes,
      product,
    });
  };

  const handleViewIncomingPo = (row) => {
    setPoTrailLogsModalToggle(row);
    console.log({ handleViewIncomingPo: 1, row });
  };

  const renderTable = () => {
    if (loading) return <PrimaryLoader visible={true} />;

    if (!hotData.data.length || hasError) return null;

    return (
      <Fragment>
        <ForecastTable
          hotData={hotData}
          width={width}
          height={height}
          togglePrevForecast={togglePrevForecast}
          onChange={handleTableOnChange}
          onViewIncomingPo={handleViewIncomingPo}
        />
      </Fragment>
    );
  };

  const render = () => {
    if (_.isEmpty(product)) return <Alert variant="warning">No Product.</Alert>;

    return (
      <div>
        <Row className="p-3">
          <Col>
            <div className="w-50">
              <Select
                options={yearDropdown}
                value={selectedYears.length ? selectedYears : ""}
                menuPortalTarget={document.body}
                placeholder="- Select Years - "
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 999 }) }}
                onChange={handleChangeYear}
                isMulti
                isClearable={false}
              />
            </div>
          </Col>
          <Col className="text-right">
            <Button
              variant="warning"
              className="btn-add-create mr-3"
              onClick={handlePurchaseRequestFormModalToggle}
            >
              <Receipt size={20} className="mr-2" />
              Add PR
            </Button>

            <Dropdown as={ButtonGroup}>
              <Button
                variant="success"
                className="btn-add-create"
                onClick={setForecastFormModalToggle}
              >
                <PlusCircleFill size={20} className="mr-2" />
                Add Forecast
              </Button>
              <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item eventKey="1" onClick={handleTogglePrevForecast}>
                  Toggle Previous Forecast
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col lg="12" className="d-none">
            <Alert variant="info" className="mt-2 mb-0">
              <span className="font-weight-bold">
                <ExclamationTriangleFill style={{ marginTop: "-5px" }} /> Note:
              </span>{" "}
              Previous forecast are by default hidden to check for the
              previously created forecast select the{" "}
              <span className="font-weight-bold">
                2 arrows on the left side of table and right click "Show Rows".
              </span>
            </Alert>
          </Col>
        </Row>
        <div className="pl-3 pr-3">{notifyForecast()}</div>
        <div id="forecast-hot-container" ref={fcRef}>
          {renderTable()}
        </div>
        {/* modal */}
        <ForecastFormModal
          productCode={productCode}
          toggle={forecastFormModal.toggle}
          minimize={forecastFormModal.minimize}
          onClose={setForecastFormModalClose}
          onMinimize={setForecastFormModalMinimize}
          onSubmitted={handleForecastFormSubmitted}
          loading={false}
        />

        <PurchaseRequestFormModal
          data={purchaseRequestFormModal.data}
          toggle={purchaseRequestFormModal.toggle}
          minimize={purchaseRequestFormModal.minimize}
          onClose={setPurchaseRequestFormModalClose}
          onMinimize={handlePurchaseRequestFormModalMinimize}
          onSubmitted={handlePurchaseRequestFormSubmitted}
        />

        <PoTrailLogsModal
          toggle={trailLogsModal.toggle}
          data={trailLogsModal.data}
          onClose={setPoTrailLogsModalClose}
        />
      </div>
    );
  };

  return render();
};

export default Forecast;
