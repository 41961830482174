import React from "react";
import { Tooltip } from "@material-ui/core";

function ProductPackaging({
  styles,
  values,
  productData,
  onInputChange,
  updateForm,
  specifyOthers,
  packagingHolder,
  unitMeasureHolder,
  computeUnit,
  smallUplaodImage,
  smallImage,
  bigUplaodImage,
  bigImage,
}) {
  return (
    <div className="col-md-12 row" id="packaging_information">
      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Packaging</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <label
              for="packaging"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Packaging
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <select
                  className="form-control form-control-sm text-dark"
                  name="packaging"
                  value={values.packaging}
                  onChange={(e) => {
                    onInputChange(e);
                    specifyOthers(e);
                  }}
                  disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE PACKAGING
                  </option>
                  {packagingHolder.map((packaging) => (
                    <option
                      value={packaging.packaging_id}
                      key={packaging.packaging_id}
                      selected={
                        values.packaging == packaging.packaging_id
                          ? "selected"
                          : ""
                      }
                    >
                      {packaging.packaging_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="packaging_description"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Packaging Description
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="packaging_description"
                  id="packaging_description"
                  value={values.packaging_description}
                  style={{ marginTop: "6px" }}
                  placeholder="Packaging Description"
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="supplier"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Unit of Measure
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <select
                  className="form-control form-control-sm"
                  name="unit_measure"
                  value={values.unit_measure}
                  style={{ marginTop: "6px" }}
                  placeholder="Packaging Description"
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                  novalidate
                >
                  <option disabled selected>
                    CHOOSE UNIT OF MEASURE
                  </option>
                  {unitMeasureHolder.map((unit) => (
                    <option
                      value={unit.unit_id}
                      key={unit.unit_id}
                      selected={
                        values.unit_id == unit.unit_id ? "selected" : ""
                      }
                    >
                      {unit.unit_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="qty_per_pack"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Qty per Pack
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="qty_per_pack"
                  id="qty_per_pack"
                  value={values.qty_per_pack}
                  style={{ marginTop: "3px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Quantity Per Pack"
                  disabled={!updateForm}
                  onKeyUp={computeUnit}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="small_size"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Size (small)
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="small_size"
                  id="small_size"
                  value={values.small_size}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Small Size"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">mm</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="big_size"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Size (big)
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="big_size"
                  id="big_size"
                  value={values.big_size}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Big Size"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">cm</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="weight_net"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Weight Net
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="weight_net"
                  id="weight_net"
                  value={values.weight_net}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Weight Net"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">grams</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="weight_gross"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Weight Gross
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="weight_gross"
                  id="weight_gross"
                  value={values.weight_gross}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Weight Gross"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">grams</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Handling</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <label
              for="temperature"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Temperature
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-4 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="min_temperature"
                  id="min_temperature"
                  value={values.min_temperature}
                  style={{ marginTop: "6px" }}
                  placeholder="Min"
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                  novalidate
                />
              </div>
              <div className="col-sm-4 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="max_temperature"
                  id="max_temperature"
                  value={values.max_temperature}
                  style={{
                    marginTop: "6px",
                    marginLeft: "30px",
                  }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                  placeholder="Max"
                  novalidate
                />
              </div>
              <div className="col-sm-3">
                <span style={{ paddingLeft: "30px" }}>&#8451;</span>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="humidity"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Humidity
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="humidity"
                  id="humidity"
                  value={values.humidity}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Humidity"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">
                <span>%</span>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="storage_requirement"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Storage Requirement
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="storage_requirement"
                  id="storage_requirement"
                  value={values.storage_requirement}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                  placeholder="Storage Requirement"
                  novalidate
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <br />
      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Small Size Image Upload</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <div className="col-sm-12 row">
              <input
                type="file"
                className="form-control form-control-sm text-dark"
                name="small_size_image"
                id="small_size_image"
                value={values.small_size_image}
                style={{ marginTop: "6px" }}
                onChange={smallUplaodImage}
                disabled={!updateForm}
              />
            </div>
          </div>

          {smallImage.length > 0 ? (
            <center>
              <a href={smallImage} target="_blank" rel="noreferrer">
                <img src={smallImage} style={{ padding: "20px" }} alt="small" />
              </a>
            </center>
          ) : (
            ""
          )}
        </section>
      </div>

      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Big Size Image Upload</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <div className="col-sm-12 row">
              <input
                type="file"
                className="form-control form-control-sm text-dark"
                name="big_size_image"
                id="big_size_image"
                value={values.big_size_image}
                style={{ marginTop: "6px" }}
                onChange={bigUplaodImage}
                disabled={!updateForm}
              />
            </div>
          </div>
          {bigImage.length > 0 ? (
            <center>
              <a href={bigImage} target="_blank" rel="noreferrer">
                <img src={bigImage} style={{ padding: "20px" }} alt="big" />
              </a>
            </center>
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
}

export default ProductPackaging;
