import makeApiRequest from './makeApiCall';
//sales
export const requestGetByTransactionDate = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/transactions/sales`, 'POST', null, data);
};

export const getTransactionType = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/transactions/types`, 'GET', null, null);
};

export const getProductsData = async (data, files = null) => {
  return await makeApiRequest(`/products`, `GET`, null, data, files);
};

export const getActiveCustomer = async () => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/customers`, 'GET', null, null);
};

export const getCustomers = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/customers/search`, 'post', null, data);
};

export const getStatus = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/customers/regular-nonregular`,
    'post',
    null,
    data,
  );
};

export const requestGetReceivablesByTransactionDate = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/recivables`,
    'POST',
    null,
    data,
  );
};

export const getActivePrducts = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/products/all/active`, 'POST', null, data);
};

export const getBatchNo = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/search/batches`,
    'POST',
    null,
    data,
  );
};

export const getBreakDownComputationPerModule = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/product/batches`,
    'POST',
    null,
    data,
  );
};

export const requestGetInventoriesByTransactionDate = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/inventory`,
    'POST',
    null,
    data,
  );
};

export const requestGetProductByTransactionDate = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/product`,
    'POST',
    null,
    data,
  );
};

export const requestProductExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/product/export`,
    'POST',
    null,
    data,
  );
};

export const requestSalesExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/sales/export`,
    'POST',
    null,
    data,
  );
};

export const requestReceivablesExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/recivables/export/report`,
    'POST',
    null,
    data,
  );
};

export const requestGeneralLedgerExport = async (data) => {
  return await makeApiRequest(`/ledger/general/export`, 'POST', null, data);
};

export const requestInventoryExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/inventory/export`,
    'POST',
    null,
    data,
  );
};

export const requestSalesPerProductExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/sales-per-product/report`,
    'POST',
    null,
    data,
  );
};

export const requestVatableSalesExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/vatable-sales/report`,
    'POST',
    null,
    data,
  );
};

export const requestGetVatableSalesLedger = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/vatable-sales`,
    'POST',
    null,
    data,
  );
};

export const requestGetSalesPerProductLedger = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/sales-per-product`,
    'POST',
    null,
    data,
  );
};

export const requestSalesPerProductBatches = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/product/batches`,
    'POST',
    null,
    data,
  );
};

export const requestYtdSalesData = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/ledger/transactions/sales-per-channel-segment`,
    'POST',
    null,
    data,
  );
};

export const getGeneralLedgerData = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/general`, 'POST', null, data);
};

export const getAccounts = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/accounts/list`, 'GET', null, data);
};

export const getSubgroup = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/accounts/groups/sub`, 'GET', null, data);
};

export const getFSGroup = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/accounts/groups/fs`, 'GET', null, data);
};
