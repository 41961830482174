import { useState, useEffect } from "react";
import VatableSalesScreen from "../Screens/VatableSalesScreen";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {} from "../../../api/ledgers";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  requestVatableSalesExport,
  requestGetVatableSalesLedger,
} from "../../../api/ledgers";
import { numberFormatCurrency } from "../../../utils/helper";
import useNavActive from "../../../components/Navbar/useNavActive";
import moment from "moment";

const formDefault = {
  customer_id: "",
  date_from: moment().subtract(20, "days").format("YYYY-MM-DD"),
  date_to: moment().format("YYYY-MM-DD"),
  page: 0,
  page_size: 50,
  order_by: null,
  direction: null,
  reg_or_nonreg: "",
};

const VatableSales = () => {
  useNavActive(["master_databases", "sub_ledger_database", "vatable_sales"]);
  //
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [vatableSalesData, setVatableSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);

  useNavActive(["master_databases", "sub_ledger_database", "vatable_sales"]);

  useEffect(() => loadLedgerData(formData), []);

  const loadLedgerData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setVatableSalesData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await requestGetVatableSalesLedger(form);

        const { code, result, total_rows } = res.data.response;

        if (code !== "00000") {
          toast.error(result);
          return;
        }
        let tmp = [];
        _.each(result, (row) => {
          tmp.push({
            id: row.id,
            si_date: row.si_date,
            dr_number: row.dr_number,
            si_number: row.si_number,
            customer_name: row.customer_name,
            tin: row.tin,
            net_sales: numberFormatCurrency(row.net_sales ?? 0),
            vat_sales: numberFormatCurrency(row.vat_sales ?? 0),
            rated_sales: numberFormatCurrency(row.rated_sales ?? 0),
            vat_exempt: numberFormatCurrency(row.vat_exempt ?? 0),
            vat_amount: numberFormatCurrency(row.vat_amount ?? 0),
            sales: numberFormatCurrency(row.sales ?? 0),
          });
        });
        setVatableSalesData(tmp);
        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error occured. Please try again later");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning("Please provide valid date!");
    }
  };

  const handleSelectedTab = (key) => {
    if (key === "sales_ledger") {
      history.push("/ledgers/sales");
    } else if (key === "receivables_ledger") {
      history.push("/ledgers/receivables");
    } else if (key === "inventories_ledger") {
      history.push("/ledgers/inventories");
    } else if (key === "sales_per_product_ledger") {
      history.push("/ledgers/sales-per-products");
    } else if (key === "vatable_sales_ledger") {
      history.push("/ledgers/vatable-sales");
    } else if (key === "product_ledger") {
      history.push("/ledgers/products");
    } else if (key === "ytd_sales_ledger") {
      history.push("/ledgers/ytd-sales");
    } else {
      history.push("/ledgers/sales");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => loadLedgerData(formData);

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };

    //setFormData(temp);
    loadLedgerData(temp);
  };

  const OnHandleExport = () => {
    let tmp = {
      id: formData.customer_id,
      customer_id: formData.customer_id,
      date_from: formData.date_from,
      date_to: formData.date_to,
      tab: "vatable_sales",
    };
    console.log(tmp);
    toast.loading("Exporting data in progress, Please wait...", {
      position: toast.POSITION.TOP_RIGHT,
    });
    requestVatableSalesExport(tmp)
      .then((res) => {
        console.log({ res });
        if (res.data.response.code === "00000") {
          console.log(res);
          window.location.href = res.data.response.result;
          toast.dismiss();
        } else {
          Swal.fire({
            title: "Error",
            text: res,
            icon: "error",
          });
        }
      })
      .catch((err) => {});
  };

  return (
    <VatableSalesScreen
      //throw parameter or props.
      formData={formData}
      vatableSalesData={vatableSalesData}
      loading={loading}
      totalRow={totalRow}
      reloadTable={reloadTable}
      selectedTab={"vatable_sales_ledger"}
      handleSelectedTab={handleSelectedTab}
      handleChange={handleChange}
      handleSearch={handleSearch}
      handleDataTableChange={handleDataTableChange}
      handleExport={OnHandleExport}
    />
  );
};

export default VatableSales;
