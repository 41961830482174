import React, { useEffect, useState, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useForm from "../../../useForm";
import axiosInstance from "../../axiosInstance";
import Swal from "sweetalert2";

const PermissionAddPage = () => {
  const { id } = useParams();
  const [rolesData, setRolesData] = useState([]);
  const history = useHistory();
  /* Get All Roles */
  useEffect(() => {
    let componentMounted = true;

    const loadRoles = async () => {
      const res = await axiosInstance.get(`/role`, {
        method: "GET",
      });
      if (componentMounted) {
        setRolesData(res.data.response.result);
      }
    };

    loadRoles();
    return () => {
      componentMounted = false;
    };
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/permission/create", values, {
        method: "POST",
      })
      .then((res) =>
        res.data.response.code === "00003"
          ? Swal.fire({
              icon: "success",
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                /*  history.push("/permissions"); */
              }
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            })
      );
  };

  const [values, handleChange] = useForm({
    page_id: id,
    module_id: "",
    role_id: "",
    function_id: "",
  });
  return (
    <Fragment>
      <div className="col-md-10 offset-1">
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
          <div className="card">
            <div className="card-header">
              <h1>Add Permission</h1>
            </div>
            <div className="card-body">
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label>Page ID</label>

                  <input
                    className="form-control"
                    name="page_id"
                    type="text"
                    value={id}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label>Module ID</label>

                  <input
                    className="form-control"
                    name="module_id"
                    type="text"
                    value={values.module_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Role ID</label>

                  <input
                    className="form-control"
                    name="role_id"
                    type="text"
                    value={values.role_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Function ID</label>

                  <input
                    className="form-control"
                    name="function_id"
                    type="text"
                    value={values.function_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button className="btn btn-secondary mt-2" type="submit">
                  Add
                </button>
                <Link to="/user-control">
                  <button
                    className="btn btn-primary mt-2"
                    style={{ marginLeft: "4px" }}
                  >
                    Cancel
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PermissionAddPage;
