import { lazy } from "react";

const JournalVoucher = lazy(() => import("../pages/JournalVoucher"));

const journalVoucherRoute = {
  public: [],
  auth: [
    {
      name: "journal_voucher",
      path: "/finance-and-accounting/journal-voucher/:status?",
      component: JournalVoucher,
    },
  ],
};

export default journalVoucherRoute;
