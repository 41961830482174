import makeApiRequest from './makeApiCall';

export const getProducts = async () => {
  return await makeApiRequest(`/products`, 'GET', null);
};

export const getProductSearch = async (data) => {
  return await makeApiRequest(`/products/search`, 'POST', null, data);
};

export const getProductBatch = async (data) => {
  return await makeApiRequest(`/products/batch/list`, 'POST', null, data);
};

export const getProductForecast = async (data) => {
  return await makeApiRequest(`/forecasting/show`, 'GET', null, data);
};

export const saveProductForecast = async (data) => {
  return await makeApiRequest(`/forecasting/add`, 'POST', null, data);
};

export const saveProductPurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/createpurchaserequest`,
    'POST',
    null,
    data,
    true,
  );
};

export const getForecastPurchaseOrderTrailLogs = async (po_hdr_id) => {
  return await makeApiRequest(`/v3/purchase/order/log/trails`, 'GET', null, {
    po_hdr_id,
  });
};

export const getPurchaseLogs = async (data) => {
  return await makeApiRequest(`/products/purchase-logs`, 'GET', null, data);
};

export const postAddPurchaseLogs = async (data) => {
  return await makeApiRequest(
    `/products/purchase-logs/add`,
    'POST',
    null,
    data,
  );
};

export const getUnitofMeasure = async (data) => {
  return await makeApiRequest(`/products/unit`, 'GET', null, data);
};

export const getProductbyid = async (data) => {
  return await makeApiRequest(`/products/by/id`, 'GET', null, data);
};

export const getProductUnitOfMeasurement = async () => {
  return await makeApiRequest(`/products/unit`, 'GET');
};
