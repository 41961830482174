import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { PencilSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export const zoneCol = ({ handleEdit }) => [
  {
    field: "zone_name",
    headerName: "Zone",
    minWidth: 500,
    renderCell: (data) => {
      return (
        <Fragment>
          <input
            type="color"
            value={data.row.color_code}
            name="color_code"
            style={{ width: "30px", padding: "0", border: "0" }}
            disabled
          />
          <div className="ml-3">{data.row.zone_name}</div>
        </Fragment>
      );
    },
  },

  {
    field: "warehouse",
    headerName: "Warehouse",
    minWidth: 500,
    renderCell: (data) => {
      if (data.row.warehouse !== null) {
        return <span>{data.row.warehouse.warehouse_name}</span>;
      } else {
        return "";
      }
    },
  },
  {
    field: "floor",
    headerName: "Floor",
    minWidth: 350,
    renderCell: (data) => {
      if (data.row.floor !== null) {
        return <span>{data.row.floor.floor}</span>;
      } else {
        return "";
      }
    },
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    renderCell: (data) => {
      return (
        <div className="btn-action-buttons">
          <Link
            className="btn btn-link btn-sm btn-action"
            to="/warehouse/zone-type"
            onClick={() => handleEdit(data.row)}
          >
            <Tooltip title="Edit Warehouse Zone">
              <PencilSquare />
            </Tooltip>
          </Link>
        </div>
      );
    },
  },
];
