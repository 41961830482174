//import { Banks, Branches, Accounts } from "../pages/Banks/components";
import { lazy } from "react";

const Banks = lazy(() =>
  import("../pages/Banks/components/index.js").then((module) => ({
    default: module.Banks,
  }))
);

const Branches = lazy(() =>
  import("../pages/Banks/components/index.js").then((module) => ({
    default: module.Branches,
  }))
);

const Accounts = lazy(() =>
  import("../pages/Banks/components/index.js").then((module) => ({
    default: module.Accounts,
  }))
);

const courierRoute = {
  public: [],
  auth: [
    {
      name: "banks_maintenance",
      path: "/banks-maintenance",
      component: Banks,
    },
    {
      name: "banks_maintenance",
      path: "/banks-maintenance/:id",
      component: Banks,
    },
    {
      name: "bank_branches",
      path: "/banks-maintenance/branches/:id",
      component: Branches,
    },
    {
      name: "bank_account",
      path: "/banks-maintenance/accounts/:id",
      component: Accounts,
    },
  ],
};

export default courierRoute;
