import { useEffect, useState, Fragment } from 'react';

import DropdownCustom from './DropdownCustom';
import CustomerShippingAddresses from '../ViewCustomerShippingAddresses';

import { getCourier } from '../../../../../api/customer';
import PrimaryToolTip from '../PrimaryToolTip';
import CustomerDeliveryTime from '../CustomerDeliveryTime';

// const days = [
//   {
//     day_id: "1",
//     day: "Mon",
//   },
//   {
//     day_id: "2",
//     day: "Tue",
//   },
//   {
//     day_id: "3",
//     day: "Wed",
//   },
//   {
//     day_id: "4",
//     day: "Thu",
//   },
//   {
//     day_id: "5",
//     day: "Fri",
//   },
//   {
//     day_id: "6",
//     day: "Sat",
//   },
//   {
//     day_id: "7",
//     day: "Sun",
//   },
// ];

const Shipping = ({
  styles,
  customer,
  form,
  handleChange,
  onChangeAddress,
  onAddAddress,
  onRemoveAddress,
  handleShippingScheduleDay,
}) => {
  //let shipping = customer.shipping[0];

  const [deliveryProvider, setDeliveryProvider] = useState([]);

  useEffect(() => {
    loadDeliveryProvider();
  }, []);

  const loadDeliveryProvider = () => {
    getCourier().then((res) => {
      if (res.data.response.code === '00000') {
        setDeliveryProvider(res.data.response.result);
      }
    });
  };

  return (
    <Fragment>
      <fieldset className="details-field-set mb-4 mt-0">
        <legend>Shipping</legend>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <CustomerDeliveryTime values={form} handleChange={handleChange} />
            </div>
            <div className="col-md-6">
              {/*  */}
              <div className="row mt-2">
                <div className="col-lg-4">
                  <label className="col-form-label col-form-label-sm">
                    Dist. Service Provider
                  </label>
                  <PrimaryToolTip />
                </div>
                <div className="col-lg-6">
                  <DropdownCustom
                    data={deliveryProvider}
                    name={'shipping_delivery_service_provider'}
                    valueField={'courier_code'}
                    nameField={'company_name'}
                    selected={form.shipping_delivery_service_provider}
                    placeholder={'- Select Service Provider -'}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </fieldset>
      <CustomerShippingAddresses
        data={form.shipping_addresses}
        onChangeAddress={onChangeAddress}
      />
    </Fragment>
  );
};

export default Shipping;
