import axios from "axios";
import { store } from "../redux/store";
//const token = JSON.parse(localStorage.getItem("token"));

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_END_POINT,
  headers: { accept: "application/json" },
});

// intercept all endpoint and add authorization token
axiosInstance.interceptors.request.use(function (config) {
  // set authorization token from store
  const { accessToken } = store.getState().auth;
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // 403 expired token
    // 401 unauthorized
    if (error.response.status === 403 || error.response.status === 401) {
      window.location.href = "/unauthenticated";
    }

    return;
  }
);

export default axiosInstance;
