import { Fragment } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import _ from "lodash";

const DetailModal = ({ toggle, closeModal, data }) => {
  const renderSignature = () => {
    if (_.isEmpty(data.get_signature)) return null;

    return (
      <Fragment>
        <dt class="col-sm-4">Signature</dt>
        <dd class="col-sm-8">
          <a
            href={data.get_signature.processed_path}
            target="_blank"
            rel="noreferrer"
          >
            Attachment
          </a>
        </dd>
      </Fragment>
    );
  };

  return (
    <Modal
      show={toggle}
      onHide={closeModal}
      hidin
      backdrop="static"
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Footprint</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!_.isEmpty(data) ? (
          <Fragment>
            <dl class="row">
              <dt class="col-sm-4">Date</dt>
              <dd class="col-sm-8">{data.date_created}</dd>
              {renderSignature()}
            </dl>
            <Alert variant="warning">{data.note}</Alert>
          </Fragment>
        ) : (
          <Alert variant="warning">No Data.</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={closeModal}
          style={{ width: "100px" }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailModal;
