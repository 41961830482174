import General from '../pages/Ledgers/General';

const generalLedgerRoute = {
  public: [],
  auth: [
    {
      name: 'general_ledger',
      path: '/finance-and-accounting/general-ledger',
      component: General,
    },
  ],
};

export default generalLedgerRoute;
