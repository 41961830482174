import makeApiRequest from './makeApiCall';

export const getCollections = async (params) => {
  return await makeApiRequest(
    `/collections/list_of_collections`,
    'POST',
    null,
    params,
    false,
  );
};

export const getChod = async (params) => {
  return await makeApiRequest(
    `/collections/list_of_chod`,
    'POST',
    null,
    params,
    false,
  );
};

export const getDebitMemo = async (params) => {
  return await makeApiRequest(
    `/collections/list_of_dm`,
    'POST',
    null,
    params,
    false,
  );
};

export const getRetDocs = async (params) => {
  return await makeApiRequest(
    `/adminRetDocs`,
    'GET',
    null,
    null,
    false,
    `http://localhost:4001`,
  );
};

export const uploadDepositSlip = async (data, withFiles) => {
  return await makeApiRequest(
    `collections/upload_deposit_slip`,
    'POST',
    null,
    data,
    withFiles,
  );
};

export const getAllBanks = async (data) => {
  return await makeApiRequest(`/collections/all_banks`, 'GET', null, data);
  // return await makeApiRequest(
  //   `/collections/all_banks`,
  //   "GET",
  //   null,
  //   null,
  //   data
  // );
};

export const getDeductionTypes = async () => {
  return await makeApiRequest(
    `/collections/payment_application/get_deduction_types`,
    'GET',
    null,
    null,
    false,
  );
};

export const getDeductionRemarks = async () => {
  return await makeApiRequest(
    `/collections/payment_application/get_deduction_remarks`,
    'GET',
    null,
    null,
    false,
  );
};

export const getCollectionBanks = async () => {
  return await makeApiRequest(
    `/collections/all_banks`,
    'GET',
    null,
    null,
    false,
  );
};

export const getRemittanceMode = async () => {
  return await makeApiRequest(
    `/collections/all_remittance_modes`,
    'GET',
    null,
    null,
    false,
  );
};

export const getOrNo = async () => {
  return await makeApiRequest(
    `/collections/payment_application/get_reference_numbers/or`,
    'GET',
    null,
    null,
    false,
  );
};

export const savePaymentApplication = async (data) => {
  return await makeApiRequest(
    `/collections/payment_application`,
    'POST',
    null,
    data,
    true,
  );
};

export const updatePaymentApplication = async (data) => {
  return await makeApiRequest(
    `update_payment_details`,
    'POST',
    null,
    data,
    true,
  );
};

export const getPaymentApplication = async (orNo) => {
  return await makeApiRequest(`/get_payment_details/` + orNo, 'GET', null);
};

export const getPaymentApplicationList = async () => {
  return await makeApiRequest(`/get_payment_listings`, 'GET', null);
};

export const getCustomerAR = async (params) => {
  return await makeApiRequest(
    `/collections/get_ars/` + params,
    'GET',
    null,
    null,
    false,
  );
};

export const getCustomersInfoAndOutstandingBalance = async (data) => {
  return await makeApiRequest(`/collections/customer_lists`, 'GET', null, data);
};

export const getPdcStatus = async (id) => {
  return await makeApiRequest(
    `/collections/collection_details_pdc_status/` + id,
    'GET',
    null,
    null,
    false,
  );
};

export const savePdcPayment = async (params, id) => {
  return await makeApiRequest(
    `/collections/post_collection_details_pdc_status/` + id,
    'POST',
    null,
    params,
    id,
    false,
  );
};

export const getCustomerCollectionDetails = async (id) => {
  return await makeApiRequest(
    `/collections/collection_details/` + id,
    'GET',
    null,
    null,
    false,
  );
};

export const getCustomerCollectionDetailsAR = async (id) => {
  return await makeApiRequest(
    `/collections/collection_details_ar/` + id,
    'GET',
  );
};

// customer summary
export const getCustomerSummaryTotals = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/gettotal`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryOutstandingBalance = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/outstanding`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryCheckDetails = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/check`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryAdvances = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/advances`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerStockReturn = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/returns`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryEWT = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/ewt`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryTransactionHistory = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/transactionhistory`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const getCustomerSummaryTransactionHistoryDetails = async (
  customer_code,
) => {
  return await makeApiRequest(
    `/collections/customer/transaction/per/si`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

// footprint tracing
export const getFootprintTracing = async (customer_code) => {
  return await makeApiRequest(
    `/collections/customer/footprint`,
    'GET',
    null,
    { customer_code },
    false,
  );
};

export const postFootprintTracing = async (form, withFiles) => {
  return await makeApiRequest(
    `/collections/customer/footprint/create`,
    'POST',
    null,
    form,
    withFiles,
  );
};

export const deleteFootprintTracing = async (ids) => {
  return await makeApiRequest(
    `/collections/customer/footprint/delete`,
    'POST',
    null,
    { footprint_id: ids },
  );
};

// Customer Dashboard
export const getCustomerDashboard = async (params) => {
  return await makeApiRequest(
    `/collections/dashboard`,
    'POST',
    null,
    params,
    false,
  );
};

export const validateUniqueCrNo = async (cr_no, or_no = '') => {
  return await makeApiRequest(`/collections/cr/is-exist`, 'GET', null, {
    cr_no,
    or_no,
  });
};
export const validateUniquePrNo = async (pr_no, or_no = '') => {
  return await makeApiRequest(`/collections/pr/is-exist`, 'GET', null, {
    pr_no,
    or_no,
  });
};

export const getBranchBank = async (id) => {
  return await makeApiRequest(
    `/collections/bank-branches/` + id,
    'GET',
    null,
    null,
    false,
  );
};

export const postBranchBank = async (data) => {
  return await makeApiRequest(
    `/collections/bank-branches`,
    'POST',
    null,
    data,
    false,
  );
};

export const getBKBankAccounts = async (bank_id) => {
  return await makeApiRequest(
    `/treasury/select/bank/account`,
    'GET',
    null,
    { bank_id },
    false,
  );
};

export const requestExportList = async (data) => {
  return await makeApiRequest(`/collections/list/export`, 'GET', null, data);
};

export const getCustomerAdvancesReport = async (data) => {
  return await makeApiRequest(`/advances/get`, 'POST', null, data);
};

export const exportCustomerAdvancesReport = async (data) => {
  return await makeApiRequest(`/advances/export`, 'POST', null, data);
};

export const getAllBankDepository = async (data) => {
  return await makeApiRequest(
    `/collections/bank-depository`,
    'GET',
    null,
    data,
  );
  // return await makeApiRequest(
  //   `/collections/all_banks`,
  //   "GET",
  //   null,
  //   null,
  //   data
  // );
};

export const getAllDMBankDepository = async (data) => {
  return await makeApiRequest(
    `/collections/dm-bank-depository`,
    'GET',
    null,
    data,
  );
  // return await makeApiRequest(
  //   `/collections/all_banks`,
  //   "GET",
  //   null,
  //   null,
  //   data
  // );
};

export const getAllBankBranchDepository = async (data) => {
  return await makeApiRequest(
    `/collections/bank-branches-depository`,
    'GET',
    null,
    data,
    false,
  );
};

export const getBankBranches = async (id) => {
  return await makeApiRequest(
    `/collections/bank-branches-dropdown/` + id,
    'GET',
    null,
    null,
    false,
  );
};
