import { lazy } from "react";

const DealsAndDiscountLogsScreen = lazy(() =>
  import("../components/DealsAndDisountLogs/Screens/DealsAndDiscountLogsScreen")
);

// const StockVendors = lazy(() =>
//   import("../components/Vendor/pages/StockVendors")
// );

const DealsAndDiscountLogsRoute = {
  public: [],
  auth: [
    {
      name: "deals_logs",
      path: "/customers/deals-logs",
      component: DealsAndDiscountLogsScreen,
    },
    // {
    //   name: "vendor_info",
    //   path: "/vendors/:vendor_id",
    //   component: StockVendors,
    // },
  ],
};

export default DealsAndDiscountLogsRoute;