import React from "react";
import Modal from "react-bootstrap/Modal";
const DetailsModal = ({
  toggle,
  data,
  sending,
  handleCloseModal,
  handleStatus,
  form,
}) => {
  return (
    <div>
      <Modal
        show={toggle}
        onHide={handleCloseModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data !== null ? (
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <p>Requestor : {data.requestor_name}</p>
                    <p>Date Requested : {data.date_requested}</p>
                    {/* <p>Detailed ID : {data.detail_id}</p> */}
                    <p>Reason : {data.reason}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={() => handleStatus(1)}>
            Approve
          </button>
          <button className="btn btn-danger" onClick={() => handleStatus(2)}>
            Disapprove
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DetailsModal;
