import makeApiRequest from './makeApiCall';

export const getPendingForReceive = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/pending-receive`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getReceived = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/received`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getPDC = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/pdc-on-hand`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getDeposited = async (params) => {
  return await makeApiRequest(`/treasury/deposited`, 'GET', null, params);
};

export const getClearedChecks = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/cleared-checks`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getReturned = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/returned`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getReDeposited = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/redeposited`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

//Customer OA list
export const getCustomerAO = async (params) => {
  return await makeApiRequest(
    `/treasury/directdeposit/unidentified/customer_ao`,
    'GET',
    null,
    params,
  );
};

export const getIdentifiedDirectDeposit = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/identified-direct-deposit`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getCNCRemittance = async (params, otherOptions) => {
  return await makeApiRequest(
    `/treasury/cnc-remittance`,
    'GET',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getDetail = async (collection_payment_details_id) => {
  return await makeApiRequest(
    `collection/treasury/view/details`,
    'GET',
    null,
    { collection_payment_details_id },
    false,
  );
};

// tag status
export const tagReceived = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/receive`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagDeposited = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/deposited`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagCleared = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/cleared`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagReturned = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/returned`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagRedeposit = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/redeposit`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagReturnToMaker = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/return-to-maker`,
    'POST',
    null,
    data,
    false,
  );
};

export const tagRemitted = async (data) => {
  return await makeApiRequest(
    `/treasury/tag/remitted`,
    'POST',
    null,
    data,
    false,
  );
};

// dropdown
export const getReturnedReasons = async () => {
  return await makeApiRequest(
    `/treasury/return/reason`,
    'GET',
    null,
    null,
    false,
  );
};

// changes
export const changeDepositDate = async (data, withFile) => {
  return await makeApiRequest(
    `/treasury/changes/change_of_deposit_date`,
    'POST',
    null,
    data,
    withFile,
  );
};

export const changeBank = async (data, withFile) => {
  return await makeApiRequest(
    `/treasury/changes/change_of_bank`,
    'POST',
    null,
    data,
    withFile,
  );
};

export const getApprover = async () => {
  return await makeApiRequest(
    `treasury/changes/for_approvals`,
    'GET',
    null,
    null,
    false,
  );
};

export const setStatus = async (params) => {
  return await makeApiRequest(
    `/treasury/changes/approved_disapproved`,
    'POST',
    null,
    params,
    null,
    false,
  );
};

export const getListOfApprovals = async (id) => {
  return await makeApiRequest(
    `/treasury/changes/get_approval/` + id,
    'GET',
    null,
    id,
    null,
    false,
  );
};

// export const getStatusList = async (id) => {
//   return await makeApiRequest(
//     `/treasury/changes/approved_disapproved/list/` + id,
//     "GET",
//     null,
//     id,
//     null,
//     false
//   );
// };

//jhay
export const getApprovedData = async (params) => {
  return await makeApiRequest(
    `/treasury/changes/approved/lists`,
    'GET',
    null,
    params,
  );
};

export const getDeclinedData = async (params) => {
  return await makeApiRequest(
    `/treasury/changes/disapproved/lists`,
    'GET',
    null,
    params,
  );
};

export const getAllBanksAccounts = async (data) => {
  return await makeApiRequest(
    `/treasury/select/bank/account`,
    'GET',
    null,
    data,
  );
};

export const exportCollection = async (status, params = {}) => {
  return await makeApiRequest(
    `/treasury/export/${status}`,
    'GET',
    null,
    params,
  );
};

export const postCheckEditDetails = async (data) => {
  return await makeApiRequest(
    `/treasury/edit/check_bank_details`,
    'POST',
    null,
    data,
  );
};

export const postAwaitingConfirmation = async (params) => {
  return await makeApiRequest(
    `/treasury/awaiting_confirmation`,
    'POST',
    null,
    params,
    null,
    false,
  );
};

export const updatePulledOutStatus = async (data) => {
  return await makeApiRequest(`/treasury/returned/update`, 'PUT', null, data);
};

export const postCollectorName = async (data) => {
  return await makeApiRequest(
    `/treasury/directdeposit/unidentified/customer_ao/add`,
    'POST',
    null,
    data,
    null,
    false,
  );
};
