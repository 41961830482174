import { lazy } from "react";

const CheckVoucher = lazy(() => import("../pages/CheckVoucher"));

const checkVoucherRoute = {
  public: [],
  auth: [
    {
      name: "check_voucher",
      path: "/finance-and-accounting/check-voucher/:status?",
      component: CheckVoucher,
    },
  ],
};

export default checkVoucherRoute;
