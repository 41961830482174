import { lazy } from 'react';

const EWTSubledger = lazy(() => import('../pages/EWTSubledger'));

const ewtSubledgerRoute = {
  public: [],
  auth: [
    {
      name: 'ewt_subledger',
      path: '/finance-and-accounting/ewt-subledger',
      component: EWTSubledger,
    },
  ],
};

export default ewtSubledgerRoute;
