import React, { useState, useEffect, Fragment } from 'react';
import Swal from 'sweetalert2';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomerTransactionModal from '../Srpf/CustomerTransactionModal';
import CustomerDeclineReasonModal from '../Srpf/CustomerDeclineReasonModal';

import PrimaryDataTable from '../PrimaryDataTable';
import PrimaryLoader from '../PrimaryLoader';

import {
  getSRPF,
  approveSRPF,
  getSRPFOnProcess,
  exportPDF,
  approvalSRPF,
  splitBatch,
  srpfExport,
  srpfUploadAttachment,
} from '../../api/srpf';

import {
  AlertDefaultError,
  hasPermission,
  PermissionsGate,
} from '../PermissionsGate';

import { transactionDetails, onProcessColumn, tab_location } from './const';
import ExportModal from './ExportModal';

const OnProcessSRPF = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [onProcessSRPFData, setOnProcessSRPFData] = useState([]);
  const [onProcessSRPFDetails, setOnProcessSRPFDetails] =
    useState(transactionDetails);
  const [showExport, setShowExport] = useState({
    date_from: '',
    date_to: '',
    srpf_type: '',
    show: false,
    status: 0,
  });
  const [modalShow, setModalShow] = useState(false);
  const [decline, setDecline] = useState({
    srpf_no: '',
    reason: '',
  });

  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    loadOnProcessData();
  }, []);

  const loadOnProcessData = () => {
    setLoading(true);
    getSRPFOnProcess()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          const viewSrpfPermission = hasPermission(['view_srpf']);
          setOnProcessSRPFData(
            result.map((row) => ({ ...row, viewSrpfPermission })),
          );
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleShowTransactionModal = (id) => {
    getSRPF({
      srpf_id: id,
    })
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          setOnProcessSRPFDetails({
            ...onProcessSRPFDetails,
            ...{ ...result, documents_new: [] },
          });
          setShowTransaction(true);
        }
      })
      .catch((err) => {});
  };

  const handleExportPDFTransaction = (id, location) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to export this transaction?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Processing request please wait');
        exportPDF({
          srpf_id: id,
          location: location,
        })
          .then((res) => {
            let { code, result } = res.data.response;
            if (code === '00000') {
              window.open(result, '_blank', 'noopener,noreferrer');

              toast.dismiss();
              setPageLoader(false);
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleApprovalTransaction = (id, requestor_signature) => {
    if (onProcessSRPFDetails.has_new_attachments) {
      Swal.fire({
        icon: 'warning',
        text: 'Attachment(s) not yet uploaded, Please click Upload Attachment.',
        allowOutsideClick: false,
      });

      return false;
    }

    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to proceed this transaction?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setPageLoader(true);
        toast.loading('Processing request please wait');
        approvalSRPF({
          srpf_id: id,
          documents_new: onProcessSRPFDetails.documents_new,
          requestor_signature: requestor_signature ?? '',
        })
          .then((res) => {
            let { code, result } = res.data.response;

            if (code === '00000') {
              Swal.fire({
                icon: 'success',
                title: 'Submitted successfully!',
                text: result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: result,
                showConfirmButton: true,
              }).then((result) => {});
            }
            toast.dismiss();
            setPageLoader(false);
          })
          .catch((err) => {});
      }
    });
  };

  const handleApproveTransaction = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to approve this transaction?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setPageLoader(true);
        toast.loading('Processing request please wait');

        approveSRPF({
          srpf_id: id,
          documents_new: onProcessSRPFDetails.documents_new,
        })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Submitted successfully!',
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

            toast.dismiss();
            setPageLoader(false);
          })
          .catch((err) => {});
      }
    });
  };

  const handleDeclinedTransaction = (srpf_no) => {
    Swal.fire({
      title: 'Decline Transaction',
      text: 'Are you sure you want to decline this transaction?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3545',
    }).then((result) => {
      if (result.isConfirmed) {
        setDecline({ ...decline, srpf_no: srpf_no });
        setModalShow(true);
      }
    });
  };

  const handleConfirmReturn = () => {
    splitBatch(decline)
      .then((res) => {
        if (res.data.response.code === '00000') {
          Swal.fire({
            icon: 'success',
            title: 'Submitted successfully!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops Something went Wrong!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            // if (result.isConfirmed) {
            //   window.location.reload();
            // }
          });
        }
      })
      .catch((err) => {});
  };

  const handleTabLocation = (key) => tab_location[key];

  const handleCloseTransactionModal = () => setShowTransaction(false);

  const handleChangeReason = (e) =>
    setDecline({ ...decline, reason: e.target.value });

  const handleSplitBatching = (id) => {
    Swal.fire({
      title: 'Return To Maker',
      text: 'Are you sure you want to return this transaction to maker?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalShow(true);
        setDecline({ ...decline, srpf_no: id });
      }
    });
  };

  const handleFileChange = (event) => {
    console.log(event.target.files.length > 0);
    setOnProcessSRPFDetails({
      ...onProcessSRPFDetails,
      documents_new: [...event.target.files],
      has_new_attachments: event.target.files.length > 0,
    });
  };

  const handleUploadFile = (params) => {
    srpfUploadAttachment(params)
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          toast.success(result);
        }

        setOnProcessSRPFDetails({
          ...onProcessSRPFDetails,
          reset_attachment: onProcessSRPFDetails.reset_attachment === 1 ? 0 : 1,
          has_new_attachments: false,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.data.response.result,
        });
      });
  };

  const handleViewExportModal = () =>
    setShowExport({ ...showExport, show: true });

  const handleExport = (e) => {
    toast.loading('Exporting Data...');
    srpfExport(showExport)
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          window.open(result, '_blank').focus();
          toast.dismiss();
        }
      })
      .catch((err) => {
        toast.loading(
          'Something went wrong while exporting, Please try again.',
        );
      });
  };

  return (
    <Fragment>
      <PrimaryLoader visible={pageLoader} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
            RenderError={AlertDefaultError}
          >
            <Tabs
              className="default-tab"
              activeKey="onprocess"
              onSelect={(k, event) => {
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab eventKey="add_new" title="Add New"></Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="onprocess"
                  title="On-Process SRPF"
                >
                  <div className="mt-2 mb-2">
                    <div className="text-right">
                      <Button
                        type="button"
                        variant="info"
                        onClick={handleViewExportModal}
                        className="mb-2"
                      >
                        Export
                      </Button>
                      {/* <Dropdown className="d-inline-block mb-3">
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            Export
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleExport}>
                              Download as XLSX
                            </Dropdown.Item>
                          </Dropdown.Menu>{' '}
                        </Dropdown> */}
                    </div>
                    <PrimaryDataTable
                      loading={loading}
                      rows={onProcessSRPFData}
                      columns={onProcessColumn({
                        handleShowTransactionModal,
                      })}
                      getRowId={(row) => row.srpf_approval_id}
                      columnVisibilityModel={{
                        reason: false,
                      }}
                      getCellClassName={({ row }) =>
                        row.reject_reason !== null ||
                        row.reject_reason !== undefined
                          ? row.reject_reason.length > 0
                            ? 'bg-green-lt'
                            : ''
                          : ''
                      }
                    />
                  </div>
                </Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab eventKey="approval" title="For Approval"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="approved" title="Approved SRPF"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="declined" title="Declined SRPF"></Tab>
              )}
              {hasPermission(['srpf_returned_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab eventKey="not_yet_received" title="Not yet Received"></Tab>
              )}
              {/* {hasPermission(["srpf_declined_list"]) && (
                <Tab eventKey="declined" title="Approved RTS(WAREHOUSE)"></Tab>
              )} */}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>

      <CustomerTransactionModal
        srpfDetails={onProcessSRPFDetails}
        showTransactionModal={showTransaction}
        handleCloseTransactionModal={handleCloseTransactionModal}
        showApprovalButton={true}
        showApprovedButton={false}
        showDeclinedButton={false}
        showSplitButton={true}
        handleExportPDFTransaction={handleExportPDFTransaction}
        handleApprovalTransaction={handleApprovalTransaction}
        handleApproveTransaction={handleApproveTransaction}
        handleDeclinedTransaction={handleDeclinedTransaction}
        handleSplitBatching={handleSplitBatching}
        handleFileChange={handleFileChange}
        handleUploadFile={handleUploadFile}
        pageLoader={pageLoader}
        showExportPDF={{ status: true, location: 'onprocess' }}
      />
      {/*  */}
      <CustomerDeclineReasonModal
        show={modalShow}
        setModalShow={setModalShow}
        handleConfirmDecline={handleConfirmReturn}
        handleChange={handleChangeReason}
      />
      {/*  */}
      <ExportModal
        show={showExport}
        setModalShow={() =>
          setShowExport((prevState) => ({ ...prevState, show: false }))
        }
        handleChange={(e) =>
          setShowExport((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
        }
        handleConfirmReturn={handleExport}
      />
    </Fragment>
  );
};

export default OnProcessSRPF;
