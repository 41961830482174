import makeApiRequest from './makeApiCall';

export const getWarehouses = async () => {
  return await makeApiRequest(`/warehouses`, 'GET', null);
};

export const getWarehousesFloor = async (data) => {
  return await makeApiRequest(`/warehouse/pallet`, 'POST', null, data);
};

export const getFloors = async (warehouse_id = false) => {
  return await makeApiRequest(`/warehouse/floor`, 'POST', null, {
    warehouse_id,
  });
};

export const getFloorsByCode = async (warehouse_code = false) => {
  return await makeApiRequest(`/warehouse/code`, 'POST', null, {
    warehouse_code,
  });
};

export const getFloorZone = async ({ warehouse_id, floor_id }) => {
  return await makeApiRequest(`/warehouse/floor/zone`, 'POST', null, {
    warehouse_id,
    floor_id,
  });
};

export const getFloorZonePallet = async ({
  warehouse_id,
  floor_id,
  zone_id,
}) => {
  return await makeApiRequest(
    `/warehouse/pallet/assignment/distinct/options`,
    'POST',
    null,
    {
      warehouse_id,
      floor_id,
      zone_id,
    },
  );
};

export const getProductBatches = async (product_id) => {
  return await makeApiRequest(
    `/warehouse/product/batch/distinct/options`,
    'POST',
    null,
    { product_id },
  );
};

export const getSuppliers = async () => {
  return await makeApiRequest(`/vendor-for-warehouse`, 'GET', null, null);
};

export const getPalletAssignmentList = async (data) => {
  return await makeApiRequest(
    `/warehouse/pallet/assignment/transfer3`,
    'POST',
    null,
    data,
  );
};

export const exportPalletAssignmentList = async (data) => {
  return await makeApiRequest(
    `warehouse/pallet/assignment/transfer/export`,
    'POST',
    null,
    data,
  );
};

export const getZoneType = async () => {
  return await makeApiRequest(`/v2/warehouse/zones`, 'GET', null);
};
