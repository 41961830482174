import { Tooltip } from '@material-ui/core';
import { Tags, TagsFill } from 'react-bootstrap-icons';
import { numberFormatCurrency } from '../../utils/helper';
import { Button } from 'react-bootstrap';

export const trialBalanceDataCol = ({ onClickAccountCode }) => [
  {
    field: 'account_code',
    headerName: 'Account Code',
    width: 150,
    sortable: true,
    renderCell: ({ row }) => {
      return (
        <Button
          variant="link"
          style={{ padding: 0 }}
          className="text-blue"
          onClick={() => onClickAccountCode(row)}
        >
          {row.account_code}
        </Button>
      );
    },
  },
  {
    field: 'account_name',
    headerName: 'Account Name',
    width: 150,
    sortable: true,
  },
  {
    field: 'account_fs_group',
    headerName: 'FS Group',
    width: 150,
    sortable: true,
  },
  {
    field: 'account_group',
    headerName: 'Group',
    width: 150,
    sortable: true,
  },
  {
    field: 'account_sub_group',
    headerName: 'Subgroup',
    width: 150,
    sortable: true,
  },
  {
    field: 'account_line_item',
    headerName: 'Line Item',
    width: 150,
    sortable: true,
  },
  {
    field: 'account_sub_line_item',
    headerName: 'Sub Line Item',
    width: 150,
    sortable: true,
  },
  {
    field: 'beginning_balance',
    headerName: 'Beginning Balance',
    width: 200,
    sortable: true,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.beginning_balance, '');
    },
  },
  {
    field: 'debit',
    headerName: 'Debit',
    width: 200,
    type: 'number',
    sortable: true,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.debit, '');
    },
  },
  {
    field: 'credit',
    headerName: 'Credit',
    width: 200,
    type: 'number',
    sortable: true,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.credit, '');
    },
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 200,
    type: 'number',
    sortable: true,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.balance, '');
    },
  },
];
