import { Fragment } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CustomerDeclineReasonModal = ({
  show,
  setModalShow,
  handleConfirmDecline,
  handleChange,
}) => {
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reason for Declining
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control as="textarea" rows={3} onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => handleConfirmDecline()}
          >
            Confirm
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CustomerDeclineReasonModal;
