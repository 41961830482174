import { useEffect, useState } from "react";
import { Form, Modal, Row, Col, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const defaultCredentials = {
  username: "htpass",
  password: "htpass123",
};

const defaultForm = { username: "", password: "" };

const cookieMaxAge = 60 * (60 * 5);

const ModalHtpass = () => {
  const cookies = new Cookies();

  const [show, setShow] = useState(false);
  const [form, setForm] = useState(defaultForm);

  useEffect(() => triggerShow(), []);

  const triggerShow = () => {
    let origin = window.location.origin;
    const url = new URL(origin);

    if (url?.hostname === "bkclouderp.com" && url?.port === "") {
      if (!cookies.get("htpass")) {
        setTimeout(() => setShow(true), 500);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      form.username === defaultCredentials.username &&
      form.password === defaultCredentials.password
    ) {
      setShow(false);
      cookies.set("htpass", 1, { path: "/", maxAge: cookieMaxAge });

      //handleCheck();
    } else {
      Swal.fire({
        title: "Authentication",
        text: "Incorrect Credentials.",
        icon: "error",
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setForm({ ...form, ...defaultForm });
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Authentication Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="small">
          {window.location.origin} requires a username and password
          <br />
          Your connection to this site is not private
        </p>
        <Form onSubmit={handleSubmit} id="form_htpass">
          <Form.Group as={Row} className={"mb-3"}>
            <Form.Label column md="3">
              Username
            </Form.Label>
            <Col md="9">
              <Form.Control
                type="text"
                placeholder="Username"
                name="username"
                value={form.username}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={"mb-3"}>
            <Form.Label column md="3">
              Password
            </Form.Label>
            <Col md="9">
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={form.password}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <div className="text-right"></div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          onClick={handleSubmit}
          form="form_htpass"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHtpass;
