import { lazy } from "react";

const ProgramProject = lazy(() =>
  import("../pages/ProgramProject/index.js").then((module) => ({
    default: module.ProgramProject,
  }))
);

const programProjectRoute = {
  public: [],
  auth: [
    {
      name: "program_project1",
      path: "/finance-and-accounting/program-project",
      component: ProgramProject,
    },
  ],
};

export default programProjectRoute;
