import { Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import AddSrpf from '../Srpf/AddSrpf';
import useNavActive from '../Navbar/useNavActive';

import {
  AlertDefaultError,
  hasPermission,
  PermissionsGate,
} from '../PermissionsGate';
import { tab_location } from './const';

const Srpf = ({ editMode, srpfNo }) => {
  const { customer_code } = useParams();

  const history = useHistory();

  useNavActive(['credit_and_collections', 'cc_sales_return_pull_out']);

  const handleTabLocation = (key) => tab_location[key];

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
            RenderError={AlertDefaultError}
          >
            <Tabs
              className="default-tab"
              activeKey="add_new"
              onSelect={(k) => {
                if (editMode && k === 'add_new') return false;
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="add_new"
                  title={editMode ? 'Edit SRPF' : 'Add New'}
                >
                  <AddSrpf
                    editMode={editMode}
                    customerCode={customer_code}
                    srpfNo={srpfNo}
                  />
                </Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab eventKey="onprocess" title="On-Process SRPF"></Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab eventKey="approval" title="For Approval"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="approved" title="Approved SRPF"></Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab eventKey="declined" title="Declined SRPF"></Tab>
              )}
              {hasPermission(['srpf_returned_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab eventKey="not_yet_received" title="Not yet Received"></Tab>
              )}
              {/* {hasPermission(["srpf_declined_list"]) && (
                <Tab eventKey="declined" title="Approved RTS(WAREHOUSE)"></Tab>
              )} */}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>
    </Fragment>
  );
};

export default Srpf;
