import { Fragment, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import {
  DropdownProvince,
  DropdownCities,
  DropdownBarangays,
  DropdownZipCodes,
} from '../../../Addresses';
import PrimaryToolTip from './PrimaryToolTip';

const styles = {
  border: {
    border: '1px solid #e7eaef',
  },
  divPadding: {
    padding: '5px',
  },
  fieldset: {
    border: '1px solid #e7eaef',
    padding: '-4px',
  },
  legend: {
    position: 'static',
    marginTop: '-20px',
    top: 1,
    fontSize: '13px',
    lineHeight: 1,
    margin: '-9px 0 0' /* half of font-size */,
    background: '#fff',
    color: '#000',
  },

  formGroup: {
    margin: 0,
    paddingTop: '0px',
    paddingLeft: '20px',
    paddingBottom: '0px',
    paddingRight: '0',
  },
};

const defaultShippingAddressForm = {
  customer_shipping_address_id: '',
  shipping_bldg_street_address: '',
  shipping_barangay: '',
  shipping_city: '',
  shipping_province: '',
  shipping_zip_code: '',
  shipping_lba_code: '',
  shipping_lba_name: '',
  shipping_district: '',
};

const CustomerShippingAddresses = ({ data, onChangeAddress }) => {
  const [shippingAddresses, setShippingAddresses] = useState([
    defaultShippingAddressForm,
  ]);

  useEffect(() => {
    setShippingAddresses(data);
  }, [data]);

  useEffect(() => {
    const timeOutId = setTimeout(() => onChangeAddress(shippingAddresses), 500);
    return () => clearTimeout(timeOutId);
  }, [shippingAddresses]);

  const handleAddAddress = () => {
    setShippingAddresses([...shippingAddresses, defaultShippingAddressForm]);
  };

  const handleDeleteAddress = (selectedIndex) => {
    const newAddresses = shippingAddresses.filter((address, index) => {
      return selectedIndex !== index;
    });

    setShippingAddresses(newAddresses);
  };

  const handleChangeAddress = (selectedIndex, name, value) => {
    const newShippingAddresses = shippingAddresses.map((address, index) => {
      if (index === selectedIndex) {
        return { ...address, [name]: value };
      }

      return address;
    });

    setShippingAddresses(newShippingAddresses);
  };

  const rowAddress = (rowData, index) => {
    return (
      <section className="mb-3" key={index}>
        <fieldset className="details-field-set mb-4 mt-0">
          <legend>Shipping Addresses</legend>
          <div>
            <button
              className="btn btn-danger btn-sm mb-3"
              onClick={() => handleDeleteAddress(index)}
              type="button"
              key={index}
            >
              Delete Shipping Address
            </button>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/*  */}
              <div className="col-lg-2">
                <label className="col-form-label col-form-label-sm">
                  Bldg & Street Address
                  <span className="requiredField"> *</span>
                </label>
                <PrimaryToolTip />
              </div>
              <div className="col-lg-9">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name={
                    'shipping_addresses' +
                    '[' +
                    [index] +
                    ']' +
                    '[shipping_bldg_street_address]'
                  }
                  //defaultValue={rowData.shipping_bldg_street_address}
                  value={rowData.shipping_bldg_street_address}
                  id="shipping_bldg_street_address"
                  onChange={(e) => {
                    //console.log({ value: e.target.value });
                    handleChangeAddress(
                      index,
                      'shipping_bldg_street_address',
                      e.target.value,
                    );
                  }}
                />
              </div>
            </div>
            {/*  */}
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Province
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownProvince
                      classWrapper="form-control form-control-sm"
                      value={rowData.shipping_province}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_province]'
                      }
                      onChange={(value) =>
                        handleChangeAddress(index, 'shipping_province', value)
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      City
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownCities
                      classWrapper={'form-control form-control-sm'}
                      value={rowData.shipping_city}
                      provinceId={rowData.shipping_province}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_city]'
                      }
                      onChange={(value) =>
                        handleChangeAddress(index, 'shipping_city', value)
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Barangay
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownBarangays
                      classWrapper={'form-control form-control-sm'}
                      value={rowData.shipping_barangay}
                      cityId={rowData.shipping_city}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_barangay]'
                      }
                      onChange={(value) =>
                        handleChangeAddress(index, 'shipping_barangay', value)
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Zip Code
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownZipCodes
                      classWrapper={'form-control form-control-sm'}
                      value={rowData.shipping_zip_code}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_zip_code]'
                      }
                      cityId={rowData.shipping_city}
                      onChange={(value) =>
                        handleChangeAddress(index, 'shipping_zip_code', value)
                      }
                    />
                  </div>
                </div>
                {/*  */}
              </div>

              <div className="col-md-6">
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      LBA Code
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={rowData.shipping_lba_code}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_lba_code]'
                      }
                      id="shipping_lba_code"
                      onChange={(e) =>
                        handleChangeAddress(
                          index,
                          'shipping_lba_code',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      LBA Name
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={rowData.shipping_lba_name}
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_lba_name]'
                      }
                      id="shipping_lba_name"
                      onChange={(e) =>
                        handleChangeAddress(
                          index,
                          'shipping_lba_name',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      District
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name={
                        'shipping_addresses' +
                        '[' +
                        [index] +
                        ']' +
                        '[shipping_district]'
                      }
                      id="shipping_district"
                      value={rowData.shipping_district}
                      onChange={(e) =>
                        handleChangeAddress(
                          index,
                          'shipping_district',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </fieldset>
      </section>
    );
  };

  const renderAddresses = () => {
    return shippingAddresses.map((value, index) => rowAddress(value, index));
  };

  return (
    <Fragment>
      {renderAddresses()}
      <div className="col-sm-12 d-flex justify-content-center mb-2">
        <button
          type="button"
          className="btn btn-info"
          onClick={handleAddAddress}
        >
          Add Another Shipping Address
        </button>
      </div>
    </Fragment>
  );
};

export default CustomerShippingAddresses;
