import NavbarNotification from "../components/NavbarNotification";

const systemNotificationRoute = {
  public: [],
  auth: [
    {
      name: "system-notification",
      path: "/notification/:customer_code",
      component: NavbarNotification,
    },
  ],
};

export default systemNotificationRoute;
