import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-bootstrap';
import makeApiRequest from '../../../../api/makeApiCall';

const CustomerDocuments = (props) => {
  const [bcaf, setBcaf] = useState({});
  const [prcid, setPrcid] = useState({});
  const [other_gov_id, setOtherGovId] = useState({});
  const [cert_reg, setCertReg] = useState({});
  const [lto, setLto] = useState({});
  const [bus_permit, setBusPermit] = useState({});
  const [gis, setMayorsPermit] = useState({});
  const [photo_est, setPhotoEst] = useState({});
  const [other_docs, setOtherDocs] = useState({});

  const getCustomerDocumentsOthers = async (data, files = null) => {
    let customer_code = props.hasOwnProperty('customer') ? props.customer : '';

    return await makeApiRequest(
      `/customer/${customer_code}`,
      `GET`,
      null,
      data,
      files,
    )
      .then((res) => {
        if (res.data.response.code === '00000') {
          setOtherDocs(res.data.response.result.customer_documents_others);
        }
      })
      .catch((err) => {});
  };

  const getCustomerDocuments = async (data, files = null) => {
    let customer_code = props.hasOwnProperty('customer') ? props.customer : '';

    return await makeApiRequest(
      `/customer/${customer_code}`,
      `GET`,
      null,
      data,
      files,
    )
      .then((res) => {
        res.data.response.result.customer_documents.map((value, key) => {
          if (value.doc_type === 'BCAF') {
            let bcaf = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setBcaf(bcaf);
          }

          if (value.doc_type === 'PRCID') {
            let prcid = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setPrcid(prcid);
          }

          if (value.doc_type === 'other_gov_id') {
            let other_gov_id = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setOtherGovId(other_gov_id);
          }

          if (value.doc_type === 'cert_reg') {
            let cert_reg = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setCertReg(cert_reg);
          }

          if (value.doc_type === 'lto') {
            let lto = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setLto(lto);
          }

          if (value.doc_type === 'bus_permit') {
            let bus_permit = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setBusPermit(bus_permit);
          }

          if (value.doc_type === 'gis') {
            let gis = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setMayorsPermit(gis);
          }

          if (value.doc_type === 'photo_est') {
            let photo_est = {
              customer_doc_id: value.customer_doc_id,
              doc_type: value.doc_type,
              file_name: value.file_name,
              path: value.path,
            };
            setPhotoEst(photo_est);
          }
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCustomerDocuments();
    getCustomerDocumentsOthers();
  }, []);

  const fileChangeHandler = (event) => {
    /*  */
    let x = props.customerProductDiscount;
    if (x.length < 1) {
      props.handleProductDiscount([
        ...props.customerProductDiscount,
        {
          customer_doc_id: event.target.getAttribute('docId'),
          doc_type: event.target.getAttribute('docType'),
          attachment: event.target.files[0],
        },
      ]);
    } else {
      if (locateIndex(event.target.getAttribute('docType'))[1]) {
        let key = locateIndex(event.target.getAttribute('docType'))[0];
        props.customerProductDiscount[key].attachment = event.target.files[0];
      } else {
        props.handleProductDiscount([
          ...props.customerProductDiscount,
          {
            customer_doc_id: event.target.getAttribute('docId'),
            doc_type: event.target.getAttribute('docType'),
            attachment: event.target.files[0],
          },
        ]);
      }
    }
  };

  const fileChangeHandlerOtherDocs = (event) => {
    /*  */
    let x = props.customerProductDiscount;
    if (x.length < 1) {
      props.handleProductDiscount([
        ...props.customerProductDiscount,
        {
          customer_doc_id: event.target.getAttribute('docId'),
          doc_type: event.target.getAttribute('docType'),
          attachment: event.target.files[0],
        },
      ]);
    } else {
      if (locateIndexOtherDocs(event.target.getAttribute('docId'))[1]) {
        let key = locateIndexOtherDocs(event.target.getAttribute('docType'))[0];
        props.customerProductDiscount[key].attachment = event.target.files[0];
      } else {
        props.handleProductDiscount([
          ...props.customerProductDiscount,
          {
            customer_doc_id: event.target.getAttribute('docId'),
            doc_type: event.target.getAttribute('docType'),
            attachment: event.target.files[0],
          },
        ]);
      }
    }
  };

  const locateIndex = (type) => {
    let x = props.customerProductDiscount;
    let index = 0;
    let is_found = false;
    x.map((value, key) => {
      if (type === x[key].doc_type) {
        index = key;
        is_found = true;
      }
    });
    return [index, is_found];
  };

  const locateIndexOtherDocs = (type) => {
    let x = props.customerProductDiscount;
    let index = 0;
    let is_found = false;
    x.map((value, key) => {
      if (type === x[key].docId) {
        index = key;
        is_found = true;
      }
    });
    return [index, is_found];
  };

  const addNewOtherDocs = () => {
    setOtherDocs([...other_docs, { row: '1' }]);
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Customer Documents</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 row">
                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>Audited Financial Statement</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="BCAF"
                      onChange={fileChangeHandler}
                      docId={bcaf.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a href={bcaf.path} target="_blank" rel="noreferrer">
                          Get Attachment {bcaf.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>
                      PRC ID (Front and back)
                      {String(props.formData.customer_type) === '4' ? (
                        <span className="req">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="PRCID"
                      onChange={fileChangeHandler}
                      docId={prcid.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a href={prcid.path} target="_blank" rel="noreferrer">
                          Get Attachment {prcid.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>Government ID</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="other_gov_id"
                      onChange={fileChangeHandler}
                      docId={other_gov_id.customer_doc_id}
                    />
                    <Form.Label>
                      <a
                        href={other_gov_id.path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Get Attachment {other_gov_id.file_name}
                      </a>
                    </Form.Label>
                  </Form.Group>
                </div>

                <div className="col-md-12 row">
                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>
                      Certificate of Registration (2303)
                      {String(props.formData.customer_type) !== '4' ? (
                        <span className="req">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="cert_reg"
                      onChange={fileChangeHandler}
                      docId={cert_reg.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a
                          href={cert_reg.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Attachment {cert_reg.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>
                      License to Operate(LTO)
                      {String(props.formData.customer_type) !== '4' ? (
                        <span className="req">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="lto"
                      onChange={fileChangeHandler}
                      docId={lto.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a href={lto.path} target="_blank" rel="noreferrer">
                          Get Attachment {lto.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>
                      Business Permit
                      {String(props.formData.business_type) !== '1' &&
                      String(props.formData.customer_type) !== '4' ? (
                        <span className="req">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="bus_permit"
                      onChange={fileChangeHandler}
                      docId={bus_permit.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a
                          href={bus_permit.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Attachment {bus_permit.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>
                </div>

                {/* 3rd Row */}

                <div className="col-md-12 row">
                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>GIS</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="gis"
                      onChange={fileChangeHandler}
                      docId={gis.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a href={gis.path} target="_blank" rel="noreferrer">
                          Get Attachment {gis.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>
                      Photograph of establishment
                      {String(props.formData.customer_type) !== '4' ? (
                        <span className="req">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="File"
                      name="upload_attachment"
                      docType="photo_est"
                      onChange={fileChangeHandler}
                      docId={photo_est.customer_doc_id}
                    />
                    <Form.Label>
                      <em>
                        <a
                          href={photo_est.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Attachment {photo_est.file_name}
                        </a>
                      </em>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Other Documents</legend>
            <div className="col-md-12">
              <div className="row">
                {other_docs.length > 0
                  ? other_docs.map((row, index) => {
                      if (row)
                        return (
                          <Form.Group className="mb-3 col-md-4">
                            <Form.Label>Other Document {index + 1}</Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="File"
                              name="upload_attachment"
                              docType="others"
                              onChange={fileChangeHandlerOtherDocs}
                              docId={other_docs[index].customer_doc_id}
                            />
                            <Form.Label>
                              <em>
                                <a
                                  href={other_docs[index].path}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Get Attachment {other_docs[index].file_name}
                                </a>
                              </em>
                            </Form.Label>
                          </Form.Group>
                        );
                    })
                  : ''}
              </div>
              <span
                className="btn btn-sm btn-success mt-2"
                onClick={addNewOtherDocs}
              >
                + Add Attachment{' '}
              </span>
            </div>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerDocuments;
