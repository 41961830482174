import React, { Fragment } from "react";
import { Tooltip } from "@material-ui/core";

const CustomToolTip = () => {
  return (
    <Fragment>
      <div className="col-sm-1">
        <Tooltip
          title={
            <span
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              Indicated in 2303 BIR Certificate of Registration
            </span>
          }
        >
          <span style={{ fontSize: "12px" }} className="material-icons">
            help_outline
          </span>
        </Tooltip>
      </div>
    </Fragment>
  );
};

export default CustomToolTip;
