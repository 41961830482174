import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "95px",
    height: "24px",
    padding: "0px",
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#23bf58",
      },
    },
  },
  thumb: {
    color: "white",
    width: "43px",
    height: "20px",
    margin: "1px",
    borderRadius: "10px",
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "12px",
      position: "absolute",
      top: "3px",
    },
    "&:after": {
      content: "'Active'",
      left: "8px",
    },
    "&:before": {
      content: "'Inactive'",
      right: "5px",
    },
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(47px) !important",
  },
});

const PrimarySwitch = ({
  name = "primary_switch",
  checked = false,
  onChange = {},
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange({
      name,
      value: event.target.checked,
    });
  };

  return (
    <div>
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        name={name}
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
};

export default PrimarySwitch;
