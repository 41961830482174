import React, { Fragment, useState } from "react";
import { Tabs, Tab, Row, Col, Form, Dropdown } from "react-bootstrap";
import DefaultLayout from "../../../components/Layouts/DefaultLayout";
import RevisionHistoryList from "../components/RevisionHistoryList";

const RevisionHistoryScreen = ({
  data,
  total,
  loading,
  formData,
  reloadTable,
  handleClearFilterSearch,
  handleFilterChange,
  onChange,
}) => {
  const [key, setKey] = useState("general");
  return (
    <Fragment>
      <DefaultLayout title="Revision History">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            key={0}
            eventKey={"general"}
            title={"General"}
            className="default-tab-content"
          >
            <Row>
              <Col>
                <RevisionHistoryList
                  data={data}
                  total={total}
                  loading={loading}
                  formData={formData}
                  reloadTable={reloadTable}
                  handleClearFilterSearch={handleClearFilterSearch}
                  handleFilterChange={handleFilterChange}
                  onChange={onChange}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </DefaultLayout>
    </Fragment>
  );
};

export default RevisionHistoryScreen;
