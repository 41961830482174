import { forwardRef } from "react";
import DatePicker from "react-datepicker";

import moment from "moment";
import { Calendar3 } from "react-bootstrap-icons";
//import { parseISO } from "date-fns";
const YearDatePicker = ({
  name,
  value,
  onChange,
  format,
  formatString,
  // minDate,
  // maxDate,
  // startDate,
  // endDate,
  // selectsEnd,
  // selectsStart,
  readOnly = false,
  //disabled = false,
  yearItemNumber,
}) => {
  const datePickerFormat = typeof format !== "undefined" ? format : "yyyy";
  const datePickerFormatString =
    typeof format !== "undefined" ? formatString : "yyyy";

  const PrimaryCoreInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="input-group">
        <input
          type="text"
          className={"form-control"}
          defaultValue={value}
          onClick={onClick}
          ref={ref}
          readOnly
          style={
            readOnly
              ? {
                  backgroundColor: "rgba(35, 46, 60, 0.1)",
                }
              : { backgroundColor: "#fff" }
          }
        />
        <div className={"input-group-append"}>
          <span className={"input-group-text"}>
            <Calendar3 />
          </span>
        </div>
      </div>
    );
  });

  return (
    <div className="primary-date-picker">
      <DatePicker
        selected={value !== "" ? new Date(value) : ""}
        dateFormat={datePickerFormat}
        name={name}
        onChange={(e) => onChange(moment(e).format(datePickerFormatString))}
        customInput={<PrimaryCoreInput />}
        showYearDropdown
        showFourColumnYearPicker
        yearItemNumber={yearItemNumber}
        showYearPicker
        readOnly={readOnly}

        // showMonthDropdown

        // minDate={!minDate ? null : parseISO(minDate)}
        // maxDate={!maxDate ? null : parseISO(maxDate)}
        // startDate={!startDate ? null : parseISO(startDate)}
        // endDate={!endDate ? null : parseISO(endDate)}
        // selectsStart={selectsStart}
        // selectsEnd={selectsEnd}
        // disabled={disabled}
        //isEditable
      />
    </div>
  );
};

export default YearDatePicker;
