import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from "react-draggable";

const ModalDraggable = (props) => {
  return (
    <Draggable handle=".modal-header">
      <ModalDialog {...props} />
    </Draggable>
  );
};

export default ModalDraggable;
