import { useState, Fragment } from 'react';
import LoginScreen from './Screens/LoginScreen';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// reducer
import { authUser } from '../../redux/modules/auth';

// api
import { requestLogin } from '../../api/login';

const Login = () => {
  const dispatch = useDispatch();
  const { isAuthed } = useSelector(({ auth }) => auth);
  const [form, setForm] = useState({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const onHandleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    requestLogin(form)
      .then((res) => {
        const { code, result } = res.data.response;

        if (code === '00000') {
          let tmpUser = result.user;
          let roles = result.user.roles.map(({ role }) => role);

          const isAdmin = result.user.roles.find(
            ({ slug }) => slug === 'admin',
          );

          if (isAdmin) roles.push('aaf1');
          //if (!result.permissions.length) roles.push("aaf1");

          dispatch(
            authUser({
              accessToken: result.access_token,
              user: { ...tmpUser, roles },
              permissions: [...result.permissions],
            }),
          );
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid Credentials',
          });
        }
      })
      .catch((err) => {});
  };

  const redirectDashboard = () => {
    if (isAuthed) {
      window.location.href = '/dashboard';
    }
  };

  return (
    <Fragment>
      {redirectDashboard()}
      <LoginScreen
        form={form}
        onHandleChange={onHandleChange}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        onSubmit={onSubmitHandler}
      />{' '}
    </Fragment>
  );
};

export default Login;
