import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {
  requestGetProductByTransactionDate,
  requestProductExport,
} from "../../../api/ledgers";
import { toast } from "react-toastify";
import useNavActive from "../../../components/Navbar/useNavActive";
import ProductLedgerReportScreen from "../Screens/ProductLedgerReportScreen";
import moment from "moment";

const formDefault = {
  product_id: "",
  date_from: moment().subtract(20, "days").format("YYYY-MM-DD"),
  date_to: moment().format("YYYY-MM-DD"),
  page: 0,
  page_size: 50,
  order_by: "",
  direction: "",
  batch_no: "",
};

const Products = () => {
  //declaration
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [firstLoad, setFirstLoad] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [productsDataWithBatch, setProductsDataWithBatch] = useState([]);
  const [exportButton, setExportButton] = useState(false);

  useNavActive(["master_databases", "sub_ledger_database", "product_ledger"]);

  //for modal
  const [moduleModal, setModuleModal] = useState({
    toggle: false,
    module: [],
  });

  const handleSearch = () => {
    formData.batch_no !== ""
      ? loadLedgerDataWithBatch(formData)
      : loadLedgerData(formData);
  };

  const loadLedgerDataWithBatch = async (formData) => {
    setProductsData([]);
    setProductsDataWithBatch([]);
    setExportButton(true);
    try {
      let res = await requestGetProductByTransactionDate(formData);

      const { result, code } = res.data.response;

      if (code === "00000") {
        setProductsDataWithBatch(result);
      }
    } catch (error) {
      toast.error("Error occured. Please try again later");
    }
  };

  const loadLedgerData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setExportButton(false);
    setProductsData([]);
    setProductsDataWithBatch([]);

    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);

    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1 && form.product_id !== "") {
      try {
        let res = await requestGetProductByTransactionDate(form);

        const { code, result, total_rows } = res.data.response;

        if (code !== "00000") {
          toast.error(result);
          return;
        }
        setProductsData(result);
        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        toast.error("Error occured. Please try again later");
      } finally {
        setLoading(false);
      }
    } else {
      if (firstLoad !== 0) {
        toast.warning("Please provide valid data!");
      } else {
        setFirstLoad(1);
      }
      setLoading(false);
    }
  };

  const handleSelectedTab = (key) => {
    if (key === "sales_ledger") {
      history.push("/ledgers/sales");
    } else if (key === "receivables_ledger") {
      history.push("/ledgers/receivables");
    } else if (key === "inventories_ledger") {
      history.push("/ledgers/inventories");
    } else if (key === "sales_per_product_ledger") {
      history.push("/ledgers/sales-per-products");
    } else if (key === "vatable_sales_ledger") {
      history.push("/ledgers/vatable-sales");
    } else if (key === "product_ledger") {
      history.push("/ledgers/products");
    } else if (key === "ytd_sales_ledger") {
      history.push("/ledgers/ytd-sales");
    } else {
      history.push("/ledgers/sales");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const searchLedger = () => {
    toast.loading("Getting Transaction, Please wait...", {
      position: toast.POSITION.TOP_RIGHT,
    });
    requestGetProductByTransactionDate(formData)
      .then((res) => {
        if (res.data.response.code === "00000") {
          if (res.data.response.result.length == 0) {
            toast.warning("No Data Available");
          }
          toast.dismiss();
          setProductsData(res.data.response.result);
        } else {
          Swal.fire({
            title: "Error",
            text: res,
            icon: "error",
          });
        }
      })
      .catch((err) => {});
  };

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };

    loadLedgerData(temp);
  };

  const OnHandleExport = () => {
    if (
      formData.date_from === "" ||
      formData.date_to === "" ||
      formData.product_id === ""
    ) {
      Swal.fire({
        title: "Warning",
        text: "Please select product and dates!",
        icon: "warning",
      });
    } else {
      let tmp = {
        id: formData.product_id,
        date_from: formData.date_from,
        date_to: formData.date_to,
        tab: "inventory",
      };

      toast.loading("Exporting data in progress, Please wait...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      requestProductExport(tmp)
        .then((res) => {
          if (res.data.response.code === "00000") {
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: "Error",
              text: res,
              icon: "error",
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handleModuleModal = (row) => {
    let data = {
      ...formData,
      module: row.module,
      module_name: row.particulars,
    };

    setModuleModal({
      toggle: true,
      module: data,
    });
  };

  const handleCloseModuleModal = () =>
    setModuleModal({ toggle: false, data: [], batchInfo: [] });

  return (
    <ProductLedgerReportScreen
      //throw parameter or props.
      formData={formData}
      handleChange={handleChange}
      //dateChange={getDate}
      handleSearch={handleSearch}
      onSearch={searchLedger}
      productsData={productsData}
      productsDataWithBatch={productsDataWithBatch}
      selectedTab={"product_ledger"}
      handleSelectedTab={handleSelectedTab}
      loading={loading}
      totalRow={totalRow}
      handleDataTableChange={handleDataTableChange}
      reloadTable={reloadTable}
      handleExport={OnHandleExport}
      //revamp by jhay
      handleModuleModal={handleModuleModal}
      handleCloseModuleModal={handleCloseModuleModal}
      moduleModal={moduleModal}
      exportButton={exportButton}
    />
  );
};

export default Products;
