import React, { useState, useEffect, Fragment } from 'react';
import { GridToolbar } from '@mui/x-data-grid-pro';

import { Accordion, Col, Row } from 'react-bootstrap';
import PrimaryDataTable from '../PrimaryDataTable';
import {
  createPermissionInSideModule,
  getModules,
  getPermissionPerID,
  grantPermission,
  revokePermission,
} from '../../api/permission';

import Swal from 'sweetalert2';

import { toast } from 'react-toastify';
import _ from 'lodash';
import { generateUUID } from '../../utils/helper';
import PermissionAssignmentForm from './PermissionAssignmentForm';
import defaultOptions from '../../utils/defaultOptions';
import PrimaryLoader from '../PrimaryLoader';

const Permission = () => {
  const [module, setModule] = useState([]);
  const [permissionPerId, setPermissionPerId] = useState([]);
  const [column, setColumn] = useState([]); //datagrid column
  const [row, setRow] = useState([]); //datagrid row
  const [loading, setLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(1);
  const [moduleId, setModuleId] = useState();

  const [formData, setFormData] = useState({
    role_id: '',
  });
  const [showAssignmentModal, setShowAssignmentModal] = useState({
    toggle: false,
  });

  useEffect(() => {
    loadModules();
  }, []);

  const loadModules = async () => {
    setLoading(true);
    setModule([]);
    try {
      let res = await getModules();

      const { code, result } = res.data.response;

      if (code !== '00000') {
        return;
      }
      setModule(result);
    } catch {
      toast.error('Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (module_id) => {
    setModuleId(module_id);
    setLoading(true);
    setPermissionPerId([]);
    try {
      let res = await getPermissionPerID(module_id);

      const { code, result } = res.data.response;
      let rolesData = result.role;
      let functionRows = result.functions;

      if (code !== '00000') {
        return;
      }
      setPermissionPerId(result);

      let tmpROwValue = { id: '', functionalities: '' };

      let columnsHolder = [
        {
          headerName: 'Functionalities',
          field: 'functionalities',
          minWidth: 350,
        },
      ];

      rolesData.map((role) => {
        columnsHolder.push({
          headerName: role.role,
          field: 'role_' + role.role_id,
          minWidth: 250,
          hideable: false,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => (
            <Fragment>
              <input
                type="checkbox"
                checked={row['role_' + role.role_id]}
                onChange={() =>
                  checkStatus(
                    row.function_id,
                    role.role_id,
                    row['role_' + role.role_id],
                    tmpRows,
                  )
                }
                style={{ width: '20px', height: '15px' }}
              />
            </Fragment>
          ),
        });

        tmpROwValue['role_' + role.role_id] = '';
      });
      columnsHolder.push({
        headerName: 'Add Role',
        field: 'add_role',
        minWidth: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <label
            title="Add Role"
            onClick={handleShowAssignmentModal}
            style={{ color: 'black', textDecoration: 'underline' }}
          >
            Add Role
          </label>
        ),
      });
      setColumn(columnsHolder);

      tmpROwValue['add_row'] = '';

      let tmpRows = [];

      _.each(functionRows, (row) => {
        let data_mod_temp = Object.assign({}, tmpROwValue);
        data_mod_temp['id'] = generateUUID();
        //data_mod_temp["functionalities"] = row.function_label;
        data_mod_temp['functionalities'] =
          row.function_label + ' - ' + row.function_name;
        data_mod_temp['function_id'] = row.function_id;

        _.each(row.role_has_permission_in_function, (data_mod) => {
          data_mod_temp['role_' + data_mod.role_id] =
            data_mod.function_has_permission;
        });

        tmpRows.push(data_mod_temp);
      });
      setRow(tmpRows);
    } catch {
      toast.error('Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  const checkStatus = (function_id, role_id, is_checked, tmpRows) => {
    let data = {
      function_id: function_id,
      role_id: role_id,
    };

    tmpRows.map((rows) => {
      if (rows.function_id === function_id) {
        if (rows['role_' + role_id] === 1) {
          return (rows['role_' + role_id] = 0);
        } else {
          return (rows['role_' + role_id] = 1);
        }
      }
    });
    // **sir drex solution ( immutable )
    // let newTmpRows = tmpRows.map((rows) => {
    //   if (rows.function_id === function_id) {
    //     return {
    //       ...rows,
    //       ["role_" + role_id]: !rows["role_" + role_id],
    //     };
    //   }
    //   return rows;
    // });
    // setRow(newTmpRows);

    if (is_checked === 1) {
      grantPermission(data).then((response) =>
        toast.success(response.data.result),
      );
    } else {
      revokePermission(data).then((response) => {
        toast.success(response.data.result);
      });
    }
  };

  const handleShowAssignmentModal = (row) => {
    setShowAssignmentModal({
      toggle: true,
    });
  };
  const handleCloseAssignmentModal = () => {
    setShowAssignmentModal({ toggle: false });

    setFormData({ role_id: '' });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let newData = {
    ...formData,
    module_id: moduleId,
    function_has_permission: 0,
    label: 'N/A',
    tag: 'N/A',
    permitted_by: 1,
  };

  const submitHandler = (e) => {
    e.preventDefault();

    createPermissionInSideModule(newData).then((res) => {
      const { code, result } = res.data.response;
      code === '00003'
        ? Swal.fire({
            icon: 'success',
            text: result,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              loadModules();
              handleClick(moduleId);
              handleCloseAssignmentModal();
            }
          })
        : Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result,
          });
    });
  };
  return (
    <Fragment>
      <Row className="mb-3">
        <Col lg="6">
          <h2 className="page-title">Permission List</h2>
        </Col>
      </Row>

      <Row className="mb-3">
        <Accordion defaultActiveKey="0">
          <PrimaryLoader visible={loading} />

          {module.map((value) => (
            <Accordion.Item eventKey={value.module_id}>
              <Accordion.Header onClick={() => handleClick(value.module_id)}>
                {value.label}
                {/* {value.label} - {value.module_id} */}
              </Accordion.Header>
              <Accordion.Body>
                <PrimaryDataTable
                  rows={row}
                  columns={column}
                  loading={loading}
                  pageSize={defaultOptions.pageSize}
                  reload={reloadTable}
                  hideFooterSelectedRowCount
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
      <PermissionAssignmentForm
        toggle={showAssignmentModal.toggle}
        closeModal={handleCloseAssignmentModal}
        handleChange={handleChange}
        formData={formData}
        submitHandler={submitHandler}
      />
    </Fragment>
  );
};

export default Permission;
