export const productsListColumn = [
  {
    field: "product_code",
    headerName: "Product Code",
    sortable: true,
    flex: 0,
    minWidth: 170,
    renderCell: (productsData) => {
      return (
        <a href={`/view/products/list/${productsData.row.product_code}`}>
          {productsData.row.product_code}
        </a>
      );
    },
  },
  { field: "product_name", headerName: "Product Name", width: 200 },
  { field: "brand_name", headerName: "Generic Name", width: 200 },
  { field: "supplier", headerName: "Supplier", width: 200 },
  { field: "segment", headerName: "Product Segment", width: 200 },
  { field: "group", headerName: "Product Group", width: 200 },
  { field: "packaging", headerName: "Packaging", width: 200 },
  { field: "qty_per_pack", headerName: "Qty Per Pack", width: 200 },
  {
    field: "running_balance",
    headerName: "Stock Level",
    headerAlign: "left",
    type: "number",
    align: "left",
    width: 200,
  },
  { field: "cpr_no", headerName: "CPR No.", width: 200 },
  { field: "cpr_expiry", headerName: "CPR Expiry", width: 200 },
  {
    field: "active",
    headerName: "Active",
    sortable: true,
    flex: 0,
    minWidth: 150,
    align: "center",
    renderCell: (productsData) => {
      return (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {productsData.row.active === 1 ? (
            <input type="radio" checked disabled />
          ) : (
            <input type="radio" disabled />
          )}
        </span>
      );
    },
  },
];
