import { lazy } from "react";

const ChartOfAccount = lazy(() => import("../pages/ChartOfAccount"));

const chartOfAccountRoute = {
  public: [],
  auth: [
    {
      name: "chart-of-account",
      path: "/finance-and-accounting/chart-of-account/:id?",
      component: ChartOfAccount,
    },
  ],
};

export default chartOfAccountRoute;
