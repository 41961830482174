import { Button, Modal, ListGroup } from "react-bootstrap";

const CustomerTransactionReasonModal = ({
  showTransactionModal,
  handleCloseTransactionModal,
  srpfDetails
}) => {
  return (
    <Modal
      size="lg"
      show={showTransactionModal}
      onHide={handleCloseTransactionModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Declined Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {
            srpfDetails.map((row) => (
              <ListGroup.Item>{row.reject_reason}</ListGroup.Item>
            ))
          }
          {
            srpfDetails.length === 0 && 
            <ListGroup.Item className="text-center font-bold">No Declined Reason</ListGroup.Item>
          }
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseTransactionModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomerTransactionReasonModal;