import { lazy } from "react";

const Unauthenticated = lazy(() => import("../pages/Login/Unauthenticated"));

const authRoute = {
  public: [
    {
      name: "unauthenticated",
      path: "/unauthenticated",
      component: Unauthenticated,
    },
  ],
  auth: [],
};

export default authRoute;
