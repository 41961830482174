import { useState, useEffect } from "react";

import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import RolesForm from "./RolesForm";

import { getRolesID, updateRole } from "../../../api/users";

const RolesEditPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    role: "",
    description: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  /* Use hooks to load the specific roles */
  useEffect(() => {
    getRolesID(id).then((res) => {
      let { code, result } = res.data.response;
      if (code === "00000") {
        setForm(result[0]);
      }
    });
    // let componentMounted = true;
    // const loadUserRole = async () => {
    //   const res = await axiosInstance.get(`/role/${id}`, {
    //     method: "GET",
    //   });
    //   if (componentMounted) {
    //     setUserRole(res.data.response.result[0]);
    //   }
    // };
    // loadUserRole();
    // return () => {
    //   componentMounted = false;
    // };
  }, [id]);

  const submitHandler = (e) => {
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to update role?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "#dc3545",
    }).then((result) => {
      if (result.isConfirmed) {
        if (checkEmptyFields()) {
          Swal.fire({
            icon: "info",
            title: "All fields are required!",
          });
        } else {
          updateRole(form)
            .then((res) => {
              let { code, result } = res.data.response;
              if (code !== "00004") {
                Swal.fire({
                  icon: "error",
                  text: result,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  text: result,
                  showConfirmButton: true,
                });
                history.push("/user-control#tab-top-2");
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.data.response.result,
              });
            });
        }
      }
    });
    // e.preventDefault();
    // axiosInstance.put(`/role/update`, values, {}).then((res) =>
    //   res.data.response.code === "00004"
    //     ? Swal.fire({
    //         icon: "success",
    //         title: "Your work has been saved",
    //         showConfirmButton: true,
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           history.push("/user-control");
    //         }
    //       })
    //     : Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: res.data.response.result,
    //       })
    // );
  };

  const checkEmptyFields = () => {
    return Object.keys(form).reduce((previous, key) => {
      return form[key] === "" ? previous + 1 : 0;
    }, 0);
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <h3>Edit Role Page</h3>
            </div>

            <RolesForm form={form} handleChange={handleChange} />

            <div className="card-footer">
              <div className="text-right">
                <Button
                  id="add"
                  variant="success"
                  type="button"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Save
                </Button>
                <Link
                  id="cancel"
                  to="/user-control#tab-top-2"
                  className="ml-2 btn btn-danger"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    // <Fragment>
    //   <div className="col-md-10 offset-1">
    //     <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
    //       <div className="card">
    //         <div className="card-header">
    //           <h1>Edit Role Page</h1>
    //         </div>
    //         <div className="card-body">
    //           <form onSubmit={submitHandler}>
    //             <div className="form-group">
    //               <label>Role</label>

    //               <input
    //                 className="form-control"
    //                 name="role"
    //                 id="role"
    //                 type="text"
    //                 value={values.role}
    //                 onChange={(e) => onInputChange(e)}
    //                 required
    //               />
    //             </div>

    //             <div className="form-group">
    //               <label>Description</label>

    //               <input
    //                 className="form-control"
    //                 name="description"
    //                 id="description"
    //                 type="text"
    //                 value={values.description}
    //                 onChange={(e) => onInputChange(e)}
    //                 required
    //               />
    //             </div>

    //             <div className="btn-grp" style={{ float: "right" }}>
    //               <button
    //                 id="save_changes"
    //                 className="btn btn-primary"
    //                 type="submit"
    //               >
    //                 Save Changes
    //               </button>
    //               <Link
    //                 id="cancel"
    //                 to="/user-control"
    //                 className="ml-2 btn btn-secondary"
    //               >
    //                 Cancel
    //               </Link>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  );
};

export default RolesEditPage;
