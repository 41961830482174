import React, { useState, useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import axiosInstance from '../axiosInstance';
import ProductInformation from '../Products/ProductInformation/ProductInformation';
import ProductPackaging from '../Products/Packaging/ProductPackaging';
import AddProducts from '../Products/AddProducts';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import '../Products/modal.css';
import Swal from 'sweetalert2';
import {
  HddFill,
  ListUl,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import SideBarSearch from '../SideBarSearch';
import { getProductSearch } from '../../api/product';
import PrimaryLoader from '../PrimaryLoader';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { AlertDefaultError, PermissionsGate } from '../PermissionsGate';
import PrimarySwitch from '../PrimarySwitch';
import Forecast from './Forecast';
import Purchase from '../Products/Purchase';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import { Trash } from 'react-bootstrap-icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ViewProduct = () => {
  const history = useHistory();
  const { product_code } = useParams();
  const [productId, setProductId] = useState();
  const [showTab, setTab] = useState('tab1');
  const [showTab1, setShowTab1] = useState(true);
  const [showTab2, setShowTab2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [smallImage, setsmallImage] = useState('');
  const [bigImage, setbigImage] = useState('');
  const [cprDocument, setCprDocument] = useState('');
  const [supplierHolder, setSupplierHolder] = useState([]);
  const [prodSegmentHolder, setProdSegmentHolder] = useState([]);
  const [prodGroupHolder, setProdGroupHolder] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [productSubGroup, setProductSubGroup] = useState([]);
  const [productSubSegment, setProductSubSegment] = useState([]);
  const [unitMeasureHolder, setUnitMeasureHolder] = useState([]);
  const [packagingHolder, setPackagingeHolder] = useState([]);
  const [costVatValue, setCostVatValue] = useState('0.00');
  const [costUnitVatValue, setCostUnitVatValue] = useState('0.00');
  const [sellingVatValue, setSellingVatValue] = useState('0.00');
  const [sellingUnitVatValue, setSellingUnitVatValue] = useState('0.00');
  const [activeIsCheck, setActiveIsCheck] = useState();
  const [vatIsCheck, setVatIsCheck] = useState(false);
  const [showSpecifyOthers, setSpecifyOthers] = useState(false);

  const [costBox, setCostBox] = useState(0);
  const [costUnit, setCostUnit] = useState('');
  const [sellingBox, setSellingBox] = useState(0);
  const [sellingUnit, setSellingUnit] = useState('');
  const [qtyPerPack, setQtyperpac] = useState(0);

  const [productData, setProductData] = useState({});
  const [pdcTerms, setPdcTerms] = useState([]);

  let emptyValues = {
    product_id: '',
    supplier_id: '',
    product_segment_id: '',
    product_sub_segment_id: '',
    product_group_id: '',
    product_sub_group_id: '',
    inventory_id: 1,
    product_code: '',
    product_name: '',
    generic_name: '',
    packaging: '',
    qty_per_pack: '',
    stock_level: '',
    shelf_life: '',
    quantity: '',
    manufacturer: '',
    packaging_description: '',
    unit_id: '',
    storage_requirement: '',
    min_temperature: '',
    max_temperature: '',
    safety_stock_level: '',
    forecast: '',
    supplier_lead_time: '',
    active: 1,
    vat_exemption: '',
    cost: '',
    cost_vat: '',
    cost_unit: '',
    cost_unit_vat: '',
    selling_price: '',
    selling_price_unit: '',
    selling_price_vat: '',
    selling_price_unit_vat: '',
    cpr_no: '',
    cpr_expiry: '',
    cpr_document: '',
    moq: '',
    temperature: '',
    humidity: '',
    small_size: '',
    small_size_image: '',
    big_size: '',
    big_size_image: '',
    weight_net: '',
    weight_gross: '',
    is_submit: 2,
    specify_others: '',
    percent_down_payment: '',
    percent_intransit: '',
    percent_upon_delivery: '',
    payments: [],
  };

  const [values, setProduct] = useState(emptyValues);
  const [loader, setLoader] = useState(true);

  const onInputChange = (e) => {
    setProduct({ ...values, [e.target.name]: e.target.value });
  };

  const defaultPaymentsField = {
    percent_amount: '',
    days_remaining: '',
  };

  const [paymentsField, setPaymentsField] = useState([]);

  const handleAddPaymentTerms = () => {
    setPaymentsField([...paymentsField, defaultPaymentsField]);
  };

  const handleRemovePaymentTerms = (selectedIndex) => {
    let tmp = [
      ...paymentsField.filter((row, index) => {
        return selectedIndex !== index;
      }),
    ];
    setPaymentsField(tmp);
  };

  const handleChangePaymentTerms = (selectedIndex, name, value) => {
    const newPaymentTerms = paymentsField.map((terms, index) => {
      if (index === selectedIndex) {
        return { ...terms, [name]: value };
      }
      return terms;
    });
    setPaymentsField(newPaymentTerms);
  };

  useEffect(() => {
    if (pdcTerms) {
      setPaymentsField(
        pdcTerms.map((e) => ({
          ...e,
          percent_amount: e.percent_amount,
          days_remaining: e.days_remaining,
        })),
      );
    }
  }, [pdcTerms]);

  const renderPaymentTerms = () => {
    return paymentsField.map((value, index) => rowPaymentTerms(value, index));
  };

  const rowPaymentTerms = (rowData, index) => {
    return (
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-1">
            {index ? (
              <Button
                className={'btn ' + divHidden}
                variant="link"
                onClick={() => handleRemovePaymentTerms(index)}
              >
                <Tooltip title="Delete">
                  <Trash />
                </Tooltip>
              </Button>
            ) : null}
          </div>

          <div className="col-md-4">
            <input
              type="text"
              className="form-control form-control-sm text-dark"
              name={'payments' + '[' + [index] + ']' + '[percent_amount]'}
              id="percentage_downpayment"
              value={rowData.percent_amount}
              style={{ marginTop: '6px' }}
              onKeyPress={(event) => {
                if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                handleChangePaymentTerms(
                  index,
                  'percent_amount',
                  e.target.value,
                );
              }}
              disabled={!updateForm}
              placeholder="Enter % PDC"
            />
          </div>

          <div className="col-md-4">
            <input
              type="text"
              className="form-control form-control-sm text-dark"
              name={'payments' + '[' + [index] + ']' + '[days_remaining]'}
              id="no_of_days"
              value={rowData.days_remaining}
              style={{ marginTop: '6px' }}
              onKeyPress={(event) => {
                if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                handleChangePaymentTerms(
                  index,
                  'days_remaining',
                  e.target.value,
                );
              }}
              disabled={!updateForm}
              placeholder="# of Days"
            />
          </div>
          <div className="col-md-3">
            <label className="col-sm-12 " style={{ marginTop: '6px' }}>
              After Delivery
            </label>
          </div>
        </div>
      </div>
    );
  };

  const styles = {
    border: {
      border: '1px solid #e7eaef',
    },
    divPadding: {
      padding: '5px',
    },
    fieldset: {
      border: '1px solid #e7eaef',
      padding: '-4px',
    },
    legend: {
      position: 'static',
      marginTop: '-20px',
      top: 1,
      fontSize: '13px',
      lineHeight: 1,
      margin: '-9px 0 0' /* half of font-size */,
      background: '#fff',
      color: '#000',
    },

    formGroup: {
      margin: 0,
      paddingTop: '0px',
      paddingLeft: '20px',
      paddingBottom: '0px',
      paddingRight: '0',
    },
  };

  const [updateForm, setUpdateForm] = useState(false);
  const [divHidden, setDivHidden] = useState('d-none');

  const onUpdate = () => {
    setUpdateForm(!updateForm);
    setDivHidden('');
  };

  const appendSubmitValue = (e) => {
    values.is_submit = 2;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    toast.loading('Processing request please wait', {
      autoClose: false,
    });

    const vat = vatIsCheck ? 1 : 0;

    const data = new FormData(e.target);
    data.append('product_id', values.product_id);
    data.append('is_submit', values.is_submit);
    data.append('vat_exemption', vat);
    data.append('product_code', product_code);
    data.append('category', 'UPDATED');

    axiosInstance
      .post(`/products/submit/updated`, data, {})
      .then((response) => {
        toast.dismiss();
        setDisableSubmit(false);
        response.data.response.code == '00004'
          ? Swal.fire({
              icon: 'success',
              title: 'Sucess',
              text: response.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
          : response.data.response.code === '00001'
          ? Swal.fire({
              icon: 'error',
              title: 'Invalid Request',
              html: response.data.response.result.join('<br/>'),
            })
          : Swal.fire({
              icon: 'error',
              title: 'Someting went wrong...',
              text: response.data.response.result,
            });
      });
  };

  const [sideBarLoader, setSideBarLoader] = useState(false);
  const [searchProduct, setsearchProduct] = useState([]);

  useEffect(() => {
    loadProduct();
    loadSupplier();
    loadProdSegment();
    loadProdGroup();
    populateSubGroup();
    populateSubSegment();
    loadUnitMasure();
    loadPackaging();
    populateProduct();
  }, [product_code]);

  const loadProduct = () => {
    setLoader(true);
    axiosInstance.get(`/products/code/${product_code}`).then((response) => {
      if (response.data.response.code === '00000') {
        setUpdateForm(false);
        setDivHidden('d-none');
        document.getElementById(
          'page-title',
        ).innerHTML = `${response.data.response.result.product_name} - ${response.data.response.result.product_code}`;

        let data = {
          product_id: response.data.response.result.product_id,
          product_code: response.data.response.result.product_code,
          supplier_id: response.data.response.result.supplier_id,
          product_segment_id: response.data.response.result.product_segment_id,
          product_sub_segment_id:
            response.data.response.result.product_sub_segment_id,
          product_group_id: response.data.response.result.product_group_id,
          product_sub_group_id:
            response.data.response.result.product_sub_group_id,

          inventory_id: response.data.response.result.inventory_id,
          product_name: response.data.response.result.product_name,
          generic_name: response.data.response.result.brand_name,
          packaging: response.data.response.result.packaging,

          qty_per_pack: response.data.response.result.qty_per_pack,
          stock_level: response.data.response.available_stock,
          shelf_life: response.data.response.result.shelf_life,
          quantity: response.data.response.result.quantity,
          safety_stock_level: response.data.response.result.safety_stock_level,

          manufacturer: response.data.response.result.manufacturer,
          packaging_description:
            response.data.response.result.packaging_description,
          unit_id: response.data.response.result.unit_id,
          storage_requirement:
            response.data.response.result.storage_requirement,
          min_temperature: response.data.response.result.min_temperature,
          max_temperature: response.data.response.result.max_temperature,
          forecast: response.data.response.result.forecast,
          supplier_lead_time: response.data.response.result.supplier_lead_time,
          active: 1,
          vat_exemption: response.data.response.result.vat_exemption,

          cost: response.data.response.result.cost.toFixed(2),
          cost_vat: response.data.response.result.cost_vat.toFixed(2),
          cost_unit: response.data.response.result.cost_unit.toFixed(2),
          cost_unit_vat: response.data.response.result.cost_unit_vat.toFixed(2),
          selling_price: response.data.response.result.selling_price.toFixed(2),
          selling_price_unit:
            response.data.response.result.selling_price_unit.toFixed(2),
          selling_price_vat:
            response.data.response.result.selling_price_vat.toFixed(2),
          selling_price_unit_vat:
            response.data.response.result.selling_price_unit_vat.toFixed(2),

          cpr_no: response.data.response.result.cpr_no,
          cpr_expiry: response.data.response.result.cpr_expiry,

          cpr_document: response.data.response.result.cpr_document,
          temperature: response.data.response.result.temperature,
          humidity: response.data.response.result.humidity,
          small_size: response.data.response.result.small_size,

          small_size_image: response.data.response.result.small_size_image,
          big_size: response.data.response.result.big_size,
          big_size_image: response.data.response.result.big_size_image,
          weight_net: response.data.response.result.weight_net,
          weight_gross: response.data.response.result.weight_gross,

          percent_down_payment:
            response.data.response.result.percent_down_payment,
          percent_intransit: response.data.response.result.percent_intransit,
          percent_upon_delivery:
            response.data.response.result.percent_upon_delivery,

          product_pdc_payment_term:
            response.data.response.result.product_pdc_payment_term,
        };

        setPdcTerms(response.data.response.result.product_pdc_payment_term);
        setProductId(data.product_id);
        setProductData(data);
        setProduct(data);
        setQtyperpac(data.qty_per_pack.toFixed(2));
        setCostBox(data.cost);
        setCostUnit(data.cost_unit);
        setSellingBox(data.selling_price);
        setSellingUnit(data.selling_price_unit);

        setCostVatValue(data.cost_vat);
        setCostUnitVatValue(data.cost_unit_vat);
        setSellingVatValue(data.selling_price_vat);
        setSellingUnitVatValue(data.selling_price_unit_vat);

        if (response.data.response.result.upload.length > 0) {
          response.data.response.result.upload.map((upload, i) => {
            if (response.data.response.result.upload[i].doc_type === 'cpr') {
              setCprDocument({
                file_name:
                  response.data.response.result.upload[i].with_image[0]
                    .file_name,
                file_url:
                  response.data.response.result.upload[i].with_image[0]
                    .processed_path,
              });
            }

            if (response.data.response.result.upload[i].doc_type === 'small') {
              setsmallImage(
                response.data.response.result.upload[i].with_image[0]
                  .processed_path,
              );
            }

            if (response.data.response.result.upload[i].doc_type === 'big') {
              setbigImage(
                response.data.response.result.upload[i].with_image[0]
                  .processed_path,
              );
            }
          });
        } else {
          setCprDocument('');
          setsmallImage('');
          setbigImage('');
        }

        if (response.data.response.result.vat_exemption == 1) {
          setVatIsCheck(true);
        } else {
          setVatIsCheck(false);
        }

        if (response.data.response.result.active === 1) {
          setActiveIsCheck(true);
        } else {
          setActiveIsCheck(false);
        }

        if (
          response.data.response.result.packaging !== 'Box' &&
          response.data.response.result.packaging !== 'Cans' &&
          response.data.response.result.packaging !== 'Pouch'
        ) {
          values.packaging = 'Others';
          values.specify_others = response.data.response.result.packaging;
          setSpecifyOthers(true);
        }
        setLoader(false);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: response.data.response.result,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/products`);
          }
        });
      }
    });
  };

  const loadSupplier = () => {
    axiosInstance
      .get(`/vendor-db/vendor-dropdown?status=0`)
      .then((response) => {
        setSupplierHolder(response.data.response.result);
      });
  };

  const loadProdSegment = () => {
    axiosInstance.get(`/products/segment`).then((response) => {
      setProdSegmentHolder(response.data.response.result);
    });
  };

  const loadProdGroup = () => {
    axiosInstance.get(`/products/group`).then((response) => {
      setProdGroupHolder(response.data.response.result);
    });
  };

  const loadUnitMasure = () => {
    axiosInstance.get(`/products/unit`).then((response) => {
      setUnitMeasureHolder(response.data.response.result);
    });
  };

  const loadPackaging = () => {
    axiosInstance.get(`/products/packaging`).then((response) => {
      setPackagingeHolder(response.data.response.result);
    });
  };

  const smallUplaodImage = (e) => {
    const file = document.getElementById('small_size_image').files[0];

    if (file) {
      setsmallImage(URL.createObjectURL(file));
    } else {
      setsmallImage('');
    }
  };

  const bigUplaodImage = (e) => {
    const file = document.getElementById('big_size_image').files[0];

    if (file) {
      setbigImage(URL.createObjectURL(file));
    } else {
      setbigImage('');
    }
  };

  const vatCheck = (e) => {
    const isCheck = e.target.checked;

    if (isCheck) {
      values.vat_exemption = 1;
      setVatIsCheck(true);

      const cost_vat_rate =
        parseFloat(values.cost.replace(/,/g, '')).toFixed(2) / 100;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      const cost_unit_vat_rate =
        parseFloat(values.cost_unit.replace(/,/g, '')).toFixed(2) / 100;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      const selling_vat_rate =
        parseFloat(values.selling_price.replace(/,/g, '')).toFixed(2) / 100;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      const selling_unit_vat_rate =
        parseFloat(values.selling_price_unit.replace(/,/g, '')).toFixed(2) /
        100;
      const selling_price_unit_vat = selling_unit_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
      setCostUnitVatValue(values.cost_unit_vat);
      setSellingVatValue(values.selling_price_vat);
      setSellingUnitVatValue(values.selling_price_unit_vat);
    } else {
      values.vat_exemption = 0;
      setVatIsCheck(false);

      const cost_vat_rate =
        parseFloat(values.cost.replace(/,/g, '')).toFixed(2) / 112;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      const cost_unit_vat_rate =
        parseFloat(values.cost_unit.replace(/,/g, '')).toFixed(2) / 112;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      const selling_vat_rate =
        parseFloat(values.selling_price.replace(/,/g, '')).toFixed(2) / 112;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      const selling_unit_vat_rate =
        parseFloat(values.selling_price_unit.replace(/,/g, '')).toFixed(2) /
        112;
      const selling_price_unit_vat = selling_unit_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
      setCostUnitVatValue(values.cost_unit_vat);
      setSellingVatValue(values.selling_price_vat);
      setSellingUnitVatValue(values.selling_price_unit_vat);
    }
  };
  const computeUnit = (e) => {
    let cost_unit = parseFloat(costBox);
    let selling_unit = parseFloat(sellingBox);
    let pack = e.target.value;

    let costUnitValue = cost_unit / pack;
    values.cost_unit = costUnitValue.toFixed(2);
    setCostUnit(costUnitValue.toFixed(2));
    computeVatCostUnit(costUnitValue.toFixed(2));

    let sellingUnitValue = selling_unit / pack;
    values.selling_price_unit = sellingUnitValue.toFixed(2);
    setSellingUnit(sellingUnitValue.toFixed(2));
    computeVatSellingUnit(sellingUnitValue.toFixed(2));

    setQtyperpac(e.target.value);
  };

  const computeVatCostUnit = (costUnitValue) => {
    const costPriceFormat = parseFloat(costUnitValue.replace(/,/g, '')).toFixed(
      2,
    );

    if (vatIsCheck) {
      const cost_unit_vat_rate = costPriceFormat / 100;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      setCostUnitVatValue(values.cost_unit_vat);
    } else {
      const cost_unit_vat_rate = costPriceFormat / 112;
      const cost_unit_vat = cost_unit_vat_rate * 100;
      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      setCostUnitVatValue(values.cost_unit_vat);
    }
  };

  const computeVatCost = (e) => {
    const costPriceFormat = parseFloat(
      e.target.value.replace(/,/g, ''),
    ).toFixed(2);

    const pack = qtyPerPack;

    let costUnitValue = costPriceFormat / parseFloat(pack);
    values.cost_unit = costUnitValue.toFixed(2);
    setCostUnit(costUnitValue.toFixed(2));
    computeVatCostUnit(costUnitValue.toFixed(2));

    if (vatIsCheck) {
      const cost_vat_rate = costPriceFormat / 100;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
    } else {
      const cost_vat_rate = costPriceFormat / 112;
      const cost_vat = cost_vat_rate * 100;
      values.cost_vat = cost_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
    }

    setCostBox(e.target.value);
  };

  const computeVatSelling = (e) => {
    const sellingPriceFormat = parseFloat(
      e.target.value.replace(/,/g, ''),
    ).toFixed(2);
    const pack = qtyPerPack;

    if (vatIsCheck) {
      const selling_vat_rate = sellingPriceFormat / 100;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      setSellingVatValue(values.selling_price_vat);
    } else {
      const selling_vat_rate = sellingPriceFormat / 112;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      setSellingVatValue(values.selling_price_vat);
    }

    let sellingUnitValue = sellingPriceFormat / parseFloat(pack);
    values.selling_price_unit = sellingUnitValue.toFixed(2);
    setSellingUnit(sellingUnitValue.toFixed(2));
    computeVatSellingUnit(sellingUnitValue.toFixed(2));

    setSellingBox(e.target.value);
  };

  const computeVatSellingUnit = (sellingUnitValue) => {
    const sellingUnitPriceFormat = parseFloat(
      sellingUnitValue.replace(/,/g, ''),
    ).toFixed(2);

    if (vatIsCheck) {
      const selling_vat_rate = sellingUnitPriceFormat / 100;
      const selling_price_unit_vat = selling_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setSellingUnitVatValue(values.selling_price_unit_vat);
    } else {
      const selling_vat_rate = sellingUnitPriceFormat / 112;
      const selling_price_unit_vat = selling_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setSellingUnitVatValue(values.selling_price_unit_vat);
    }
  };

  const populateSubGroup = (e) => {
    let subgroup = [
      {
        sub_group_id: 1,
        group_name: 'sub-group 1',
      },
      {
        sub_group_id: 2,
        group_name: 'sub-group 2',
      },
    ];

    setProductSubGroup(subgroup);
  };

  const populateSubSegment = (e) => {
    let subsegment = [
      {
        sub_segment_id: 1,
        segment_name: 'sub-segment 1',
      },
      {
        sub_segment_id: 2,
        segment_name: 'sub-segment 2',
      },
    ];

    setProductSubSegment(subsegment);
  };

  const specifyOthers = (e) => {
    const packaging = e.target.value;

    if (packaging === 'Others') {
      setSpecifyOthers(true);
    } else {
      setSpecifyOthers(false);
    }
  };

  const checkActiveStatus = ({ value }) => {
    setActiveIsCheck(value);

    const data = {
      product_id: values.product_id,
      is_check: value,
    };

    axiosInstance.post(`/product/active/status`, data, {}).then((response) => {
      setActiveIsCheck(value);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.response.result,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/view/products/list/${values.product_code}`);
        }
      });
    });
  };

  const populateProduct = (data = {}) => {
    // document.getElementById("search-loading").style.visibility = "visible";
    setSideBarLoader(true);
    getProductSearch(data)
      .then((res) => {
        const { code, result } = res.data.response;

        if (code === '00000') {
          setsearchProduct(result);
          setSideBarLoader(false);
        }
      })
      .catch((err) => {});
    // document.getElementById("search-loading").style.visibility = "hidden";
    // document.getElementById("search-result").style.visibility = "visible";
  };

  const populateSearchProductDetails = (e) => {
    populateProduct({
      search_param: e.target.value,
    });
  };

  const labelField = {
    code: 'product_code',
    name: 'product_name',
    link: '/view/products/list/',
  };

  const cancelChanges = (e) => {
    e.preventDefault();
    let result = _.isEqual(productData, values);
    if (result) {
      setUpdateForm(false);
      setDivHidden('d-none');
      setProduct(productData);
    }

    if (!result) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to discard changes?',
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setUpdateForm(false);
          setDivHidden('d-none');
          setProduct(productData);
        }
      });
    }
  };

  const handleClickTab = useCallback(
    (e) => setTab(e.target.getAttribute('data-tab_name')),
    [],
  );

  return (
    <Fragment>
      <form onSubmit={submitHandler}>
        <div className="page-wrapper" style={{ fontSize: '12px' }}>
          <div className="row container-fluid">
            <div className="col-lg-3 side-selector">
              <span className="side-selector-arrow">
                <ListUl color="white" size={40} />
              </span>
              <div className="mt-4">
                <PermissionsGate
                  scopes={['view_products']}
                  RenderError={AlertDefaultError}
                >
                  <SideBarSearch
                    title="Products List"
                    data={searchProduct}
                    onSearch={populateSearchProductDetails}
                    labelField={labelField}
                    sideBarLoader={sideBarLoader}
                    oldData={productData}
                    values={values}
                    moduleName="products"
                  />
                </PermissionsGate>
              </div>
              {/* <SearchProduct /> */}
              <PermissionsGate scopes={['add_products']}>
                <div className="mt-3">
                  <span
                    class="btn btn-info btn-md btn-block"
                    onClick={() => setShowModal(true)}
                  >
                    Add Product
                  </span>
                </div>
              </PermissionsGate>
            </div>
            <div className="col-lg-9 stretch-content-1024">
              <div>
                <div className="page-header d-block">
                  <h2 className="page-title float-left" id="page-title">
                    &nbsp;
                  </h2>
                  <PermissionsGate scopes={['update_status']}>
                    <div className="ml-3 float-left mt-1">
                      <PrimarySwitch
                        checked={activeIsCheck}
                        onChange={checkActiveStatus}
                      />
                    </div>

                    {/* <div className="float-left">
                      <div className="custom-control custom-switch float-right">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="active_status"
                            style={{ marginTop: "6px" }}
                            onChange={checkActiveStatus}
                            checked={activeIsCheck}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div> */}
                  </PermissionsGate>
                  <PermissionsGate scopes={['update_product']}>
                    {!updateForm ? (
                      <div className="float-right">
                        <span
                          className="btn btn-warning"
                          style={{ top: '-10px', right: 0 }}
                          onClick={onUpdate}
                        >
                          <PencilSquare style={{ marginTop: '-3px' }} />
                          &nbsp; Edit
                        </span>
                      </div>
                    ) : (
                      <div className="float-right">
                        <span
                          className="btn btn-danger"
                          disabled={disableSubmit}
                          onClick={cancelChanges}
                        >
                          <XCircleFill style={{ marginTop: '-3px' }} />
                          &nbsp; Cancel
                        </span>

                        <button
                          className="btn btn-success ml-3"
                          onClick={appendSubmitValue}
                          type="submit"
                          disabled={disableSubmit}
                        >
                          <HddFill style={{ marginTop: '-3px' }} />
                          &nbsp; Save
                        </button>
                      </div>
                    )}
                  </PermissionsGate>
                </div>
              </div>
              <div>
                <PrimaryLoader visible={loader} />
                <div className="mt-2 position-relative">
                  <ul className="nav nav-tabs bg-header">
                    <li className="nav-item">
                      <a
                        href="#tab-top-1"
                        className={`nav-link ${
                          showTab === 'tab1' ? 'active' : ''
                        }`}
                        data-toggle="tab"
                        data-tab_name="tab1"
                        onClick={handleClickTab}
                      >
                        Product Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#tab-top-2"
                        className={`nav-link ${
                          showTab === 'tab2' ? 'active' : ''
                        }`}
                        data-toggle="tab"
                        data-tab_name="tab2"
                        onClick={handleClickTab}
                      >
                        Packaging
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#tab-top-3"
                        data-toggle="tab"
                        className={`nav-link ${
                          showTab === 'tab3' ? 'active' : ''
                        }`}
                        data-tab_name="tab3"
                        onClick={handleClickTab}
                      >
                        Forecasting
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#tab-top-4"
                        className={`nav-link ${
                          showTab === 'tab4' ? 'active' : ''
                        }`}
                        data-toggle="tab"
                        data-tab_name="tab4"
                        onClick={handleClickTab}
                      >
                        Purchase Logs
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content default-tab-content mb-5"
                    style={{ borderColor: '#dee2e6' }}
                  >
                    <div
                      id="tab-top-1"
                      className={
                        showTab == 'tab1'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body">
                        <PermissionsGate
                          scopes={['get_specific_product']}
                          RenderError={AlertDefaultError}
                        >
                          <ProductInformation
                            styles={styles}
                            values={values}
                            productData={values}
                            onInputChange={onInputChange}
                            updateForm={updateForm}
                            divHidden={divHidden}
                            populateSubSegment={populateSubSegment}
                            prodSegmentHolder={prodSegmentHolder}
                            productSubSegment={productSubSegment}
                            populateSubGroup={populateSubGroup}
                            prodGroupHolder={prodGroupHolder}
                            productSubGroup={productSubGroup}
                            supplierHolder={supplierHolder}
                            cprDocument={cprDocument}
                            vatIsCheck={vatIsCheck}
                            vatCheck={vatCheck}
                            computeVatCost={computeVatCost}
                            costVatValue={costVatValue}
                            costUnit={costUnit}
                            costUnitVatValue={costUnitVatValue}
                            computeVatSelling={computeVatSelling}
                            sellingVatValue={sellingVatValue}
                            sellingUnit={sellingUnit}
                            sellingUnitVatValue={sellingUnitVatValue}
                            paymentsField={paymentsField}
                            handleAddPaymentTerms={handleAddPaymentTerms}
                            renderPaymentTerms={renderPaymentTerms}
                          />
                        </PermissionsGate>
                      </div>
                    </div>
                    <div
                      id="tab-top-2"
                      className={
                        showTab == 'tab2'
                          ? 'card tab-pane active show'
                          : 'card tab-pane'
                      }
                    >
                      <div className="card-body">
                        <PermissionsGate
                          scopes={['get_specific_product']}
                          RenderError={AlertDefaultError}
                        >
                          <ProductPackaging
                            styles={styles}
                            values={values}
                            productData={productData}
                            onInputChange={onInputChange}
                            updateForm={updateForm}
                            specifyOthers={specifyOthers}
                            packagingHolder={packagingHolder}
                            unitMeasureHolder={unitMeasureHolder}
                            computeUnit={computeUnit}
                            smallUplaodImage={smallUplaodImage}
                            smallImage={smallImage}
                            bigUplaodImage={bigUplaodImage}
                            bigImage={bigImage}
                          />
                        </PermissionsGate>
                      </div>
                    </div>
                    <div
                      className={
                        showTab === 'tab3' ? 'tab-pane active show' : 'tab-pane'
                      }
                    >
                      <div style={{ margin: '-30px -20px' }}>
                        <Forecast
                          product={productData}
                          productCode={product_code}
                          visible={showTab === 'tab3'}
                          otherData={{
                            unitMeasureHolder,
                            prodSegmentHolder,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        showTab === 'tab4' ? 'tab-pane active show' : 'tab-pane'
                      }
                    >
                      <div style={{ margin: '-10px -20px 10px -20px' }}>
                        <Purchase
                          product={productData}
                          productCode={product_code}
                          visible={showTab === 'tab4'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-width"
        keyboard={false}
        backdrop="static"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Create New Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProducts />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ViewProduct;
