import { lazy } from "react";

const BalanceSheet = lazy(() =>
  import("../pages/BalanceSheetNewFormat/BalanceSheet")
);

const balanceSheetNewFormatRoute = {
  public: [],
  auth: [
    {
      name: "balance_sheet_v2",
      path: "/finance-and-accounting/balance-sheet-v2",
      component: BalanceSheet,
    },
  ],
};

export default balanceSheetNewFormatRoute;
