import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InventoriesLedgerReportScreen from '../Screens/InventoriesLedgerReportScreen';
import Swal from 'sweetalert2';
import {
  requestGetInventoriesByTransactionDate,
  requestInventoryExport,
} from '../../../api/ledgers';
import { toast } from 'react-toastify';
import useNavActive from '../../../components/Navbar/useNavActive';
import axiosInstance from '../../../components/axiosInstance';
import { getProductSearch } from '../../../api/product';
import { getSupplier } from '../../../api/purchaseRequest';

const today = new Date();
const prior = new Date().setDate(today.getDate() - 20);
const priorDate = new Date(prior);

const formDefault = {
  supplier_id: '',
  product_id: [],
  date_from:
    priorDate.getFullYear() +
    '-' +
    (priorDate.getMonth() + 1) +
    '-' +
    priorDate.getDate(), //moment().format("YYYY-MM-DD"),
  date_to:
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(), //moment().subtract(30, "days").format("YYYY-MM-DD"),
  page: 0,
  page_size: 50,
  order_by: null,
  direction: null,
};

const Inventories = () => {
  //declaration
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [inventoriesData, setInventoriesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [suppliername_options, setsuppliername_options] = useState([]);
  const [productname_options, setproductname_options] = useState([]);

  useNavActive([
    'master_databases',
    'sub_ledger_database',
    'inventory_sub_ledger',
  ]);

  useEffect(() => {
    listSupplier();
    listProducts();
  }, []);

  const handleSearch = () => loadLedgerData(formData);

  const loadLedgerData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setInventoriesData([]);

    try {
      let res = await requestGetInventoriesByTransactionDate(form);

      const { code, result, total_rows } = res.data.response;

      if (code !== '00000') {
        toast.error(result);
        return;
      }

      if (result.data) {
        setInventoriesData(result.data);
      }

      setTotalRow(total_rows);
      setLoading(false);
    } catch (error) {
      toast.error('Error occured. Please try again later');
    } finally {
      setLoading(false);
    }
  };

  const listSupplier = (data = {}) => {
    setsuppliername_options([]);

    getSupplier(data)
      .then((res) => {
        let { code, result } = res.data.response;

        if (code !== '00000') {
          toast.warning('No Data Available');
        }

        setsuppliername_options(
          result.map((row) => ({
            label: row.company_name,
            value: row.vendor_id,
          })),
        );
      })
      .catch((err) => {});
    // axiosInstance.get(`/inventoryreport/dropdown/product`).then((response) => {});
  };

  const listProducts = (data = {}) => {
    setproductname_options([]);

    getProductSearch(data)
      .then((res) => {
        let { code, result } = res.data.response;

        if (code !== '00000') {
          toast.warning('No Data Available');
        }

        setproductname_options(
          result.map((row) => ({
            label: row.product_name,
            value: row.product_code,
          })),
        );
      })
      .catch((err) => {});
    // axiosInstance.get(`/inventoryreport/dropdown/product`).then((response) => {});
  };

  const handleSelectedTab = (key) => {
    if (key === 'sales_ledger') {
      history.push('/ledgers/sales');
    } else if (key === 'receivables_ledger') {
      history.push('/ledgers/receivables');
    } else if (key === 'inventories_ledger') {
      history.push('/ledgers/inventories');
    } else if (key === 'sales_per_product_ledger') {
      history.push('/ledgers/sales-per-products');
    } else if (key === 'vatable_sales_ledger') {
      history.push('/ledgers/vatable-sales');
    } else if (key === 'product_ledger') {
      history.push('/ledgers/products');
    } else if (key === 'ytd_sales_ledger') {
      history.push('/ledgers/ytd-sales');
    } else {
      history.push('/ledgers/sales');
    }
  };

  const handleChangeSupplier = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    listProducts({ supplier_id: e.target.value });
  };

  const getProduct = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      product_id: e.target.value.map((row) => row.value),
    }));
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const searchLedger = () => {
    toast.loading('Getting Transaction, Please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });

    requestGetInventoriesByTransactionDate(formData)
      .then((res) => {
        //const userToken = res.data.response.result.access_token;
        if (res.data.response.code === '00000') {
          if (res.data.response.result.length === 0) {
            toast.warning('No Data Available');
          }
          toast.dismiss();
          setInventoriesData(res.data.response.result);
        } else {
          Swal.fire({
            title: 'Error',
            text: res,
            icon: 'error',
          });
        }
      })
      .catch((err) => {});
  };

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };
    //setFormData(temp);
    loadLedgerData(temp);
  };

  const OnHandleExport = () => {
    if (
      formData.date_from === '' ||
      formData.date_to === '' ||
      formData.product_id === ''
    ) {
      Swal.fire({
        title: 'Warning',
        text: 'Please select product and dates!',
        icon: 'warning',
      });
    } else {
      let tmp = {
        ...formData,
        id: formData.product_id,
        date_from: formData.date_from,
        date_to: formData.date_to,
        tab: 'inventory',
      };

      toast.loading('Exporting data in progress, Please wait...', {
        position: toast.POSITION.TOP_RIGHT,
      });

      requestInventoryExport(tmp)
        .then((res) => {
          if (res.data.response.code === '00000') {
            console.log(res);
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <InventoriesLedgerReportScreen
      formData={formData}
      suppliername_options={suppliername_options}
      productname_options={productname_options}
      handleChangeSupplier={handleChangeSupplier}
      handleChange={getProduct}
      dateChange={getDate}
      handleSearch={handleSearch}
      onSearch={searchLedger}
      inventoriesData={inventoriesData}
      selectedTab={'inventories_ledger'}
      handleSelectedTab={handleSelectedTab}
      loading={loading}
      totalRow={totalRow}
      handleDataTableChange={handleDataTableChange}
      reloadTable={reloadTable}
      handleExport={OnHandleExport}
    />
  );
};

export default Inventories;
