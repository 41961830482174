import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCustomersInfoAndOutstandingBalance } from "../../../api/collections";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import defaultOptions from "../../../utils/defaultOptions";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { exportColumnModal } from "./exportColumnModal";
import "./exportColumnModal/hideSelectAll.css";
import fileDownload from "js-file-download";

import debounce from "lodash.debounce";
import { getExportData } from "../../../api/smppProductMaintenance";

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

//QuickSearchToolbar
function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const ExportFormModal = ({ toggle, closeModal }) => {
  const [reloadTable, setReloadTable] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [form, setForm] = useState({
    page_size: defaultOptions.pageSize,
    offset: 0,
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const columns = exportColumnModal();

  useEffect(() => {
    loadCustomers(form);
  }, []);

  const [selectionModel, setSelectionModel] = useState([]);

  const loadCustomers = async (formData) => {
    setExportData([]);
    setLoading(true);
    try {
      let res = await getCustomersInfoAndOutstandingBalance(formData);
      const { code, result, total_rows } = res.data.response;

      if (code !== "00000") {
        toast.error("Error Processing Request, Please try again later.");
        setTotalRows(0);
        return;
      }

      setExportData(result.data);
      setTotalRows(total_rows);
    } catch (error) {
      toast.error("Error Processing Request, Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDataTableChange = (data) => {
    setForm({ ...form, ...data });
    loadCustomers({ ...form, ...data });
  };

  const handleSearchExporthData = useCallback(
    debounce((newFormData) => loadCustomers(newFormData), 1000),
    []
  );

  const handleSearch = (e) => {
    let newFormData = {
      ...form,
      search: e.target.value,
    };

    setReloadTable(Math.floor(Math.random() * 101));
    handleSearchExporthData(newFormData);

    setForm(newFormData);
  };

  const handleClearSearch = () => {
    let newFormData = { ...form, search: "" };
    setReloadTable(Math.floor(Math.random() * 101));
    loadCustomers(newFormData);
    setForm(newFormData);
  };

  const handleExportData = async () => {
    if (!selectionModel.length) return;

    const id = selectionModel[0];
    const customer = exportData.find((row) => row.customer_id === id);

    let tload = toast.loading("Please wait...");

    try {
      let res = await getExportData(selectionModel);

      const { code, result } = res.data.response;

      if (code !== "00000") {
        toast.error(Array.isArray(result) ? result.join("<br />") : result);
        return;
      }

      window.location.href = result;

      //fileDownload(res.data, customer.customer_name + ".xlsx");
    } catch (error) {
      console.log({ error });
      toast.error("Error Processing Request, Please try again later.");
    } finally {
      toast.dismiss(tload);
    }
  };

  return (
    <Fragment>
      <Modal
        show={toggle}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Customer Product</Modal.Title>
        </Modal.Header>
        <Modal.Body className="anti-select-all">
          <PrimaryDataTable
            rows={exportData}
            columns={columns}
            rowCount={totalRows}
            getRowId={(row) => row.customer_id}
            loading={loading}
            disableColumnFilter
            checkboxSelection
            hideFooterSelectedRowCount
            mode="server"
            onChange={handleDataTableChange}
            reload={reloadTable}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: form.search,
                onChange: handleSearch,
                clearSearch: handleClearSearch,
              },
            }}
            selectionModel={selectionModel}
            onSelectionModelChange={(selection) => {
              const newSelectionModel = selection;
              if (newSelectionModel.length > 1) {
                const selectionSet = new Set(selectionModel);
                const result = newSelectionModel.filter(
                  (s) => !selectionSet.has(s)
                );
                setSelectionModel(result);
              } else {
                setSelectionModel(newSelectionModel);
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            disabled={!selectionModel.length}
            onClick={handleExportData}
          >
            Export
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ExportFormModal;
