import React, { useState } from "react";
import axiosInstance from "../axiosInstance";
import useForm from "../../useForm";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Fragment } from "react";

const ChangePassword = ({ email }) => {
  const [showPassword, setPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const search = useLocation().search;
  const history = useHistory();

  let queries = queryString.parse(search);

  const [values, handleChange] = useForm({
    password: "",
    token: queries.token,
    email: queries.email,
  });

  const [error, setError] = useState("");

  const changePassword = () => {
    if (values.password === values.password2) {
      setError("");
      axiosInstance
        .post("/forgot-password/reset", values)
        .then((res) => {
          console.log(res);
          if (res.data[0] === "status") {
            Swal.fire({
              icon: "Success",
              title: "Password Has been Change!",
              text: res.data[1],
            });
            history.push("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.email,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setError("Password doesn't match!");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    changePassword();
  };
  return (
    <div>
      <div class="page page-center">
        <div class="container-tight py-4">
          <div class="text-center mb-4">
            <a href="#">
              <img src="/Stacked Version.png" height="120px !important" />
            </a>
          </div>
          <form
            class="card card-md"
            action="."
            method="get"
            onSubmit={submitHandler}
          >
            <div class="card-body">
              {/* <h3 class="text-center mb-4">Change your password</h3> */}
              {error != "" ? (
                <div className="error text-danger text-center">
                  <h5 className="text-center">{error}</h5>
                </div>
              ) : null}
              <input
                type="hidden"
                className="form-control"
                id="email"
                name="email"
                value={queries.email}
              />
              <input
                type="hidden"
                className="form-control"
                id="token"
                name="token"
                value={queries.token}
              />
              <div class="mb-3">
                <label class="form-label">New Password</label>
                <div class="input-group input-group-flat">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="New Password"
                    autocomplete="off"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                  <span class="input-group-text">
                    <a
                      href="#"
                      class="link-secondary"
                      title="Show password"
                      data-bs-toggle="tooltip"
                      onClick={() => {
                        setPassword(!showPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Confirm Password</label>
                <div class="input-group input-group-flat">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Confirm Password"
                    autocomplete="off"
                    id="password"
                    name="password2"
                    value={values.password2}
                    onChange={handleChange}
                    required
                  />
                  <span class="input-group-text">
                    <a
                      href="#"
                      class="link-secondary"
                      title="Show password"
                      data-bs-toggle="tooltip"
                      onClick={() => {
                        setConfirmPassword(!showConfirmPassword);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
              <div class="form-footer">
                <button
                  type="submit"
                  value="Submit"
                  class="btn btn-primary w-100"
                >
                  Update Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <div>
        <div className="content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 contents">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="form-block shadow">
                      <div className="mb-4">
                        <h3 className="text-black">Change your password</h3>
                      </div>
                      <form onSubmit={submitHandler}>
                        {error != "" ? (
                          <div className="error text-danger">
                            <h5 className="text-center">{error}</h5>
                          </div>
                        ) : null}
                        <div className="form-group first">
                          <input
                            type="hidden"
                            className="form-control"
                            id="email"
                            name="email"
                            value={queries.email}
                          />
                        </div>

                        <div className="form-group first">
                          <input
                            type="hidden"
                            className="form-control"
                            id="token"
                            name="token"
                            value={queries.token}
                          />
                        </div>

                        <div className="form-group first">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="New Password"
                            value={values.password}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-group first">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password2"
                            placeholder="Confirm your password"
                            value={values.password2}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-pill text-white btn-block btn-primary"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ChangePassword;
