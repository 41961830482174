import React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import WarehouseZoneFormModal from "../WarehouseZoneFormModal";
import DefaultLayout from "../../../../components/Layouts/DefaultLayout";
import useNavActive from "../../../../components/Navbar/useNavActive";
import ZoneTypeList from "../ZoneTypeList";

const ZoneTypeScreen = ({
  zoneType,
  zoneFormModalData,
  toggle,
  handleCloseFormModal,
  handleEditZone,
  onCallBack,
  setZoneFormModal,
}) => {
  useNavActive(["master_databases", "warehouse_database", "zone_type"]);

  return (
    <DefaultLayout title="Zone Type List">
      <Tabs
        id="controlled-tab-example"
        activeKey={"general"}
        className="default-tab"
        onSelect={(selected) => console.log(selected)}
      >
        <Tab
          key={0}
          eventKey={"general"}
          title={"General"}
          className={"default-tab-content"}
        >
          <div className="text-right mb-2">
            <Button
              variant="success"
              className="btn-payment-application-create btn-add-create"
              onClick={() => setZoneFormModal({ data: null, toggle: true })}
            >
              <PlusCircleFill /> Add Zone
            </Button>
          </div>
          <ZoneTypeList data={zoneType} handleEdit={handleEditZone} />

          <WarehouseZoneFormModal
            toggle={toggle}
            defaultData={zoneFormModalData}
            onClose={handleCloseFormModal}
            onCallback={onCallBack}
          />
        </Tab>
      </Tabs>
    </DefaultLayout>
  );
};

export default ZoneTypeScreen;
