import { Fragment } from 'react';
import PrimaryInputPassword from '../../PrimaryInputPassword';
import PrimaryAsyncSelect from '../../PrimaryAsyncSelect';

import { loadDepartments, loadJobs } from '../../../api/users';
import { Form, Image } from 'react-bootstrap';

const UserForm = ({ form, handleChange, handleFileUpload, showPassword }) => {
  console.log({ form });
  return (
    <Fragment>
      <div className="card-body">
        <div className="form-group">
          <label className="form-label">
            Username <span className="req">*</span>
          </label>

          <input
            id="username"
            className="form-control"
            name="username"
            type="text"
            value={form.username}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Employee No. <span className="req">*</span>
          </label>

          <input
            id="employee_no"
            className="form-control"
            name="employee_no"
            type="text"
            value={form.employee_no}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Last Name <span className="req">*</span>
          </label>

          <input
            className="form-control"
            name="last_name"
            id="last_name"
            type="text"
            value={form.last_name}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            First Name <span className="req">*</span>
          </label>

          <input
            className="form-control"
            name="first_name"
            id="first_name"
            type="text"
            value={form.first_name}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Email <span className="req">*</span>
          </label>

          <input
            className="form-control"
            name="email"
            id="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        {showPassword && (
          <div className="form-group">
            <label className="form-label">
              Password <span className="req">*</span>
            </label>

            <PrimaryInputPassword
              autocomplete="off"
              onChange={handleChange}
              required={true}
              tabIndex=""
              placeholder="Password"
              id="password"
              name="password"
            />
          </div>
        )}

        <div className="form-group">
          <label className="form-label">
            Department <span className="req">*</span>
          </label>

          <PrimaryAsyncSelect
            onChange={(e) =>
              handleChange({
                target: { name: 'department_id', value: e.value },
              })
            }
            customFunction={loadDepartments}
            name="department_id"
            value={form.department_id}
            isClearable={true}
            config={{
              searchField: 'department_name',
              valueField: 'department_id',
              labelField: 'department_name',
            }}
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Job <span className="req">*</span>
          </label>

          <PrimaryAsyncSelect
            onChange={(e) =>
              handleChange({
                target: { name: 'job_id', value: e.value },
              })
            }
            customFunction={loadJobs}
            isClearable={true}
            name={'job_id'}
            value={form.job_id}
            config={{
              searchField: 'job_name',
              valueField: 'job_id',
              labelField: 'job_name',
            }}
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Contact Number <span className="req">*</span>
          </label>

          <input
            className="form-control"
            name="contact_no"
            id="contact_no"
            type="text"
            value={form.contact_no}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Signature</label>
          <Form.Control
            type="file"
            name="signature"
            onChange={handleFileUpload}
          />
        </div>

        {form.signature_file_path !== '' ? (
          <Image src={form.signature_file_path} thumbnail />
        ) : (
          ''
        )}
      </div>
    </Fragment>
  );
};

export default UserForm;
