import { useState } from 'react';
import _ from 'lodash';
import './styles.css';
import { X } from 'react-bootstrap-icons';

const MultipleFileUploadPreview = ({
  name,
  newAttachments,
  oldAttachments,
  handleFileUpload,
  handleRemoveFile,
  disableDelete = true,
  disabled = false,
  resetKey = 0,
  hideInput = false,
}) => {
  return (
    <div className="mfup-container">
      <div className="mfup-preview">
        {oldAttachments?.map((file) => (
          <div className="mfup-preview-file" key={file.path}>
            <span>
              <a href={file.path} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            </span>
            {!disableDelete && (
              <X
                className="mfup-remove-file"
                onClick={() => handleRemoveFile(file)}
              />
            )}
          </div>
        ))}
        {newAttachments?.map((file) => (
          <div key={file.name} className="mfup-preview-file">
            <span>{file.name}</span>
            {/*<X className="mfup-remove-file" />*/}
          </div>
        ))}
      </div>
      {!hideInput && (
        <input
          type="file"
          name={name}
          className="mfup-file-upload form-control"
          multiple
          onChange={handleFileUpload}
          disabled={disabled}
          key={resetKey}
        />
      )}
    </div>
  );
};

export default MultipleFileUploadPreview;
