import makeApiRequest from "./makeApiCall";

//sales
export const getTrialBalance = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/trial_balance/get`, "GET", null, data);
};

export const getTrialBalanceExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/trial_balance/get_export`, "GET", null, data);
};
