import { Fragment } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import PrimaryDatePicker from '../PrimaryDatePicker';
import Select from 'react-select';

const ExportModal = ({
  show,
  setModalShow,
  handleConfirmReturn,
  handleChange,
}) => {
  const selectType = [
    {
      label: 'Returns to Supplier',
      value: 'SRPF-RTS',
    },
    {
      label: 'Customer Returns',
      value: 'SRPF',
    },
  ];
  return (
    <Fragment>
      <Modal
        show={show.show}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Filter Export
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <PrimaryDatePicker
                value={show.date_from}
                placeholder="Date From"
                onChange={(date) =>
                  handleChange({
                    target: {
                      name: 'date_from',
                      value: date,
                    },
                  })
                }
              />
            </Col>
            <Col>
              <PrimaryDatePicker
                value={show.date_to}
                placeholder="Date To"
                onChange={(date) =>
                  handleChange({
                    target: {
                      name: 'date_to',
                      value: date,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                isClearable
                options={selectType}
                value={
                  selectType.find((row) => row.value === show.srpf_type) || null
                }
                className="form-control padding-0 dropdown-account-officer-select"
                classNamePrefix="dropdown-account-officer-select_"
                placeholder="Select All Customer"
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'srpf_type',
                      value: e?.value || '',
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => handleConfirmReturn()}
          >
            Export
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ExportModal;
