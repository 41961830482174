import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { orderColumn } from './const';
import PrimaryDataTable from '../PrimaryDataTable';
// import debounce from "lodash.debounce";

const CustomerOrderHistoryModal = ({
  selectionModel,
  setSelectionModel,

  handleModalCustomerOrder,
  handleModalCustomerOrderSet,

  customerOrderData,
  historicalTotal,
  handleSelectedOrderProduct,
  handlePageChange,

  customerID,
  paginateHistory,
  setPaginateHistory,
  loadCustomerOrderHistoryData,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleFilterProducts = (event) => {
    let newPaginate = {
      ...paginateHistory,
      search: event.target.value,
    };

    setSearchText(event.target.value);

    setPaginateHistory(newPaginate);
    loadCustomerOrderHistoryData(customerID, newPaginate);
  };

  const handleFilterModelChange = (filterModel) => {
    if (Array.isArray(filterModel.items)) {
      const searchValue = filterModel.items[0]?.value || '';

      handleFilterProducts({
        target: {
          value: searchValue,
        },
      });

      setFilterModel(filterModel);
    }
  };

  // const handleFilterProductsDebounce = debounce(
  // 	(event) => {
  // 		handleFilterProducts(event)
  // 	}
  // , 800);

  return (
    <div>
      <Modal
        size="xl"
        show={handleModalCustomerOrder}
        onHide={() => handleModalCustomerOrderSet(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-width"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            Select Products <small>(Historical)</small>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleFilterProducts(e)}
                value={searchText}
                placeholder="Search here..."
              />
            </div>
            <div className="col-md-12">
              <PrimaryDataTable
                rows={customerOrderData}
                columns={orderColumn}
                getRowId={(row) => row.transaction_id}
                checkboxSelection={true}
                rowCount={historicalTotal}
                mode="server"
                onChange={handlePageChange}
                selectionModel={selectionModel}
                onSelectionModelChange={(param) => {
                  setSelectionModel(param);
                  handleSelectedOrderProduct(
                    customerOrderData.filter((value) => {
                      return param.includes(value.transaction_id);
                    }),
                  );
                }}
                filterMode="server"
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) =>
                  handleFilterModelChange(newFilterModel)
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-success"
            onClick={() => handleModalCustomerOrderSet(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerOrderHistoryModal;
