import { Tabs, Tab } from "react-bootstrap";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";
import PrimaryDatePicker from "../../../components/PrimaryDatePicker";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { getCustomers, getStatus } from "../../../api/ledgers";
import "../ledger.css";

import {
  AlertDefaultError,
  PermissionsGate,
} from "../../../components/PermissionsGate";
import { vatableSalesCol } from "../const";

const VatableSalesScreen = ({
  formData,
  vatableSalesData,
  loading,
  totalRow,
  reloadTable,
  selectedTab,
  handleSelectedTab,
  handleChange,
  handleDataTableChange,
  handleSearch,
  handleExport,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab eventKey="inventories_ledger" title="Inventory Sub Ledger"></Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            className="default-tab-content"
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          >
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <PermissionsGate scopes={["view_vatable_sales_ledger_list"]}>
                    <div className="col-md-3">
                      <label>Status Of Customer</label>
                      <PrimaryAsyncSelect
                        onChange={(e) =>
                          handleChange({
                            target: { name: "reg_or_nonreg", value: e.value },
                          })
                        }
                        customFunction={getStatus}
                        isClearable={false}
                        name={"reg_or_nonreg"}
                        value={formData.reg_or_nonreg}
                        config={{
                          searchField: "keyword",
                          valueField: "customer_regular_nonregular_id",
                          labelField: "description",
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <label>Select Customer</label>
                      <PrimaryAsyncSelect
                        onChange={(e) =>
                          handleChange({
                            target: { name: "customer_id", value: e.value },
                          })
                        }
                        customFunction={getCustomers}
                        isClearable={false}
                        name={"customer_id"}
                        value={formData.customer_id}
                        config={{
                          searchField: "keyword",
                          valueField: "customer_id",
                          labelField: "customer_name",
                        }}
                      />
                    </div>

                    <div className="col-md-2">
                      <label>Date From</label>
                      <label className="req">*</label>
                      <PrimaryDatePicker
                        name={"date_from"}
                        datePickerFormat={"yyyy-MM-dd"}
                        datePickerFormatString={"YYYY-MM-DD"}
                        value={formData.date_from}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "date_from", value: date },
                          })
                        }
                      />
                    </div>

                    <div className="col-md-2">
                      <label>Date To</label>
                      <label className="req">*</label>
                      <PrimaryDatePicker
                        name={"date_to"}
                        datePickerFormat={"yyyy-MM-dd"}
                        datePickerFormatString={"YYYY-MM-DD"}
                        value={formData.date_to}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "date_to", value: date },
                          })
                        }
                      />
                    </div>

                    <div className="col-md-1">
                      <label>&nbsp;</label>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="col-md-1 text-right">
                      <label>&nbsp;</label>
                      <div>
                        <PermissionsGate
                          scopes={["export_vatable_sales_ledger_list"]}
                        >
                          <button
                            className=" btn btn-success"
                            onClick={handleExport}
                          >
                            Export
                          </button>
                        </PermissionsGate>
                      </div>
                    </div>
                  </PermissionsGate>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <PermissionsGate
                scopes={["view_vatable_sales_ledger_list"]}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={vatableSalesData}
                  columns={vatableSalesCol}
                  getRowId={(row) => row.id}
                  onChange={handleDataTableChange}
                  disableColumnFilter
                  rowCount={totalRow}
                  loading={loading}
                  mode="server"
                  reload={reloadTable}
                />
              </PermissionsGate>
            </div>
          </Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default VatableSalesScreen;
