import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import User from "../User/User";
import Roles from "../Roles/Roles";
import Permission from "../Permissions/Permission";
import useNavActive from "../Navbar/useNavActive";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const UserControl = () => {
  useNavActive([
    "master_databases",
    "user_database"
  ]);
  const [showTab, setTab] = useState("tab1");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [showUsers, setShowUsers] = useState(true);
  const [showRoles, setShowRoles] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useNavActive(["master_databases", "user_database", "user_control"]);

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">Users and Roles</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card-tabs mt-2">
            <ul className="nav nav-tabs bg-header">
              <li className="nav-item">
                <a
                  href="#tab-top-1"
                  className={showTab == "tab1" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab1");
                    setShowUsers(true);
                    setShowRoles(false);
                    setShowPermissions(false);
                  }}
                >
                  User Management
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-2"
                  className={showTab == "tab2" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab2");
                    setShowUsers(false);
                    setShowRoles(true);
                    setShowPermissions(false);
                  }}
                >
                  Roles Management
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-3"
                  className={showTab == "tab3" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab3");
                    setShowUsers(false);
                    setShowRoles(false);
                    setShowPermissions(true);
                  }}
                >
                  Permissions Management
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="tab-top-1"
                className={
                  showTab == "tab1"
                    ? "card tab-pane active show"
                    : "card tab-pane"
                }
              >
                <div className="card-body">{showUsers ? <User /> : ""}</div>
              </div>
              <div
                id="tab-top-2"
                className={
                  showTab == "tab2"
                    ? "card tab-pane active show"
                    : "card tab-pane"
                }
              >
                <div className="card-body">{showRoles ? <Roles /> : ""}</div>
              </div>
              <div
                id="tab-top-3"
                className={
                  showTab == "tab3"
                    ? "card tab-pane active show"
                    : "card tab-pane"
                }
              >
                <div className="card-body">
                  {showPermissions ? <Permission /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserControl;
