import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Async from 'react-select/async';
import Select from 'react-select';

import _ from 'lodash';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import SignaturePad from 'react-signature-pad-wrapper';

import Box from '@material-ui/core/Box';
import { GridPagination } from '@mui/x-data-grid-pro';
import { generateUUID, numberFormatCurrency } from '../../utils/helper';

import CustomerAgingModal from '../Srpf/CustomerAgingModal';
import CustomerOrderProductsModal from '../Srpf/CustomerOrderProductsModal';
import CustomerOrderHistoryModal from '../Srpf/CustomerOrderHistoryModal';

import {
  selectedOrderListColumn,
  selectedAgingListColumn,
  dispositionOptions,
  customStyles,
  transactionForm,
  srpfType,
} from './const';

import {
  getCustomersData,
  getCustomersDataID,
  getCustomersOrderData,
  getCustomersOrderHistoryData,
  getCustomersAgingData,
  createSRPF,
  updateSRPF,
  getDeclinedSRPF,
  getCustomerDetails,
} from '../../api/srpf';

import { getUnitofMeasure, getProductbyid } from '../../api/product';

import PrimaryDataTable from '../PrimaryDataTable';
import MultipleFileUploadPreview from '../MultipleFileUploadPreview';
import { Form } from 'react-bootstrap';
import PrimaryLoader from '../PrimaryLoader';

const AddSrpf = ({ editMode, customerCode, srpfNo }) => {
  const history = useHistory();
  // Show Signature Pad
  const [showPad, setShowPad] = useState(true);
  const signatureRef = useRef(null);
  // Customers List
  const [showCustomers, setShowCustomers] = useState([]);
  // OUM List
  const [showOUM, setShowOUM] = useState([]);
  const [showProductOUM, setProductShowOUM] = useState();
  // Multiple Reason
  const [showMultipleReasonRow, setShowMultipleReasonRow] = useState([]);
  // See Aging Modal
  const [showAging, setShowAging] = useState(false);
  const [agingLoader, setAgingLoader] = useState(false);
  // Customer Aging Transaction
  const [showAgingTransaction, setAgingTransaction] = useState([]);
  // disable DR/SI button
  const [dispositionButton, setDispositionButton] = useState(false);
  // Customer Order Product DR/SI
  const [customerOrderData, setCustomerOrderData] = useState([]);
  const [customerOrderLoading, setCustomerOrderLoading] = useState(false);
  const [customerOrderHistoryData, setCustomerOrderHistoryData] = useState([]);

  // const [orderTotal, setOrderTotal] = useState(0);
  const [historicalTotal, setHistoricalTotal] = useState(0);

  const [selectedDisposition, setSelectedDisposition] = useState('');

  const [showCustomerOrderModal, setShowCustomerOrderModal] = useState(false);
  const [showCustomerOrderHistoryModal, setShowCustomerOrderHistoryModal] =
    useState(false);

  // Select Selected Customer Option
  const [defaultCustomer, setDefaultCustomer] = useState('');
  const [selectedCustomerID, setSelectedCustomerID] = useState('');

  // Set Selected Orders ERP and HISTORICAL
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [selectedOrderHistory, setSelectedOrderHistory] = useState([]);
  // Merged Selected Order from ERP and History
  const [selectedOrderList, setSelectedOrderList] = useState([]);
  const [selectedAgingList, setSelectedAgingList] = useState([]);

  // Show Modals
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionHistoryModel, setSelectionHistoryModel] = useState([]);
  const [selectionAgingModel, setSelectionAgingModel] = useState([]);

  // const [page, setPage] = useState(25);
  const [form, setForm] = useState(transactionForm);

  const [customerID, setCustomerID] = useState('');
  const [pageLoader, setPageLoader] = useState(false);

  const [paginateHistory, setPaginateHistory] = useState({
    page_size: 50,
    page: 1,
    search: '',
  });

  useEffect(() => {
    if (srpfNo) {
      loadSrpfData();
    }

    if (customerCode) {
      loadCustomerDetailsCode(customerCode);
    }
  }, [showCustomers]);

  useEffect(() => {
    handleMixedOrderProducts();
    loadOUM();
  }, [selectedOrder, selectedOrderHistory]);

  useEffect(() => {
    handleComputeTotal();
  }, [selectedOrderList]);

  const showSignaturePad = () => {
    setShowPad(false);
    setTimeout(() => {
      setShowPad(true);
      setForm((prevState) => ({ ...prevState, old_signature: [] }));
    }, 0);
  };

  const loadCustomerDetailsCode = (customerCode) => {
    getCustomerDetails({
      customer_code: customerCode,
    })
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          handleSelectCustomer({
            value: result.customer_id,
            codes: result.customer_code,
            label: result.customer_code + ' - ' + result.customer_name,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid Customer Code:' + customerCode,
        });
      });
  };

  // const loadCustomers = () => {
  //   getCustomersData()
  //     .then((res) => {
  //       let { code, result } = res.data.response;
  //       if (code === "00000") {
  //         setShowCustomers(result);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const loadOUM = () => {
    getUnitofMeasure()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          let oum = result.map((row) => ({
            unit_id: row.unit_id,
            value: row.unit_name,
            label: row.unit_name,
          }));
          setShowOUM(oum);
        }
      })
      .catch((err) => {});
  };

  const loadCustomersPromise = (inputValue) =>
    new Promise((resolve, reject) => {
      getCustomersData({ search_param: inputValue })
        .then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            let data = result.map((row) => ({
              value: row.customer_id,
              codes: row.customer_code,
              label: row.customer_code + ' - ' + row.customer_name,
            }));

            data = _.uniqBy(data, (e) => e.value);

            resolve(data);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  const loadCustomerDetails = (customerID) => {
    getCustomersDataID({
      customer_id: customerID,
    })
      .then((res) => {
        if (res.data.response.code === '00000') {
          let customer = res.data.response.result;
          let customer_address = '';

          if (res.data.response.result.get_customer_billing === null) {
            Swal.fire({
              icon: 'info',
              title: 'Oops...',
              text: 'This Customer has no Billing Information.',
            });
          } else {
            customer_address =
              customer.get_customer_billing.get_address.bldg_street_address;
          }

          setForm({
            ...form,
            customer_id: customer.customer_id,
            account_name: customer.customer_name,
            account_type: customer.get_customer_type.channel,
            address: customer_address,
          });
        }
      })
      .catch((err) => {});
  };

  const loadCustomerOrderData = (customerID) => {
    setCustomerOrderLoading(true);
    getCustomersOrderData({
      customer_id: customerID,
    })
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          setCustomerOrderData(
            result.map((value) => {
              // let total_qty = (value.qty_ordered - (value.bad_order_qty + value.returned_qty));
              return {
                ...value,
                transaction_id: Math.random().toString(16).slice(2),
                transaction_type: 'ERP',
                qty_ordered: value?.remaining_qty || 0,
                // selling_price : value.srp * total_qty
              };
            }),
          );
          setCustomerOrderLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadCustomerOrderHistoryData = (customerID, newPaginate = []) => {
    getCustomersOrderHistoryData({
      customer_id: customerID,
      si_number: '',
      ...paginateHistory,
      ...newPaginate,
    }).then((res) => {
      let { code, result, total_rows } = res.data.response;
      if (code === '00000') {
        setHistoricalTotal(total_rows);
        setCustomerOrderHistoryData(
          result.map((value) => {
            // const dilCost = value.product_purchase_logs?.find(
            //   (item) => item.batch === value.batch_no,
            // );
            return {
              transaction_id: value.transaction_id,
              transaction_type: 'HISTORICAL',
              amount: value.gross,
              // batch_no: value.batch_no,
              // uom: value.uom,
              // discount: value.discount,
              // expiry_date: value.expiry_date,
              id_customer: value.customer_id,
              id_item: value.product_id,
              od_id: value.transaction_id,
              // product_code: value.product_code,
              // product_name: value.product_name,
              si_number: value.si_no,
              so_number: '',
              // dr_no: value.dr_no,
              qty_ordered: value.remaining_qty,
              // returned_qty: value.returned_qty,
              selling_price: value.price,
              // net: value.net,
              bad_order_qty: 0,
              fg: 0,
              srp: value.selling_price,
              vat: value.vat_exemption,
              // vat_exemption: value.vat_exemption,
              // unit_id: value.unit_id,
              // unit_vat: value.unit_vat,
              // unit_vat_exclusive: value.unit_vat_exclusive,
              // unit_vat_inclusive: value.unit_vat_inclusive,
              // diluted_cost: dilCost?.diluted_cost ?? 0, SA BACKEND NA TO MANGGAGALING
              // total_inventory_amount: value.total_inventory_amount,
              ...value,
            };
          }),
        );
      }
    });
  };

  const handleHistoricalPageChange = (e) => {
    let newPaginate = {
      page_size: e.page_size,
      page: e.page + 1,
    };

    setPaginateHistory(newPaginate);
    loadCustomerOrderHistoryData(customerID, newPaginate);
  };

  const loadSrpfData = () => {
    getDeclinedSRPF({
      srpf_id: srpfNo,
    })
      .then((res) => {
        let { code, result } = res.data.response;

        if (code === '00000') {
          let signature = result.documents
            .filter((row) => row.img_type === 'SIG')
            .map((row) => ({
              path: row.document_info?.raw_path,
              name: row.document_info?.file_name,
            }));

          let documents = result.documents
            .filter((row) => row.img_type === 'OTH')
            .map((row) => ({
              path: row.document_info?.raw_path,
              name: row.document_info?.file_name,
            }));

          setForm({
            ...form,
            srpf_approval_id: result.srpf_approval_id,
            srpf_no: result.srpf_no,
            account_name: result.account_name,
            account_type: result.account_type,
            address: result.address,
            customer_id: result.get_customer_info?.customer_id,
            customer_code: result.get_customer_info?.customer_code,
            customer_name: result.get_customer_info?.customer_name,
            srpf_date: result.srpf_date,
            srpf_type: result.srpf_type,
            remarks: result.remarks,
            total_cost: result.products.reduce((total, key) => {
              return total + parseFloat(key.unit_cost);
            }, 0),
            disposition: result.disposition,
            total_amount: result.total_amount,
            old_signature: signature || [],
            old_attachments: documents || [],
            products: result.products,
            disposition_reference: result.disposition_reference.map((row) => ({
              ...row,
              disposition_amount: row.applied_amount,
              disposition_si: row.si_no,
              disposition_dr: row.dr_no,
              disposition_balance: row.current_balance,
            })),
            get_customer_info: result.get_customer_info,
            reject_reason: result.reject_reason,
          });

          setSelectedOrderList(
            result.products.map((row) => ({
              ...row,
              transaction_id_old: row.transaction_id,
              transaction_id: generateUUID(),
              unique_id: generateUUID(),
              item_id: row.product_info?.product_id,
              product_name: row.product_info?.product_name,
              batch_no: row.batch_no,
              expiry_date: row.expiry_date,
              si_reference: row.si_reference,
              quantity: row.quantity,
              uom: row.unit,
              unit_cost: row.unit_cost,
              total_cost: row.total_cost,
              remarks: row.reason,
              is_loose: 0,
              packaging_name: row.unit,
              is_parent: row.is_loose === 0 ? 1 : 0,
            })),
          );

          setSelectedAgingList(
            result.disposition_reference.map((row) => ({
              ...row,
              disposition_id: row.disposition_approval_id,
              disposition_date: '',
              disposition_due_date: '',
              disposition_dr: row.dr_no || '',
              disposition_si: row.si_no || '',
              disposition_balance: row.current_balance,
              disposition_amount: row.applied_amount,
            })),
          );

          setCustomerID(result.get_customer_info?.customer_id);
          setSelectedCustomerID(result.get_customer_info?.customer_id);
          setDefaultCustomer(result.get_customer_info?.customer_code);

          loadCustomerOrderData(result.get_customer_info?.customer_id);
          loadCustomerOrderHistoryData(result.get_customer_info?.customer_id);

          setSelectedDisposition(result.disposition);
          // setDeclinedSRPFDetails(result);
          // setShowTransaction(true);
        }
      })
      .catch((err) => {});
  };

  const handleSelectCustomer = (event) => {
    if (event) {
      setCustomerID(event.value);
      loadCustomerDetails(event.value);

      loadCustomerOrderData(event.value);
      loadCustomerOrderHistoryData(event.value);

      setSelectedCustomerID(event.value);
      setDefaultCustomer(event);
      history.push('/collection/srpf/' + event.codes);

      setForm({ ...form, customer_code: event.codes });
      setSelectedOrderList([]);
    } else {
      setForm({
        ...form,
        customer_id: '',
        account_name: '',
        account_type: '',
        address: '',
      });

      setSelectedCustomerID('');
      setDefaultCustomer('');
      history.push('/collection/srpf');
    }
  };

  const handleShowTransactionModal = () => {
    if (selectedCustomerID) {
      setShowCustomerOrderModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select customer first',
      });
    }
  };

  const handleShowHistoryTransactionModal = () => {
    if (selectedCustomerID) {
      setShowCustomerOrderHistoryModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select customer first',
      });
    }
  };

  const handleSelectedOrderProduct = (productOrder) => {
    let selectedProducts = productOrder.map((value) => {
      return {
        ...value,
        transaction_id_old: value.od_id,
        transaction_id:
          value.transaction_type + '' + value.batch_no + '' + value.od_id,
        // transaction_type: value.transaction_type,
        product_name: value.product_name,
        // unit_cost: value.srp,
        unit_cost: value.selling_price,
        unit_cost_origin: value.selling_price,
        // od_id: value.od_id,
        item_id: value.id_item,
        // batch_no: value.batch_no,
        // expiry_date: value.expiry_date,
        so_reference: value.so_number,
        si_reference: value.si_number,
        quantity: value.qty_ordered,
        quantity_orig: value.qty_ordered,
        quantity_orig_box: value.qty_ordered,
        // total_cost : value.selling_price,
        total_cost:
          parseInt(value.qty_ordered) * parseFloat(value.selling_price),
        applicable_amount: value.selling_price,
        dr_reference: value.dr_no,
        reason: '',
        uom: value.uom,
        diluted_cost_orig: value.diluted_cost,
        unit: value.uom,
        // vat_exemption: value.vat_exemption,
        // unit_id: value.unit_id,
        // unit_vat: value.unit_vat,
        // unit_vat_exclusive: value.unit_vat_exclusive,
        // unit_vat_inclusive: value.unit_vat_inclusive,
        // diluted_cost: value.diluted_cost,
        // discount: value.discount,
        // total_inventory_amount: value.total_inventory_amount,
        is_parent: 1,
        is_loose: 0,
        unique_id: generateUUID(),
      };
    });

    setSelectedOrder(selectedProducts);
  };

  const handleSelectedOrderHistoryProduct = (productOrderHistory) => {
    let selectedProducts = productOrderHistory.map((value) => {
      return {
        ...value,
        transaction_id_old: value.transaction_id,
        transaction_id:
          value.transaction_type + '' + value.batch_no + '' + value.od_id,
        // transaction_type: value.transaction_type,
        // product_name: value.product_name,
        // unit_id: value.unit_id,
        // unit_cost: value.srp,
        unit_cost: value.selling_price,
        unit_cost_origin: value.selling_price,
        // od_id: value.od_id,
        item_id: value.id_item,
        // batch_no: value.batch_no,
        // expiry_date: value.expiry_date,
        so_reference: value.so_number,
        si_reference: value.si_number,
        quantity: value.qty_ordered,
        quantity_orig: value.qty_ordered,
        quantity_orig_box: value.qty_ordered,
        total_cost: value.selling_price * value.qty_ordered,
        applicable_amount: value.selling_price,
        dr_reference: value.dr_no,
        reason: '',
        uom: value.uom,
        diluted_cost_orig: value.diluted_cost,
        unit: value.uom,
        // vat_exemption: value.vat_exemption,
        // unit_vat: value.unit_vat,
        // unit_vat_exclusive: value.unit_vat_exclusive,
        // unit_vat_inclusive: value.unit_vat_inclusive,
        // diluted_cost: value.diluted_cost,
        // discount: value.discount,
        // total_inventory_amount: value.total_inventory_amount,
        is_parent: 1,
        is_loose: 0,
        unique_id: generateUUID(),
      };
    });

    setSelectedOrderHistory(selectedProducts);
  };

  const handleMixedOrderProducts = () => {
    // Generate New Unique ID
    let newDataOld = _.uniqBy(
      [...selectedOrderList, ...selectedOrder],
      (row) => row.transaction_id,
    );
    let newDataHis = _.uniqBy(
      [...selectedOrderList, ...selectedOrderHistory],
      (row) => row.transaction_id,
    );

    setSelectedOrderList(
      _.uniqBy([...newDataOld, ...newDataHis], (row) => row.unique_id).map(
        (value) => {
          return { ...value, unique_id: generateUUID() };
        },
      ),
    );
  };

  const handleChangeQuantity = useCallback(
    (event, row) => {
      setSelectedOrderList(
        selectedOrderList.map((value) => {
          if (value.unique_id === row.unique_id) {
            if (event.target.value > row.quantity_orig) {
              Swal.fire({
                icon: 'warning',
                title: row.quantity_orig + ' is the maximum quantity to return',
              });

              event.target.value = row.quantity_orig;

              return {
                ...value,
                quantity: row.quantity_orig,
                total_cost:
                  parseFloat(row.quantity_orig) * parseFloat(row.unit_cost),
              };
            } else {
              return {
                ...value,
                quantity: event.target.value,
                total_cost:
                  parseFloat(event.target.value) * parseFloat(row.unit_cost),
              };
            }
          } else {
            return value;
          }
        }),
      );
    },
    [selectedOrderList],
  );

  const handleChangeUOM = useCallback(
    (event, row) => {
      let data = {
        product_id: row.item_id,
      };
      getProductbyid(data)
        .then((res) => {
          let { code, result } = res.data.response;
          if (code === '00000') {
            // Get the selected option element
            const selectedOption =
              event.target.options[event.target.selectedIndex];

            // Get the value and data attribute from the selected option
            const selectedDataAttribute =
              selectedOption.getAttribute('data-type');

            let unit_cost_value =
              selectedDataAttribute === 'packaging'
                ? parseFloat(row.unit_cost_origin)
                : parseFloat(row.unit_cost_origin) / result.qty_per_pack;

            let unit_cost_value_diluted_cost =
              selectedDataAttribute === 'packaging'
                ? parseFloat(row.diluted_cost_orig)
                : parseFloat(row.diluted_cost_orig) / result.qty_per_pack;

            let total_cost_value =
              selectedDataAttribute === 'packaging'
                ? row.quantity * parseFloat(row.unit_cost_origin)
                : row.quantity *
                  (parseFloat(row.unit_cost_origin) / result.qty_per_pack);

            let quantity_orig =
              selectedDataAttribute === 'packaging'
                ? row.quantity_orig_box
                : result.qty_per_pack * row.quantity_orig_box;

            let diluted_cost =
              selectedDataAttribute === 'packaging'
                ? row.diluted_cost_orig
                : unit_cost_value_diluted_cost;

            let is_loose = selectedDataAttribute === 'packaging' ? 0 : 1;

            setSelectedOrderList(
              selectedOrderList.map((value) => {
                return value.unique_id === row.unique_id
                  ? {
                      ...value,
                      unit: event.target.value,
                      uom: event.target.value,
                      unit_cost: unit_cost_value,
                      quantity: row.quantity_orig_box,
                      quantity_orig: quantity_orig,
                      total_cost: total_cost_value,
                      diluted_cost: diluted_cost,
                      is_loose: is_loose,
                    }
                  : value;
              }),
            );
          }
        })
        .catch((err) => {});
    },
    [selectedOrderList],
  );

  const handleMultipleReason = useCallback(
    (event) => {
      let rowValue = parseInt(event.target.value);
      showMultipleReasonRow.includes(parseInt(rowValue))
        ? setShowMultipleReasonRow((state) =>
            state.filter((item) => item !== rowValue),
          )
        : setShowMultipleReasonRow([...showMultipleReasonRow, rowValue]);
    },
    [showMultipleReasonRow],
  );

  const handleChangeReason = useCallback(
    (event, row, unique_id) => {
      let rowId = parseInt(unique_id);
      if (showMultipleReasonRow.includes(rowId)) {
        setSelectedOrderList(
          selectedOrderList.map((value) => {
            return showMultipleReasonRow.includes(parseInt(value.unique_id))
              ? {
                  ...value,
                  remarks: event.target.value,
                  reason: event.target.value,
                }
              : value;
          }),
        );
      } else {
        setSelectedOrderList(
          selectedOrderList.map((value) => {
            return value.unique_id === row.unique_id
              ? {
                  ...value,
                  remarks: event.target.value,
                  reason: event.target.value,
                }
              : value;
          }),
        );
      }
    },
    [selectedOrderList, showMultipleReasonRow],
  );

  const handleRemoveRow = useCallback(
    (unique_id, od_id, type) => {
      Swal.fire({
        icon: 'warning',
        title: 'Do you want to remove this row?',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedOrderList(
            selectedOrderList.filter((value) => {
              return value.unique_id !== unique_id;
            }),
          );

          if (type === 'ERP') {
            setSelectionModel(
              selectionModel.filter((value) => {
                return value !== od_id;
              }),
            );
          } else {
            setSelectionHistoryModel(
              selectionHistoryModel.filter((value) => {
                return value !== od_id;
              }),
            );
          }
        }
      });
    },
    [selectedOrderList, selectionModel, selectionHistoryModel],
  );

  const handleSplitBatch = useCallback(
    (unique_id) => {
      let newData = [];

      const toDuplicate = selectedOrderList.find(
        (row) => row.unique_id === unique_id,
      );

      const combine = {
        ...toDuplicate,
        is_parent: 0,
      };

      selectedOrderList.forEach((row) => {
        if (row.unique_id === unique_id) {
          newData.push({
            ...row,
          });
          newData.push({
            ...combine,
            unique_id: generateUUID(),
            transaction_id: generateUUID(),
          });
        } else {
          newData.push(row);
        }
      });

      setSelectedOrderList(newData);
    },
    [selectedOrderList],
  );

  const handleComputeTotal = () => {
    let total_amount = 0;
    let total_amount_applicable = 0;
    let total_amount_near_ex = 0;

    selectedOrderList.forEach((value) => {
      let total_cost = value.total_cost === null ? 0.0 : value.total_cost;
      let total_cost_total =
        value.reason === '60-40 Near Expiry'
          ? parseFloat(total_cost) * 0.4
          : parseFloat(total_cost);

      total_amount += parseFloat(total_cost);

      if (value.reason === '60-40 Near Expiry') {
        total_amount_near_ex += total_cost_total;
      }

      total_amount_applicable =
        parseFloat(total_amount) - parseFloat(total_amount_near_ex);
    });

    setForm({
      ...form,
      products: selectedOrderList,
      total_amount: total_amount,
      applicable_amount: total_amount_applicable,
      total_amount_near_ex: total_amount_near_ex,
    });
  };
  // Disposition Area
  const handleChangeDisposition = (event) => {
    if (event) {
      setSelectedDisposition(event);
      if (event.value === 'apply_aging') {
        setForm({ ...form, disposition: 'AR' });
        setDispositionButton(true);
      } else {
        setSelectedAgingList([]);
        setDispositionButton(false);
        setForm({ ...form, disposition: 'ADV', disposition_reference: [] });
      }
    } else {
      setSelectedAgingList([]);
      setDispositionButton(false);
      setSelectedDisposition('');
      setForm({ ...form, disposition: '', disposition_reference: [] });
    }
  };

  const handleSelectedAging = (agingData) => {
    let dispositionReference = agingData.map((value) => {
      return {
        disposition_id: value.unique_id,
        disposition_date: value.date,
        disposition_due_date: value.due_date,
        disposition_dr: value.dr_no,
        disposition_si: value.si_number,
        disposition_balance: value.amount,
        disposition_amount: '',
      };
    });

    setForm({ ...form, disposition_reference: dispositionReference });
    setSelectedAgingList(dispositionReference);
  };

  const handleDispositionButton = () => {
    if (selectedCustomerID) {
      if (selectedDisposition) {
        if (selectedDisposition.value === 'apply_aging') {
          setShowAging(true);
        }
      }

      setAgingLoader(true);
      setAgingTransaction([]);

      getCustomersAgingData({
        customer_id: selectedCustomerID,
      })
        .then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            setAgingLoader(false);

            let unique_id = 0;

            setAgingTransaction(
              result.map((value) => {
                return { ...value, unique_id: unique_id++ };
              }),
            );
          } else {
            Swal.fire({
              icon: 'warning',
              text: result,
            });
          }
        })
        .catch((err) => {});
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select a Customer',
      });
    }
  };

  const handleChangeAgingAmount = (event, row) => {
    let dispositionReference = selectedAgingList.map((value) => ({
      ...value,
      disposition_amount:
        value.disposition_id === row.disposition_id
          ? event.target.value
          : value.disposition_amount,
    }));

    let totalApplicableAmount = dispositionReference.reduce((total, row) => {
      return total + parseFloat(row.disposition_amount) || 0;
    }, 0);

    setSelectedAgingList(dispositionReference);

    setForm({
      ...form,
      disposition_reference: dispositionReference,
      total_applicable_amount: totalApplicableAmount,
      total_total_applicable_amount:
        form.applicable_amount - totalApplicableAmount,
    });
  };

  const handleRemoveAgingRow = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to remove this row?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedAgingList(
          selectedAgingList.filter((value) => {
            return value.disposition_id !== id;
          }),
        );
      }
    });
  };

  const handleCancelButton = () => {
    Swal.fire({
      title: 'Are you sure to clear all fields?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        handleClearFields();
      }
    });
  };

  const handleClearFields = () => {
    setForm(transactionForm);

    setSelectedOrderList([]);
    setSelectionModel([]);

    setSelectedAgingList([]);
    setSelectionAgingModel([]);

    showSignaturePad();
    setSelectedDisposition('');

    history.push('/collection/srpf');
    setDefaultCustomer('');
  };

  const handleFormChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      attachments: [...event.target.files],
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    console.log(
      signatureRef.current?.isEmpty(),
      signatureRef.current?.isEmpty() === false
        ? dataURLtoBlob(signatureRef.current.toDataURL('image/png'))
        : [],
    );

    let errorProdMessage = [];

    form.products.filter((item) =>
      parseFloat(item.diluted_cost).toFixed(2) === 0
        ? errorProdMessage.push(
            'Product: ' + item.product_name + ' diluted cost is 0',
          )
        : '',
    );

    if (errorProdMessage.length > 0) {
      Swal.fire({
        icon: 'warning',
        html: Array.isArray(errorProdMessage)
          ? errorProdMessage.join('<br />')
          : errorProdMessage,
      });
    } else {
      if (editMode) {
        updateHandler(e);

        return false;
      }

      let newProducts = form.products.map((row) => ({
        ...row,
        transaction_id: row.transaction_id_old,
      }));

      let SubmitFormData = {
        ...form,
        signature:
          signatureRef.current?.isEmpty() === false
            ? dataURLtoBlob(signatureRef.current.toDataURL('image/png'))
            : [],
        products: newProducts,
      };

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to save?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          setPageLoader(true);
          createSRPF(SubmitFormData).then((res) => {
            let { code, result } = res.data.response;
            if (code !== '00013') {
              Swal.fire({
                icon: 'error',
                html: Array.isArray(result.errors)
                  ? result.errors.join('<br />')
                  : result,
                allowOutsideClick: false,
              });

              setPageLoader(false);
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Sale Return successfully submitted for approval',
                text: res.data.response.result,
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleClearFields();
                }
              });

              setPageLoader(false);
            }
          });
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();

    let newProducts = form.products.map((row) => ({
      ...row,
      transaction_id: row.transaction_id_old,
    }));

    let SubmitFormData = {
      ...form,
      signature:
        signatureRef.current?.isEmpty() === false
          ? dataURLtoBlob(signatureRef.current.toDataURL('image/png'))
          : [],
      products: newProducts,
    };

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to update?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setPageLoader(true);
        updateSRPF(SubmitFormData).then((res) => {
          let { code, result } = res.data.response;
          if (code !== '00013') {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result.errors)
                ? result.errors.join('<br />')
                : result,
              allowOutsideClick: false,
            });

            setPageLoader(false);
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Sale Return successfully submitted for approval',
              text: res.data.response.result,
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                handleClearFields();
              }
            });

            setPageLoader(false);
          }
        });
      }
    });
  };

  const CustomFooterStatusComponent = ({ totalText, totalAmount }) => {
    return (
      <>
        <Box
          sx={{
            padding: '15px',
          }}
          className="text-right text-dark"
        >
          <strong>
            {totalText}: {numberFormatCurrency(totalAmount)}
          </strong>
        </Box>
        <Box className="text-right">
          <GridPagination />
        </Box>
      </>
    );
  };

  const orderColumn = useMemo(
    () =>
      selectedOrderListColumn({
        showOUM,
        handleChangeQuantity,
        handleChangeUOM,
        handleChangeReason,
        handleRemoveRow,
        handleMultipleReason,
        getProductbyid,
        handleSplitBatch,
      }),
    [
      showOUM,
      handleChangeQuantity,
      handleChangeUOM,
      handleChangeReason,
      handleRemoveRow,
      handleMultipleReason,
      handleSplitBatch,
    ],
  );

  return (
    <Fragment>
      <PrimaryLoader visible={pageLoader} />
      <div className="container-fluid mb-3">
        <form onSubmit={submitHandler} encType="mutipart/form-data">
          <div className="row">
            <div className="col-md-6">
              {/* Customer Code */}
              <div className="form-group row">
                <label
                  htmlFor="customer_code"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Customer Code
                </label>
                <div className="col-sm-9">
                  {editMode && (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={form.customer_code + ' - ' + form.customer_name}
                      onChange={handleFormChange}
                      readOnly
                    />
                  )}
                  {!editMode && (
                    <Async
                      onChange={(e) => handleSelectCustomer(e)}
                      placeholder="-Select Customer-"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadCustomersPromise}
                      value={defaultCustomer}
                      isClearable={true}
                      styles={customStyles}
                    />
                  )}
                </div>
              </div>
              {/* Account Name */}
              <div className="form-group row">
                <label
                  htmlFor="account_name"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Account Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="account_name"
                    className="form-control form-control-sm"
                    id="account_name"
                    value={form.account_name || ''}
                    onChange={handleFormChange}
                    readOnly
                  />
                </div>
              </div>
              {/* Account Type */}
              <div className="form-group row">
                <label
                  htmlFor="account_type"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Account Type
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="account_type"
                    className="form-control form-control-sm"
                    id="account_type"
                    value={form.account_type || ''}
                    onChange={handleFormChange}
                    readOnly
                  />
                </div>
              </div>
              {/* Address */}
              <div className="form-group row">
                <label
                  htmlFor="address"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Address
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="address"
                    className="form-control form-control-sm"
                    id="address"
                    value={form.address || ''}
                    onChange={handleFormChange}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/*Date*/}
              <div className="form-group row">
                <label
                  htmlFor="srpf_date"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Date
                </label>
                <div className="col-sm-9">
                  <input
                    type="date"
                    name="srpf_date"
                    className="form-control form-control-sm"
                    value={form.srpf_date}
                    onChange={handleFormChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="srpf_date"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Type
                </label>
                <div className="col-sm-9">
                  <Select
                    isClearable={true}
                    placeholder="-Select Type-"
                    options={srpfType}
                    value={srpfType.find((row) => row.value === form.srpf_type)}
                    onChange={(e) =>
                      handleFormChange({
                        target: {
                          name: 'srpf_type',
                          value: e?.value,
                        },
                      })
                    }
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="col-md-12 mb-3">
              <button
                type="button"
                className="btn btn-info btn-sm"
                onClick={handleShowTransactionModal}
              >
                Show Customer Transactions
              </button>{' '}
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={handleShowHistoryTransactionModal}
              >
                Show Customer Historical Transactions
              </button>
            </div>
            <div className="col-md-12 mb-3">
              <PrimaryDataTable
                autoHeight
                disableSelectionOnClick
                getRowId={(row) => row.unique_id}
                rows={selectedOrderList}
                columns={orderColumn}
                components={{
                  Footer: CustomFooterStatusComponent,
                }}
                componentsProps={{
                  footer: {
                    totalText: 'Total Cost',
                    totalAmount: selectedOrderList.reduce((total, row) => {
                      return total + parseFloat(row.total_cost);
                    }, 0),
                  },
                }}
              />
            </div>
            <div className="col-md-12 mb-3">
              {selectedAgingList.length > 0 && (
                <PrimaryDataTable
                  autoHeight
                  getRowId={(row) => row.disposition_id}
                  rows={selectedAgingList}
                  disableSelectionOnClick={true}
                  columns={selectedAgingListColumn({
                    numberFormatCurrency,
                    handleChangeAgingAmount,
                    handleRemoveAgingRow,
                  })}
                  components={{
                    Footer: CustomFooterStatusComponent,
                  }}
                  componentsProps={{
                    footer: {
                      totalText: 'Total Applied Amount',
                      totalAmount: form.total_applicable_amount,
                    },
                  }}
                />
              )}
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  htmlFor="disposition"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Prefered Disposistion
                </label>
                <div className="col-sm-9 d-flex">
                  <div>
                    {dispositionButton ? (
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        onClick={handleDispositionButton}
                      >
                        Choose DR/SI
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        disabled
                      >
                        Choose DR/SI
                      </button>
                    )}
                  </div>
                  <div className="w-100">
                    <Select
                      isClearable={true}
                      placeholder="-Select Disposition-"
                      options={dispositionOptions}
                      onChange={(e) => handleChangeDisposition(e)}
                      value={dispositionOptions.find(
                        (row) => row.type === form.disposition,
                      )}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="disposition"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Remarks
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="remarks"
                    value={form.remarks || ''}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="disposition"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Customer Acknowledgement
                </label>
                <div className="col-sm-9">
                  {showPad && form.old_signature.length === 0 ? (
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        borderRadius: '0.25rem',
                        width: '100%',
                        height: '130px',
                        marginBottom: '5px',
                      }}
                    >
                      <SignaturePad
                        ref={signatureRef}
                        height={130}
                        options={{
                          minWidth: 2,
                          maxHeight: 100,
                          penColor: 'rgb(0, 0, 0)',
                        }}
                        style={{ backGroundColor: '#000' }}
                      />
                    </div>
                  ) : (
                    form.old_signature.map((value, key) => (
                      <div className="row" key={key}>
                        <div className="col-sm-12" key={key}>
                          <img
                            key={key}
                            alt="Signature"
                            src={value.path}
                            height="130"
                            width="100%"
                            className="border"
                          />
                        </div>
                      </div>
                    ))
                  )}
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={showSignaturePad}
                    >
                      Clear Signature
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="disposition"
                  className="col-sm-3 col-form-label"
                  style={{ fontSize: '12px' }}
                >
                  Additional Attachment
                </label>
                <div className="col-sm-9">
                  <MultipleFileUploadPreview
                    newAttachments={form.attachments}
                    oldAttachments={form.old_attachments}
                    handleRemoveFile={[]}
                    handleFileUpload={handleFileChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label text-right"
                  style={{ fontSize: '12px' }}
                >
                  Total Amount
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="total_amount"
                    className="form-control form-control-sm text-right"
                    value={numberFormatCurrency(form.total_amount)}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label text-right"
                  style={{ fontSize: '12px' }}
                >
                  Total 60/40 Near Expiry
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm text-right"
                    value={numberFormatCurrency(form.total_amount_near_ex)}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label text-right"
                  style={{ fontSize: '12px' }}
                >
                  Amount Applicable (JPI)
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm text-right"
                    value={numberFormatCurrency(form.applicable_amount)}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label text-right"
                  style={{ fontSize: '12px' }}
                >
                  Total Applied Amt.
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm text-right"
                    value={numberFormatCurrency(form.total_applicable_amount)}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label text-right"
                  style={{ fontSize: '12px' }}
                >
                  Total Balance
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm text-right"
                    value={numberFormatCurrency(
                      form.total_total_applicable_amount,
                    )}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-danger btn-sm float-right"
                style={{ marginLeft: '10px' }}
                onClick={handleCancelButton}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-success btn-sm float-right"
              >
                Submit
              </button>
              {/* <button
                className="btn btn-info btn-sm float-right mr-2"
                onClick={testSubmit}
              >
                Test
              </button> */}
            </div>
          </div>
        </form>
      </div>

      <CustomerOrderProductsModal
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleModalCustomerOrder={showCustomerOrderModal}
        handleModalCustomerOrderSet={setShowCustomerOrderModal}
        customerOrderData={customerOrderData}
        customerOrderLoading={customerOrderLoading}
        handleSelectedOrderProduct={handleSelectedOrderProduct}
      />

      <CustomerOrderHistoryModal
        selectionModel={selectionHistoryModel}
        setSelectionModel={setSelectionHistoryModel}
        handleModalCustomerOrder={showCustomerOrderHistoryModal}
        handleModalCustomerOrderSet={setShowCustomerOrderHistoryModal}
        customerOrderData={customerOrderHistoryData}
        historicalTotal={historicalTotal}
        handleSelectedOrderProduct={handleSelectedOrderHistoryProduct}
        handlePageChange={handleHistoricalPageChange}
        customerID={customerID}
        paginateHistory={paginateHistory}
        setPaginateHistory={setPaginateHistory}
        loadCustomerOrderHistoryData={loadCustomerOrderHistoryData}
      />

      <CustomerAgingModal
        showAgingTransaction={showAgingTransaction}
        handleSelectedAging={handleSelectedAging}
        // Selection Model
        selectionAgingModel={selectionAgingModel}
        setSelectionAgingModel={setSelectionAgingModel}
        // Modal
        showAging={showAging}
        setShowAging={setShowAging}
        agingLoader={agingLoader}
      />
    </Fragment>
  );
};

export default AddSrpf;
