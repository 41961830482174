import React, { Fragment } from 'react';
import PrimaryToolTip from '../PrimaryToolTip.js';

function AuthorizedReceivedPerson({
  form,
  handleChange,
  dataStatus,
  handleCheck,
}) {
  /* let authorized_receive_person =
    customer.business_contacts.authorized_receive_person[0]; */

  return (
    <Fragment>
      <fieldset className="details-field-set mb-4 mt-2">
        <legend>Authorized Person To Received</legend>
        {/*  */}
        {form.customer_type != 4 ? (
          <div className="row pl-3">
            <div className="col-lg-4">
              <label className="col-form-label col-form-label-sm">
                Same with Account Info?
              </label>
              <PrimaryToolTip />
            </div>
            <div className="col-lg-6">
              <input
                type="checkbox"
                name="isCopyAuthPersonToReceive"
                id="isCopyAuthPersonToReceive"
                onChange={handleCheck}
                disabled={!form.accounting_contact_person}
              />
            </div>
          </div>
        ) : null}
        {/*  */}
        {/*  */}
        <div className="row pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Contact Person
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="authorized_receive_person_contact_person"
              id="authorized_receive_person_contact_person"
              value={form.authorized_receive_person_contact_person}
              onChange={handleChange}
              disabled={dataStatus ? true : false}
            />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Contact Number
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="authorized_receive_person_contact_no"
              id="authorized_receive_person_contact_no"
              value={form.authorized_receive_person_contact_no}
              onChange={handleChange}
              disabled={dataStatus ? true : false}
            />
          </div>
        </div>
        {/*  */}

        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Designation
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="authorized_receive_person_designation"
              id="authorized_receive_person_designation"
              value={form.authorized_receive_person_designation}
              onChange={handleChange}
              disabled={dataStatus ? true : false}
            />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">Email</label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control form-control-sm"
              name="authorized_receive_person_email"
              id="authorized_receive_person_email"
              value={form.authorized_receive_person_email}
              onChange={handleChange}
              disabled={dataStatus ? true : false}
            />
          </div>
        </div>
        {/*  */}
      </fieldset>
    </Fragment>
  );
}

export default AuthorizedReceivedPerson;
