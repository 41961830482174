import { Tabs, Tab } from "react-bootstrap";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";
import "../ledger.css";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";
import PrimaryDatePicker from "../../../components/PrimaryDatePicker";
import { getActivePrducts, getBatchNo } from "../../../api/ledgers";

import PrimaryDataTable from "../../../components/PrimaryDataTable";
import {
  AlertDefaultError,
  PermissionsGate,
} from "../../../components/PermissionsGate";
import { productsCol, productsWithBatchCol } from "../Products/const";
import _ from "lodash";

import ProductLedgerFormModal from "../ProductLedgerFormModal";
import { toast } from "react-toastify";
import "../ProductLedgerFormModal/productFormModal.css";

const ProductLedgerReportScreen = ({
  handleChange,
  formData,
  //dateChange,
  handleSearch,
  productsData,
  selectedTab,
  handleSelectedTab,
  handleDataTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
  //revamp by jhay
  handleModuleModal,
  handleCloseModuleModal,
  moduleModal,
  productsDataWithBatch,
  exportButton,
}) => {
  const columns = productsCol({
    handleModuleModal: handleModuleModal,
  });

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab
            className="default-tab-content"
            eventKey="inventories_ledger"
            title="Inventory Sub Ledger"
          ></Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab
            className="default-tab-content"
            eventKey="product_ledger"
            title="Product Ledger"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <PermissionsGate scopes={["view_product_ledger_list"]}>
                    <div className="col-md-3">
                      <label>Select Product</label>
                      <label className="req">*</label>
                      <PrimaryAsyncSelect
                        onChange={(e) =>
                          handleChange({
                            target: { name: "product_id", value: e.value },
                          })
                        }
                        customFunction={getActivePrducts}
                        isClearable={false}
                        name={"product_id"}
                        value={formData.product_id}
                        config={{
                          searchField: "keyword",
                          valueField: "product_id",
                          labelField: "product_name",
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Select Batch No.</label>
                      <label className="req">*</label>
                      <PrimaryAsyncSelect
                        onChange={(e) =>
                          handleChange({
                            target: { name: "batch_no", value: e.value },
                          })
                        }
                        customFunction={getBatchNo}
                        isClearable={true}
                        name={"batch_no"}
                        value={formData.batch_no}
                        config={{
                          searchField: "keyword",
                          valueField: "batch_no",
                          labelField: "batch_no",
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <label>Date From</label>
                      <label className="req">*</label>

                      <PrimaryDatePicker
                        name={"date_from"}
                        datePickerFormat={"yyyy-MM-dd"}
                        datePickerFormatString={"YYYY-MM-DD"}
                        value={formData.date_from}
                        onChange={(value) =>
                          handleChange({
                            target: { name: "date_from", value },
                          })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <label>Date To</label>
                      <label className="req">*</label>
                      <PrimaryDatePicker
                        name={"date_to"}
                        datePickerFormat={"yyyy-MM-dd"}
                        datePickerFormatString={"YYYY-MM-DD"}
                        value={formData.date_to}
                        onChange={(value) =>
                          handleChange({
                            target: { name: "date_to", value },
                          })
                        }
                      />
                    </div>
                    <div className="col-md-1">
                      <label>&nbsp;</label>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="col-md-1 text-right">
                      <label>&nbsp;</label>
                      <div>
                        <PermissionsGate
                          scopes={["export_product_ledger_list"]}
                        >
                          {exportButton ? (
                            <button
                              className=" btn btn-success"
                              onClick={() => toast.error("Not Yet Working")} //handleExport
                            >
                              Export
                            </button>
                          ) : null}
                        </PermissionsGate>
                      </div>
                    </div>
                  </PermissionsGate>
                </div>
                <br />
                <PermissionsGate
                  scopes={["view_product_ledger_list"]}
                  RenderError={AlertDefaultError}
                >
                  {productsDataWithBatch.length > 0 ? (
                    <PrimaryDataTable
                      rows={productsDataWithBatch}
                      columns={productsWithBatchCol}
                      getRowId={(row) => row.id}
                      onChange={handleDataTableChange}
                      disableColumnFilter
                      rowCount={productsDataWithBatch.length}
                      loading={loading}
                      mode="server"
                      reload={reloadTable}
                      hideFooterSelectedRowCount
                      getRowClassName={(params) => {
                        if (params.row.status === 0) {
                          return "pending-status-text";
                        }
                      }}
                    />
                  ) : (
                    <PrimaryDataTable
                      style={{ height: "50vh" }}
                      rows={productsData}
                      columns={columns}
                      getRowId={(row) => row.id}
                      onChange={handleDataTableChange}
                      disableColumnFilter
                      rowCount={productsData.length}
                      loading={loading}
                      mode="server"
                      reload={reloadTable}
                      hideFooterSelectedRowCount
                      getRowClassName={(params) => {
                        if (params.row.particulars === "Ending Balance") {
                          return "ending_balance";
                        }
                      }}
                    />
                  )}
                </PermissionsGate>
              </div>
            </div>
          </Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
      {/* {!_.isEmpty(moduleModal.data) ? ( */}
      <ProductLedgerFormModal
        toggle={moduleModal.toggle}
        closeModal={handleCloseModuleModal}
        moduleData={moduleModal.module}
      />
      {/* ) : null} */}
    </div>
  );
};

export default ProductLedgerReportScreen;
