import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import useForm from "../../useForm";
import Swal from "sweetalert2";
import axiosInstance from "../axiosInstance";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PrimaryInputPassword from "../PrimaryInputPassword";

const UserChangePassword = () => {

  const { id } = useParams();
  const history = useHistory();
  const [error, setError] = useState("");

  const [values, handleChange] = useForm({
    user_id: id,
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const changePassword = () => {
    if (values.current_password === values.new_password) {
      setError("Current Password cannot be the same as new password");
      return;
    }

    if (values.new_password !== values.confirm_new_password) {
      setError("");
      setError("Password doesn't match!");
      return;
    }

    if (values.new_password === values.confirm_new_password) {
      setError("");

      axiosInstance
        .put("/users/change-password", values)
        .then((res) => {
          if (res.data.response.code === "00000") {
            Swal.fire({
              icon: "Success",
              title: "Password has been Change!",
              showConfirmButton: false,
              text: res.data.response.result,
              timer: 1000,
            });
            setTimeout(() => {
              history.push("/user-control");
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const submitHandler = (e) => {
    console.log("12312321");
    e.preventDefault();
    changePassword();
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col lg="5">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <div className="mr-2">
                <img src="/Stacked Version.png" height="30px !important" alt="bk logo"/>
              </div>
              <h3>Change Password</h3> 
            </div>
            <form
                action="."
                method="get"
                onSubmit={submitHandler}
              >
              <div className="card-body">
                {error !== "" ? (
                  <div className="error text-danger">
                    <h5 style={{ fontSize: "small" }}>{error}</h5>
                  </div>
                ) : null}
                <input
                  type="hidden"
                  className="form-control"
                  id="user_id"
                  name="user_id"
                  value={id}
                />
                <div className="mb-3">
                  <label className="form-label">Current Password</label>
                  <PrimaryInputPassword
                    placeholder="Current Password"
                    value={values.current_password}
                    onChange={handleChange}
                    id="current_password"
                    name="current_password"
                    autocomplete="off"
                    required="true"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">New Password</label>
                  <PrimaryInputPassword
                    placeholder="New Password"
                    value={values.new_password}
                    onChange={handleChange}
                    id="new_password"
                    name="new_password"
                    autocomplete="off"
                    required="true"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Confirm Password</label>
                  <PrimaryInputPassword
                    placeholder="Cofnrim Password"
                    value={values.confirm_new_password}
                    onChange={handleChange}
                    id="confirm_new_password"
                    name="confirm_new_password"
                    autocomplete="off"
                    required="true"
                  />
                </div>
              </div>
              <div className="card-footer">
                <div className="text-right">
                  <Button
                    type="submit"
                    value="Submit"
                    variant="success"
                  >
                    Save
                  </Button>
                  <Link
                    id="cancel"
                    to="/user-control"
                    className="ml-2 btn btn-danger"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>  
        </Col>
      </Row>
      {/* <div className="container-tight py-4">
        <div className="text-center mb-4">
          <a href="#">
            <img src="/Stacked Version.png" height="120px !important" />
          </a>
        </div>
        <form
          className="card card-md"
          action="."
          method="get"
          onSubmit={submitHandler}
        >
          <div className="card-body">
            {error != "" ? (
              <div className="error text-danger">
                <h5 style={{ fontSize: "small" }}>{error}</h5>
              </div>
            ) : null}
            <input
              type="hidden"
              className="form-control"
              id="user_id"
              name="user_id"
              value={id}
            />
            <div className="mb-3">
              <label className="form-label">Current Password</label>
              <PrimaryInputPassword
                placeholder="Current Password"
                value={values.current_password}
                onChange={handleChange}
                id="current_password"
                name="current_password"
                autocomplete="off"
                required="true"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">New Password</label>
              <PrimaryInputPassword
                placeholder="New Password"
                value={values.new_password}
                onChange={handleChange}
                id="new_password"
                name="new_password"
                autocomplete="off"
                required="true"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Confirm Password</label>
              <PrimaryInputPassword
                placeholder="Cofnrim Password"
                value={values.confirm_new_password}
                onChange={handleChange}
                id="confirm_new_password"
                name="confirm_new_password"
                autocomplete="off"
                required="true"
              />
            </div>
            <div className="form-footer">
              <button
                type="submit"
                value="Submit"
                className="btn btn-primary w-100"
              >
                Update Password
              </button>
            </div>
          </div>
        </form>
      </div> */}
    </Container>
  );
};

export default UserChangePassword;
