import { useEffect, useState } from 'react';
import {
  isValidDate,
  isColumnRunning,
  transformData,
  isValidCode,
} from './const';
import { getGeneralLedgerData } from '../../../../api/ledgers';
import { useQuery } from 'react-query';

const useGenerateLedgerQuery = ({ form }) => {
  const defaultParams = {
    ...form,
    direction: 'asc',
    order_by: 'account_code',
    page: 0,
    page_size: 50,
  };
  const [paginate, setPaginate] = useState(defaultParams);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['general_ledger', paginate],
    queryFn: async () => {
      let form = paginate;
      isValidDate({ form });

      const res = await getGeneralLedgerData(form);

      const { code, result, total_rows } = res.data;

      isValidCode(code);

      return {
        general_ledger: transformData({ result }),
        total_rows: total_rows,
      };
    },
  });

  const handleFilterModelChange = async (filterModel) => {
    let filter = { ...defaultParams, filter: filterModel };

    return setPaginate(filter);
  };

  const handleDataTableChange = async (params) => {
    let filter = { ...defaultParams, ...params };

    return setPaginate(filter);
  };

  return {
    generalLedgerData: data === undefined ? [] : data.general_ledger,
    totalRows: data === undefined ? 0 : data.total_rows,
    isLoading,
    isFetching,
    column_running: isColumnRunning({ form }),
    handleFilterModelChange: handleFilterModelChange,
    handleDataTableChange: handleDataTableChange,
  };
};

export default useGenerateLedgerQuery;
