import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles';

import { Button, Col, Row } from 'react-bootstrap';
import { PencilSquare, Trash, PlusCircleFill } from 'react-bootstrap-icons';
import PrimaryDataTable from '../PrimaryDataTable';

import { deleteRole, getRoles } from '../../api/users';

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        {/* <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </GridToolbarContainer> */}
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(0),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const Roles = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [rolesData, setRolesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rolesData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  /* Continuation of Search */

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setRolesData([]);
    setFilteredData([]);
    setLoading(true);

    try {
      let res = await getRoles();

      const { code, result } = res.data.response;

      setLoading(false);
      if (code !== '00000') {
        return;
      }

      setRolesData(result);
      setFilteredData(result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRoleButton = async ({ id: role_id }) => {
    let { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete it?',
      showCancelButton: true,
      icon: 'warning',
    });

    if (!isConfirmed) return;

    let res = await deleteRole(role_id);

    const { code, result } = res.data.response;

    await Swal.fire({
      icon: code === '00006' ? 'success' : 'error',
      text: Array.isArray(result) ? result.join('<br />') : result,
    });

    if (code === '00006') {
      loadData();
    }
  };

  const columns = [
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.2,
      minWidth: 150,
      hideable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.3,
      minWidth: 150,
      hideable: false,
    },
    {
      field: 'user_count',
      headerName: 'User Count',
      flex: 0.1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      flex: 0.1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      renderCell: (rolesData) => {
        return (
          <div className="btn-action-buttons">
            <Link
              className="usersAction btn btn-link btn-sm btn-action"
              to={`/edit/roles/${rolesData.id}`}
            >
              <Tooltip title="Edit Role">
                <PencilSquare />
              </Tooltip>
            </Link>
            <Button
              className="usersAction btn btn-sm btn-action"
              onClick={() => handleDeleteRoleButton(rolesData)}
              variant="link"
            >
              <Tooltip title="Delete Role">
                <Trash />
              </Tooltip>
            </Button>
          </div>
        );
      },
    },
  ];

  const addRoles = () => history.push('/add/roles');

  return (
    <Fragment>
      <Row className="mb-3">
        <Col lg="6"></Col>
        <Col lg="6">
          <div className="text-right">
            <Button
              variant="success"
              onClick={addRoles}
              className="btn-add-create btn-md"
            >
              <PlusCircleFill /> Add Role
            </Button>
          </div>
        </Col>
      </Row>

      <PrimaryDataTable
        rows={filteredData}
        columns={columns}
        getRowId={(row) => row.role_id}
        components={{ Toolbar: QuickSearchToolbar }}
        loading={loading}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
      />
    </Fragment>
  );
};

export default Roles;
