// import InventoryReciept from "../components/Inventory/InventoryReciept";
// import InventoryRecieptQcQa from "../pages/Inventory/Qcqa";
// import InventoryRecieptQcQaApproval from "../pages/Inventory/Qcqa/inventoryQcQaApproval";
// import InventoryRecieptQcQaApproved from "../pages/Inventory/Qcqa/inventoryQcQaApproval";
// import InventoryAdjustment from "../pages/Inventory/Adjustment/InventoryAdjustment";
// import InventoryAdjustmentApproval from "../pages/Inventory/Adjustment/InventoryAdjustmentApproval";
// import InventoryAdjustmentApproved from "../pages/Inventory/Adjustment/InventoryAdjustmentApproved";
// import InventoryReceipts from "../pages/Inventory/Receipts";
// import InventoryReceiptForm from "../pages/Inventory/Receipts/InventoryReceiptForm";
// // Inventory Transfer New
// import InventoryTransfer from "../pages/InventoryTransfer/InventoryTransfer";
// import InventoryTransferProductApproval from "../pages/InventoryTransfer/InventoryTransferProductApproval";
// // Inventory Transfer Destination Request
// import InventoryTransferDestination from "../pages/InventoryTransferDestination/InventoryTransferDestination";
// import InventoryMovement from "../pages/InventoryMovement/InventoryMovement";
import { lazy } from 'react';

const InventoryReciept = lazy(() =>
  import('../components/Inventory/InventoryReciept'),
);

const InventoryRecieptQcQa = lazy(() => import('../pages/Inventory/Qcqa'));

const InventoryRecieptQcQaApproval = lazy(() =>
  import('../pages/Inventory/Qcqa/inventoryQcQaApproval'),
);

const InventoryAdjustment = lazy(() =>
  import('../pages/Inventory/Adjustment/InventoryAdjustment'),
);

const InventoryAdjustmentApproval = lazy(() =>
  import('../pages/Inventory/Adjustment/InventoryAdjustmentApproval'),
);

const InventoryAdjustmentApproved = lazy(() =>
  import('../pages/Inventory/Adjustment/InventoryAdjustmentApproved'),
);

const InventoryReceipts = lazy(() => import('../pages/Inventory/Receipts'));

// const InventoryReceiptForm = lazy(() =>
//   import('../pages/Inventory/Receipts/InventoryReceiptForm'),
// );

// const InventoryReceiptFormPO = lazy(() =>
//   import('../pages/Inventory/Receipts/InventoryReceiptFormPO'),
// );

const InventoryTransfer = lazy(() =>
  import('../pages/InventoryTransfer/InventoryTransfer'),
);

const InventoryTransferProductApproval = lazy(() =>
  import('../pages/InventoryTransfer/InventoryTransferProductApproval'),
);

const InventoryMovement = lazy(() =>
  import('../pages/InventoryMovement/InventoryMovement'),
);

const InventoryMovementTransactionLog = lazy(() =>
  import('../pages/InventoryMovementTransactionLog/MovementLog'),
);

const InventoryTransferDestination = lazy(() =>
  import('../pages/InventoryTransferDestination/InventoryTransferDestination'),
);

const InventoryReportGenerator = lazy(() =>
  import('../pages/InventoryReportGenerator'),
);

const InventoryImport = lazy(() => import('../pages/InventoryImport'));

const inventoryRoute = {
  public: [],
  auth: [
    {
      name: 'inventory_receipt',
      path: '/inventoryreceipt',
      component: InventoryReciept,
    },
    {
      name: 'inventory_adjustment',
      path: '/inventory/adjustment',
      component: InventoryAdjustment,
    },
    {
      name: 'inventory_adjustment_approval',
      path: '/inventory/adjustment/approval',
      component: InventoryAdjustmentApproval,
    },
    {
      name: 'inventory_adjustment_approved',
      path: '/inventory/adjustment/approved',
      component: InventoryAdjustmentApproved,
    },
    {
      name: 'inventory_transfer',
      path: '/inventory/transfer',
      component: InventoryTransfer,
    },
    {
      name: 'inventory_transfer_destination',
      path: '/v2/inventory/transfer/:tab',
      component: InventoryTransferDestination,
    },
    {
      name: 'inventory_transfer_product_approval',
      path: '/:version/inventory/transfer/approval',
      component: InventoryTransferProductApproval,
    },
    // {
    //   name: "inventory_receipt_purcharse_order",
    //   path: "/inventory/receipts/form/purchaseOrder",
    //   component: InventoryReceiptFormPO,
    // },
    // {
    //   name: "inventory_receipt_purcharse_order_edit",
    //   path: "/inventory/receipts/form/purchaseOrder/:id",
    //   component: InventoryReceiptFormPO,
    // },
    // {
    //   name: "inventory_receipt_edit",
    //   path: "/inventory/receipts/form/:id",
    //   component: InventoryReceipts,
    // },
    // {
    //   name: "inventory_receipt_add",
    //   path: "/inventory/receipts/form",
    //   component: InventoryReceiptForm,
    // },
    {
      name: 'inventory_receipt',
      path: '/inventory/receipts',
      component: InventoryReceipts,
    },
    {
      name: 'inventory_receipt',
      path: '/inventory/receipts/:tab/:id?',
      component: InventoryReceipts,
    },
    {
      name: 'inventory_receipt_list_qcqa',
      path: '/inventoryreceiptinspection',
      component: InventoryRecieptQcQa,
    },
    {
      name: 'inventory_receipt_list_approval',
      path: '/inventoryreceiptapproval/:type?',
      component: InventoryRecieptQcQaApproval,
    },
    {
      name: 'inventoryt_movement',
      path: '/inventory/movement',
      component: InventoryMovement,
    },
    {
      name: 'inventoryt_movement_transaction_log',
      path: '/inventory/movement-transaction-log/transaction-log',
      component: InventoryMovementTransactionLog,
    },
    {
      name: 'inventory_report_generator',
      path: '/inventory/report/generator',
      component: InventoryReportGenerator,
    },
    {
      name: 'inventory_import',
      path: '/inventory/import',
      component: InventoryImport,
    },
  ],
};

export default inventoryRoute;
