import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import {
  getSMPPProduct,
  saveSMPPProduct,
  saveSMPPProductInline,
} from '../../api/smppProductMaintenance';
import useNavActive from '../../components/Navbar/useNavActive';
import defaultOptions from '../../utils/defaultOptions';

import ProductMaintenanceScreen from './Screens/ProductMaintenanceScreens';

const ProductMaintenance = () => {
  useNavActive([
    'master_databases',
    'customer_databases',
    'customer_product_maintenance',
  ]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [updateModal, setUpdateModal] = useState({ toggle: false, data: null });

  const [modalLoading, setModalLoading] = useState(false);

  const [exportModal, setExportModal] = useState({ toggle: false, data: null });

  const [importModal, setImportModal] = useState({ toggle: false, data: null });

  const [form, setForm] = useState({
    page: 0,
    page_size: defaultOptions.pageSize,
    order_by: 'customer_id',
    direction: 'asc',
    customer_id: '',
  });
  const [total, setTotal] = useState({ rows: 0 });

  const [tableForm, setTableForm] = useState({});

  const [reloadTable, setReloadTable] = useState(1);

  useNavActive([
    'master_databases',
    'customer_databases',
    'customer_product_maintenance',
  ]);

  useEffect(() => {
    setTotal({ rows: 0 });
    loadData(form);
  }, []);

  const loadData = async (form) => {
    setLoading(true);
    setData([]);

    try {
      let res = await getSMPPProduct(form);

      const { code, result, total_rows } = res.data.response;

      if (code !== '00000') {
        toast.error(Array.isArray(result) ? result.join('<br />') : result);
        return;
      }

      setData(result);
      setTotal({ rows: total_rows });
    } catch (error) {
      toast.error('Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateModal = (row) => {
    setUpdateModal({
      toggle: true,
      data: row,
    });
  };

  const handleCloseUpdateModal = () =>
    setUpdateModal({ toggle: false, data: null });

  const handleSubmitUpdateModal = async (form) => {
    let { isConfirmed } = await Swal.fire({
      title: 'Product Maintenance',
      text: 'Are you sure you want to save this form?',
      icon: 'warning',
      showCancelButton: true,
    });

    if (!isConfirmed) return;

    setModalLoading(true);

    try {
      let res = await saveSMPPProduct(form);

      const { code, result } = res.data.response;

      await Swal.fire({
        title: 'Product Maintenance',
        text: Array.isArray(result) ? result.join('<br />') : result,
        icon: code !== '00000' ? 'error' : 'success',
      });

      if (code === '00000') {
        setUpdateModal({ toggle: false, data: null });
      }
    } catch (error) {
    } finally {
      setModalLoading(false);
    }
  };

  const handleEditCell = async ({ field, id, value }) => {
    // let internal_product_code = row.product_code;
    // // let customer_product_code = row.smpp_product_code;
    // let customer_code = row.customer_code;

    try {
      const selectedRow = data.find((row) => row.id === id);

      if (!selectedRow) {
        toast.error('Record not found.');
        return;
      }

      let internal_code = {
        internal_product_code: selectedRow.product_code,
        customer_code: selectedRow.customer_code,
        field,
        value,
      };

      let res = await saveSMPPProductInline(internal_code);

      const { code, result } = res.data.response;

      if (code === '00000') {
        toast.loading('Please wait saving form ...');
        // Swal.fire({
        //   title: "Save",
        //   html: result,
        //   icon: "success",
        // });

        //loadData(form); --remove reloading of table
        setTimeout(() => toast.dismiss(), 1000);
      } else {
        Swal.fire({
          title: 'Error',
          html: result,
          icon: 'warning',
        });
      }
    } catch (error) {
      toast.error('Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  // Export Modal

  const handleExportModal = (row) => {
    setExportModal({
      toggle: true,
      data: row,
    });
  };

  const handleCloseExportModal = () =>
    setExportModal({ toggle: false, data: null });

  // Import Modal
  const handleImportModal = (row) => {
    setImportModal({
      toggle: true,
      data: row,
    });
  };

  const handleCloseImportModal = () =>
    setImportModal({ toggle: false, data: null });

  const handleOnDataTableChange = (data) => {
    setTableForm(data);
    loadData({ ...form, ...data });
  };

  const handleFilterChange = (e) => {
    let tmpObj = {
      ...form,
      customer_id: e.target.value,
    };
    setReloadTable(Math.floor(Math.random() * 101));

    setForm(tmpObj);
    loadData(tmpObj);
  };

  const handleClearFilterSearch = () => {
    let newFormData = { ...form, customer_id: '' };
    setReloadTable(Math.floor(Math.random() * 101));
    loadData(newFormData);
    setForm(newFormData);
  };

  return (
    <ProductMaintenanceScreen
      data={data}
      total={total.rows}
      formData={form}
      handleFilterChange={handleFilterChange}
      handleEditCell={handleEditCell}
      loading={loading}
      reloadTable={reloadTable}
      handleClearFilterSearch={handleClearFilterSearch}
      onChange={handleOnDataTableChange}
      handleUpdateModal={handleUpdateModal}
      modalLoading={modalLoading}
      updateModal={updateModal}
      handleCloseUpdateModal={handleCloseUpdateModal}
      handleSubmitUpdateModal={handleSubmitUpdateModal}
      // Export Section
      exportModal={exportModal}
      handleExportModal={handleExportModal}
      handleCloseExportModal={handleCloseExportModal}
      // Import Section
      importModal={importModal}
      //reloadData={handleOnDataTableChange}
      handleImportModal={handleImportModal}
      handleCloseImportModal={handleCloseImportModal}
    />
  );
};

export default ProductMaintenance;
