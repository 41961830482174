import React, { Fragment } from 'react';
import PrimaryToolTip from '../PrimaryToolTip';

function Pharmacy({ form, handleChange }) {
  return (
    <Fragment>
      <fieldset className="details-field-set mb-4 mt-2">
        <legend>Pharmacy Contact Information</legend>
        {/*  */}
        <div className="row pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm"></label>
          </div>
          <div className="col-lg-6"></div>
        </div>
        {/*  */}
        {/*  */}
        <div className="row pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Contact Person
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="pharmacy_contact_person"
              id="pharmacy_contact_person"
              value={form.pharmacy_contact_person}
              onChange={handleChange}
            />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Contact Number
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="pharmacy_contact_no"
              id="pharmacy_contact_no"
              value={form.pharmacy_contact_no}
              onChange={handleChange}
            />
          </div>
        </div>
        {/*  */}

        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">
              Designation
            </label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control form-control-sm"
              name="pharmacy_designation"
              id="pharmacy_designation"
              value={form.pharmacy_designation}
              onChange={handleChange}
            />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="row mt-2 pl-3">
          <div className="col-lg-4">
            <label className="col-form-label col-form-label-sm">Email</label>
            <span className="requiredField"> *</span>
            <PrimaryToolTip />
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control form-control-sm"
              name="pharmacy_email"
              id="pharmacy_email"
              value={form.pharmacy_email}
              onChange={handleChange}
            />
          </div>
        </div>
        {/*  */}
      </fieldset>
    </Fragment>
  );
}

export default Pharmacy;
