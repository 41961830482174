import React, { useState } from "react";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";
import { createRole } from "../../../api/users";

import RolesForm from "./RolesForm";

const RolesAddPage = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    role: "",
    description: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to add new role?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "#dc3545",
    }).then((result) => {
      if (result.isConfirmed) {
        if (checkEmptyFields()) {
          Swal.fire({
            icon: "info",
            title: "All fields are required!",
          });
        } else {
          createRole(form)
            .then((res) => {
              let { code, result } = res.data.response;
              if (code !== "00003") {
                Swal.fire({
                  icon: "error",
                  text: result,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  text: result,
                  showConfirmButton: true,
                });
                history.push("/user-control#tab-top-2");
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.data.response.result,
              });
            });
        }
      }
    });
    // e.preventDefault();
    // Swal.fire({
    //   icon: "info",
    //   text: "Are you sure?",
    //   showConfirmButton: true,
    //   confirmButtonText: "Confirm",
    // }).then((result) => {
    //   axiosInstance
    //     .post("/role/create", values, {
    //       method: "POST",
    //     })
    //     .then((res) =>
    //       res.data.response.code === "00003"
    //         ? Swal.fire({
    //             icon: "success",
    //             text: res.data.response.result,
    //             showConfirmButton: true,
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               history.push("/user-control");
    //             }
    //           })
    //         : Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             text: res.data.response.result,
    //           })
    //     );
    // });
  };

  const checkEmptyFields = () => {
    return Object.keys(form).reduce((previous, key) => {
      return form[key] === "" ? previous + 1 : 0;
    }, 0);
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <h3>Add Role Page</h3>
            </div>
            <RolesForm form={form} handleChange={handleChange} />
            <div className="card-footer">
              <div className="text-right">
                <Button
                  id="add"
                  variant="success"
                  type="button"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Save
                </Button>
                <Link
                  id="cancel"
                  to="/user-control#tab-top-2"
                  className="ml-2 btn btn-danger"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    // <Fragment>
    //   <div className="col-md-10 offset-1">
    //     <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
    //       <div className="card">
    //         <div className="card-header">
    //           <h1>Add Role Page</h1>
    //         </div>
    //         <div className="card-body">
    //           <form onSubmit={submitHandler}>
    //             <div className="form-group">
    //               <label>Role</label>

    //               <input
    //                 className="form-control"
    //                 name="role"
    //                 id="role"
    //                 type="text"
    //                 value={values.role}
    //                 onChange={handleChange}
    //                 required
    //               />
    //             </div>

    //             <div className="form-group">
    //               <label>Description</label>

    //               <input
    //                 className="form-control"
    //                 name="description"
    //                 id="description"
    //                 type="text"
    //                 value={values.description}
    //                 onChange={handleChange}
    //                 required
    //               />
    //             </div>
    //             <div className="btn-grp" style={{ float: "right" }}>
    //               <button id="add" className="btn btn-primary" type="submit">
    //                 Add
    //               </button>
    //               <Link
    //                 id="cancel"
    //                 to="/user-control"
    //                 className="ml-2 btn btn-secondary"
    //               >
    //                 Cancel
    //               </Link>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  );
};

export default RolesAddPage;
