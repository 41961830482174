import makeApiRequest from "./makeApiCall";

export const requestLogin = async (data) => {
  return await makeApiRequest(`/users/login`, "POST", "", data);
};

export const crossLogin = async (euid, token) => {
  return await makeApiRequest(`/signin`, "GET", "", {
    euid,
    token,
  });
};

export const getEcosystemDetails = async (euid) => {
  return await makeApiRequest("/ecosystem_detail/" + euid, "GET");
};
