import Permission from "../components/Permissions/Permission";

const permissionRoute = {
  public: [],
  auth: [
    {
      name: "product_maintenance",
      path: "/customers/smpp-product-maintenance",
      component: Permission,
    },
  ],
};

export default permissionRoute;
