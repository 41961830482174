import { lazy } from "react";

const TreasuryTrade = lazy(() => import("../pages/TreasuryTrade/List"));

const treasuryRoute = {
  public: [],
  auth: [
    {
      name: "treasury",
      path: "/treasury/trade/:status?",
      component: TreasuryTrade,
    },
  ],
};

export default treasuryRoute;
