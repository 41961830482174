import moment from "moment";

export const statusText = (status) => {
  let getStatus = {
    0: "For Approval",
    1: "Approved",
    2: "Declined",
    3: "Cancelled",
  };

  return getStatus[parseInt(status)] ?? "Create";
};

export const requestData = {
  pr_hdr_id: "",
  pr_no: "",
  pr_date: moment().format("YYYY-MM-DD"),
  supplier: "",
  department: "",
  estimated_delivery_date: "",
  payment_term: "",
  grand_total: "",
  prepared_by: "",
  generate_type: "",
  particulars: "",
  term_po: "",
  term_dr: "",
  term_transit: "",
  percent_down_payment: "",
  percent_intransit: "",
  percent_upon_delivery: "",
  pdc_days_remaining: [],
  status: "",
  approved_by: "",
  approved_date: "",
  cancelled_by: "",
  cancelled_date: "",
  declined_by: "",
  declined_date: "",
  attachments: [],
  old_attachments: [],
  details: [],
};
