import { lazy } from "react";

const CustomerAging = lazy(() => import("../pages/Customers/CustomerAging"));

const customerAgingRoute = {
  public: [],
  auth: [
    {
      name: "customer_aging",
      path: "/credit-and-collections/management/customer-aging",
      component: CustomerAging,
    },
  ],
};

export default customerAgingRoute;
