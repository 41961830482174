import axios from "axios";
const token = JSON.parse(localStorage.getItem("token"));

export const axiosDelete = (route, objPayload) => {

    let config = 
    {
        data: objPayload,
        baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_END_POINT,
        headers: { Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")), accept: "application/json" },
      }     

    return axios.delete(route, config)

}

export default axiosDelete;