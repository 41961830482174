import makeApiRequest from "./makeApiCall";

// 0 = Pending for approval
// 1 = Approved
// 2 = Declined
// 3 = Cancelled

export const getSupplier = async (data) => {
  return await makeApiRequest(
    `/vendor-db/vendor-dropdown?status=0`,
    "GET",
    null,
    data
  );
};

export const getProducts = async (data) => {
  return await makeApiRequest(
    `/purchase/request/product_filter`,
    "GET",
    null,
    data
  );
};

export const getRequestApproval = async (data) => {
  return await makeApiRequest(
    `/purchase/request/forapproval`,
    "GET",
    null,
    data
  );
};

export const getRequestApproved = async (data) => {
  return await makeApiRequest(`/purchase/request/approved`, "GET", null, data);
};

export const getRequestDeclined = async (data) => {
  return await makeApiRequest(`/purchase/request/declined`, "GET", null, data);
};

export const getRequestCancelled = async (data) => {
  return await makeApiRequest(`/purchase/request/cancelled`, "GET", null, data);
};

export const getRequestDetails = async (data) => {
  return await makeApiRequest(
    `/purchase/request/viewprdetails`,
    "GET",
    null,
    data
  );
};

export const createPurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/createpurchaserequest`,
    "POST",
    null,
    data,
    true
  );
};

export const updatePurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/updatepurchaserequest`,
    "POST",
    null,
    data,
    true
  );
};

export const approvePurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/approvepurchaserequest`,
    "POST",
    null,
    data
  );
};

export const declinePurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/declinepurchaserequest`,
    "POST",
    null,
    data
  );
};

export const cancelPurchaseRequest = async (data) => {
  return await makeApiRequest(
    `/purchase/request/cancelpurchaserequest`,
    "POST",
    null,
    data
  );
};
