// @flow
const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
const RESET_ACTIVE_MENU = "RESET_ACTIVE_MENU";

export function setActiveMenu(menu = []) {
  return {
    type: SET_ACTIVE_MENU,
    activeMenu: menu,
  };
}

export function resetActiveMenu() {
  return {
    type: RESET_ACTIVE_MENU,
    activeMenu: [],
  };
}

const initialState = {
  activeMenu: [], // id of menu
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.activeMenu,
      };
    case RESET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: [],
      };

    default:
      return state;
  }
}
