import { PlusCircleFill } from 'react-bootstrap-icons';
import { Col, Row, Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { hasPermission } from '../PermissionsGate';
import { isUndefined } from 'lodash';

const WithFunction = (props) => {
  return (
    <Row>
      {props.customDropDown ? (
        <>
          {props?.customDropDown.map((item, key) => {
            if (isUndefined(item.permissons)) {
              if (!hasPermission(item.permissions)) return '';
            }

            return (
              <Col key={key} className="ms-auto  d-print-none">
                <Dropdown>
                  <Dropdown.Toggle variant="info" id="dropdown-basic">
                    {item.title}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {item.child &&
                      item.child.map((item, key) => {
                        return (
                          <Dropdown.Item
                            key={key}
                            disabled={props.loading}
                            onClick={item.handleOnClick}
                          >
                            {item.title}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            );
          })}
        </>
      ) : (
        ''
      )}
      {props?.customAddBtn ? (
        <>
          {props.customAddBtn.map((item, key) => {
            if (isUndefined(item.permissons)) {
              if (!hasPermission(item.permissions)) return '';
            }

            return (
              <Col key={key} className="ms-auto col-auto">
                <Button variant={item.variant} onClick={item.handleOnClick}>
                  <PlusCircleFill /> Add New {item.title}
                </Button>
              </Col>
            );
          })}
        </>
      ) : (
        ''
      )}
    </Row>
  );
};

WithFunction.propTypes = {
  customAddBtn: PropTypes.array.isRequired,
  customDropDown: PropTypes.arrayOf(
    PropTypes.shape({
      child: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};
export default WithFunction;
