import React, { Fragment } from "react";
import Accounting from "./Accounting.js";
import Purchasing from "./Purchasing.js";
import Pharmacy from "./Pharmacy.js";
import Secretary from "./Secretary.js";
import AuthorizedPurchasePerson from "./AuthorizedPurchasePerson.js";
import AuthorizedReceivedPerson from "./AuthorizedReceivedPerson";

function BusinessContacts({
  customer,
  form,
  handleChange,

  authPersonToReceiveFunction,
  authPersonToReceive,
  authPersonToPurchase,
  authPersonToPurchaseFunction,
}) {
  return (
    <Fragment>
      <fieldset className="details-field-set mb-4 mt-0">
        <legend>Business Contacts</legend>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              {form.customer_type !== 4 ? (
                <Fragment>
                  <Accounting form={form} handleChange={handleChange} />
                  <Purchasing form={form} handleChange={handleChange} />
                  <Pharmacy form={form} handleChange={handleChange} />
                </Fragment>
              ) : (
                <Secretary form={form} handleChange={handleChange} />
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <AuthorizedPurchasePerson
                  form={form}
                  handleChange={handleChange}
                  handleCheck={authPersonToPurchaseFunction}
                  dataStatus={authPersonToPurchase}
                />
                <AuthorizedReceivedPerson
                  form={form}
                  handleChange={handleChange}
                  handleCheck={authPersonToReceiveFunction}
                  dataStatus={authPersonToReceive}
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  );
}

export default BusinessContacts;
