import makeApiRequest from "./makeApiCall";

export const getAllIncomeStatement = async (data) => {
  return await makeApiRequest(
    `/ledger/other-reports/income-statement`,
    "POST",
    null,
    data,
    false
  );
};

//accounts dropdown
export const getAccountList = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ledger/accountcode-list`, "GET", null, data);
};
