import React, { useState, useEffect, Fragment } from 'react';
import NewMD from './NewMD';
import MD from './MD';
import Select from 'react-select';
import axiosInstance from '../../../../axiosInstance';
import useFormSelect2 from '../../../../../useFormSelect2';
import axiosDelete from '../../../../axiosDelete';
import PrimaryLoader from '../../../../PrimaryLoader';
import PrimaryToolTip from '../PrimaryToolTip';
import { getHospitalUsers } from '../../../../../api/customer';
import _ from 'lodash';

function CustomerMD({ styles, form, handleChange }) {
  const [mdValue, handleMdValue] = useFormSelect2({
    search: '',
  });

  const searchOptionHolder = [];
  const [mdListHolder, setMdListHolder] = useState([]);
  const [md, setMD] = useState({});
  const [customerMD, setCustomerMD] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMdCode, setSelectedMdCode] = useState();

  const hospitalSearchHolder = [];
  const [hospitalSearchValue, setHospitalSearchValue] = useState([]);

  useEffect(() => {
    let componentMounted = true;

    const loadMds = async () => {
      const res = await axiosInstance.get(`/search/customer/mds`, {
        method: 'GET',
      });
      if (componentMounted) {
        setMdListHolder(res.data.response.result);
      }
    };

    loadMds();
    setCustomerMD(form.customer_mds);
    loadHospitalSearch();

    return () => {
      componentMounted = false;
    };
  }, []);

  const loadHospitalSearch = async () => {
    setHospitalSearchValue([]);
    setLoading(true);

    try {
      let res = await getHospitalUsers();
      const { code, result } = res.data;
      if (code === '00000') {
        setHospitalSearchValue(result);
      }
    } catch (error) {
      console.log('Error Loading Customer Search');
    } finally {
      setLoading(false);
    }
  };

  const getMdByMdCode = (e) => {
    setLoading(true);
    setSelectedMdCode(e.value);

    let data = {
      md_code: e.value,
    };

    axiosInstance.post('/customer/associate/md/get', data).then((md) => {
      let responseMD = md.data.response.result;

      if (Array.isArray(responseMD)) {
        setMD(responseMD);
        //console.log(`MD  ${responseMD[0].md_profile.first_name}`);
      }

      setLoading(false);
    });
  };

  const associateMD = () => {
    setLoading(true);

    let data = {
      customer_code: form.customer_code,
      approval_code: form.approval_code,
      md_code: selectedMdCode,
    };

    axiosInstance.post('/customer/mds/store', data).then((res) => {
      getCustomerMD(form.customer_code);
    });
  };

  const deleteCustomerMD = (customerCode, customerMdId) => {
    setLoading(true);

    axiosDelete(`/customer/mds/soft-delete`, {
      customer_md_id: customerMdId,
    }).then(() => {
      getCustomerMD(customerCode);
    });
  };

  const deleteSelectedMD = () => {
    setMD({ ...md });
  };

  const getCustomerMD = (customerCode) => {
    axiosInstance.get(`/customer/${customerCode}`).then((res) => {
      let md = res.data.response.result;

      setCustomerMD(md.customer_mds);
      setLoading(false);
    });
  };

  {
    mdListHolder.map((md) => {
      searchOptionHolder.push({
        value: md.md_code,
        label: md.md_code + ' - ' + md.md_profile.first_name,
      });
    });

    hospitalSearchValue.map((value) => {
      hospitalSearchHolder.push({
        value: value.unique_id,
        label: value.hosp_user,
      });
    });
  }

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Associate MDs</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 col-sm-3"></div>
                <div className="col-md-5 col-sm-5">
                  <Select
                    name="search"
                    id="search"
                    placeholder="Search MD Code or Md Name"
                    value={mdValue.search}
                    options={searchOptionHolder}
                    onChange={getMdByMdCode}
                    required
                  />
                </div>
                <div className="col-md-3 col-sm-3">
                  <button className="btn btn-primary" onClick={associateMD}>
                    Associate MD
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>MDs Info</legend>
            <div className="col-md-12">
              <div className="row">
                <PrimaryLoader visible={loading} />
                <div className="accordion mb-3" id="accordionExample">
                  <NewMD
                    styles={styles}
                    md={md}
                    deleteSelectedMD={deleteSelectedMD}
                  />

                  {Array.isArray(customerMD) &&
                    customerMD.length > 0 &&
                    customerMD.map((md, index) => (
                      <MD
                        styles={styles}
                        number={index}
                        md={md}
                        onDelete={deleteCustomerMD}
                        customerCode={form.customer_code}
                      />
                    ))}
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>KAM / KASS / SAR Info</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        KAM
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <Select
                        options={hospitalSearchHolder}
                        placeholder="- List Of Names -"
                        name="kam_name"
                        value={
                          form.kam_name !== ''
                            ? hospitalSearchHolder.find(
                                (row) => row.value === form.kam_name,
                              )
                            : ''
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: 'kam_name', value: e.value },
                          })
                        }
                      />
                      {/* 
                      <input
                        name="kam_name"
                        value={form.kam_name}
                        onChange={handleChange}
                        className={"form-control"}
                      /> */}
                    </div>
                  </div>
                  {/*  */}
                  <div className="row mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        SAR
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <Select
                        options={hospitalSearchHolder}
                        placeholder="- List Of Names -"
                        name="sar"
                        value={
                          form.sar !== ''
                            ? hospitalSearchHolder.find(
                                (row) => row.value === form.sar,
                              )
                            : ''
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: 'sar', value: e.value },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  {/*  */}
                  <div className="row mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Segment Manager
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <Select
                        options={hospitalSearchHolder}
                        placeholder="- List Of Names -"
                        name="segment_manager"
                        value={
                          form.segment_manager !== ''
                            ? hospitalSearchHolder.find(
                                (row) => row.value === form.segment_manager,
                              )
                            : ''
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: 'segment_manager', value: e.value },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        KASS
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <Select
                        options={hospitalSearchHolder}
                        placeholder="- List Of Names -"
                        name="kass_name"
                        value={
                          form.kass_name !== ''
                            ? hospitalSearchHolder.find(
                                (row) => row.value === form.kass_name,
                              )
                            : ''
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: 'kass_name', value: e.value },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Hospital SAR
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <Select
                        options={hospitalSearchHolder}
                        placeholder="- List Of Names -"
                        name="hsar"
                        value={
                          form.hsar !== ''
                            ? hospitalSearchHolder.find(
                                (row) => row.value === form.hsar,
                              )
                            : ''
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: 'hsar', value: e.value },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomerMD;
