import React, { useState, useEffect } from "react";
import useForm from "../../useForm";
import axiosInstance from "../axiosInstance";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

const ProvideEmail = () => {
  const [values, handleChange] = useForm({
    email: "",
  });

  const closeInSeconds = 60;

  const getEmail = () => {
    axiosInstance
      .post("/forgot-password", values)
      .then((res) => {
        console.log(res);
        res.data.email
          ? Swal.fire({
              icon: "warning",
              title: "Invalid Email",
              text: res.data.email,
            })
          : Swal.fire({
              icon: "warning",
              title: "Email has been sent!",
              timer: closeInSeconds * 1000,
              showConfirmButton: false,
              allowOutsideClick: false,
              text: res.data.status,
            });
      })
      .catch((err) => {});
  };

  const submitHandler = (e) => {
    e.preventDefault();

    getEmail();
  };

  return (
    <div>
      <div class="page page-center">
        <div class="container-tight py-4">
          <div class="text-center mb-4">
            <a href="#">
              <img src="/Stacked Version.png" height="120px !important" />
            </a>
          </div>
          <form
            class="card card-md"
            action="."
            method="get"
            onSubmit={submitHandler}
          >
            <div class="card-body">
              <h2 class="card-title text-center mb-4">Forgot password?</h2>
              <p class="text-muted mb-4">
                Enter your email address and your password will be reset and
                emailed to you.
              </p>
              <div class="mb-3">
                <label class="form-label">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="eg. your-email@mail.com"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="form-footer">
                <button class="btn btn-primary w-100" value="Submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="3" y="5" width="18" height="14" rx="2" />
                    <polyline points="3 7 12 13 21 7" />
                  </svg>
                  Send me reset password link
                </button>
              </div>
            </div>
          </form>
          <div class="text-center text-muted mt-3">
            Forget it, <NavLink to="/">send me back</NavLink> to the sign in
            screen.
          </div>
        </div>
      </div>
      {/*<div>
        <div className="content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 contents">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="form-block shadow">
                      <div className="mb-4">
                        <h3 className="text-black">Provide Your Email</h3>
                      </div>
                      <form onSubmit={submitHandler}>
                        <div className="form-group first">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="eg. your-email@mail.com"
                            value={values.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-pill text-white btn-block btn-primary"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default ProvideEmail;
