import { useState, useRef, memo } from "react";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/react";
import _ from "lodash";

registerAllModules();

const ForecastTable = ({
  hotData,
  width,
  height,
  togglePrevForecast,
  onChange,
  onViewIncomingPo,
}) => {
  const [hiddenRows, setHiddenRows] = useState(hotData.hiddenRows);
  const hotRef = useRef();

  const handleTableCells = (row, col) => {
    let cellProperties = { className: "table-text-black", readOnly: true };
    if (row === 0) {
      if (col === 0) {
      } else {
        cellProperties.className = "table-year-row";
      }
    } else if (row === 1) {
      if (col === 0) {
      } else {
        cellProperties.className = "table-quarter-row";
      }
    } else if (row === 2) {
      if (col === 0) {
      } else {
        cellProperties.className = "table-month-row";
      }
    } else if (row === 3) {
      if (col === 0) {
      } else {
        cellProperties.className = "table-hit-rate-row";
      }
    }

    if (
      hotData.indexRow.current_inventory &&
      row === hotData.indexRow.current_inventory
    ) {
      cellProperties.className = "table-current-inventory-row";
    }

    if (
      hotData.indexRow.projected_ending_inventory &&
      row === hotData.indexRow.projected_ending_inventory
    ) {
      cellProperties.className = "table-projected-inventory-row";
    }

    if (row === hotData.indexRow.incoming_po_editable && col !== 0) {
      cellProperties.className = "table-editable-row";
      cellProperties.readOnly = false;
    }

    if (col === 0 && hotData.indexRow.incoming_po.indexOf(row) !== -1) {
      cellProperties.className = "table-incoming-po-label";
    }

    return cellProperties;
  };

  const handleTableAfterRenderer = (
    TD,
    row,
    col,
    prop,
    val,
    cellProperties
  ) => {
    if (
      hotData.indexColumn.current_month_year &&
      col === hotData.indexColumn.current_month_year &&
      row > 2
    ) {
      if (
        row === hotData.indexRow.actual_sales ||
        row === hotData.indexRow.pencil_book
      ) {
        TD.classList.add("text-danger");
        TD.classList.add("font-weight-bold");
      }

      TD.classList.add("table-current-month-year-column");
    }

    if (
      hotData.indexRow.actual_sales === row &&
      col > hotData.indexColumn.current_month_year
    ) {
      TD.classList.add("table-actual-sales-projected");
    }

    if (
      hotData.indexRow.inventory_level_per_month &&
      row === hotData.indexRow.inventory_level_per_month
    ) {
      if (val < 1.5 || val > 4.5) {
        TD.classList.add("table-inventory-level-row-red");
        //cellProperties.className = "table-inventory-level-row-red";
      } else if ((val >= 1.5 && val <= 2) || (val >= 4 && val <= 4.5)) {
        TD.classList.add("table-inventory-level-row-yellow");
        //cellProperties.className = "table-inventory-level-row-yellow";
      } else if ((val > 2 && val < 3) || (val > 3 && val < 4)) {
        TD.classList.add("table-inventory-level-row-light-green");
        //cellProperties.className = "table-inventory-level-row-light-green";
      } else if (val >= 3 && val <= 3) {
        TD.classList.add("table-inventory-level-row-dark-green");
        //cellProperties.className = "table-inventory-level-row-dark-green";
      } else {
        TD.classList.add("table-inventory-level-row-gray");
        //cellProperties.className = "table-inventory-level-row-gray";
      }
    }
  };

  const handleTableAfterSetDataCell = (changes, source) => {
    if (source !== "edit") return;

    const change = changes[0];

    if (!_.isEmpty(hotRef.current?.props?.data)) {
      // get years
      const years = hotRef.current?.props?.data[0].filter((val) => val);
      // get months
      let months = _.uniq(hotRef.current?.props?.data[2].filter((val) => val));

      // create monthYearKeys
      let parseMonthYearKeys = {};
      years.forEach((year) => {
        months.forEach((mos) => {
          parseMonthYearKeys[`${mos}_${year}`] = 0;
        });
      });

      let tmpData = [];

      _.each(hotRef.current?.props?.data, (row, index) => {
        if (index === hotData.indexRow.incoming_po_editable) {
          let tmpObject = { ...parseMonthYearKeys };

          _.each(row, (val, colIndex) => {
            let ctrYear = parseInt(years[0]) || 0;

            if (colIndex === 0) return;

            let getMonth = colIndex;

            while (getMonth > 12) {
              getMonth -= 12;
              ctrYear++;
            }

            tmpObject[`${months[getMonth - 1]}_${ctrYear}`] =
              parseFloat(val) || 0;
          });

          if (change[0] === index) {
            let getMonth = change[1];
            while (getMonth > 12) {
              getMonth -= 12;
            }

            getMonth -= 1;

            const getYear = Math.ceil(change[1] / 12) - 1;
            const initYear = parseInt(years[0]) || 0;

            tmpObject[`${months[getMonth]}_${initYear + getYear}`] =
              parseFloat(change[3]) || 0;
          }

          tmpData.push(tmpObject);
        }
      });

      onChange({
        incoming_po_editable: tmpData,
      });
    }
  };

  const handleTableAfterOnCellMouseDown = (e, coords, TD) => {
    if (coords.col === 0) {
      if (hotData.indexRow.incoming_po.indexOf(coords.row) !== -1) {
        onViewIncomingPo(hotData.indexRow.incoming_po_obj[coords.row]);
      }
    }
  };

  return (
    <HotTable
      ref={hotRef}
      data={hotData.data}
      mergeCells={hotData.mergeCells}
      colHeaders={false}
      rowHeaders={Array(hotData.data.length).fill("")}
      licenseKey="non-commercial-and-evaluation"
      width={width}
      height={height}
      fixedColumnsStart={1}
      fixedRowsTop={3}
      customBorders={hotData.customBorders}
      cells={handleTableCells}
      afterRenderer={handleTableAfterRenderer}
      afterSetDataAtCell={handleTableAfterSetDataCell}
      afterOnCellMouseDown={handleTableAfterOnCellMouseDown}
      stretchH={"all"}
      hiddenRows={{
        rows: togglePrevForecast ? hiddenRows : [],
        //indicators: true,
      }}
      contextMenu={["hidden_rows_show", "hidden_rows_hide"]}
    />
  );
};

export default memo(ForecastTable, (prevProps, nextProps) => {
  if (
    prevProps.hotData === nextProps.hotData &&
    prevProps.togglePrevForecast === nextProps.togglePrevForecast
  ) {
    return true;
  }

  return false;
});
