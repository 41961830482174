import makeApiRequest from "./makeApiCall";

export const getPublicCustomerCode = async (params) => {
  return await makeApiRequest(
    `/customer/credit/assessment`,
    "POST",
    null,
    params,
    false
  );
};
