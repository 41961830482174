//import { Banks, Branches, Accounts } from "../pages/Banks/components";
import { lazy } from "react";

const AssetManagement = lazy(() => import("../pages/AssetManagement/index"));

const PurchaseOrderNS = lazy(() => import("../pages/PurchaseOrderNS/index"));

const AssetSubledger = lazy(() => import("../pages/AssetSubledger/index"));

const assetManagementRoute = {
  public: [],
  auth: [
    {
      name: "am_asset_management",
      path: "/finance-and-accounting/asset-management/purchase-order-non-stock/:status?",
      component: PurchaseOrderNS,
    },
    {
      name: "am_asset_management",
      path: "/finance-and-accounting/asset-management/:page_slug/:id?",
      component: AssetManagement,
    },

    {
      name: "am_asset_subledger",
      path: "/finance-and-accounting/capitalized-assets",
      component: AssetSubledger,
    },
  ],
};

export default assetManagementRoute;
