import { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import UserForm from './UserForm';

import { createUser } from '../../../api/users';

const UserAddNewPage = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    username: 'admin',
    employee_no: '',
    last_name: '',
    first_name: '',
    email: '',
    password: '',
    department_id: '',
    job_id: '',
    contact_no: '',
    signature: '',
    signature_file_path: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files });
  };

  const submitHandler = () => {
    Swal.fire({
      icon: 'info',
      title: 'Are you sure you want to add new user?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#dc3545',
    }).then((result) => {
      if (result.isConfirmed) {
        if (checkEmptyFields()) {
          Swal.fire({
            icon: 'warning',
            title: 'Please fill up required fields.',
          });
        } else {
          createUser(form)
            .then((res) => {
              let { code, result } = res.data.response;
              if (code !== '00003') {
                Swal.fire({
                  icon: 'error',
                  text: result,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: 'success',
                  text: result,
                  showConfirmButton: true,
                });
                history.push('/user-control');
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.data.response.result,
              });
            });
        }
      }
    });
  };

  const checkEmptyFields = () => {
    let hasEmptyFields = false;

    let excludedFields = ['signature', 'signature_file_path'];
    Object.keys(form).every((field) => {
      if (form[field] === '' && excludedFields.indexOf(field) === -1) {
        hasEmptyFields = true;
        return false;
      }

      return true;
    });

    return hasEmptyFields;
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <h3>Add User Page</h3>
            </div>
            <UserForm
              form={form}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              showPassword={true}
            />
            <div className="card-footer">
              <div className="text-right">
                <Button
                  id="add"
                  variant="success"
                  type="button"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Save
                </Button>
                <Link
                  id="cancel"
                  to="/user-control"
                  className="ml-2 btn btn-danger"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserAddNewPage;
