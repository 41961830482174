import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import axiosInstance from "../axiosInstance";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";

import PrimaryDataTable from "../PrimaryDataTable";
import DefaultLayout from "../Layouts/DefaultLayout";

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */

const InventoryReportWarehouse = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [tableColumns, setTableColumns] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [pageSize, setPageSize] = useState(50); // for page purposes

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rowsData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  const location = useLocation();

  const productsData = location.state.objoutput.data;
  const warehouseFilter = location.state.warehouses;
  const warehouses = [];

  useEffect(() => {
    listWarehouse();
  }, []);

  const listWarehouse = () => {
    let columns = [
      {
        field: "product_code",
        headerName: "Product Code",
        width: 200,
      },
      {
        field: "product_name",
        headerName: "Product Name",
        width: 200,
      },
      {
        field: "generic_name",
        headerName: "Generic Name",
        width: 200,
      },
    ];

    axiosInstance
      .get(`/inventoryreport/dropdown/warehouse`)
      .then((response) => {
        var warehouselist = response.data.response.result;
        warehouselist.map((warehouse) => {
          if (warehouseFilter.length > 0) {
            if (warehouseFilter.includes(warehouse.warehouse_code)) {
              warehouses.push({
                warehouse_code: warehouse.warehouse_code,
                warehouse_name: warehouse.warehouse_name,
              });
            }
          } else {
            warehouses.push({
              warehouse_code: warehouse.warehouse_code,
              warehouse_name: warehouse.warehouse_name,
            });
          }
        });

        warehouses.map((warehouse) => {
          columns = [
            ...columns,
            {
              field: warehouse.warehouse_code,
              headerName: warehouse.warehouse_name,
              type: "number",
              width: 200,
            },
          ];
        });

        columns = [
          ...columns,
          {
            field: "totalprod",
            headerName: "Total Inventory",
            type: "number",
            width: 200,
          },
        ];

        setTableColumns(columns);
      });
  };

  useEffect(() => {
    const storeData = [];
    var res = productsData.map((data) => {
      var totalinventoryvalue = 0;

      let rowContent = {
        product_id: data.product_id,
        product_code: data.product_code,
        product_name: data.product_name,
        generic_name: data.brand_name,
      };

      data.movement_begin_warehouse_in.map((warehousein) => {
        rowContent = {
          ...rowContent,
          [warehousein.warehouse]: warehousein.TOTAL_IN,
        };
        totalinventoryvalue =
          parseInt(totalinventoryvalue) + parseInt(warehousein.TOTAL_IN);
      });

      var invvalue_in = 0;
      var invvalue_out = 0;
      var warehouse_val = 0;

      if (data.movement_warehouse_in.length > 0) {
        data.movement_warehouse_in.map((w_in) => {
          invvalue_in = w_in.TOTAL_IN;
          var warehouse_code = w_in.warehouse;
          if (data.movement_warehouse_out.length > 0) {
            //get if warehouse code in warehouse out
            let obj = data.movement_warehouse_out.find(
              (o) => o.warehouse === warehouse_code
            );

            if (obj) {
              invvalue_out = obj.TOTAL_OUT;
              warehouse_val = parseInt(invvalue_in) - parseInt(invvalue_out);
              totalinventoryvalue =
                parseInt(totalinventoryvalue) + parseInt(warehouse_val);

              rowContent = { ...rowContent, [warehouse_code]: warehouse_val };
            } else {
              warehouse_val = parseInt(invvalue_in);
              totalinventoryvalue =
                parseInt(totalinventoryvalue) + parseInt(warehouse_val);

              rowContent = { ...rowContent, [warehouse_code]: warehouse_val };
            }
          } else {
            warehouse_val = parseInt(invvalue_in);
            totalinventoryvalue =
              parseInt(totalinventoryvalue) + parseInt(warehouse_val);

            rowContent = { ...rowContent, [warehouse_code]: warehouse_val };
          }
        });
      }
      rowContent = { ...rowContent, totalprod: totalinventoryvalue };
      storeData.push(rowContent);
    });

    setRowsData(storeData);
    setFilteredData(storeData);
  }, []);

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  return (
    <Fragment>
      <DefaultLayout title="Inventory List of all Products">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <Link to="/inventoryreport">
              <button
                id="save_changes"
                className="btn btn-success"
                type="submit"
              >
                Change Table Columns
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <PrimaryDataTable
              autoHeight
              rows={filteredData}
              columns={tableColumns}
              getRowId={(row) => row.product_id}
              disableSelectionOnClick
              density="compact"
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </div>
      </DefaultLayout>
    </Fragment>
  );
};

export default InventoryReportWarehouse;
