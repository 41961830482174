import _ from "lodash";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import PrimaryLoader from "../../../components/PrimaryLoader";
import { useParams } from "react-router-dom";
import { getPublicCustomerCode } from "../../../api/public";
import ViewCustomerCreditAssessment from "../../../components/Customer/List/views/ViewCustomerCreditAssessment";
import moment from "moment";

const ViewCustomerCreditAssessmentPublic = () => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { customer_code } = useParams();

  useEffect(() => {
    loadData(customer_code);
  }, [customer_code]);

  const loadData = async (customerCode) => {
    setLoading(true);
    let res = await getPublicCustomerCode({
      customer_code: customerCode,
      date: moment().format("YYYY-MM-DD"),
    });

    const { code, result } = res.data.response;

    setLoading(false);

    if (code === "00000") {
      //set data
      parseData(result);
    } else {
      // show not found
      setCustomerData(null);
    }
  };

  const parseData = (data) => {
    let newData = {
      criteria_1: {},
      criteria_2: {},
      criteria_3: {},
      criteria_4: {},
      criteria_5: {},
      final_assessment: {},
      credit_assessment_basis: {},
    };

    _.each(data, (row, field) => {
      let key = Object.keys(row)[0];
      if (newData.hasOwnProperty(key)) {
        newData[key] = row[key].rating;
      }

      if (key === "final_assessment") {
        newData["credit_assessment_basis"] = row["breakdown"];
      }
    });

    setCustomerData(newData);
  };

  const renderData = () => {
    if (!customerData) {
      return <Alert variant="danger">No Data.</Alert>;
    }

    return <ViewCustomerCreditAssessment customer={customerData} />;
  };

  return (
    <div className="position-relative" style={{ minHeight: "100px" }}>
      <PrimaryLoader visible={loading} />
      {!loading && renderData()}
    </div>
  );
};

export default ViewCustomerCreditAssessmentPublic;
