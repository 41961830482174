import { lazy } from 'react';

const CashInBankSubledger = lazy(() =>
  import('../pages/CashInBankSubledger/Screens/CashInBankSubledgerScreen.js'),
);

const cashInBankSubledgerRoute = {
  public: [],
  auth: [
    {
      name: 'cash_in_bank_subledgder',
      path: '/finance-and-accounting/cash-in/bank/subledgder',
      component: CashInBankSubledger,
    },
  ],
};

export default cashInBankSubledgerRoute;
