import React, { useEffect } from 'react';
import PrimaryDataTable from '../../../../components/PrimaryDataTable';
import useGenerateLedgerQuery from './useGenerateLedgerQuery';
import { generalLedgerCol } from '../../const';

const GeneralLedgerLists = ({ form }) => {
  const {
    generalLedgerData,
    totalRows,
    isFetching,
    isLoading,
    column_running,
    handleFilterModelChange,
    handleDataTableChange,
  } = useGenerateLedgerQuery({ form });

  return (
    <PrimaryDataTable
      rows={generalLedgerData}
      columns={generalLedgerCol}
      columnVisibilityModel={{
        running_balance: column_running,
      }}
      getRowId={(row) => row.id}
      rowCount={totalRows}
      loading={isLoading}
      reload={isFetching}
      mode="server"
      onChange={handleDataTableChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
    />
  );
};

export default GeneralLedgerLists;
