export const productMaintenanceColumns = [
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    minWidth: 300,
    flex: 1,
  },
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    flex: 1,
  },
  {
    field: 'smpp_product_code',
    headerName: 'Customer Product Code',
    flex: 1,
    editable: true,
  },
  {
    field: 'template_product_name',
    headerName: 'Template Product Name',
    flex: 1,
    editable: true,
    description: 'DR / SI templated overrided product name',
  },
  {
    field: 'product_name',
    headerName: 'Internal Product Name',
    minWidth: 300,
    flex: 1,
  },
  {
    field: 'product_code',
    headerName: 'Internal Product Code',
    flex: 1,
  },
  // {
  //   field: "select",
  //   headerName: "Actions",
  //   align: "center",
  //   headerAlign: "center",
  //   minWidth: 80,
  //   flex: 1,
  //   renderCell: ({ row }) => {
  //     return (
  //       <div className="btn-actions">
  //         <Button
  //           className="btn-action-update"
  //           variant="link"
  //           size="sm"
  //           onClick={() => handleUpdateModal(row)}
  //         >
  //           <PencilSquare />
  //         </Button>
  //       </div>
  //     );
  //   },
  // },
];
