import { lazy } from 'react';

const TradeAndNonTradePayables = lazy(() =>
  import('../pages/TradeAndNonTradePayables/index'),
);
//
const tradeNonTradePayableRoute = {
  auth: [
    {
      name: 'separation_of_trade_non_trade_payables',
      path: '/finance-and-accounting/separation-of-trade-non-trade-payables',
      component: TradeAndNonTradePayables,
    },
  ],
};

export default tradeNonTradePayableRoute;
