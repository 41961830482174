import { lazy } from "react";

const PurchaseOrderScreens = lazy(() =>
  import("../components/PurchaseOrder/Screens/PurchaseOrderScreens")
);

const purchaseOrderRoute = {
  public: [],
  auth: [
    {
      name: "purchase_order",
      path: "/purchase-order",
      component: PurchaseOrderScreens,
    },
  ],
};

export default purchaseOrderRoute;
