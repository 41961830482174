import { useEffect, useState, useMemo, useCallback } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { productDataColumn } from "../components/productDataColumn";
import PrimaryLoader from "../../../components/PrimaryLoader";

const ProductSelectionModal = ({
  data,
  toggle,
  supplierID,
  handleCloseModal,
  handleRowDoubleClick,
  getData,
  rowKey,
  handleProductList,
}) => {
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [showSelected, setShowSelected] = useState(0);

  useEffect(() => {
    setSelectionModel([]);
  }, [supplierID]);

  useEffect(() => {
    // IF status is not new
    if (data.status !== "") {
      setSelectionModel(data.details.map((e) => parseInt(e.id)));

      loadData({
        supplier: data.supplier,
        vat_type: data.vat_exemption,
        percent_down_payment: data.percent_down_payment,
        percent_intransit: data.percent_intransit,
        percent_upon_delivery: data.percent_upon_delivery,
      });
    } else {
      loadData({
        supplier: data.supplier,
      });
    }
  }, [toggle]);

  // useEffect(() => {}, [selectionModel]);

  const loadData = useCallback((search = null) => {
    setPageLoader(true);

    toast.loading("Loading data...");

    getData(search).then((res) => {
      let { code, result } = res.data.response;

      if (code === "00000") {
        const newRows = parseData(result);

        setRows(newRows);
        setResult(newRows);

        setLoading(false);
        setPageLoader(false);

        toast.dismiss();
      }
    });
  }, []);

  const handleOnSelectProduct = useCallback(
    (e, row) => {
      // Check if not Exist
      if (!selectionModel.includes(row.id)) {
        let collectData = [...selectionModel, ...[row.id]];

        setSelectionModel(collectData);

        handleProductList(
          result
            .filter((row) => collectData.includes(parseInt(row.id)))
            .map((row) => ({
              ...row,
              requested_qty: row.quantity,
            }))
        );

        if (collectData.length === 1) {
          let toFilterData = {
            supplier: row.supplier_id,
            // vat_type: row.vat_exemption,
            // percent_down_payment: row.percent_down_payment,
            // percent_intransit: row.percent_intransit,
            // percent_upon_delivery: row.percent_upon_delivery,
            // pdc_days_remaining: row.product_pdc_payment_term.map(
            //   (e) => e.days_remaining
            // ),
            // pdc_percent_amount: row.product_pdc_payment_term.map(
            //   (e) => e.percent_amount
            // ),
          };

          loadData(toFilterData);
        }
      } else if (selectionModel.includes(row.id)) {
        let filteredData = selectionModel.filter((e) => e !== parseInt(row.id));

        setSelectionModel(filteredData);

        handleProductList(
          result
            .filter((row) => filteredData.includes(parseInt(row.id)))
            .map((row) => ({
              ...row,
              requested_qty: row.quantity,
            }))
        );

        if (filteredData.length === 0) {
          loadData({
            supplier: row.supplier_id,
            vat_type: "",
            percent_down_payment: "",
            percent_intransit: "",
            percent_upon_delivery: "",
            pdc_days_remaining: [],
            pdc_percent_amount: [],
          });
        }
      }
    },
    [result, selectionModel, loadData, handleProductList]
  );

  const parseProductDataColumn = useMemo(
    () =>
      productDataColumn({
        selectionModel,
        handleOnSelectProduct,
      }),
    [selectionModel, handleOnSelectProduct]
  );

  const parseData = (data) => {
    let columnName = parseProductDataColumn.map((col) => col["field"]);

    columnName.push(rowKey);

    // set dropdown data
    let newRows = data.map((row) => {
      return {
        ...row,
        id: row.product_id,
        unit_price: row.cost,
        total_amount: parseFloat(row.cost) * parseFloat(row.quantity),
        lead_time: row.supplier_lead_time,
        status: "",
      };
    });

    return newRows;

    // console.log("newRows", newRows);

    // setRows(newRows);
    // setLoading(false);
  };

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const handleSearch = (e) => {
    let tmpRows = rows;
    let searchValue = e.target.value;

    setRows([]);
    // setLoading(true);

    if (searchValue === "") {
      setRows(parseData(result));

      return;
    }

    // @TODO must from the server not from just array

    // @TODO change search
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = tmpRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test((row[field] || "").toString());
      });
    });

    setRows(filteredRows);
    setLoading(false);
  };

  const handleShowSelected = (e) => {
    setShowSelected(e.target.value);

    if (e.target.value === 1) {
      setRows(
        result
          .filter((row) => selectionModel.includes(parseInt(row.id)))
          .map((row) => ({
            ...row,
            requested_qty: row.quantity,
          }))
      );
    } else {
      setRows(result);
    }
  };

  return (
    <Modal
      show={toggle}
      onHide={handleCloseModal}
      backdrop="static"
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title> Product List </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PrimaryLoader visible={pageLoader} />
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Search Product</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search here..."
                onChange={handleSearch}
              />
            </Form.Group>
          </Col>
          {/*  */}
          <Col sm="4">
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label style={{ height: 27, minWidth: 200 }}></Form.Label>
              <Form.Check
                type="checkbox"
                checked={showSelected === 1}
                value={showSelected}
                onChange={(e) =>
                  handleShowSelected({
                    target: {
                      value: parseInt(e.target.value) === 1 ? 0 : 1,
                    },
                  })
                }
                label="Show all selected"
              />
            </Form.Group>
          </Col>
        </Row>
        {/*  */}
        <Row>
          <Col>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={parseProductDataColumn}
                rowsPerPageOptions={[100]}
                disableColumnSelector
                disableColumnFilter
                loading={loading}
                onRowDoubleClick={(e) => handleRowDoubleClick(e.row)}
                // checkboxSelection
                // selectionModel={selectionModel}
                // onSelectionModelChange={(event) => handleSelectionModel(event)}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ProductSelectionModal;
