import { useState } from 'react';
import { GridToolbar } from '@mui/x-data-grid-pro';

//component
import DetailsModal from '../components/Approver/DetailsModal.js';

//const
import { approverColumns } from '../Approver/const';

import defaultOptions from '../../../utils/defaultOptions';
import DefaultLayout from '../../../components/Layouts/DefaultLayout.js';
import { Tabs, Tab } from 'react-bootstrap';
import PrimaryDataTable from '../../../components/PrimaryDataTable/index.js';
import Approved from '../components/Status/Approved.js';
import Declined from '../components/Status/Declined.js';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate/index.js';
import { treasuryListApprovalPermission } from '../../../components/Navbar/permissions.js';

const ApproverScreen = ({
  forApprovalData,
  forApprovalLoading,
  approvedData,
  approvedLoading,
  declinedData,
  declinedLoading,
  handleDetailFormModal,
  detailModal,
  handleCloseModal,
  handleStatus,
  id,
}) => {
  const [pageSize, setPageSize] = useState(defaultOptions.pageSize);
  const columns = approverColumns({
    handleDetailsModal: handleDetailFormModal,
  });
  const [key, setKey] = useState('general');

  return (
    <DefaultLayout title="List of Approvals">
      <PermissionsGate
        scopes={treasuryListApprovalPermission}
        RenderError={AlertDefaultError}
      >
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          //onSelect={(selected) => console.log(selected)}
        >
          <Tab
            key={0}
            eventKey={'general'}
            title={'General'}
            className="default-tab-content"
          >
            <PrimaryDataTable
              rows={forApprovalData}
              columns={columns}
              //  disableColumnSelector={true}
              getRowId={(row) => row.detail_id}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={defaultOptions.rowsPerPageOptions}
              hideFooterSelectedRowCount
              components={{
                Toolbar: GridToolbar,
              }}
              loading={forApprovalLoading}
            />

            <DetailsModal
              toggle={detailModal.toggle}
              data={detailModal.data}
              sending={detailModal.sending}
              handleCloseModal={handleCloseModal}
              handleStatus={handleStatus}
              id={detailModal.id}
            />
          </Tab>
          <Tab
            key={1}
            eventKey={'approved'}
            title={'Approved'}
            className="default-tab-content"
          >
            <Approved data={approvedData} loading={approvedLoading} />
          </Tab>
          <Tab
            key={2}
            eventKey={'declined'}
            title={'Declined'}
            className="default-tab-content"
          >
            <Declined data={declinedData} loading={declinedLoading} />
          </Tab>
        </Tabs>
      </PermissionsGate>
    </DefaultLayout>
  );
};

export default ApproverScreen;
