import { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import Select from "react-select";
const DropdownProvince = ({ value, onChange, classWrapper, name }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const loadOptions = async () => {
      const res = await axiosInstance.get(`/addresses/provinces`, {
        method: "GET",
      });

      setOptions(res.data.response.result);
    };

    loadOptions();
  }, []);
  let provinceOptions = [];
  options.map((data) => {
    return provinceOptions.push({
      value: data.province_id,
      label: data.province,
    });
  });

  return (
    <Select
      options={provinceOptions}
      placeholder={"- Select an Option -"}
      name={typeof name === "undefined" ? "province" : name}
      value={
        value
          ? provinceOptions.find(
              ({ value: provinceId }) => provinceId === value
            )
          : ""
      }
      onChange={(e) => onChange(e.value)}
    />
  );

  // const renderOptions = () => {
  //   return options.map((data, index) => {
  //     return (
  //       <option key={data.province_id} value={data.province_id}>
  //         {data.province}
  //       </option>
  //     );
  //   });
  // };

  // return (
  //   <select
  //     value={value}
  //     className={classWrapper}
  //     name={typeof name === "undefined" ? "province" : name}
  //     onChange={(e) => onChange(e.target.value)}
  //   >
  //     <option value="">- Select an option -</option>
  //     {renderOptions()}
  //   </select>
  // );
};

const DropdownCities = ({
  provinceId,
  value,
  onChange,
  classWrapper,
  name,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (provinceId) {
      const loadOptions = async () => {
        const res = await axiosInstance.post(`/addresses/cities`, {
          province_id: provinceId,
        });
        setOptions(res.data.response.result);
      };

      loadOptions();
    } else {
      setOptions([]);
    }
  }, [provinceId]);
  let citiesOptions = [];

  options.map((data) => {
    return citiesOptions.push({
      value: data.city_id,
      label: data.city,
    });
  });
  return (
    <Select
      options={citiesOptions}
      placeholder={"- Select an Option -"}
      name={typeof name === "undefined" ? "city" : name}
      value={
        value
          ? citiesOptions.find(({ value: city_id }) => city_id === value)
          : ""
      }
      onChange={(e) => onChange(e.value)}
    />
  );

  // const renderOptions = () => {
  //   return options.map((data) => {
  //     return (
  //       <option key={data.city_id} value={data.city_id}>
  //         {data.city}
  //       </option>
  //     );
  //   });
  // };

  // return (

  //   <select
  //     value={value}
  //     className={classWrapper}
  //     name={typeof name === "undefined" ? "city" : name}
  //     onChange={(e) => onChange(e.target.value)}
  //   >
  //     <option value="">- Select an option -</option>
  //     {renderOptions()}
  //   </select>
  // );
};

const DropdownBarangays = ({ cityId, value, onChange, classWrapper, name }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (cityId) {
      const loadOptions = async () => {
        const res = await axiosInstance.post(`/addresses/barangays`, {
          city_id: cityId,
        });
        setOptions(res.data.response.result);
      };

      loadOptions();
    } else {
      setOptions([]);
    }
  }, [cityId]);
  let brgyOptions = [];
  options.map((data) => {
    return brgyOptions.push({
      value: data.barangay_id,
      label: data.barangay,
    });
  });
  // const renderOptions = () => {
  // <option key={data.barangay_id} value={data.barangay_id}>
  //   {data.barangay}
  // </option>
  //};
  return (
    <Select
      options={brgyOptions}
      placeholder={"- Select an Option -"}
      name={typeof name === "undefined" ? "barangay" : name}
      value={
        value
          ? brgyOptions.find(({ value: barangay_id }) => barangay_id === value)
          : ""
      }
      onChange={(e) => onChange(e.value)}
    />
    // <select
    //   value={value}
    //   className={classWrapper}
    //   name={typeof name === "undefined" ? "barangay" : name}
    //   onChange={(e) => onChange(e.target.value)}
    // >
    //   <option value="">- Select an option -</option>
    //   {renderOptions()}
    // </select>
  );
};

const DropdownZipCodes = ({ cityId, value, onChange, classWrapper, name }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (cityId) {
      const loadOptions = async () => {
        const res = await axiosInstance.post(`/addresses/zipcodes`, {
          city_id: cityId,
        });
        setOptions(res.data.response.result);
      };

      loadOptions();
    } else {
      setOptions([]);
    }
  }, [cityId]);

  let zipCodeOptions = [];

  options.map((data) => {
    return zipCodeOptions.push({
      value: data.id,
      label: data.area + " - " + data.zip_code_number,
    });
  });
  return (
    <Select
      options={zipCodeOptions}
      placeholder={"- Select an Option -"}
      name={typeof name === "undefined" ? "zip_code" : name}
      value={value ? zipCodeOptions.find(({ value: id }) => id === value) : ""}
      onChange={(e) => onChange(e.value)}
    />
  );

  // const renderOptions = () => {
  //   return options.map((data) => {
  //     return (
  //       <option key={data.id} value={data.id}>
  //         {data.area} - {data.zip_code_number}
  //       </option>
  //     );
  //   });
  // };

  // return (
  //   <select
  //     value={value}
  //     className={classWrapper}
  //     name={typeof name === "undefined" ? "zip_code" : name}
  //     onChange={(e) => onChange(e.target.value)}
  //   >
  //     <option value="">- Select an option -</option>
  //     {renderOptions()}
  //   </select>
  // );
};

export {
  DropdownProvince,
  DropdownCities,
  DropdownBarangays,
  DropdownZipCodes,
};
