import { lazy } from 'react';

const TransactionListing = lazy(() =>
  import('../pages/TransactionListing/TransactionListing'),
);

const transactionListingRoute = {
  public: [],
  auth: [
    {
      name: 'transaction_listing_route',
      path: '/finance-and-accounting/transaction-listing',
      component: TransactionListing,
    },
  ],
};

export default transactionListingRoute;
