import { lazy } from 'react';

const AdvancesSubledgder = lazy(() =>
  import('../pages/AdvancesSubledgder/index.js').then((module) => ({
    default: module.AdvancesSubledgder,
  })),
);

const AdvancesSupplierSubledger = lazy(() =>
  import('../pages/AdvancesSupplierSubledger/index'),
);

const advancesSubledgderRoute = {
  auth: [
    {
      name: 'advances_subledger',
      path: '/credit-and-collections/management/memo-credit/advances-subledger',
      component: AdvancesSubledgder,
    },
    {
      name: 'advances_supplier_subledger',
      path: '/finance-and-accounting/advances-supplier-subledger',
      component: AdvancesSupplierSubledger,
    },
  ],
};

export default advancesSubledgderRoute;
