import React, { useState, useEffect, Fragment } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import axiosInstance from "../axiosInstance";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import AddProducts from "../Products/AddProducts";
import Modal from "react-bootstrap/Modal";
import "../Products/modal.css";
import { AlertDefaultError, PermissionsGate } from "../PermissionsGate";
// reducer
import useNavActive from "../Navbar/useNavActive";
import PrimaryDataTable from "../PrimaryDataTable";
import { productsListColumn } from "./const";
/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const Products = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [productsData, setProductsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  useNavActive(["master_databases", "product_databases", "product_list"]);

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = productsData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  /* Continuation of Search */

  useEffect(() => {
    let componentMounted = true;

    const loadProducts = async () => {
      const res = await axiosInstance.get(`/products`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      });
      if (componentMounted) {
        setProductsData(res.data.response.result);
        setFilteredData(res.data.response.result);
      }
    };

    loadProducts();
    return () => {
      componentMounted = false;
    };
  }, [pageSize]);

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">List of Product Stocks</h2>
              </div>
              <PermissionsGate scopes={["add_product"]}>
                <div className="col-auto ms-auto d-print-none">
                  <span
                    onClick={() => setShowModal(true)}
                    className="btn btn-success btn-pill bg-header"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    Create New Product
                  </span>
                </div>
              </PermissionsGate>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-md-12">
              <PermissionsGate
                scopes={["view_products"]}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={filteredData}
                  columns={productsListColumn}
                  getRowId={(row) => row.product_id}
                  density="compact"
                  disableColumnFilter
                  rowCount={filteredData.length}
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                  //mode="server"
                  //reload={reloadTable}
                  //onChange={handleOnDataTableChange}
                  //loading={loading}
                  /*
                  Norman Implem

                  rows={filteredData}
                  columns={wrapColumns.current}
                  sortingOrder={["desc", "asc"]}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  pageSize={pageSize}
                  disableColumnResize={true}
                  hideFooterSelectedRowCount
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  density="compact"
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                  autoHeight
                  */
                />
              </PermissionsGate>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-width"
        keyboard={false}
        backdrop="static"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Create New Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProducts />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Products;
