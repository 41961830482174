import FormRequestScreen from "../Screens/FormRequestScreen";
import PendingRequestScreen from "../Screens/PendingRequestScreen";
import ApprovedRequestScreen from "../Screens/ApprovedRequestScreen";
import CancelledRequestScreen from "../Screens/CancelledRequestScreen";
import EditRequestScreen from "../Screens/EditRequestScreen";

export const tabs = [
  // {
  //   tab_name: 'Add New',
  //   tab_key: 'create',
  //   tab_permission: ['view_create_purchase_request'],
  //   tab_component: FormRequestScreen
  // },
  {
    tab_name: "For Approval PR's",
    tab_key: "approval",
    tab_permission: ["view_pending_purchase_request_list"],
    tab_status: 0,
    tab_component: PendingRequestScreen,
  },
  {
    tab_name: "Approved PR's",
    tab_key: "approved",
    tab_permission: ["view_approved_purchase_request_list"],
    tab_status: 1,
    tab_component: ApprovedRequestScreen,
  },
  {
    tab_name: "Cancelled PR's",
    tab_key: "cancelled",
    tab_permission: ["view_cancelled_purchase_request_list"],
    tab_status: 3,
    tab_component: CancelledRequestScreen,
  },
  {
    tab_name: "For Editing PR's",
    tab_key: "edit",
    tab_permission: ["view_editing_purchase_request_list"],
    tab_status: 2,
    tab_component: EditRequestScreen,
  },
];
