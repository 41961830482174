import PurchaseRequest from "../pages/PurchaseRequest";
import FormRequestScreen from "../pages/PurchaseRequest/Screens/FormRequestScreen";

const purchasingRoute = {
  public: [],
  auth: [
    {
      name: "purchase_request",
      path: "/purchasing/purchaseRequest/create",
      component: FormRequestScreen,
    },
    {
      name: "purchase_request",
      path: "/purchasing/purchaseRequest/show/:id",
      component: FormRequestScreen,
    },
    {
      name: "purchase_request",
      path: "/purchasing/purchaseRequest/:tab",
      component: PurchaseRequest,
    },
  ],
};

export default purchasingRoute;