import { createElement, useEffect } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import DefaultLayout from "../../components/Layouts/DefaultLayout";
import useNavActive from "../../components/Navbar/useNavActive";
import { hasPermission } from "../../components/PermissionsGate";
import { tabs } from "./components/tabs";

const PurchaseRequest = () => {
  useNavActive(["purchasing", "purchase_request"]);

  const { tab } = useParams();
  const history = useHistory();

  const [activeKey, setActivekey] = useState(tab);

  useEffect(() => {
    setActivekey(tab);
  }, [tab]);

  const handleChangeTab = (key) => {
    history.replace("/purchasing/purchaseRequest/" + key);
    setActivekey(key);
  };

  return (
    <DefaultLayout title="Purchase Request">
      <Tabs
        id="controlled-tab-example"
        activeKey={activeKey}
        className="default-tab"
        onSelect={(key) => handleChangeTab(key)}
      >
        {tabs.map(
          (tab) =>
            hasPermission(tab.tab_permission) && (
              <Tab
                key={tab.tab_key}
                eventKey={tab.tab_key}
                title={tab.tab_name}
                className="default-tab-content"
              >
                {createElement(tab.tab_component)}
              </Tab>
            )
        )}
      </Tabs>
    </DefaultLayout>
  );
};

export default PurchaseRequest;
