import { lazy } from "react";

const PayableVoucher = lazy(() => import("../pages/PayableVoucher"));

const payableVoucherRoute = {
  public: [],
  auth: [
    {
      name: "payable-voucher",
      path: "/finance-and-accounting/payable-voucher/:id?",
      component: PayableVoucher,
    },
  ],
};

export default payableVoucherRoute;
