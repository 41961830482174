import { lazy } from 'react';

const CreditMemoSubledgder = lazy(() =>
  import('../pages/CreditMemoSubledgder/index.js').then((module) => ({
    default: module.CreditMemoSubledgder,
  })),
);

const creditMemoSubledgderRoute = {
  public: [],
  auth: [
    {
      name: 'credit_memo_subledgder',
      path: '/credit-and-collections/management/memo-credit/credit-memo-subledger',
      component: CreditMemoSubledgder,
    },
  ],
};

export default creditMemoSubledgderRoute;
