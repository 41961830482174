import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { numberFormatCurrency, toFixed } from "../../../utils/helper";

const defaultHotData = {
  data: [],
  mergeCells: [],
  customBorders: [],
  indexRow: {
    current_inventory: 0,
    projected_ending_inventory: 0,
    inventory_level_per_month: 0,
    actual_sales: 0,
    pencil_book: 0,
    incoming_po_editable: 0,
    incoming_po_obj: {},
    incoming_po: [],
  },
  indexColumn: {
    current_month_year: 0,
  },
  hiddenRows: [],
  parseData: null,
};
const useForecast = () => {
  const [rawData, setRawData] = useState({ result: [], product: null });
  const [hotData, setHotData] = useState(defaultHotData); // handonstable data

  const [forecastData, setForecastData] = useState({
    keys: {},
    forecast: [],
    current_forecast_per_month: {},
    beginning_inventory: {},
    rts: {},
    adjustment: {},
    received_inventories: {},
    total_received_inventories_per_month: {},
    actual_sales: {},
    pencil_book: {},
    current_inventory: {},
    incoming_shipments: [],
    total_incoming_shipments_per_month: {},
    projected_ending_inventory: {},
    inventory_level_per_month: {},
    hit_rate: {},
  });

  const monthObj = {
    1: "jan",
    2: "feb",
    3: "mar",
    4: "apr",
    5: "may",
    6: "jun",
    7: "jul",
    8: "aug",
    9: "sep",
    10: "oct",
    11: "nov",
    12: "dec",
  };

  const monthNameObj = {
    january: "jan",
    february: "feb",
    march: "mar",
    april: "apr",
    may: "may",
    june: "jun",
    july: "jul",
    august: "aug",
    september: "sep",
    october: "oct",
    november: "nov",
    december: "dec",
  };

  const monthNoObj = {
    1: "jan",
    2: "feb",
    3: "mar",
    4: "apr",
    5: "may",
    6: "jun",
    7: "jul",
    8: "aug",
    9: "sep",
    10: "oct",
    11: "nov",
    12: "dec",
  };

  const setRawForecastData = useCallback((data) => {
    setRawData(data);
  }, []);

  useEffect(() => {
    if (rawData.product && rawData.result.length) {
      parseData();
      console.log("test");
    }
  }, [rawData]);

  const parseData = () => {
    let firstRowCol = 1;
    let firstRowColSpan = 12;
    let secondRowCol = 1;

    let hotHeader = [
      [""], //year
      [""], // quarter
      [""], // month
    ];

    let mergeCells = [];
    let customBorders = [];

    let parseData = {
      keys: {},
      forecast: [],
      current_forecast_per_month: {},
      actual_sales: {},
      rts: {},
      incoming_shipments: [],
    };

    console.log({ rawData });

    // get headers and keys
    rawData.result.forEach((row, index) => {
      let quarterCtr = 1;

      for (let i = 1; i <= 12; i++) {
        hotHeader[0].push(i === 1 ? row.year : "");

        if (i % 3 === 1) {
          hotHeader[1].push("QTR " + quarterCtr);

          // merge quarter
          mergeCells.push({
            row: 1,
            rowspan: 1,
            col: secondRowCol,
            colspan: 3,
          });

          //add quarter border
          customBorders.push({
            range: {
              from: {
                row: 1,
                col: secondRowCol,
              },
              to: {
                row: 1,
                col: secondRowCol + 2,
              },
            },
            top: {
              width: 2,
              color: "black",
            },
            bottom: {
              width: 2,
              color: "black",
            },
            start: {
              width: 2,
              color: "black",
            },
            end: {
              width: 2,
              color: "black",
            },
          });

          secondRowCol += 3;

          quarterCtr++;
        } else {
          hotHeader[1].push("");
        }

        hotHeader[2].push(monthObj[i]);

        let month_year = monthObj[i] + "_" + row.year;
        parseData.keys[month_year] = 0;
      }

      // merge year
      mergeCells.push({
        row: 0,
        rowspan: 1,
        col: firstRowCol,
        colspan: firstRowColSpan,
      });

      //add year border
      customBorders.push({
        range: {
          from: {
            row: 0,
            col: firstRowCol,
          },
          to: {
            row: 0,
            col: (index + 1) * 12,
          },
        },
        top: {
          width: 2,
          color: "black",
        },
        bottom: {
          width: 2,
          color: "black",
        },
        start: {
          width: 2,
          color: "black",
        },
        end: {
          width: 2,
          color: "black",
        },
      });

      firstRowCol = firstRowCol + firstRowColSpan;
    });

    // merge
    mergeCells.push({
      row: 0,
      rowspan: 3,
      col: 0,
      colspan: 1,
    });

    // set columns
    parseData.actual_sales = { ...parseData.keys };
    parseData.rts = { ...parseData.keys };
    parseData.received_inventories = { ...parseData.keys };
    parseData.pencil_book = { ...parseData.keys };
    parseData.current_inventory = { ...parseData.keys };
    parseData.adjustment = { ...parseData.keys };
    parseData.hit_rate = { ...parseData.keys };
    parseData.projected_ending_inventory = { ...parseData.keys };
    parseData.inventory_level_per_month = { ...parseData.keys };
    parseData.beginning_inventory = { ...parseData.keys };
    parseData.incoming_shipments = [];
    parseData.total_incoming_shipments_per_month = { ...parseData.keys };

    rawData.result.forEach((yearData) => {
      // set forecast row per month
      yearData.forecast.forEach((row) => {
        let { forecast_date, ...forecastMonthData } = row;

        let tmp = {
          title:
            "Forecast " + row.forecast_year + " (" + row.forecast_date + ")",
          ...parseData.keys,
        };

        let convertForecastMonthData = {};

        _.forEach(forecastMonthData, (value, key) => {
          convertForecastMonthData[monthNameObj[key] + "_" + yearData.year] =
            value;
        });

        tmp = { ...tmp, ...convertForecastMonthData };

        parseData.forecast.push(tmp);
      });

      // parse actual sales data per month
      yearData.actual_sales.forEach((row) => {
        const month = monthNameObj[row.month.toLowerCase()];
        parseData.actual_sales[month + "_" + yearData.year] =
          row.actual_sales_data;
      });

      // if start of year set the beginning inventory
      parseData.beginning_inventory["jan_" + yearData.year] =
        yearData.beginning_inventory;

      // yearData?.beginning_inventory.forEach((row) => {
      //   const month = monthNameObj[row.month.toLowerCase()];
      //   parseData.beginning_inventory[month + "_" + yearData.year] =
      //     row.actual_sales_data;
      // });

      // parse rts
      yearData?.rts.forEach((row) => {
        const month = monthNameObj[row.month.toLowerCase()];
        parseData.rts[month + "_" + yearData.year] = row.qty;
      });

      //parse adjustment
      yearData?.adjustment.forEach((row) => {
        const month = monthNameObj[row.month.toLowerCase()];
        parseData.adjustment[month + "_" + yearData.year] = row.qty;
      });

      //parse received inventories
      yearData?.received_inventories.forEach((row) => {
        const month = monthNameObj[row.month.toLowerCase()];
        parseData.received_inventories[month + "_" + yearData.year] = row.qty;
      });

      //parse pencil book
      yearData?.pencil_book.forEach((row) => {
        const month = monthNameObj[row.month.toLowerCase()];
        parseData.pencil_book[month + "_" + yearData.year] = row.qty;
      });

      // if (yearData?.incoming_shipments.length) {
      //   yearData.incoming_shipments.forEach((incomingShipment) => {
      //     let tmp = { title: incomingShipment.title, ...parseData.keys };

      //     _.forEach(incomingShipment.months, (row) => {
      //       const month = monthNameObj[row.month.toLowerCase()];
      //       tmp[month + "_" + yearData.year] = row.qty;

      //       parseData.total_incoming_shipments_per_month[
      //         month + "_" + yearData.year
      //       ] += row.qty;
      //     });

      //     parseData.incoming_shipments.push(tmp);
      //   });
      // }

      yearData.incoming_po.forEach((incoming_shipment) => {
        const month = monthNameObj[incoming_shipment.month.toLowerCase()];

        incoming_shipment.po.forEach((row) => {
          const tmp = {
            title: `(${row.po_no}) ${row.pr_no}`,
            po_no: row.po_no,
            pr_no: row.pr_no,
            po_hdr_id: row.po_hdr_id,
            ...parseData.keys,
            [month + "_" + yearData.year]: row.total_orders,
          };

          parseData.incoming_shipments.push(tmp);

          parseData.total_incoming_shipments_per_month[
            month + "_" + yearData.year
          ] += row.total_orders;
        });
      });
    });

    rawData.incoming_po_editable.forEach((row) => {
      _.forEach(parseData.keys, (value, month_year) => {
        parseData.total_incoming_shipments_per_month[month_year] +=
          row[month_year];
      });
    });

    _.forEach(parseData.keys, (value, month_year) => {
      const monthYearSplit = month_year.split("_");
      const month = moment(
        `${monthYearSplit[0]} 01, ${monthYearSplit[1]}`
      ).format("MM");

      // get the current forecast per month
      parseData.current_forecast_per_month[month_year] = _.chain(
        parseData.forecast
      )
        .map(month_year)
        .filter()
        .last()
        .value();

      if (month !== "01") {
        // exclude january for the beginning
        parseData.beginning_inventory[month_year] = navigateObject(
          parseData.projected_ending_inventory,
          month_year,
          -1
        );
      }

      // get current inventory per month
      parseData.current_inventory[month_year] =
        parseData.beginning_inventory[month_year] +
        parseData.rts[month_year] +
        parseData.adjustment[month_year] +
        parseData.received_inventories[month_year] -
        (parseData.actual_sales[month_year] +
          parseData.pencil_book[month_year]);

      // get current hit rate per month (actual_sales / current_forecast)
      let hitRatePerMonth =
        parseData.current_forecast_per_month[month_year] > 0
          ? toFixed(
              (parseData.actual_sales[month_year] /
                parseData.current_forecast_per_month[month_year]) *
                100,
              10
            )
          : 0;
      parseData.hit_rate[month_year] = hitRatePerMonth + "%";

      // projected ending inventory
      parseData.projected_ending_inventory[month_year] =
        parseData.total_incoming_shipments_per_month[month_year] +
        parseData.current_inventory[month_year];
    });

    parseData = calculateProjectedData(parseData);

    // parse projected inventory level
    _.forEach(parseData.keys, (value, month_year) => {
      let levelArr = [];
      for (let i = 1; i <= 3; i++) {
        const val = navigateObject(parseData.actual_sales, month_year, i);

        if (typeof val !== "undefined") {
          levelArr.push(val);
        }
      }

      const level = levelArr.reduce((partialSum, a) => partialSum + a, 0);

      const avg = level / levelArr.length;

      parseData.inventory_level_per_month[month_year] =
        avg > 0
          ? toFixed(parseData.projected_ending_inventory[month_year] / avg)
          : 0;
    });

    // calculate projected data
    buildHandsOnTable(parseData, hotHeader, mergeCells, customBorders);
  };

  const calculateProjectedData = (parseData) => {
    // get current month year
    const currentMonthYear = moment().format("YYYY-MM-01");

    _.forEach(parseData.keys, (value, month_year) => {
      // convert key to date
      const keyArr = month_year.split("_");
      const keyDate = moment(`${keyArr[0]} 01, ${keyArr[1]}`).format(
        "YYYY-MM-DD"
      );

      const isAfterCurrentMonth = moment(keyDate).isAfter(currentMonthYear);
      // check if

      if (isAfterCurrentMonth) {
        // set beginning inventory from previous beginning inventory
        const prevProjectedEndingInventory =
          parseInt(
            navigateObject(parseData.projected_ending_inventory, month_year, -1)
          ) || 0;

        // check if there is already beginning inventory from return
        if (
          keyArr[0] === "jan" &&
          parseData.beginning_inventory[month_year] > 0
        ) {
        } else {
          parseData.beginning_inventory[month_year] =
            prevProjectedEndingInventory;
        }

        //set actual sales
        parseData.actual_sales[month_year] =
          parseInt(parseData.current_forecast_per_month[month_year]) || 0;

        // set current inventory
        parseData.current_inventory[month_year] = parseData.current_inventory[
          month_year
        ] =
          parseData.beginning_inventory[month_year] +
          parseData.rts[month_year] +
          parseData.adjustment[month_year] +
          parseData.received_inventories[month_year] -
          (parseData.actual_sales[month_year] +
            parseData.pencil_book[month_year]);

        // projected ending inventory
        parseData.projected_ending_inventory[month_year] =
          parseData.total_incoming_shipments_per_month[month_year] +
          parseData.current_inventory[month_year];

        // inventory level monthly

        // hit rate per month
        let hitRatePerMonth =
          parseData.current_forecast_per_month[month_year] > 0
            ? toFixed(
                (parseData.actual_sales[month_year] /
                  parseData.current_forecast_per_month[month_year]) *
                  100,
                10
              )
            : 0;
        parseData.hit_rate[month_year] = hitRatePerMonth + "%";
      }
    });

    return parseData;
  };

  const buildHandsOnTable = (
    parseData,
    hotTable = [],
    mergeCells = [],
    customBorders = []
  ) => {
    let indexRow = {
      current_inventory: 0,
      projected_ending_inventory: 0,
      incoming_po: [],
      incoming_po_obj: {},
    };
    let indexColumn = { current_month_year: 0 };
    let hiddenRows = [];

    let colLength = Object.keys(parseData.keys).length;
    let rowFiller = Array(colLength).fill("");

    hotTable.push([
      `${rawData.product.product_name} (${rawData.product.shelf_life} Mos) Hit Rate`,
      ...Object.values(parseData.hit_rate),
    ]);

    const forecastLen = parseData.forecast.length - 1;
    parseData.forecast.forEach((row, index) => {
      hotTable.push(
        Object.values(row).map((val) =>
          val ? (isNaN(val) ? val : numberFormatCurrency(val, null)) : ""
        )
      );

      if (index < forecastLen) {
        hiddenRows.push(hotTable.length - 1);
      }
    });

    hotTable.push([...rowFiller]);

    hotTable.push([
      "Beginning Inventory",
      ...Object.values(parseData.beginning_inventory).map((val) =>
        numberFormatCurrency(val, null)
      ),
    ]);

    hotTable.push([
      "ADD: RTS",
      ...Object.values(parseData.rts).map((val) =>
        val ? numberFormatCurrency(val, null) : ""
      ),
    ]);

    hotTable.push([
      "Adjustments",
      ...Object.values(parseData.adjustment).map((val) =>
        val ? numberFormatCurrency(val, null) : ""
      ),
    ]);

    hotTable.push([
      "ADD: Received Inventories",
      ...Object.values(parseData.received_inventories).map((val) =>
        val ? numberFormatCurrency(val, null) : ""
      ),
    ]);

    hotTable.push([
      "LESS: ACTUAL SALES",
      ...Object.values(parseData.actual_sales).map((val) =>
        numberFormatCurrency(val, null)
      ),
    ]);
    indexRow.actual_sales = hotTable.length - 1;

    hotTable.push([
      "LESS: PENCIL BOOKED SALES",
      ...Object.values(parseData.pencil_book).map((val) =>
        val ? numberFormatCurrency(val, null) : ""
      ),
    ]);
    indexRow.pencil_book = hotTable.length - 1;

    hotTable.push([
      "CURRENT INVENTORY",
      ...Object.values(parseData.current_inventory).map((val) =>
        numberFormatCurrency(val, null)
      ),
    ]);

    indexRow.current_inventory = hotTable.length - 1;

    let incomingShipmentFillerCtr = 3;

    if (!parseData.incoming_shipments.length) {
      hotTable.push([
        "ADD: Incoming Shipments (PO)",
        ...Array(colLength - 1).fill(""),
      ]);
      incomingShipmentFillerCtr--;
    } else {
      parseData.incoming_shipments.forEach((row) => {
        const { title, po_no, pr_no, po_hdr_id, ...months } = row;

        hotTable.push([
          `Incoming Shipment: ${title}`,
          ...Object.values(months).map((val) =>
            val ? numberFormatCurrency(val, null) : ""
          ),
        ]);

        indexRow.incoming_po.push(hotTable.length - 1);
        indexRow.incoming_po_obj[hotTable.length - 1] = row;

        incomingShipmentFillerCtr--;
      });
    }

    if (rawData.incoming_po_editable.length > 0) {
      rawData.incoming_po_editable.forEach((row) => {
        hotTable.push([
          ``,
          ...Object.values(row).map((val) =>
            val ? numberFormatCurrency(val, null) : ""
          ),
        ]);
      });

      indexRow.incoming_po_editable = hotTable.length - 1;

      incomingShipmentFillerCtr--;
    }

    if (incomingShipmentFillerCtr > 0) {
      if (!rawData.incoming_po_editable.length) {
        indexRow.incoming_po_editable = hotTable.length;
      }

      for (let i = 0; i < incomingShipmentFillerCtr; i++) {
        hotTable.push([...rowFiller]);
      }
    }

    hotTable.push([
      `MOQ ${numberFormatCurrency(rawData.product.quantity, null)} (LT: ${
        rawData.product.supplier_lead_time
      }D)`,
      ...Array(colLength - 1).fill(""),
    ]);

    hotTable.push([
      "Projected Ending Inventory",
      ...Object.values(parseData.projected_ending_inventory).map((val) =>
        numberFormatCurrency(val, null)
      ),
    ]);

    indexRow.projected_ending_inventory = hotTable.length - 1;

    hotTable.push([
      "Inventory Level (in Mos)",
      ...Object.values(parseData.inventory_level_per_month),
    ]);

    indexRow.inventory_level_per_month = hotTable.length - 1;

    // set index column for current month year
    const currentMonthYear = moment().format("MMM_YYYY").toLowerCase();

    indexColumn = {
      ...indexColumn,
      current_month_year:
        Object.keys(parseData.keys).indexOf(currentMonthYear) + 1,
    };

    setHotData({
      ...hotData,
      data: hotTable,
      mergeCells,
      customBorders,
      indexRow,
      indexColumn,
      hiddenRows,
      parseData,
    });
  };

  const navigateObject = (obj, currentKey, direction) => {
    return Object.values(obj)[Object.keys(obj).indexOf(currentKey) + direction];
  };

  return [forecastData, hotData, setRawForecastData];
};

export default useForecast;
