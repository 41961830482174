import React from 'react';
import CustomToolTip from '../CustomToolTip';

function MD({ styles, md, number, onDelete, customerCode }) {
  return (
    <div className="accordion-item mt-2">
      <h2 className="accordion-header" id="headingTwo">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#accordion${number}`}
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          {md.last_name}, {md.first_name}
        </button>
      </h2>
      <div
        id={`accordion${number}`}
        className="accordion-collapse collapse"
        aria-labelledby="headingTwo"
      >
        <div className="accordion-body">
          <div className="col-sm-12 row">
            <div className="col-sm-6" style={styles.divPadding}>
              <div className="form-group row " style={styles.formGroup}>
                <label
                  htmlFor="customer_account_name"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  MD Firstname
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="md_firstname"
                      id="md_firstname"
                      value={md.first_name}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}
              <div className="form-group row" style={styles.formGroup}>
                <label
                  htmlFor="customer_account_name"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  MD Lastname
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="md_lastname"
                      id="md_lastname"
                      value={md.last_name}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="form-group row" style={styles.formGroup}>
                <label
                  htmlFor="md_contact_no"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  Contact Number
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="md_contact_no"
                      id="md_contact_no"
                      value={md.contact_no}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div className="form-group row" style={styles.formGroup}>
                <label
                  htmlFor="md_email"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  Email
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      name="md_email"
                      id="md_email"
                      value={md.email}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}
            </div>
            {/* bottom col 6 */}
            <div className="col-sm-6" style={styles.divPadding}>
              <div className="form-group row " style={styles.formGroup}>
                <label
                  htmlFor="contact_person"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  Contact Person
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="contact_person"
                      id="contact_person"
                      value={md.contact_person_name}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}

              <div className="form-group row" style={styles.formGroup}>
                <label
                  htmlFor="contact_person_number"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  Contact Number
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="contact_person_number"
                      id="contact_person_number"
                      value={md.contact_person_contact_no}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}

              <div className="form-group row" style={styles.formGroup}>
                <label
                  htmlFor="contact_person_email"
                  className="col-sm-4 col-form-label col-form-label-sm"
                >
                  Email
                </label>
                <div
                  className="col-sm-8 row pr-0"
                  style={{
                    marginTop: '6px',
                  }}
                >
                  <div className="col-sm-11 row">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      name="contact_person_email"
                      id="contact_person_email"
                      value={md.contact_person_email}
                    />
                  </div>
                  <CustomToolTip />
                </div>
              </div>
              {/*  */}
            </div>
            {/*  */}

            {/* Address Section */}
            <div className="col-sm-12" style={styles.divPadding}>
              <section style={styles.fieldset}>
                <legend
                  style={{ ...styles.legend, backgroundColor: '#f4f6fa' }}
                >
                  Address
                </legend>
                <div className="col-sm-12 row mt-4">
                  <div className="form-group row" style={styles.formGroup}>
                    <label
                      htmlFor="bldg_street_address"
                      className="col-sm-2 col-form-label col-form-label-sm"
                    >
                      Bldg & Street Address
                    </label>
                    <div className="col-sm-9 row ">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="bldg_street_address"
                        id="bldg_street_address"
                        value={md.building_street}
                      />
                    </div>
                    <CustomToolTip />
                  </div>
                </div>
                {/* SM-12 */}
                <div className="col-sm-12 row">
                  <div className="col-sm-6" style={styles.divPadding}>
                    <div className="form-group row " style={styles.formGroup}>
                      <label
                        htmlFor="brgy"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        Barangay
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="brgy"
                            id="brgy"
                            value={md.barangay}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-group row" style={styles.formGroup}>
                      <label
                        htmlFor="city"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        City
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="city"
                            id="city"
                            value={md.city}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="form-group row" style={styles.formGroup}>
                      <label
                        htmlFor="province"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        Province
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="province"
                            id="province"
                            value={md.province}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="form-group row" style={styles.formGroup}>
                      <label
                        htmlFor="zip_code"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        Zip Code
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="zip_code"
                            id="zip_code"
                            value={md.zip_code}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  {/* bottom col 6 */}
                  <div className="col-sm-6" style={styles.divPadding}>
                    <div className="form-group row " style={styles.formGroup}>
                      <label
                        htmlFor="lba_code"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        LBA Code
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="lba_code"
                            id="lba_code"
                            value={md.lba_code}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}

                    <div className="form-group row" style={styles.formGroup}>
                      <label
                        htmlFor="lba_name"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        LBA Name
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="lba_name"
                            id="lba_name"
                            value={md.lba_name}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}

                    <div className="form-group row" style={styles.formGroup}>
                      <label
                        htmlFor="district"
                        className="col-sm-4 col-form-label col-form-label-sm"
                      >
                        District
                      </label>
                      <div
                        className="col-sm-8 row pr-0"
                        style={{
                          marginTop: '6px',
                        }}
                      >
                        <div className="col-sm-11 row">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="district"
                            id="district"
                            value={md.district}
                          />
                        </div>
                        <CustomToolTip />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  {/*  */}

                  {/* End of Address Section */}
                </div>
                {/* END SM-12 */}
              </section>
            </div>
            {/* End of Address Section */}

            <div className="col-sm-12 d-flex justify-content-center mt-3">
              <button
                className="btn btn-danger"
                onClick={() => onDelete(customerCode, md.customer_md_id)}
              >
                Remove MD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MD;
