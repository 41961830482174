import { lazy } from 'react';

const InventoryInvoicedSubledger = lazy(() =>
  import('../pages/InventoryInvoicedSubledger'),
);

const InventoryInvoicedSubledgerRoute = {
  auth: [
    {
      name: 'inventory_invoiced_subledger',
      path: '/finance-and-accounting/inventory-invoiced-subledger',
      component: InventoryInvoicedSubledger,
    },
  ],
};

export default InventoryInvoicedSubledgerRoute;
