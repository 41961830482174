import Sales from "../pages/Ledgers/Sales";
import Receivables from "../pages/Ledgers/Receivables";
import Inventories from "../pages/Ledgers/Inventories";
import SalesPerProducts from "../pages/Ledgers/SalesPerProducts";
import VatableSales from "../pages/Ledgers/VatableSales";
import Products from "../pages/Ledgers/Products";
import YtdSales from "../pages/Ledgers/YtdSales";

const ledgerRoute = {
  public: [],
  auth: [
    {
      name: "sales_ledger_report",
      path: "/ledgers/sales",
      component: Sales,
    },
    {
      name: "receivable_ledger_report",
      path: "/ledgers/receivables",
      component: Receivables,
    },
    {
      name: "rinventories_ledger_report",
      path: "/ledgers/inventories",
      component: Inventories,
    },
    {
      name: "sales_per_product_ledger",
      path: "/ledgers/sales-per-products",
      component: SalesPerProducts,
    },
    {
      name: "vatable_sales_ledger",
      path: "/ledgers/vatable-sales",
      component: VatableSales,
    },
    {
      name: "product_ledger",
      path: "/ledgers/products",
      component: Products,
    },
    {
      name: "ytd_sales_ledger",
      path: "/ledgers/ytd-sales",
      component: YtdSales,
    },
  ],
};

export default ledgerRoute;
