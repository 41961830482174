import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState, Fragment } from 'react';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';

const freeDescriptionPosition = [
  { label: 'Middle', value: 'mid' },
  { label: 'End', value: 'end' },
];

const freeGoodsPosition = [
  { label: 'Top before product', value: 'top_before_product' },
  { label: 'Bottom of product', value: 'bottom_of_product' },
  { label: 'Bottom of all product', value: 'bottom_of_all_product' },
];

const quantityOption = [
  { label: 'Package', value: 'package' },
  { label: 'Quantity per Package', value: 'quantity' },
];

const vatOption = [
  { value: 'vatable_sales', label: 'VAT Sales' },
  { value: 'vat_exempt', label: 'VAT Exempt' },
  { value: 'vat_amount', label: 'VAT Amount' },
];

const CustomerSalesInvoiceTemplateSetup = ({
  form,
  setForm,
  customerDetails,
}) => {
  const [templateForm, setTemplateFrom] = useState({
    display_packaging: '0',
    free_goods_bottom: '0',
    large_font: '0',
    manufacturing_date: '0',
    one_product_one_si: '0',
    product_code: '0',
    si_template_id: '0',
    customer_name: '',
    total_gross_discount: '0',
    display_po: '0',
    maximum_line: '0',
    display_unit_of_measure: '0',
    display_gross_amount: '0',
    display_percentage: '0',
    display_notes: '0',
    display_expiry_date: '0',
    fg_manufacturing_date: '0',
    free_description_position: '',
    free_goods_position: '0',
    quantity: [],
    display_vat: [],
    remove_brackets: '0',
  });

  useEffect(() => {
    if (customerDetails.si_template) {
      setTemplateFrom({ ...templateForm, ...customerDetails.si_template });
    }
  }, [customerDetails]);

  useEffect(() => {
    setForm({ ...form, si_template: [templateForm] });
  }, [templateForm]);

  const handleChangeCheckbox = (event) => {
    setTemplateFrom({
      ...templateForm,
      [event.target.name]: event.target.checked ? '1' : '0',
    });
  };

  const handleChangeNumberInput = (event) => {
    setTemplateFrom({
      ...templateForm,
      [event.target.name]: event.target.value.replace(/[^\d]+/, ''),
    });
  };

  const handleChangeCustomerName = (event) => {
    setTemplateFrom({
      ...templateForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeSingleCheckbox = (event, row) => {
    setTemplateFrom({
      ...templateForm,
      [event.target.name]: row.value,
    });
  };

  const handleChangeMultipleCheckbox = (e) => {
    const { value, name } = e.target;
    //check existing yung value dun sa form
    let multiple = templateForm[name];
    let exist = multiple.find((row) => row === value);
    console.log({ value, name, multiple: templateForm[name], exist });

    if (exist) {
      multiple = multiple.filter((row) => row !== value);
    } else {
      multiple.push(value);
    }

    setTemplateFrom({ ...templateForm, [name]: multiple });
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Printed SI Settings</legend>
            <div className="col-md-12">
              <div className="row pl-2 pr-2 pt-4">
                <Col lg="12">
                  <Form>
                    {/* Display Settings */}
                    {/* <Form.Group as={Row} className="mb-2" controlId="checkbox2">
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="Display All Free Goods at Bottom"
                        name="free_goods_bottom"
                        onChange={(e) => {
                          handleChangeCheckbox(e);
                        }}
                        checked={
                          templateForm.free_goods_bottom === "1" ? true : false
                        }
                      />
                    </Col>
                    <Col sm="8">
                      All free goods will display at the bottom of printed SI.
                    </Col>
                  </Form.Group> */}
                    <Form.Group as={Row} className="mb-2" controlId="checkbox2">
                      <Col sm="4 pt-1">Customer Name</Col>
                      <Col sm="8" className="pl-2.0">
                        <Form.Control
                          tye="text"
                          variant="standard"
                          name="customer_name"
                          onChange={(e) => {
                            handleChangeCustomerName(e);
                          }}
                          value={templateForm.customer_name}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="checkbox3">
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Total Gross & Discount"
                          name="total_gross_discount"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.total_gross_discount === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Computation will display at the bottom of SI
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="checkbox5">
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Customer Product Code"
                          name="product_code"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.product_code === '1' ? true : false
                          }
                        />
                      </Col>
                      <Col sm="8">Managed in SMPP System.</Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="checkbox6">
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Manufacturing Date"
                          name="manufacturing_date"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.manufacturing_date === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show Item Manufacturing Date in the Description.
                      </Col>
                    </Form.Group>

                    {/* <Form.Group as={Row} className="mb-2" controlId="checkbox7">
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="Display Packaging"
                        name="display_packaging"
                        onChange={(e) => {
                          handleChangeCheckbox(e);
                        }}
                        checked={
                          templateForm.display_packaging === "1" ? true : false
                        }
                      />
                    </Col>
                    <Col sm="8">Show Item Packaging in the Description.</Col>
                  </Form.Group> */}

                    <Form.Group as={Row} className="mb-2" controlId="checkbox8">
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Purchase Order (PO)"
                          name="display_po"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_po === '1' ? true : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show PO No. on the top right of the Sales Invoice.
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox10"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Unit of Measure"
                          name="display_unit_of_measure"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_unit_of_measure === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">Show Unit of Measure in the Description.</Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox18"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Brackets"
                          name="remove_brackets"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.remove_brackets === '1' ? true : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show amount/percentage in brackets per line item.
                      </Col>
                    </Form.Group>

                    {/* Multiple Settings */}
                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox11"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Gross Amount in Product Description"
                          name="display_gross_amount"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_gross_amount === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show Gross amount before discount in the description.
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox12"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Percentage in Product Description"
                          name="display_percentage"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_percentage === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show the Discount Percentage for the Product in the
                        description.
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox13"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Notes for Continuation"
                          name="display_notes"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_notes === '1' ? true : false
                          }
                        />
                      </Col>
                      <Col sm="8">
                        Show Pagination note for 2 or more pages of Sales
                        Invoice.
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox14"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Manufacturing Date on Free Goods"
                          name="fg_manufacturing_date"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.fg_manufacturing_date === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">Show Manufacturing Date on Free Goods.</Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox20"
                    >
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Display Expiry Date"
                          name="display_expiry_date"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.display_expiry_date === '1'
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col sm="8">Show Item Expiry Date.</Col>
                    </Form.Group>
                    <hr></hr>
                    {/* Other Settings */}
                    <Form.Group as={Row} className="mb-2" controlId="checkbox4">
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Use Large Font"
                          name="large_font"
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                          checked={
                            templateForm.large_font === '1' ? true : false
                          }
                        />
                      </Col>
                      <Col sm="8">Use bigger font on printed SI.</Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2" controlId="checkbox9">
                      <Col sm="4">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Maximum no of lines"
                        >
                          <Form.Control
                            variant="standard"
                            name="maximum_line"
                            maxLength="2"
                            onChange={(e) => {
                              handleChangeNumberInput(e);
                            }}
                            value={templateForm.maximum_line}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm="8">
                        Limit no. of lines per page into{' '}
                        {templateForm.maximum_line}.
                      </Col>
                    </Form.Group>
                    <hr></hr>
                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox15"
                    >
                      <Col sm="4" className="pl-4">
                        Select Free Tag on Product Description
                      </Col>
                      <Col sm="8">
                        {freeDescriptionPosition.map((row) => (
                          <Form.Check
                            inline
                            id={row.value}
                            key={row.value}
                            value={row.value}
                            label={row.label}
                            checked={
                              templateForm.free_description_position ===
                              row.value
                            }
                            onChange={(e) => handleChangeSingleCheckbox(e, row)}
                            name="free_description_position"
                            type="radio"
                          />
                        ))}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox16"
                    >
                      <Col sm="4" className="pl-4">
                        Select Free Goods Position
                      </Col>
                      <Col sm="8">
                        {freeGoodsPosition.map((row) => (
                          <Form.Check
                            inline
                            id={row.value}
                            key={row.value}
                            value={row.value}
                            label={row.label}
                            checked={
                              templateForm.free_goods_position === row.value
                            }
                            onChange={(e) => handleChangeSingleCheckbox(e, row)}
                            name="free_goods_position"
                            type="radio"
                          />
                        ))}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="checkbox17"
                    >
                      <Col sm="4" className="pl-4">
                        Select Quantity to display
                      </Col>
                      <Col sm="8">
                        {/* {quantityOption.map((row) => (
                          <Form.Check
                            inline
                            id={row.value}
                            key={row.value}
                            value={row.value}
                            label={row.label}
                            checked={
                              templateForm.quantity.indexOf(row.value) !== -1
                            }
                            onChange={(e) => handleChangeMultipleCheckbox(e)}
                            name="quantity"
                            type="checkbox"
                          />
                        ))} */}
                      </Col>
                    </Form.Group>

                    {/* <Form.Group as={Row} className="mb-2" controlId="checkbox17">
                    <Col sm="4" className="pl-4">Select VAT Type (Sales/Exempt/Amount)</Col>
                    <Col sm="8">
                      {vatOption.map((row) => (
                        <Form.Check
                          inline
                          id={row.value}
                          key={row.value}
                          value={row.value}
                          label={row.label}
                          checked={
                            templateForm.display_vat.indexOf(row.value) !==
                            -1
                          }
                          onChange={(e) => handleChangeMultipleCheckbox(e)}
                          name="display_vat"
                          type="checkbox"
                        />
                      ))}
                    </Col>
                  </Form.Group> */}
                  </Form>
                </Col>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerSalesInvoiceTemplateSetup;
