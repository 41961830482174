import makeApiRequest from './makeApiCall';

export const getUsers = async () => {
  return await makeApiRequest(`/users`, 'GET');
};

export const getRoles = async (data) => {
  return await makeApiRequest(`/role`, 'GET', null, data);
};

export const getRolesID = async (id, data) => {
  return await makeApiRequest(`/role/${id}`, 'GET', null, data);
};

export const createRole = async (data) => {
  return await makeApiRequest(`/role/create`, 'POST', null, data);
};

export const updateRole = async (data) => {
  return await makeApiRequest(`/role/update`, 'PUT', null, data);
};

export const createUsersRole = async (id, data) => {
  return await makeApiRequest(`/user/${id}/role/create`, 'POST', null, data);
};

export const deleteRole = async (role_id) => {
  return await makeApiRequest(`/role/delete`, 'DELETE', null, { role_id });
};

export const getUser = async (id) => {
  return await makeApiRequest(`/users/${id}`, 'GET', null, null);
};

export const createUser = async (data) => {
  return await makeApiRequest(`/users/create`, 'POST', null, data, true);
};

export const updateUser = async (data) => {
  return await makeApiRequest(
    `/users/update?_method=PUT`,
    'POST',
    null,
    data,
    true,
  );
};

export const loadDepartments = async (data) => {
  return await makeApiRequest(`/departments`, 'GET', null, data);
};

export const loadJobs = async (data) => {
  return await makeApiRequest(`/jobs`, 'GET', null, data);
};

export const activateUser = async (user_id) => {
  return await makeApiRequest(`/users/activate`, 'PUT', null, { user_id });
};

export const deactivateUser = async (user_id) => {
  return await makeApiRequest(`/users/deactivate`, 'PUT', null, { user_id });
};
