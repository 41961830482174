import { useSelector } from "react-redux";

const AppToken = () => {
  const { accessToken } = useSelector(({ auth }) => auth);
  return (
    <div className="container">
      <div class="card bg-light" style={{ marginTop: "30px" }}>
        <div class="card-body">{accessToken}</div>
      </div>
    </div>
  );
};

const tokenRoute = {
  public: [],
  auth: [{ name: "get_token", path: "/access_token", component: AppToken }],
};

export default tokenRoute;
