import { Tabs, Tab, Card, Button } from 'react-bootstrap';
import '../../../globalStyles/css/bootstrap/bootstrap.min.css';
import '../ledger.css';
import PrimaryAsyncSelect from '../../../components/PrimaryAsyncSelect';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';
import { getActivePrducts } from '../../../api/ledgers';
import Select from 'react-select';

import PrimaryDataTable from '../../../components/PrimaryDataTable';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';
import { numberFormatCurrency } from '../../../utils/helper';
import PrimaryLoader from '../../../components/PrimaryLoader';

const inventoriesCol = [
  {
    field: 'product_code',
    headerName: 'Product Code',
    width: 150,
    sortable: false,
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    width: 250,
    sortable: false,
  },
  {
    field: 'batch_no',
    headerName: 'Batch No.',
    width: 150,
    sortable: false,
  },
  {
    field: 'source_document',
    headerName: 'Source Document',
    width: 150,
    sortable: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 150,
    sortable: false,
    type: 'number',
  },
  {
    field: 'qty_balance',
    headerName: 'Qty. Balance',
    width: 150,
    sortable: false,
    type: 'number',
  },
  {
    field: 'cost_unit',
    headerName: 'Cost per Unit',
    width: 200,
    sortable: false,
    type: 'number',
  },
  {
    field: 'diluted_cost',
    headerName: 'Diluted Cost per Unit',
    width: 200,
    sortable: false,
    type: 'number',
  },
  {
    field: 'amount',
    headerName: 'Peso',
    width: 200,
    sortable: false,
    type: 'number',
    valueGetter: ({ row }) =>
      row.amount ? numberFormatCurrency(row.amount) : row.amount,
  },
  {
    field: 'amount_balance',
    headerName: 'Peso Balance',
    width: 200,
    sortable: false,
    type: 'number',
    valueGetter: ({ row }) =>
      row.amount_balance
        ? numberFormatCurrency(row.amount_balance)
        : row.amount_balance,
  },
  {
    field: 'movement_type',
    headerName: 'Movement Type',
    width: 200,
    sortable: false,
  },
  {
    field: 'module',
    headerName: 'Module',
    width: 200,
    sortable: false,
  },
  {
    field: 'particulars',
    headerName: 'Particulars',
    width: 200,
    sortable: false,
  },
  {
    field: 'transaction_date',
    headerName: 'Transaction Date',
    width: 200,
    sortable: false,
  },
  {
    field: 'created_at',
    headerName: 'Movement Date',
    width: 200,
    sortable: false,
  },
  {
    field: 'supplier_name',
    headerName: 'Supplier Name',
    width: 200,
    sortable: false,
  },
  {
    field: 'floor',
    headerName: 'Floor',
    width: 200,
    sortable: false,
  },
  {
    field: 'pallet_code',
    headerName: 'Pallete',
    width: 200,
    sortable: false,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    width: 300,
    sortable: false,
  },
];

// const inventoriesCol_old = [
//   {
//     field: 'product_code',
//     headerName: 'Product Code',
//     width: 150,
//     sortable: false,
//   },
//   {
//     field: 'product_name',
//     headerName: 'Product Name',
//     width: 150,
//     sortable: false,
//   },
//   {
//     field: 'transaction_date',
//     headerName: 'Date',
//     width: 150,
//     sortable: false,
//   },
//   { field: 'reference', headerName: 'Reference', width: 150, sortable: false },
//   { field: 'source', headerName: 'Source', width: 150, sortable: false },
//   {
//     field: 'particulars',
//     headerName: 'Particulars',
//     width: 150,
//     sortable: false,
//   },

//   {
//     field: 'purchases_unit',
//     headerName: 'Purchases Unit',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'purchases_cost',
//     headerName: 'Purchases Cost',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'purchases_total',
//     headerName: 'Purchases Total',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'sold_unit',
//     headerName: 'Sold Unit',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'sold_cost',
//     headerName: 'Sold Cost',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'sold_total',
//     headerName: 'Sold Total',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'adjustment_unit',
//     headerName: 'Adjustment Unit',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'adjustment_cost',
//     headerName: 'Adjustment Cost',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'adjustment_total',
//     headerName: 'Adjustment Total',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'inventory_transfer_unit',
//     headerName: 'Inventory Transfer Unit',
//     width: 200,
//     sortable: false,
//   },
//   {
//     field: 'inventory_transfer_cost',
//     headerName: 'Inventory Transfer Cost',
//     width: 200,
//     sortable: false,
//     type: 'number',
//     renderCell: ({ row }) => {
//       numberFormatCurrency(row.inventory_transfer_cost);
//     },
//   },
//   {
//     field: 'inventory_transfer_total',
//     headerName: 'Inventory Transfer Total',
//     width: 200,
//     sortable: false,
//     type: 'number',
//     renderCell: ({ row }) => {
//       numberFormatCurrency(row.inventory_transfer_total);
//     },
//   },
//   {
//     field: 'overall_balance_unit',
//     headerName: 'Overall Unit',
//     width: 300,
//     sortable: false,
//     type: 'number',
//     renderCell: ({ row }) => {
//       numberFormatCurrency(row.overall_balance_unit);
//     },
//   },
//   {
//     field: 'overall_balance_cost',
//     headerName: 'Overall Cost',
//     width: 300,
//     sortable: false,
//     type: 'number',
//     renderCell: ({ row }) => {
//       numberFormatCurrency(row.overall_balance_cost);
//     },
//   },
//   {
//     field: 'overall_balance_total',
//     headerName: 'Overall Total',
//     sortable: false,
//     width: 300,
//     type: 'number',
//     renderCell: ({ row }) => {
//       numberFormatCurrency(row.overall_balance_total);
//     },
//   },
// ];

const InventoriesLedgerReportScreen = ({
  handleChangeSupplier,
  handleChange,
  formData,
  suppliername_options,
  productname_options,
  dateChange,
  handleSearch,
  inventoriesData,
  selectedTab,
  handleSelectedTab,
  handleDataTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <PrimaryLoader visible={loading} />
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab
            className="default-tab-content"
            eventKey="inventories_ledger"
            title="Inventory Sub Ledger"
          >
            <div className="col-md-12">
              <div className="row">
                <PermissionsGate scopes={['view_inventory_sub_ledger_list']}>
                  <div className="col-md-3">
                    <label>Select Supplier</label>
                    <div>
                      <Select
                        name="supplier_id"
                        classNamePrefix={'bk-select'}
                        options={suppliername_options}
                        menuPlacement="auto"
                        placeholder="ALL"
                        onChange={(e) =>
                          handleChangeSupplier({
                            target: {
                              name: 'supplier_id',
                              value: e?.value || null,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-3">
                    <label>Select Product</label>
                    <label className="req">*</label>
                    <div>
                      <Select
                        isMulti
                        name="product_name"
                        classNamePrefix={'bk-select'}
                        options={productname_options}
                        menuPlacement="auto"
                        placeholder="ALL"
                        //menuPosition="fixed"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: 'product_name',
                              value: e,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-md-2">
                    <label>Date From</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_from'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_from}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_from', value: date },
                        })
                      }
                    />
                  </div>
                  {/*  */}
                  <div className="col-md-2">
                    <label>Date To</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_to'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_to}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_to', value: date },
                        })
                      }
                    />
                  </div>
                  {/*  */}
                  <div className="col-md-2">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>{' '}
                      <PermissionsGate
                        scopes={['export_inventory_sub_ledger_list']}
                      >
                        <button
                          className=" btn btn-success"
                          onClick={handleExport}
                        >
                          Export
                        </button>
                      </PermissionsGate>
                    </div>
                  </div>
                </PermissionsGate>
              </div>
              <br />
              <PermissionsGate
                scopes={['view_inventory_sub_ledger_list']}
                RenderError={AlertDefaultError}
              >
                {inventoriesData.map((product) => (
                  <>
                    <Card className="shadow">
                      <Card.Header>{product.product_name}</Card.Header>
                      <Card.Body>
                        <PrimaryDataTable
                          key={product.product_id}
                          rows={product.product_movement}
                          columns={inventoriesCol}
                          getRowId={(row) => row.product_movement_id}
                          // treeData
                          // getTreeDataPath={(row) => row.path}
                          // groupingColDef={{
                          //   headerName: 'Batch No',
                          //   valueGetter: ({ row }) => row.batch_no,
                          // }}
                          disableColumnFilter
                          loading={loading}
                          reload={reloadTable}
                        />
                      </Card.Body>
                    </Card>
                    <br />
                  </>
                ))}
              </PermissionsGate>
            </div>
          </Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default InventoriesLedgerReportScreen;
