import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Plus } from "react-bootstrap-icons";
import ModalDropdown from "./CustomerProductAndDiscountsModal";
import { numberFormatCurrency } from "../../../../utils/helper";
import { getCustomerData, getProductsData } from "../../../../api/customer";
import PrimaryDataTable from "../../../PrimaryDataTable";

const CustomerProductAndDiscounts = ({
  customer,
  handleProductDiscount,
  customerProductDiscount,
}) => {
  const [show, setShow] = useState(false);

  const [productsData, setProductsData] = useState([]);
  const [productsTable, setProductsTable] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    handleProductDiscount(productsTable);
  }, [productsTable]);

  useEffect(() => {
    loadCustomerData();
    loadProductsData();
  }, []);

  const handleShowModal = () => setShow(true);
  const handleCloseModal = () => setShow(false);

  const loadCustomerData = () => {
    getCustomerData([], null, customer)
      .then((res) => {
        if (res.data.response.code === "00000") {
          let selectedProducts = [];

          let customerDiscounts = res.data.response.result.customerDiscounts;

          const productsDiscounts = customerDiscounts.map((value, key) => {
            selectedProducts.push(value.product_id);

            return {
              unique_id: "old" + value.product_id,
              srp: parseFloat(value.srp) || 0,
              moq: parseFloat(value.moq) || 0,
              free: parseFloat(value.free) || 0,
              rebate: parseFloat(value.rebate) || 0,
              discount: parseFloat(value.discount) || 0,
              product_id: value.product_id,
              product_name: value.product_name,
              customer_code: value.customer_id,
              customer_discount_id: value.customer_discount_id,
            };
          });

          setSelectionModel(selectedProducts);

          setProductsTable(productsDiscounts);
          handleProductDiscount(productsDiscounts);
        }
      })
      .catch((err) => {});
  };

  const loadProductsData = () => {
    getProductsData({
      customer_code: customer,
    })
      .then((res) => {
        if (res.data.response.code === "00000") {
          setProductsData(res.data.response.result);
        }
      })
      .catch((err) => {});
  };

  const productIDS = (array, key) => {
    return array.map((o) => o[key]);
  };

  const onHandleSelectedProduct = (products) => {
    let checkExistsProducts = products.filter((value) => {
      let existsProduct = productIDS(productsTable, "product_id");
      return !existsProduct.includes(value.product_id);
    });

    let selectedProducts = checkExistsProducts.map((product) => {
      return {
        unique_id: "new" + product.product_id,
        srp:
          product.special_price > 0
            ? product.special_price
            : product.unit_price,
        moq: 0,
        free: 0,
        rebate: 0,
        discount: 0,
        product_id: product.product_id,
        product_name: product.product_name,
        customer_code: customer,
        customer_discount_id: "",
      };
    });

    setProductsTable([...productsTable, ...selectedProducts]);
  };

  const handleEditRowsModelCommit = (row, field, param) => {
    if (productsTable.length > 0) {
      productsTable.map((value) => {
        if (
          value.customer_discount_id === row.customer_discount_id &&
          value.product_id === row.product_id
        ) {
          Object.assign(value, { [field]: param });
        }
      });
    }

    setProductsTable(productsTable);
    handleProductDiscount(productsTable);
  };

  // const handleDeleteProduct = (event) => {
  //   Swal.fire({
  //     title: 'Are you sure you want to delete?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {

  //     if (result.isConfirmed) {

  //       setSelectionModel( selectionModel.filter((value) => {
  //         return value !== event.row.product_id;
  //       }) );

  //       setProductsTable( productsTable.filter((value) => {
  //         return value.product_id !== event.row.product_id;
  //       }) );

  //       deleteDiscountProduct({
  //         customer_code : customer,
  //         discount_id : event.row.customer_discount_id,
  //       }).then((res) => {

  //       }).catch((err) => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Something went wrong!',
  //           text: err.data.response.result,
  //         })
  //       })
  //     }
  //   })
  // }

  const productsColumn = [
    {
      field: "product_code",
      headerName: "Product Code",
      sortable: true,
      width: 170,
      flex: 1,
      renderCell: (productsData) => {
        return <Link to="">{productsData.row.product_code}</Link>;
      },
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 170,
      flex: 2,
    },
    {
      field: "unit_cost",
      headerName: "SRP",
      headerAlign: "right",
      align: "right",
      width: 170,
      flex: 1,
      renderCell: (productsData) => {
        if (productsData.row.special_price > 0) {
          return <>{numberFormatCurrency(productsData.row.special_price)}</>;
        } else {
          return <>{numberFormatCurrency(productsData.row.unit_price)}</>;
        }
      },
    },
  ];

  const productsTableColumn = [
    {
      field: "product_name",
      headerName: "Product Name",
      width: 170,
      flex: 2,
    },
    {
      field: "srp",
      headerName: "SRP",
      width: 170,
      flex: 1,
      // editable: true,
      type: "number",
    },
    {
      field: "moq",
      headerName: "Quantity",
      width: 170,
      flex: 1,
      // editable: true,
      type: "number",
    },
    {
      field: "free",
      headerName: "Free Goods",
      width: 170,
      flex: 1,
      // editable: true,
      type: "number",
    },
    {
      field: "discount",
      headerName: "Discount %",
      width: 170,
      flex: 1,
      // editable: true,
      type: "number",
    },
    {
      field: "rebate",
      headerName: "Rebate %",
      width: 170,
      flex: 1,
      // editable: true,
      type: "number",
    },
    // {
    //   field: "delete",
    //   headerName: "Action",
    //   headerAlign: "center",
    //   width: 100 ,
    //   align: 'center',
    //   sortable: false,
    //   renderCell: (productsData) => {
    //     return <button type="button" className="btn btn-danger" onClick={ () => {
    //       handleDeleteProduct(productsData)
    //     }}>X</button>
    //   }
    // },
  ];

  // const handleChecker = () => {
  //   console.log(productsTable);
  //   console.log(customerProductDiscount);
  // }

  return (
    <Fragment>
      <div className="col-md-12" id="products_adn_discounts">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Discounts and Free Goods</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 text-right">
                  {/* <button type="button" className="btn btn-success" onClick={handleChecker}> Check Data </button> */}
                  {/* <button type="button" className="btn btn-success" onClick={handleShowModal}> Add Product </button> */}
                  <Link
                    className="btn btn-success"
                    to="/customers/deals-and-discounts/regular"
                  >
                    <Plus /> Create / Update
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <PrimaryDataTable
                autoHeight
                rows={productsTable}
                columns={productsTableColumn}
                getRowId={(row) => row.unique_id}
                onCellEditCommit={(products) => {
                  handleEditRowsModelCommit(
                    products.row,
                    products.field,
                    products.value
                  );
                }}
              />
            </div>
          </fieldset>
        </div>
        <ModalDropdown
          toggle={show}
          title="Products"
          handleCloseModal={handleCloseModal}
          handleRowDoubleClick={onHandleSelectedProduct}
          getData={productsData}
          columns={productsColumn}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </div>
    </Fragment>
  );
};

export default CustomerProductAndDiscounts;
