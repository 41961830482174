import { useState, useCallback } from "react";
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { ExclamationCircleFill, WindowFullscreen } from "react-bootstrap-icons";
import moment from "moment";
import Swal from "sweetalert2";

import ModalDraggable from "../../ModalDraggable";

import { saveProductPurchaseRequest } from "../../../api/product";
import { numberFormatCurrency } from "../../../utils/helper";
import PrimaryDatePicker from "../../../components/PrimaryDatePicker";
import MultipleFileUploadPreview from "../../MultipleFileUploadPreview";
import { number } from "prop-types";

const defaultFormDetails = {
  product_id: "",
  requested_qty: 0,
  unit_price: 0,
  total_amount: 0,
  lead_time: 0,
  payment_term: "",
};

const defaultForm = {
  supplier: "",
  estimated_delivery_date: "",
  payment_term: "",
  grand_total: 0,
  generate_type: 2,
  particulars: "",
  term_po: "",
  term_dr: "",
  term_pdc: [],
  term_transit: "",
  details: [defaultFormDetails],
  // total_dp: 0,
  // total_dr: 0,
  // total_pdc: 0,
  // total_intransit: 0,
  attachments: [],
};

const PurchaseRequestFormModal = ({
  data,
  toggle,
  minimize,
  onClose,
  onMinimize,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleOnExited = () => {
    if (!minimize) {
      setForm(defaultForm);
    }
  };

  const handleOnEntered = () => {
    if (!form.details[0]?.requested_qty) {
      const { multiplier, requested_qty, total, productCost } = processData(
        data?.product?.quantity
      );

      setForm({
        ...form,
        grand_total: total,
        details: [
          {
            product_id: data?.product?.product_id,
            requested_qty: requested_qty,
            unit_price: productCost,
            total_amount: total,
            lead_time: data?.product?.supplier_lead_time,
            payment_term: getPaymentTerms(),
            multiplier,
          },
        ],
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Product Purchase Request",
        text: "Are you sure you want to save this request?",
        icon: "warning",
        showCancelButton: true,
      });

      if (isConfirmed) {
        setLoading(true);

        const tmpForm = {
          ...form,

          supplier: data?.vendor?.vendor_id,
          //estimated_delivery_date: calculateEstimatedDeliveryDate(),
          term_po: data?.vendor?.product_payments.percent_down_payment,
          term_dr: data?.vendor?.product_payments.percent_upon_delivery,
          term_transit: data?.vendor.product_payments.percent_intransit,
          term_pdc: data?.vendor?.product_payments.pdc_payment.map((row) => {
            return {
              product_term_id: row.id,
              percentage: row.percent_amount,
              days: row.days_remaining + " days",
            };
          }),
          payment_term: getPaymentTerms(),
        };

        const res = await saveProductPurchaseRequest(tmpForm);

        const { code, result } = res.data.response;

        await Swal.fire({
          title: "Product Purchase Request",
          text: Array.isArray(result) ? result.join("<br />") : result,
          icon: code === "00000" ? "success" : "error",
          showCancelButton: true,
        });

        if (code === "00000") {
          onSubmitted({
            success: 1,
            message: result,
          });
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      onSubmitted({
        success: 0,
        message: error.message,
      });
    }
  };

  const handleInputChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const processData = (qty) => {
    const requested_qty = qty;

    const isVatExempted = parseInt(data?.product?.vat_exemption) || 0;

    const productCost =
      parseFloat(
        isVatExempted ? data?.product?.cost : data?.product?.cost_vat
      ) || 0;
    const total = requested_qty * productCost;

    return {
      requested_qty,
      total,
      productCost,
    };
  };

  const handleDetailQtyChange = (e) => {
    const { requested_qty, total, productCost } = processData(
      parseInt(e.target.value) || 1
    );
    setForm({
      ...form,

      grand_total: total,
      details: [
        {
          product_id: data?.product?.product_id,
          requested_qty: requested_qty,
          unit_price: productCost,
          total_amount: total,
          lead_time: data?.product?.supplier_lead_time,
          payment_term: getPaymentTerms(),
        },
      ],
    });
  };

  const getUnitOfMeasure = () => {
    const unit = data?.otherData?.unitMeasureHolder.find(
      (row) => data?.product.unit_id === row.unit_id
    );

    return unit?.unit_name;
  };

  const getProductSegment = () => {
    const segment = data?.otherData?.prodSegmentHolder.find(
      (row) => row.segment_id === data?.product.product_segment_id
    );

    return segment?.segment_name;
  };

  const getPaymentTerms = () => {
    if (data?.vendor) {
      return [
        `${data?.vendor?.product_payments.percent_down_payment}% Upon PO`,
        `${data?.vendor?.product_payments.percent_upon_delivery}% Upon Delivery`,
        `${data?.vendor?.product_payments.percent_intransit}% In Transit`,
        ...data?.vendor?.product_payments.pdc_payment.map((row) => {
          return `${row.percent_amount}% ${row.days_remaining} days PDC`;
        }),
      ].join(" ");
    }

    return null;
  };

  const handleFileUpload = (e) => {
    setForm({ ...form, attachments: [...e.target.files] });
  };

  const isReadyToSubmit = () => {
    if (
      form.details[0]?.requested_qty > 0 &&
      !loading &&
      form.estimated_delivery_date !== "" &&
      form.details[0]?.requested_qty >= data?.product?.quantity
    ) {
      return true;
    }

    return false;
  };

  return (
    <Modal
      show={toggle}
      onHide={onClose}
      onEntered={handleOnEntered}
      backdrop={false}
      centered
      size={"md"}
      onExited={handleOnExited}
      dialogAs={ModalDraggable}
    >
      <Modal.Header className="modal-draggable" closeButton>
        <Modal.Title>Purchase Request Form</Modal.Title>
        <Button variant="minimize" onClick={onMinimize}>
          &nbsp;
        </Button>
      </Modal.Header>
      <Modal.Body>
        <dl class="row">
          <dt class="col-sm-3">PR Date:</dt>
          <dd class="col-sm-9">{moment().format("MM/DD/YYYY")}</dd>
          <dt class="col-sm-3">Supplier:</dt>
          <dd class="col-sm-9">{data?.vendor?.vendor_name}</dd>
          <dt class="col-sm-3">Payment Terms:</dt>
          <dd class="col-sm-9">{getPaymentTerms()}</dd>
          <dt class="col-sm-3">Date Needed:</dt>
          <dd class="col-sm-4">
            <PrimaryDatePicker
              value={form.estimated_delivery_date}
              onChange={(date) =>
                handleInputChange({
                  target: {
                    name: "estimated_delivery_date",
                    value: date,
                  },
                })
              }
            />
          </dd>
        </dl>

        <h6>Details</h6>
        <table className="table table-striped table-condensed small">
          <thead>
            <tr>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>UOM</th>
              <th>Product Segment</th>
              <th>Current Level</th>
              <th>Lead Time</th>
              <th>Order Qty</th>
              <th className="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.product?.product_code}</td>
              <td>{data?.product?.product_name}</td>
              <td>{getUnitOfMeasure()}</td>
              <td>{getProductSegment()}</td>
              <td>{numberFormatCurrency(data?.product?.stock_level, null)}</td>
              <td>{`${data?.product?.supplier_lead_time} days`}</td>
              <td>
                <Form.Control
                  type="number"
                  value={form.details[0]?.requested_qty}
                  style={{ marginTop: "-5px" }}
                  size="sm"
                  onChange={handleDetailQtyChange}
                  min={data?.product?.quantity}
                  step={data?.product?.quantity}
                />
              </td>
              <td className="text-right">
                {numberFormatCurrency(form.details[0]?.total_amount)}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="font-weight-bold">
              <td colSpan={7} className="text-right">
                Grand Total:
              </td>
              <td className="text-right">
                {numberFormatCurrency(form.grand_total)}
              </td>
            </tr>
          </tfoot>
        </table>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Attachments</Form.Label>
              <MultipleFileUploadPreview
                newAttachments={form.attachments}
                oldAttachments={[]}
                handleFileUpload={handleFileUpload}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Particulars</Form.Label>
              <Form.Control
                as="textarea"
                name="particulars"
                value={form.particulars}
                onChange={handleInputChange}
                style={{ height: "188px" }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={handleSubmit}
          style={{ width: "100px" }}
          disabled={!isReadyToSubmit()}
        >
          Save
        </Button>
        <Button
          variant="danger"
          onClick={onClose}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const usePurchaseRequestFormModal = () => {
  const [purchaseRequestFormModal, setPurchaseRequestFormModal] = useState({
    data: null,
    toggle: false,
    minimize: false,
    loading: false,
  });

  const setPurchaseRequestFormModalClose = useCallback(() => {
    toast.dismiss("pr_toast");
    setPurchaseRequestFormModal({
      ...purchaseRequestFormModal,
      toggle: false,
      minimize: false,
      loading: false,
      data: null,
    });
  }, []);

  const setPurchaseRequestFormModalMinimize = useCallback((data) => {
    setPurchaseRequestFormModal({
      ...purchaseRequestFormModal,
      data,
      toggle: false,
      minimize: true,
      loading: false,
    });

    toast.info("Purchase Request Form", {
      autoClose: false,
      closeButton: false,
      className: "toast-modal-minimize",
      toastId: "pr_toast",
      icon: <WindowFullscreen />,
      onClick: () =>
        setPurchaseRequestFormModal({
          ...purchaseRequestFormModal,
          data,
          toggle: true,
          minimize: false,
          loading: false,
        }),
    });
  }, []);

  const setPurchaseRequestFormModalToggle = useCallback(
    (data) =>
      setPurchaseRequestFormModal({
        ...purchaseRequestFormModal,
        data,
        toggle: true,
        minimize: false,
        loading: false,
      }),
    []
  );

  return [
    purchaseRequestFormModal,
    setPurchaseRequestFormModalClose,
    setPurchaseRequestFormModalMinimize,
    setPurchaseRequestFormModalToggle,
  ];
};

export default PurchaseRequestFormModal;
