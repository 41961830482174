import { Tooltip } from "@material-ui/core";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";

const SearchRequest = ({
  search,
  handleSearch,
  handleSearchSubmit,
  handleSearchClear,
}) => {
  return (
    <Row>
      <Col sm="6 mb-3">
        <div className="d-flex flex-sm-row flex-column">
          <div className="sm-w-100 w-75">
            <div className="d-flex">
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Seach for PR Number..."
                  name="search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <div>
                <Tooltip title="Search Product">
                  <Button
                    variant="default"
                    className="border"
                    onClick={() => handleSearchClear(true)}
                  >
                    <ClearIcon style={{ height: 20 }} />
                  </Button>
                </Tooltip>
              </div>
            </div>
            <Form.Group className="mb-2"></Form.Group>
          </div>
          <div>
            <Button
              variant="success"
              type="button"
              className="mb-2 ml-md-2 ml-sm-0"
              onClick={handleSearchSubmit}
            >
              Search
            </Button>
          </div>
        </div>
      </Col>
      <Col sm="6 mb-3 text-right">
        <Link
          to="/purchasing/purchaseRequest/create"
          className="btn btn-success"
        >
          Create Purchase Request
        </Link>
      </Col>
    </Row>
  );
};

export default SearchRequest;
