import { useParams } from "react-router-dom";
import Srpf from "./Srpf";

const EditSrpf = () => {

  const { srpf_no } = useParams();

  return (<Srpf editMode={true} srpfNo={srpf_no}/>)
}

export default EditSrpf