import { Tooltip } from "@material-ui/core";
import { Tags, TagsFill } from "react-bootstrap-icons";
import { numberFormatCurrency } from "../../utils/helper";

export const incomeStatementDataCol_month = [
  {
    field: "breakdown_type",
    headerName: "Breakdown Type",
    width: 150,
    sortable: false,
    hideable: false,
  },
  {
    field: "account_code",
    headerName: "Account Code",
    width: 150,
    sortable: false,
    hideable: false,
  },
  {
    field: "account_name",
    headerName: "Account Description",
    width: 150,
    sortable: false,
    hideable: false,
  },
  {
    field: "jan",
    headerName: "January",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "feb",
    headerName: "February",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "mar",
    headerName: "March",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "apr",
    headerName: "April",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "may",
    headerName: "May",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "jun",
    headerName: "June",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "jul",
    headerName: "July",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "aug",
    headerName: "August",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "sep",
    headerName: "September",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "oct",
    headerName: "Cctober",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "nov",
    headerName: "November",
    width: 100,
    sortable: false,
    hideable: false,
  },
  {
    field: "dec",
    headerName: "December",
    width: 100,
    sortable: false,
    hideable: false,
  },
];

export const incomeStatementDataCol_quarter = [
  {
    field: "breakdown_type",
    headerName: "Breakdown Type",
    width: 150,
    sortable: false,
    hideable: false,
  },
  {
    field: "account_code",
    headerName: "Account Code",
    width: 150,
    sortable: false,
    hideable: false,
  },
  {
    field: "account_name",
    headerName: "Account Description",
    width: 200,
    sortable: false,
    hideable: false,
  },
  {
    field: "q1",
    headerName: "Quarter 1",
    width: 200,
    sortable: false,
    hideable: false,
  },
  {
    field: "q2",
    headerName: "Quarter 2",
    width: 200,
    sortable: false,
    hideable: false,
  },
  {
    field: "q3",
    headerName: "Quarter 3",
    width: 200,
    sortable: false,
    hideable: false,
  },
  {
    field: "q4",
    headerName: "Quarter 4",
    width: 200,
    sortable: false,
    hideable: false,
  },
];

export const dropdownPeriodBreakdown = [
  { value: "month", label: "Month" },
  { value: "quarter", label: "Quarter" },
  { value: "year", label: "Year" },
];

export const dropdownBreakdown = [
  { value: "type", label: "Type" },
  { value: "group", label: "Group" },
  { value: "subgroup", label: "Sub-Group" },
  { value: "line_item", label: "Line Item" },
  // { value: "sub_line_item", label: "Sub-Line Item" },
  { value: "accounts", label: "Account Name" },
];
