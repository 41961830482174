import { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { saveAccountOfficer } from '../../../../api/customerAccountOfficer';

const defaultForm = { ao_name: '', user_id: '', ao_id: '' };

const AccountOfficerFormModal = ({ toggle, onClose, onSubmitted }) => {
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);

  const handleOnEntered = () => {};

  const handleOnExited = () => {
    setForm({ ...form, ...defaultForm });
  };

  const handleOnSubmit = async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Account Officer',
        text: 'Are you sure you want to save this?',
        icon: 'warning',
        showCancelButton: true,
      });

      if (isConfirmed) {
        const res = await saveAccountOfficer(form);

        const { code, result } = res.data.response;

        setLoading(false);

        if (code !== '00000') {
          onSubmitted({ success: 0 });
          await Swal.fire({
            title: 'Account Officer',
            text: Array.isArray(result) ? result.join('<br />') : result,
            icon: 'error',
          });
          return;
        }

        await Swal.fire({
          title: 'Account Officer',
          text: 'Account officer has been saved.',
          icon: 'success',
        });

        onSubmitted({ success: 1, result });
      }
    } catch (error) {
      onSubmitted({ success: 0 });
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Modal show={toggle} onHide={onClose} centered onExited={handleOnExited}>
      <Modal.Header closeButton>
        <Modal.Title>Account Officer Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            Name <span className="req">*</span>
          </Form.Label>
          <Form.Control
            name="ao_name"
            value={form.ao_name}
            onChange={handleChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={handleOnSubmit}
          style={{ width: '100px' }}
          disabled={loading}
        >
          Save
        </Button>
        <Button
          variant="danger"
          onClick={onClose}
          style={{ width: '100px' }}
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountOfficerFormModal;
