import { Tabs, Tab } from "react-bootstrap";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";
import "../ledger.css";
import TransactionTypeAsyncSelect from "../components/TransactionTypeAsyncSelect";
import PrimaryDatePicker from "../../../components/PrimaryDatePicker";

import PrimaryDataTable from "../../../components/PrimaryDataTable";

import {
  AlertDefaultError,
  PermissionsGate,
} from "../../../components/PermissionsGate";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";
import { getCustomers } from "../../../api/ledgers";
import { salesDataCol } from "../const";

const SalesLedgerReportScreen = ({
  handleChange,
  formData,
  dateChange,
  handleSearch,
  salesData,
  selectedTab,
  handleSelectedTab,
  handleDataTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
}) => {
  //const saleCol = salesDataCol([]);

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab
            className="default-tab-content"
            eventKey="sales_ledger"
            title="Sales Special Journal"
          >
            <div className="col-md-12">
              <div className="row">
                <PermissionsGate scopes={["view_sales_special_journal_list"]}>
                  <div className="col-md-2">
                    <label>Transaction Type</label>
                    <TransactionTypeAsyncSelect
                      value={formData.id}
                      onChange={(e) =>
                        handleChange({
                          target: { name: "id", value: e.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Select Customer</label>

                    <PrimaryAsyncSelect
                      onChange={(e) =>
                        handleChange({
                          target: { name: "customer_id", value: e.value },
                        })
                      }
                      customFunction={getCustomers}
                      isClearable={true}
                      name={"customer_id"}
                      value={formData.customer_id}
                      placeholder="Select Customer - ALL Customer"
                      config={{
                        searchField: "keyword",
                        valueField: "customer_id",
                        labelField: "customer_name",
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date From</label>
                    <label className="req">*</label>
                    {/* <input type="date" className="form-control form-control-sm" name="date_from" id="date-from" onChange={dateChange}/>
                     */}

                    <PrimaryDatePicker
                      name={"date_from"}
                      datePickerFormat={"yyyy-MM-dd"}
                      datePickerFormatString={"YYYY-MM-DD"}
                      value={formData.date_from}
                      onChange={(date) =>
                        dateChange({
                          target: { name: "date_from", value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date To</label>
                    <label className="req">*</label>
                    {/* <input type="date" className="form-control form-control-sm" name="date_to" id="date-to" onChange={dateChange}/> */}
                    <PrimaryDatePicker
                      name={"date_to"}
                      datePickerFormat={"yyyy-MM-dd"}
                      datePickerFormatString={"YYYY-MM-DD"}
                      value={formData.date_to}
                      onChange={(date) =>
                        dateChange({
                          target: { name: "date_to", value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </PermissionsGate>
                <div className="col-md-1 text-right">
                  <label>&nbsp;</label>
                  <div>
                    <PermissionsGate
                      scopes={["export_sales_special_journal_list"]}
                    >
                      <button
                        className=" btn btn-success"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
              <br />
              <PermissionsGate
                scopes={["view_sales_special_journal_list"]}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={salesData}
                  columns={salesDataCol}
                  getRowId={(row) => row.id}
                  onChange={handleDataTableChange}
                  disableColumnFilter
                  rowCount={totalRow}
                  loading={loading}
                  mode="server"
                  reload={reloadTable}
                />
              </PermissionsGate>
            </div>
          </Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab eventKey="inventories_ledger" title="Inventory Sub Ledger"></Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default SalesLedgerReportScreen;
