import { lazy } from "react";

const CourierLists = lazy(() => import("../pages/Couriers/CourierLists"));
const CourierLocation = lazy(() => import("../pages/Couriers/CourierLocation"));
const CourierCustomers = lazy(() =>
  import("../pages/Couriers/CourierCustomers")
);

const courierRoute = {
  public: [],
  auth: [
    {
      name: "courier_display",
      path: "/couriers",
      component: CourierLists,
    },
    {
      name: "courier_location",
      path: "/couriers/locations",
      component: CourierLocation,
    },
    {
      name: "courier_customer",
      path: "/couriers/customers",
      component: CourierCustomers,
    },
  ],
};

export default courierRoute;
