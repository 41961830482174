/**
 * Method for merging all routes from different modules
 * @param {Array} routes - {public: [], auth: []} list of all routes per modules
 * @returns {Array}
 */
export function combineRoutes(routes) {
  let routeList = { public: [], auth: [] };

  routes.forEach((route) => {
    if (route.hasOwnProperty("public")) {
      routeList.public = [...routeList.public, ...route.public];
    }

    if (route.hasOwnProperty("auth")) {
      routeList.auth = [...routeList.auth, ...route.auth];
    }
  });

  return routeList;
}
