import { Link } from "react-router-dom";
import { numberFormatCurrency } from "../../../utils/helper";

export const productsCol = ({ handleModuleModal }) => [
  {
    field: "module",
    headerName: "Module",
    minWidth: 180,
    sortable: false,
    headerClassName: "bg-blue-lt",
    renderCell: (data) => {
      return (
        <Link
          to={"/ledgers/products"}
          onClick={() => handleModuleModal(data.row)}
        >
          {data.row.particulars}
        </Link>
      );
    },
  },
  // {
  //   field: "particulars",
  //   headerName: "Particulars",
  //   minWidth: 220,
  //   sortable: false,
  //   headerClassName: "bg-blue-lt",
  // },
  {
    field: "purchases_unit",
    headerName: "Purchase Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.purchases_unit);
    },
  },
  {
    field: "purchases_cost",
    headerName: "Purchase Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.purchases_cost.toFixed(2));
    },
  },
  {
    field: "purchases_total",
    headerName: "Purchase Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.purchases_total.toFixed(2));
    },
  },
  {
    field: "sold_unit",
    headerName: "Sold Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-red-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_unit);
    },
  },
  {
    field: "sold_cost",
    headerName: "Sold Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-red-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_cost);
    },
  },
  {
    field: "sold_total",
    headerName: "Sold Total",
    minWidth: 180,
    sortable: false,
    headerClassName: "bg-red-lt",
    type: "number",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_total);
    },
  },

  {
    field: "inventory_transfer_unit",
    headerName: "Inventory Transfer Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_unit);
    },
  },
  {
    field: "inventory_transfer_cost",
    headerName: "Inventory Transfer Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_cost);
    },
  },

  {
    field: "inventory_transfer_total",
    headerName: "Inventory Transfer Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_total);
    },
  },

  {
    field: "adjustment_unit",
    headerName: "Adjustment Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_unit);
    },
  },
  {
    field: "adjustment_cost",
    headerName: "Adjustment Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_cost);
    },
  },
  {
    field: "adjustment_total",
    headerName: "Adjustment Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_total);
    },
  },
];

export const productsWithBatchCol = [
  {
    field: "particulars",
    headerName: "Particulars",
    minWidth: 220,
    sortable: false,
    headerClassName: "bg-blue-lt",
  },
  {
    field: "batch_no",
    headerName: "Batch No.",
    minWidth: 180,
    sortable: false,
    headerClassName: "bg-blue-lt",
  },
  {
    field: "purchases_unit",
    headerName: "Purchase Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.purchases_unit);
    },
  },
  {
    field: "purchases_cost",
    headerName: "Purchase Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.purchases_cost);
    },
  },
  {
    field: "purchases_total",
    headerName: "Purchase Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-teal-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.purchases_total);
    },
  },
  {
    field: "sold_unit",
    headerName: "Sold Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-red-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_unit);
    },
  },
  {
    field: "sold_cost",
    headerName: "Sold Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-red-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_cost);
    },
  },
  {
    field: "sold_total",
    headerName: "Sold Total",
    minWidth: 180,
    sortable: false,
    headerClassName: "bg-red-lt",
    type: "number",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sold_total);
    },
  },

  {
    field: "inventory_transfer_unit",
    headerName: "Inventory Transfer Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_unit);
    },
  },
  {
    field: "inventory_transfer_cost",
    headerName: "Inventory Transfer Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_cost);
    },
  },

  {
    field: "inventory_transfer_total",
    headerName: "Inventory Transfer Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-yellow-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_total);
    },
  },

  {
    field: "adjustment_unit",
    headerName: "Adjustment Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_unit);
    },
  },
  {
    field: "adjustment_cost",
    headerName: "Adjustment Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_cost);
    },
  },
  {
    field: "adjustment_total",
    headerName: "Adjustment Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-purple-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.adjustment_total);
    },
  },

  {
    field: "overall_balance_unit",
    headerName: "Overall Balance Unit",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-green-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_unit);
    },
  },
  {
    field: "overall_balance_cost",
    headerName: "Overall Balance Cost",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-green-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_cost);
    },
  },
  {
    field: "overall_balance_total",
    headerName: "Overall Balance Total",
    minWidth: 220,
    sortable: false,
    type: "number",
    headerClassName: "bg-green-lt",
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_total);
    },
  },
];
