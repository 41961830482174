import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReceivablesLedgerReportScreen from '../Screens/ReceivablesLedgerReportScreen';
import Swal from 'sweetalert2';
import {
  requestGetReceivablesByTransactionDate,
  requestReceivablesExport,
} from '../../../api/ledgers';
import { numberFormatCurrency } from '../../../utils/helper';
import { toast } from 'react-toastify';
import _ from 'lodash';
import useNavActive from '../../../components/Navbar/useNavActive';

const today = new Date();
const prior = new Date().setDate(today.getDate() - 20);
const priorDate = new Date(prior);

const formDefault = {
  customer_id: null,
  dr_si_number: null,
  date_from:
    priorDate.getFullYear() +
    '-' +
    (priorDate.getMonth() + 1) +
    '-' +
    priorDate.getDate(), //moment().format("YYYY-MM-DD"),,
  date_to:
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(), //moment().subtract(30, "days").format("YYYY-MM-DD"),
  // beg_balance: 0,
  // pages: [],
  // page_array: [],
  page: 0,
  page_size: 50,
  order_by: null,
  direction: null,
  init: 0,
};

const Receivables = () => {
  useNavActive([
    'master_databases',
    'sub_ledger_database',
    'receivable_sub_legder',
  ]);
  //declaration
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [receivablesData, setReceivablesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [columnRunning, setColumnRunning] = useState(true);
  const [columnBalance, setColumnBalance] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [pages, setPages] = useState([]);
  const [begBal, setBegBal] = useState(0);

  useNavActive([
    'master_databases',
    'sub_ledger_database',
    'receivable_sub_ledger',
  ]);

  //functions
  //functions
  // useEffect(() => loadLedgerData(formData), []);

  const loadLedgerData = async (form) => {
    if (form.customer_id) {
      setColumnRunning(false);
      setColumnBalance(true);
    } else if (form.dr_si_number) {
      setColumnRunning(false);
      setColumnBalance(true);
    } else {
      setColumnRunning(true);
      setColumnBalance(false);
    }

    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setReceivablesData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await requestGetReceivablesByTransactionDate(form);

        const { code, result, total_rows } = res.data.response;

        if (code !== '00000') {
          toast.error(result);
          return;
        }

        let tmp = [];
        _.each(result, (row) => {
          tmp.push({
            id: row.id,
            transaction_date: row.transaction_date,
            reference: row.reference,
            customer_name: row.customer_name,
            particulars: row.new_particulars,
            customer_code: row.customer_code,

            receivable_debit: numberFormatCurrency(row.receivable_debit) ?? '-',
            receivable_credit:
              numberFormatCurrency(row.receivable_credit) ?? '-',

            // beg_balance: row.beg_balance ?? '-',
            receivable_balance: numberFormatCurrency(row.balance) ?? '-',

            running_balance: numberFormatCurrency(row.running_balance) ?? '-',
            transaction_type: row.transaction,
            si_number: row.printed_si_number,
            digital_si: row.digital_si_number,
            dr_no: row.dr_number,
          });
        });

        setReceivablesData(tmp);

        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error occured. Please try again later');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning('Please provide valid date!');
    }
  };

  const handleSelectedTab = (key) => {
    if (key === 'sales_ledger') {
      history.push('/ledgers/sales');
    } else if (key === 'receivables_ledger') {
      history.push('/ledgers/receivables');
    } else if (key === 'inventories_ledger') {
      history.push('/ledgers/inventories');
    } else if (key === 'sales_per_product_ledger') {
      history.push('/ledgers/sales-per-products');
    } else if (key === 'vatable_sales_ledger') {
      history.push('/ledgers/vatable-sales');
    } else if (key === 'product_ledger') {
      history.push('/ledgers/products');
    } else if (key === 'ytd_sales_ledger') {
      history.push('/ledgers/ytd-sales');
    } else {
      history.push('/ledgers/sales');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const searchLedger = (temp) => {
    toast.loading('Getting Transaction, Please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (formData.date_to === '') {
      requestGetReceivablesByTransactionDate(temp)
        .then((res) => {
          if (res.data.response.code === '00000') {
            if (res.data.response.result.length === 0) {
              toast.warning('No Data Available');
            }
            toast.dismiss();
            setReceivablesData(res.data.response.result);
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    } else {
      requestGetReceivablesByTransactionDate(formData)
        .then((res) => {
          if (res.data.response.code === '00000') {
            if (res.data.response.result.length === 0) {
              toast.warning('No Data Available');
            }
            toast.dismiss();
            setReceivablesData(res.data.response.result);
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handleSearch = () => {
    const newFormData = {
      ...formData,
      init: 1,
    };
    setFormData(newFormData);
    loadLedgerData(newFormData);
  };

  // const handleSearch = () => loadLedgerData(formData);

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };
    if (formData.init !== 0) {
      loadLedgerData(temp);
    }
  };

  const OnHandleExport = () => {
    let dateFrom = new Date(formData.date_from);
    let dateTo = new Date(formData.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }
    if (allow === 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Please select correct dates!',
        icon: 'warning',
      });
    } else {
      let tmp = {
        customer_id: formData.customer_id,
        dr_si_number: formData.dr_si_number,
        date_from: formData.date_from,
        date_to: formData.date_to,
      };
      toast.loading('Exporting data in progress, Please wait...', {
        position: toast.POSITION.TOP_RIGHT,
      });
      requestReceivablesExport(tmp)
        .then((res) => {
          if (res.data.response.code === '00000') {
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <ReceivablesLedgerReportScreen
      //throw parameter or props.
      formData={formData}
      dateChange={getDate}
      onSearch={searchLedger}
      handleSearch={handleSearch}
      receivablesData={receivablesData}
      selectedTab={'receivables_ledger'}
      handleSelectedTab={handleSelectedTab}
      handleChange={handleChange}
      loading={loading}
      totalRow={totalRow}
      handleDataTableChange={handleDataTableChange}
      reloadTable={reloadTable}
      handleExport={OnHandleExport}
      columnRunning={columnRunning}
      columnBalance={columnBalance}
    />
  );
};

export default Receivables;
