import React, { Fragment } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { postImportData } from "../../../api/smppProductMaintenance";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ImportFormModal = ({ toggle, closeModal, reloadData }) => {
  const [form, setForm] = useState({ file: "" });

  const onHandleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files });
  };
  const submitHandler = () => {
    Swal.fire({
      title: "Import Data",
      text: "Are you sure you want to import this data?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      let { isConfirmed } = result;
      if (isConfirmed) {
        toast.loading("Please wait saving form ...");

        postImportData(form, true).then((res) => {
          let { code, result } = res.data.response;
          toast.dismiss();

          if (code === "00000") {
            Swal.fire({
              title: "Success",
              text: result,
              icon: "success",
            });
            closeModal();
            reloadData();
          } else {
            Swal.fire({
              title: "Import Failed",
              icon: "error",
              text: result,
            });
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <Modal
        show={toggle}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Customer Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group className="mb-3">
              <Form.Label>
                Field <span className="req d-none">*</span>
              </Form.Label>
              <select className="form-control">
                <option> 1</option>
              </select>
            </Form.Group> */}

          <Form.Group>
            <Form.Label>
              Attachment <span className="req d-none">*</span>
            </Form.Label>
            <Form.Control type="file" name="file" onChange={onHandleChange} />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="warning"
            disabled={!form.file}
            onClick={submitHandler}
          >
            Import
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ImportFormModal;
