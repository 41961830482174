import makeApiRequest from "./makeApiCall";

export const getSMPPProduct = async (form) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/list`,
    "GET",
    null,
    form
  );
};

export const getCustomers = async (params) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/dropdown_customers`,
    "GET",
    null,
    params
  );
};

export const saveSMPPProduct = async (form) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/update`,
    "POST",
    null,
    form
  );
};

export const getExportData = async (id) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/export/` + id,
    "GET"
  );
};

export const postImportData = async (form, withFiles) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/import`,
    "POST",
    null,
    form,
    withFiles
  );
};

export const saveSMPPProductInline = async (form) => {
  return await makeApiRequest(
    `/product-legacy-maintenance/save`,
    "POST",
    null,
    form
  );
};

// export const getExportData = async (params) => {
//   return await makeApiRequest(
//     `/productMaintenance`,
//     "GET",
//     null,
//     null,
//     false,
//     `http://localhost:4001`
//   );
// };
