import { lazy } from 'react';

const CWTSubledger = lazy(() => import('../pages/CWTSubledger'));

const cwtSubledgerRoute = {
  public: [],
  auth: [
    {
      name: 'cwt_subledger',
      path: '/finance-and-accounting/cwt-subledger',
      component: CWTSubledger,
    },
  ],
};

export default cwtSubledgerRoute;
