import TrialBalance from "../pages/TrialBalance/TrialBalance";

const trialBalanceRoute = {
  public: [],
  auth: [
    {
      name: "trial_balance_report",
      path: "/finance-and-accounting/trial-balance",
      component: TrialBalance,
    },
  ],
};

export default trialBalanceRoute;
