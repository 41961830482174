import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";

import { getCustomers } from "../../../api/smppProductMaintenance";
import { getProductSearch } from "../../../api/product";

const formDefault = {
  id: "",
  customer_id: "",
  product_id: "",
  product_code: "",
  customer_code: "",
};

const UpdateFormModal = ({ toggle, loading, data, closeModal, onSubmit }) => {
  const [form, setForm] = useState(formDefault);

  useEffect(() => {
    setForm(
      !data
        ? formDefault
        : {
            id: data.id,
            customer_id: data.customer_id,
            product_id: data.product_id,
            product_code: data.product_code,
            customer_code: data.customer_code,
          }
    );
  }, [data]);

  const handleCustomerChange = ({ value }) => {
    setForm({
      ...form,
      customer_id: value === "" ? "" : value.customer_id,
      customer_code: value === "" ? "" : value.customer_code,
    });
  };

  const handleProductChange = ({ value }) => {
    setForm({
      ...form,
      product_id: value === "" ? "" : value.product_id,
      product_code: value === "" ? "" : value.product_code,
    });
  };

  const handleOnSubmit = () => onSubmit(form);

  const handleCloseModal = () => {
    setForm(formDefault);
    closeModal();
  };

  return (
    <Modal
      show={toggle}
      onHide={handleCloseModal}
      hidin
      backdrop="static"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Form Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="6">
            <Form.Group className="mb-3">
              {/* <Form.Label>SMPP Account</Form.Label> */}
              <Form.Control
                type="hidden"
                placeholder="SMPP Account Name"
                name="smpp_account"
                value={data?.smpp_account_name}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              {/* <Form.Label>SMPP Account Code</Form.Label> */}
              <Form.Control
                type="hidden"
                placeholder="SMPP Account Code"
                name="smpp_account_code"
                value={data?.smpp_account_code}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>SMPP Product</Form.Label>
              <Form.Control
                placeholder="SMPP Product Name"
                name="smpp_product_name"
                value={data?.smpp_product_name}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>Customer Product Code</Form.Label>
              <Form.Control
                placeholder="Customer Product Code"
                name="smpp_product_code"
                value={data?.smpp_product_code}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Customer <span class="req">*</span>
              </Form.Label>
              <PrimaryAsyncSelect
                onChange={handleCustomerChange}
                customFunction={getCustomers}
                isClearable={true}
                name={"customer_id"}
                value={form.customer_id}
                config={{
                  searchField: "customer_name",
                  valueField: "object",
                  labelField: "customer_name",
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Customer Code <span class="req">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Customer Code"
                name="customer_code"
                value={form.customer_code}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Product Name <span class="req">*</span>
              </Form.Label>
              <PrimaryAsyncSelect
                onChange={handleProductChange}
                customFunction={getProductSearch}
                isClearable={true}
                name={"product_id"}
                value={form.product_id}
                config={{
                  searchField: "search_param",
                  valueField: "object",
                  labelField: "product_name",
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Internal Product Code <span class="req">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Internal Product Code"
                name="product_code"
                value={form.product_code}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          onClick={handleCloseModal}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleOnSubmit}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateFormModal;
