import React from 'react';
import PrimaryToolTip from '../PrimaryToolTip';

function Purchasing({ form, handleChange }) {
  /* let purchasing = customer.business_contacts.purchasing[0]; */
  return (
    <fieldset className="details-field-set mb-4 mt-2">
      <legend>Purchasing Contact Information</legend>
      {/*  */}
      <div className="row pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm"></label>
        </div>
        <div className="col-lg-6"></div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Contact Person
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="purchasing_contact_person"
            id="purchasing_contact_person"
            value={form.purchasing_contact_person}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Contact Number
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="purchasing_contact_no"
            id="purchasing_contact_no"
            value={form.purchasing_contact_no}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="purchasing_contact_no_1"
                                  id="purchasing_contact_no_1"
                                  value={values.purchasing_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div> 
                            </div> */}
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Designation
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="purchasing_designation"
            id="purchasing_designation"
            value={form.purchasing_designation}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">Email</label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="email"
            className="form-control form-control-sm"
            name="purchasing_email"
            id="purchasing_email"
            value={form.purchasing_email}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
    </fieldset>
  );
}

export default Purchasing;
