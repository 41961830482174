import React, { useEffect, useState, Fragment } from "react";
import axiosInstance from "../axiosInstance";

import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useHistory, useParams } from "react-router";

import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Swal from "sweetalert2";
const DocumentPreviewMain = () => {
  const { id } = useParams();
  const history = useHistory();

  /* const s3URL =
    "bk-erp-s3-raw.s3.ap-southeast-1.amazonaws.com/images/Document/D22_U1.png"; */

  const [folderData, setFolderData] = useState([]);
  const [show, setShow] = useState(false);
  const [filePreview, setFilePreview] = useState(false);

  const [floorHolder, setFloorHolder] = useState([]);
  const [cabinetHolder, setCabinetHolder] = useState([]);
  const [drawerHolder, setDrawerHolder] = useState([]);

  const [targetfolderData, setTargetFolder] = useState([]);
  const onNameClickFileMove = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();

    const { nickname } = nodeData;
    const { name } = nodeData;
    setDocumentPerID({
      ...values,
      folder_name: name,
      folder_id: nickname,
    });
    setTargetFolder({ nickname: nickname, name: name });
  };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [values, setDocumentPerID] = useState({
    folder_id: "",
    document_code: "",
    document_id: id,
    file_name: "",
    folder_name: "",
    floor: "",
    cabinet: "",
    drawer: "",
    physically_received_by: "",
    last_released_to: "",
    attachment: "",
  });

  const onInputChange = (e) => {
    setDocumentPerID({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let componentMounted = true;

    const loadFolders = async () => {
      const res = await axiosInstance.get("/file-system/custom/file-explorer", {
        method: "GET",
      });
      if (componentMounted) {
        setFolderData(res.data.response.result[0]);
      }
    };

    const loadDocumentPerId = async () => {
      const res = await axiosInstance.get(`/file-system/preview/${id}`, {
        method: "GET",
      });

      if (componentMounted) {
        /* console.log([res.data.response.result[0].processed_path]); */
        const getData = {
          folder_id: res.data.response.result[0].folder_id,
          document_id: res.data.response.result[0].erp_uploads_id,
          document_code: res.data.response.result[0].document_code,
          file_name: res.data.response.result[0].file_name,
          folder_name: res.data.response.result[0].folder_name,
          floor: res.data.response.result[0].floor,
          cabinet: res.data.response.result[0].cabinet,
          drawer: res.data.response.result[0].drawer,
          physically_received_by:
            res.data.response.result[0].physically_received_by,
          processed_path: res.data.response.result[0].processed_path,
          last_released_to: res.data.response.result[0].last_released_to,
        };
        setDocumentPerID(getData);
        //setDocumentPerID(res.data.response.result[0]);
      }
    };

    const loadFloor = async () => {
      const res = await axiosInstance.get(`/file-system/filing/floor`, {
        method: "GET",
      });
      if (componentMounted) {
        setFloorHolder(res.data.response.result);
      }
    };

    const loadCabinet = async () => {
      const res = await axiosInstance.get(`/file-system/filing/cabinet`, {
        method: "GET",
      });
      if (componentMounted) {
        console.log(res.data.response.result);

        setCabinetHolder(res.data.response.result);
      }
    };

    const loadDrawer = async () => {
      const res = await axiosInstance.get(`/file-system/filing/drawer`, {
        method: "GET",
      });
      if (componentMounted) {
        setDrawerHolder(res.data.response.result);
      }
    };

    loadDocumentPerId();
    loadFolders();
    loadFloor();
    loadCabinet();
    loadDrawer();

    return () => {
      componentMounted = false;
    };
  }, []);

  const docs = [
    {
      uri: values.processed_path,
    },
  ];

  const deleteHandler = (e) => {
    e.preventDefault();
    const document_id = values.document_id;
    Swal.fire({
      title: "Do you want to delete it?",
      showDenyButton: true,
      confirmButtonText: `Yes Delete it!`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete("/file-system/document/delete", {
            data: {
              document_id: document_id,
            },
          })
          .then((res) => {
            if (res.data.response.code === "00006") {
              Swal.fire({
                icon: "success",
                text: res.data.response.result,
                showConfirmButton: false,
                timer: 1000,
              });
              setTimeout(() => {
                window.location.href = "/docmanagement";
              }, 1500);
            }
          });
      }
    });
  };

  const saveHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    axiosInstance.post(`/file-system/document/update`, formData).then((res) =>
      res.data.response.code === "00004"
        ? Swal.fire({
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/docmanagement");
            }
          })
        : Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.response.result,
          })
    );
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="col-xl-12 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
          <div className="card">
            <div className="card-header">
              <h6>Preview/Edit</h6>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row align-items-center ">
                  {/*  */}
                  <form className="form-group" onSubmit={saveHandler}>
                    <div className="col-md-12">
                      <input
                        type="hidden"
                        name="folder_id"
                        value={values.folder_id}
                      />
                      <input
                        type="hidden"
                        name="document_code"
                        value={values.document_code}
                      />
                      <input
                        type="hidden"
                        name="document_id"
                        value={values.document_id}
                      />
                      <div className="row">
                        <div className="col-md-4">
                          {/* Error Message here */}
                          <label>Filename : </label>

                          <input
                            type="text"
                            className="form-control"
                            id="file_name"
                            name="file_name"
                            placeholder="Filename"
                            value={values.file_name}
                            onChange={(e) => onInputChange(e)}
                          />
                          <div className="m-2"></div>
                          <label>Foldername : </label>
                          <input
                            type="text"
                            className="form-control"
                            id="folder_name"
                            name="folder_name"
                            placeholder="Folder Name"
                            onClick={handleShow}
                            value={values.folder_name}
                          />
                        </div>

                        {/* Start Of md-6 */}

                        <div className="col-md-5">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <label htmlFor="filing">Filing :</label>
                            </div>
                            {/* Start of Floor */}

                            <div className="col-md-3">
                              <select
                                name="floor"
                                id="floor"
                                className="form-control"
                                value={values.floor}
                                onChange={(e) => onInputChange(e)}
                              >
                                {floorHolder.map((floor) => (
                                  <option
                                    key={floor.floor_id}
                                    value={floor.floor_id}
                                    selected={
                                      floor.floor_id === values.floor
                                        ? true
                                        : false
                                    }
                                  >
                                    {floor.floor_id}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* End of Floor */}
                            {/* Start of Cabinet */}

                            <div className="col-md-3">
                              <select
                                name="cabinet"
                                id="cabinet"
                                className="form-control"
                                value={values.cabinet}
                                onChange={(e) => onInputChange(e)}
                              >
                                {cabinetHolder.map((cabinet) => (
                                  <option
                                    key={cabinet.cabinet_id}
                                    value={cabinet.cabinet_id}
                                    selected={
                                      cabinet.cabinet_id === values.cabinet
                                        ? true
                                        : false
                                    }
                                  >
                                    {cabinet.cabinet_id}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* End of Cabinet */}
                            {/* Start of drawer */}
                            <div className="col-md-3">
                              <select
                                name="drawer"
                                id="drawer"
                                className="form-control"
                                value={values.drawer}
                                onChange={(e) => onInputChange(e)}
                              >
                                {drawerHolder.map((drawer) => (
                                  <option
                                    key={drawer.drawer_id}
                                    value={drawer.drawer_id}
                                    selected={
                                      drawer.drawer_id === values.drawer
                                        ? true
                                        : false
                                    }
                                  >
                                    {drawer.drawer_id}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* End of drawer */}
                          </div>
                          <div className="row align-items-center mt-2">
                            <div className="col-md-2">
                              <label>Received By : </label>
                            </div>
                            <div className="col-md-3">
                              <input
                                name="physically_received_by"
                                className="form-control"
                                type="text"
                                id="physically_received_by"
                                value={values.physically_received_by}
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                            <div className="col-md-3">
                              <label>Last Release To : </label>
                            </div>
                            <div className="col-md-3">
                              <input
                                name="last_released_to"
                                className="form-control"
                                type="text"
                                id="last_released_to"
                                value={values.last_released_to}
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                            <div className="col-md-2 offset-4 mt-4">
                              {filePreview ? (
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => setFilePreview(false)}
                                >
                                  Close
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setFilePreview(true)}
                                >
                                  File Preview
                                </button>
                              )}
                            </div>
                          </div>

                          {/* End of Rows */}
                        </div>

                        {/* End of md -6 */}
                        <div className="col-md-3">
                          <div className="row align-items-center ">
                            <div>
                              <label>Re-upload : </label>
                            </div>
                            <input
                              type="file"
                              id="attachment"
                              className="form-control"
                              name="attachment"
                            />

                            {/* <button
                                className="btn btn-success"
                                onClick={reUploadHanlder}
                              >
                                Re-upload
                              </button> */}
                            <div className="row">
                              <div className="btn-group mt-3 ">
                                <button
                                  className="btn btn-danger "
                                  onClick={deleteHandler}
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-info ml-3"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pdf-container">
                  {filePreview ? (
                    <DocViewer
                      documents={docs}
                      pluginRenderers={DocViewerRenderers}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Move to Folder</Modal.Title>
        </Modal.Header>

        <Form>
          <Modal.Body>
            <FolderTree
              data={folderData}
              showCheckbox={false}
              onNameClick={onNameClickFileMove}
              readOnly
            />
            <br></br>
            <Form.Group className="mb-3" controlId="targetfolder_name">
              <Form.Label>Move to this folder</Form.Label>
              <Form.Control
                type="text"
                name="targetfoldername"
                placeholder="Target Folder"
                readOnly
                value={targetfolderData.name}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default DocumentPreviewMain;
