import { lazy } from "react";

const ExpenseSubledger = lazy(() =>
  import("../pages/ExpenseSubledger/index.js").then((module) => ({
    default: module.ExpenseSubledger,
  }))
);

const expenseSubledgerRoute = {
  public: [],
  auth: [
    {
      name: "expense_subledger",
      path: "/finance-and-accounting/allocated-expenses",
      component: ExpenseSubledger,
    },
  ],
};

export default expenseSubledgerRoute;
