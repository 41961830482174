import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggingOut } from '../../redux/modules/auth';
import NavbarMenu from './NavbarMenu';
import navItems from './navItems';
import NavbarNotification from '../NavbarNotification';
import notificationItems from '../NavbarNotification/notificationItems';

// api
import { getEcosystemDetails } from '../../api/login';
import _ from 'lodash';

// For Inventory Transfer
import { store } from '../../redux/store';
import { tabIndex } from '../../pages/InventoryTransferDestination/components/const';
import { inventoryTransferRequestPermission } from './permissions';

const SoPortalLink = () => {
  const { profileData } = useSelector(({ auth }) => auth);

  const handleClick = async () => {
    const tload = toast.loading('Please wait authenticating...');

    try {
      setTimeout(() => toast.dismiss(tload), 3000);

      let res = await getEcosystemDetails(profileData.user_id);
      const { code, result } = res.data.response;

      if (code !== '00000') {
        toast.error('Unable to authenticate', { autoClose: 5000 });
        return;
      }

      window.location.href = result;
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(tload);
    }
  };

  return (
    <span
      className="dropdown-item"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      Go To SO Portal
    </span>
  );
};

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [mainItemPath, setMainItemPath] = useState({});
  const [activeTab, setActiveTab] = useState('');

  const Logout = (e) => {
    dispatch(setLoggingOut());
    history.push('/');
  };

  const { permissions } = store.getState().auth;

  const inventoryTransfer = () => {
    if (permissions) {
      let inventory = permissions
        .filter((row) => inventoryTransferRequestPermission.includes(row))
        .filter((row) => !row.search('view_'));

      let index = tabIndex.find((row) => row.can[0] === inventory[0]);

      setActiveTab(index?.active || 'list-confirmation');
    }
  };

  useEffect(() => inventoryTransfer(), []);

  return (
    <div>
      <div className="wrapper">
        <header className="navbar navbar-expand-md navbar-light d-print-none bg-header">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-menu"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <NavLink className="nav-link" to="/dashboard">
                <img
                  src="/Spread Version.png"
                  alt=""
                  className="navbar-brand-image"
                />
              </NavLink>
            </h1>
            {auth.isAuthed ? (
              <div className="navbar-nav flex-row order-md-last">
                <div className="nav-item dropdown d-none d-md-flex me-3">
                  <a
                    href="https://bellkenz.atlassian.net/servicedesk/customer/portals"
                    target="_blank"
                    rel="noreferrer"
                    className="nav-link px-0"
                    aria-label="Show help"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="12" y1="17" x2="12" y2="17.01" />
                      <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                    </svg>
                    <span className="badge bg-red"></span>
                  </a>
                </div>

                <div className="nav-item dropdown d-none d-md-flex me-3">
                  <NavbarNotification items={notificationItems} />
                </div>

                <div className="nav-item dropdown">
                  <a
                    href="/#"
                    className="nav-link d-flex lh-1 text-reset p-0"
                    data-bs-toggle="dropdown"
                    aria-label="Open user menu"
                  >
                    <span className="avatar avatar-sm avatar-rounded bg-green-lt">
                      BK
                    </span>

                    <div className="d-none d-xl-block ps-2">
                      <div>
                        {auth.profileData.first_name +
                          ' ' +
                          auth.profileData.last_name}
                      </div>
                      <div className="mt-1 small text-muted">
                        {!auth.profileData.job_name
                          ? 'NO JOB DESC'
                          : auth.profileData.job_name.toUpperCase()}
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <SoPortalLink />
                    <div className="dropdown-divider"></div>
                    <a
                      href="/#"
                      id="logout"
                      className="dropdown-item"
                      onClick={Logout}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </header>

        <NavbarMenu items={navItems({ activeTab })} />
      </div>
    </div>
  );
};

export default Navbar;
