import { lazy } from "react";
import ZoneType from "../pages/WarehouseZone/components/ZoneType";

const Warehouse = lazy(() => import("../components/Warehouse/Warehouse"));
// const AddWarehouse = lazy(() => import("../components/Warehouse/AddWarehouse"));
// const ViewWarehouse1 = lazy(() =>
//   import("../components/Warehouse/ViewWarehouse1")
// );

const ZoneAssignment = lazy(() =>
  import("../components/Warehouse/ZoneAssignment")
);
const ViewZoneAssignment = lazy(() =>
  import("../components/Warehouse/ViewZoneAssignment")
);
const AddZoneAssignment = lazy(() =>
  import("../components/Warehouse/AddZoneAssignment")
);
const PalletAssignment = lazy(() =>
  import("../components/Warehouse/PalletAssignment")
);

const ViewWarehouse = lazy(() =>
  import("../components/Warehouse/ViewWarehouse/index")
);

const ZoneAssignmentForm = lazy(() =>
  import("../components/Warehouse/ZoneAssignmentForm")
);

const warehouseRoute = {
  public: [],
  auth: [
    {
      name: "warehouse",
      path: "/warehouse",
      component: Warehouse,
    },
    // {
    //   name: "warehouse_add",
    //   path: "/add/warehouse1",
    //   component: AddWarehouse,
    // },
    {
      name: "view_warehouse_add",
      path: "/warehouse/create",
      component: ViewWarehouse,
    },
    // {
    //   name: "warehouse_edit",
    //   path: "/view/warehouse1/:warehouse_id",
    //   component: ViewWarehouse1,
    // },
    {
      name: "view_warehouse_edit",
      path: "/warehouse/view/:warehouse_id",
      component: ViewWarehouse,
    },
    {
      name: "zone_assignment",
      path: "/warehouse/zone-assignment",
      component: ZoneAssignment,
    },
    {
      name: "add_zone_assignment1",
      path: "/warehouse/zone-assignment/create1",
      component: AddZoneAssignment,
    },
    {
      name: "create_zone_assignment",
      path: "/warehouse/zone-assignment/create",
      component: ZoneAssignmentForm,
    },
    {
      name: "view_zone_assignment",
      path: "/warehouse/zone-assignment/edit/:id",
      component: ZoneAssignmentForm,
    },
    {
      name: "warehouse_pallet_assignment",
      path: "/warehouse/pallet/assignment",
      component: PalletAssignment,
    },
    {
      name: "warehouse_pallet_assignment",
      path: "/warehouse/pallet/assignment",
      component: PalletAssignment,
    },
    {
      name: "warehouse_zone_type",
      path: "/warehouse/zone-type",
      component: ZoneType,
    },
  ],
};

export default warehouseRoute;
