import { Tabs, Tab } from 'react-bootstrap';
import '../../../globalStyles/css/bootstrap/bootstrap.min.css';
import '../ledger.css';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';
import PrimaryAsyncSelect from '../../../components/PrimaryAsyncSelect';
import { getAccounts, getSubgroup, getFSGroup } from '../../../api/ledgers';
import PrimaryDataTable from '../../../components/PrimaryDataTable';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';
import { generalLedgerCol } from '../const';

const minDate = '2022-12-01';

const GeneralLedgerReportScreen = ({
  // handleChange,
  formData,
  dateChange,
  onSearch,
  generalLedgerData,
  selectedTab,
  handleSearch,
  handleSelectedTab,
  handleChange,
  handleDataTableChange,
  handleFilterModelChange,
  handleTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
  columnRunning,
  onHandlegeneralLedgerExport,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <h4 className="headline">GENERAL LEDGERS</h4>
      <br />
      <Tabs
        id="controlled-tab-example"
        activeKey={selectedTab}
        onSelect={(k) => handleSelectedTab(k)}
        className="default-tab"
      >
        <Tab
          className="default-tab-content"
          eventKey="general_ledger"
          title="General Ledger"
        >
          <div className="col-md-12">
            <PermissionsGate scopes={['get_general_ledger']}>
              <div className="row">
                <div className="col-md-4">
                  <label>Select Account</label>

                  <PrimaryAsyncSelect
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'account_code', value: e.value },
                      })
                    }
                    customFunction={getAccounts}
                    isClearable={true}
                    name={'account_name'}
                    value={formData.account_code}
                    placeholder="Select Account"
                    config={{
                      searchField: 'keyword',
                      valueField: 'account_code',
                      labelField: 'account_name',
                    }}
                  />
                </div>
                {/* <div className="col-md-4">
                  <label>Select FS Group</label>

                  <PrimaryAsyncSelect
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'fs_group', value: e.value },
                      })
                    }
                    customFunction={getFSGroup}
                    isClearable={true}
                    name={'name'}
                    value={formData.fs_group}
                    placeholder="Select FS Group"
                    config={{
                      searchField: 'keyword',
                      valueField: 'fs_group',
                      labelField: 'name',
                    }}
                  />
                </div> */}
                <div className="col-md-4">
                  <label>Select Subgroup</label>

                  <PrimaryAsyncSelect
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: 'sub_group',
                          value: e.value,
                        },
                      })
                    }
                    customFunction={getSubgroup}
                    isClearable={true}
                    name={'name'}
                    value={formData.sub_group}
                    placeholder="Select Subgroup"
                    config={{
                      searchField: 'keyword',
                      valueField: 'sub_group',
                      labelField: 'name',
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-md-2">
                  <label>Date From</label>
                  <label className="req">*</label>
                  <PrimaryDatePicker
                    name={'date_from'}
                    datePickerFormat={'yyyy-MM-dd'}
                    datePickerFormatString={'YYYY-MM-DD'}
                    value={formData.date_from}
                    onChange={(date) =>
                      dateChange({
                        target: { name: 'date_from', value: date },
                      })
                    }
                    minDate={minDate}
                  />
                </div>
                <div className="col-md-2">
                  <label>Date To</label>
                  <label className="req">*</label>
                  <PrimaryDatePicker
                    name={'date_to'}
                    datePickerFormat={'yyyy-MM-dd'}
                    datePickerFormatString={'YYYY-MM-DD'}
                    value={formData.date_to}
                    onChange={(date) =>
                      dateChange({
                        target: { name: 'date_to', value: date },
                      })
                    }
                    minDate={minDate}
                  />
                </div>
                <div className="col-md-1">
                  <label>&nbsp;</label>
                  <div>
                    <button className="btn btn-primary" onClick={handleSearch}>
                      Search
                    </button>
                  </div>
                </div>
                <div className="col-md-1">
                  <label>&nbsp;</label>
                  <div></div>
                </div>
              </div>
              <div className="text-right mb-3" style={{ marginTop: '-80px' }}>
                <label>&nbsp;</label>
                <div>
                  <PermissionsGate scopes={['export_general_ledger']}>
                    <button
                      className=" btn btn-info"
                      onClick={onHandlegeneralLedgerExport}
                    >
                      Export
                    </button>
                  </PermissionsGate>
                </div>
              </div>
            </PermissionsGate>
            {/* <div className="col-md-1 text-right">
                  <label>&nbsp;</label>
                  <div>
                    <PermissionsGate
                      scopes={["export_receivable_sub_ledger_list"]}
                    >
                      <button
                        className=" btn btn-success"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </PermissionsGate>
                  </div>
                </div> */}
            <br />
            <PermissionsGate
              scopes={['get_general_ledger']}
              RenderError={AlertDefaultError}
            >
              <PrimaryDataTable
                rows={generalLedgerData}
                columns={generalLedgerCol}
                // columnVisibilityModel={{
                //   running_balance: columnRunning,
                // }}
                getRowId={(row) => row.id}
                rowCount={totalRow}
                loading={loading}
                reload={reloadTable}
                mode="server"
                onChange={handleTableChange}
                filterMode="server"
                onFilterModelChange={handleFilterModelChange}
              />
            </PermissionsGate>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default GeneralLedgerReportScreen;
