import { lazy } from 'react';

const CollectionList = lazy(() =>
  import('../pages/CreditCollectionsManagement/CollectionList'),
);

const CollectionListAccess = lazy(() =>
  import('../pages/CreditCollectionsManagement/CollectionListAccess'),
);

const CustomerCenter = lazy(() =>
  import('../pages/CreditCollectionsManagement/CustomerCenter'),
);
const PaymentApplication = lazy(() =>
  import('../pages/CreditCollectionsManagement/PaymentApplication'),
);
const CustomerList = lazy(() =>
  import('../pages/CreditCollectionsManagement/CustomerList'),
);
const UnidentifiedDeposit = lazy(() =>
  import('../pages/CreditCollectionsManagement/UnidentifiedDeposit'),
);

const CreditCollectionsList = lazy(() =>
  import('../pages/CreditCollections/AccountDetails'),
);
const AccountDetails = lazy(() =>
  import('../pages/CreditCollections/AccountDetails'),
);

const CreditMemo = lazy(() =>
  import(
    '../pages/CreditCollectionsManagement/components/CreditDebitMemo/index'
  ),
);

const DebitMemo = lazy(() =>
  import(
    '../pages/CreditCollectionsManagement/components/CreditDebitMemo/index'
  ),
);

const CreditDebitMemoForm = lazy(() =>
  import(
    '../pages/CreditCollectionsManagement/components/CreditDebitMemo/MemoForm'
  ),
);
const CreditMemoSrpfApprovalForm = lazy(() =>
  import(
    '../pages/CreditCollectionsManagement/components/CreditDebitMemo/SrpfApprovalForm'
  ),
);

const CustomerAdvances = lazy(() =>
  import('../pages/CreditCollectionsManagement/CustomerAdvances'),
);

const creditCollectionRoute = {
  public: [],
  auth: [
    // admin management routes
    {
      name: 'cc_management_list',
      path: '/credit-and-collections/management/collections',
      component: CollectionList,
    },
    {
      name: 'cc_management_list_access',
      path: '/credit-and-collections/management/collections-access',
      component: CollectionListAccess,
    },

    {
      name: 'cc_management_customer_center',
      path: '/credit-and-collections/management/customer-center/:id?',
      component: CustomerCenter,
    },
    {
      name: 'cc_management_customer_center',
      path: '/credit-and-collections/management/payment-application/:id?',
      component: PaymentApplication,
    },
    {
      name: 'cc_management_credit_memo',
      path: '/credit-and-collections/management/memo/:memoType',
      component: CreditMemo,
    },
    {
      name: 'cc_management_credit_memo',
      path: '/credit-and-collections/management/memo/:memoType',
      component: DebitMemo,
    },
    {
      name: 'cc_management_credit_memo_form',
      path: '/credit-and-collections/management/memo/:memoType/form/:customer_code?/:id?',
      component: CreditDebitMemoForm,
    },
    {
      name: 'cc_management_credit_memo_form',
      path: '/credit-and-collections/management/memo/:memoType/edit-form/:id?',
      component: CreditDebitMemoForm,
    },
    {
      name: 'cc_management_credit_memo_form',
      path: '/credit-and-collections/management/memo/:memoType/srpf/approval-form/:customer_code',
      component: CreditMemoSrpfApprovalForm,
    },
    {
      name: 'cc_management_credit_memo_form',
      path: '/credit-and-collections/management/memo/:memoType/form',
      component: CreditDebitMemoForm,
    },
    {
      name: 'cc_management_customer_advances_report',
      path: '/credit-and-collections/customer-advances-report',
      component: CustomerAdvances,
    },
    //Customer List Dashboard
    {
      name: 'cc_customer_dashboard',
      path: '/credit-and-collections/management/customers',
      component: CustomerList,
    },
    {
      name: 'cc_unidentified_deposits',
      path: '/credit-and-collections/management/unidentified-deposits',
      component: UnidentifiedDeposit,
    },

    // field office routes
    {
      name: 'cc_list',
      path: '/credit-and-collections',
      component: CreditCollectionsList,
    },
    {
      name: 'cc_account_details',
      path: '/credit-and-collections/customer/:id',
      component: AccountDetails,
    },
  ],
};

export default creditCollectionRoute;
