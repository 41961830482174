import { numberFormatCurrency } from '../../../../utils/helper';
import { FileEarmarkRichtextFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { PlusCircleFill } from 'react-bootstrap-icons';

//obColumns
export const obColumns = ({ handleShowCheckDetails }) => [
  {
    field: 'invoice_date',
    headerName: 'Invoice Date',
    minWidth: 150,
    renderCell: ({ row }) =>
      row.invoice_date === '0000-00-00' ? '' : row.invoice_date,
  },
  {
    field: 'delivered_date',
    headerName: 'Delivered Date',
    minWidth: 150,
    renderCell: ({ row }) =>
      row.delivered_date === '0000-00-00' ? '' : row.delivered_date,
  },
  {
    field: 'dr_number',
    headerName: 'DR No.',
    minWidth: 150,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    minWidth: 150,
  },
  {
    field: 'in_transit',
    headerName: 'In transit',
    minWidth: 150,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.in_transit);
    },
  },

  {
    field: 'current',
    headerName: 'Current',
    type: 'number',
    minWidth: 150,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.current);
    },
  },
  {
    field: '30_days',
    headerName: '30 Days',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.days_30),
  },
  {
    field: '60_days',
    headerName: '60 Days',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.days_60),
  },
  {
    field: '90_days',
    headerName: '90 Days',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.days_90),
  },

  {
    field: 'over_90_days',
    headerName: 'Over 90 Days',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.over_90_days),
  },

  {
    field: 'total_ar',
    headerName: 'Total Ar',
    type: 'number',
    minWidth: 150,
    renderCell: (data) => {
      return numberFormatCurrency(data.row.total_ar);
    },
  },
  {
    field: 'check_not_clear',
    headerName: 'Check Not Clear',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.check_not_clear),
  },
  {
    field: 'check_details',
    headerName: 'Check Details',
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: ({ row }) => {
      if (!row.check_details.length) {
        return null;
      }

      return (
        <span style={{ cursor: 'pointer' }}>
          <PlusCircleFill
            onClick={() => handleShowCheckDetails(row)}
            color="#27ae60"
          />
        </span>
      );
    },
  },
  {
    field: 'ar_net',
    headerName: 'AR Net',
    type: 'number',
    minWidth: 150,
    renderCell: ({ row }) => {
      return (
        <span className={row.ar_net < 0 ? 'color-red' : ''}>
          {numberFormatCurrency(row.ar_net)}
        </span>
      );
    },
  },
];

export const chodColumns = [
  {
    field: 'date',
    headerName: 'Delivered Date',
    type: 'date',
    flex: 1,
  },
  {
    field: 'pr_no',
    headerName: 'PR No.',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    type: 'number',
    renderCell: (data) => {
      return numberFormatCurrency(data.row.amount);
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
  // {
  //   field: "received_reason",
  //   headerName: "Remarks",
  //   minWidth:150,
  // },
];

export const checkDetailsColumns = [
  {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    minWidth: 150,
  },
  {
    field: 'pr_no',
    headerName: 'PR No.',
    minWidth: 150,
  },
  {
    field: 'cr_no',
    headerName: 'CR No.',
    minWidth: 150,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    minWidth: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    minWidth: 150,
    renderCell: ({ row }) => numberFormatCurrency(row.amount),
  },
  {
    field: 'check_date',
    headerName: 'Date',
    minWidth: 150,
  },
  {
    field: 'check_no',
    headerName: 'Check NO',
    minWidth: 150,
  },
  {
    field: 'account_name_no',
    headerName: 'Account Name/No.',
    minWidth: 300,
    renderCell: (data) => {
      return data.row.account_name_no;
    },
  },
  {
    field: 'bank',
    headerName: 'Bank & Branch',
    minWidth: 150,
    renderCell: ({ row }) => {
      return [row.bank, row.branch].filter((n) => n).join(' - ');
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
  // {
  //   field: "received_reason",
  //   headerName: "Remarks",
  //   width: 350,
  // },
];

export const advancesColumns = [
  {
    field: 'transaction_date',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'reference_code',
    headerName: 'Reference No.',
    flex: 1,
  },

  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    renderCell: (data) => {
      return numberFormatCurrency(data.row.amount);
    },
  },

  {
    field: 'remarks',
    headerName: 'Notes / Reason',
    flex: 1,
  },
];

export const outstandingEwtColumns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'dr_no',
    headerName: 'DR No.',
    flex: 1,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    flex: 1,
  },
  {
    field: 'ledger_attachment_2307_id',
    headerName: 'With 2307',
    width: 150,
    renderCell: ({ row }) => {
      return row.ledger_attachment_2307_id ? 'Yes' : 'No';
    },
  },

  {
    field: 'ledger_applied_ewt',
    headerName: 'Amount',
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(parseFloat(row.amount) || 0);
    },
  },
  {
    field: 'due_date',
    headerName: 'Due Date',
    flex: 1,
  },
];

export const stockReturnsColumns = ({ handleShowFile }) => [
  {
    field: 'srpf_date',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'srpf_no',
    headerName: 'SRPF No.',
    flex: 1,
  },
  {
    field: 'applicable_amount',
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    renderCell: ({ row }) => {
      return numberFormatCurrency(parseFloat(row.applicable_amount) || 0);
    },
  },
  {
    field: 'documents',
    headerName: 'SRPF Preview',
    minWidth: 150,
    headerAlign: 'center',
    align: 'center',
    renderCell: (data) => {
      if (data.row.documents) {
        return (
          <div>
            <span
              className="file-attachments fancy-preview"
              data-id={data.row.id}
              data-url={data.row.file}
              onClick={() => handleShowFile(data.row.documents)}
            >
              <FileEarmarkRichtextFill />
            </span>
          </div>
        );
      }

      return null;
    },
  },
];

export const transactionHistoryColumns = ({ handleShowCheckDetails }) => [
  {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    minWidth: 150,
  },
  {
    field: 'dr_number',
    headerName: 'DR No.',
    minWidth: 150,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    minWidth: 150,
  },
  {
    field: 'amount',
    headerName: 'Gross Amount',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.total_ar);
    },
  },
  {
    field: 'total_so_discount',
    headerName: 'SO Discount',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.total_so_discount);
    },
  },
  {
    field: 'total_net',
    headerName: 'Net Amount',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.total_net);
    },
  },
  {
    field: 'credit_memo',
    headerName: 'Credit Memo',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) =>
      numberFormatCurrency(row.credit_memo ? row.credit_memo : 0),
  },
  {
    field: 'total_payments',
    headerName: 'Total Payments',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.total_payments),
  },
  {
    field: 'check_details',
    headerName: 'Check Details',
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      if (!row.check_details.length) {
        return null;
      }

      return (
        <span style={{ cursor: 'pointer' }}>
          <PlusCircleFill
            onClick={() => handleShowCheckDetails(row)}
            color="#27ae60"
          />
        </span>
      );
    },
  },
  {
    field: 'amount_due',
    headerName: 'Amount Due',
    minWidth: 150,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.amount_due),
  },
  {
    field: 'with_cm',
    headerName: 'Status CM',
    flex: 1,
    renderCell: ({ row }) => {
      if (row.with_cm === 0) {
        return <span className="badge badge-danger">W/o CM</span>;
      }

      return <span className="badge badge-success">With CM</span>;
    },
  },
  {
    field: 'with_pdc_clearing',
    headerName: 'Status PDC',
    flex: 1,
    renderCell: ({ row }) => {
      if (row.with_pdc_clearing === 0) {
        return <span className="badge badge-danger">W/o PDC Clearing</span>;
      }

      return <span className="badge badge-success">With PDC Clearing</span>;
    },
  },
];

export const transactionHistoryDetailsColumns = ({
  handleShowCheckDetails,
}) => [
  {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'dr_number',
    headerName: 'DR No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'transaction_type',
    headerName: 'Transaction Type',
    minWidth: 150,
    flex: 1,
    type: 'string',
    valueFormatter: (data) => {
      console.log('data', data);
      if (data.value === 'sales') {
        return 'SALES';
      } else if (data.value === 'cm_collection') {
        return 'COLLECTION';
      } else if (data.value === 'cm_deductions') {
        return 'CM';
      } else if (data.value === 'cash_reversal') {
        return 'CASH REVERSAL';
      } else {
        return data.transaction_type;
      }
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    minWidth: 150,
    flex: 1,
    valueFormatter: (data) => numberFormatCurrency(data.value || 0),
  },
  {
    field: 'reference',
    headerName: 'Reference No',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'check_no',
    headerName: 'Check No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'check_date',
    headerName: 'Check Date',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'bank_name',
    headerName: 'Bank',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'received_date',
    headerName: 'Received Date',
    minWidth: 150,
    flex: 1,
  },

  {
    field: 'amount_due',
    headerName: 'Amount Due',
    align: 'right',
    minWidth: 150,
    flex: 1,
    valueFormatter: (data) => numberFormatCurrency(data.value || 0),
  },
  {
    field: 'pdc_not_clear',
    headerName: 'PDC not Clear',
    align: 'right',
    minWidth: 150,
    flex: 1,
    valueFormatter: (data) => numberFormatCurrency(data.value || 0),
  },
  {
    field: 'returned_amount',
    headerName: 'Returned Amount',
    align: 'right',
    minWidth: 150,
    flex: 1,
    valueFormatter: (data) => numberFormatCurrency(data.value || 0),
  },
  {
    field: 'with_cm',
    headerName: 'Status CM',
    flex: 1,
    minWidth: 150,
    valueFormatter: (data) => {
      if (parseInt(data.value) === 1) {
        return 'With CM';
      }

      if (parseInt(data.value) === 0) {
        return 'W/o CM';
      }

      return '';
    },
    renderCell: ({ row }) => {
      if (parseInt(row.with_cm) === 1) {
        return <span className="badge badge-success">With CM</span>;
      }

      if (parseInt(row.with_cm) === 0) {
        return <span className="badge badge-danger">W/o CM</span>;
      }

      return '';
    },
  },
  {
    field: 'with_pdc_clearing',
    headerName: 'Status PDC',
    minWidth: 150,
    flex: 1,
    valueFormatter: (data) => {
      if (data.value === 0) {
        return 'W/o PDC Clearing';
      }

      return 'With PDC Clearing';
    },
    renderCell: ({ row }) => {
      if (parseInt(row.with_pdc_clearing) === 1) {
        return <span className="badge badge-success">With PDC Clearing</span>;
      }
      if (parseInt(row.with_pdc_clearing) === 0) {
        return <span className="badge badge-danger">W/o PDC Clearing</span>;
      }

      return '';
    },
  },
];
