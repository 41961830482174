import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PrimaryDataTable from "../../../PrimaryDataTable";

const ModalDropdown = ({
  toggle,
  title,
  handleCloseModal,
  handleRowDoubleClick,
  columns,
  getData,
  selectionModel,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(getData);
  const [searchSelectionModal, setSearchSelectionModal] = useState([]);

  useEffect(() => {
    setSearchSelectionModal(selectionModel);
  }, [selectionModel]);

  useEffect(() => {
    setRows(getData);
  }, [getData]);

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (event) => {
    setSearchText(event.target.value);
    const searchRegex = new RegExp(escapeRegExp(event.target.value), 'i');
    const filteredRows = getData.filter((row) => {
      return Object.keys(row).some((field) => {
        if(row[field] !== null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setLoading(false);
    setRows(filteredRows);
    setSearchSelectionModal(selectionModel);
  };

  return (
    <Modal
      show={toggle}
      onHide={handleCloseModal}
      backdrop="static"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={"form-group"}>
          <input
            type="text"
            className="form-control"
            placeholder="Search here..."
            defaultValue={searchText}
            onChange={requestSearch}
          />
        </div>
        <div style={{  width: "100%" }}>
          <PrimaryDataTable
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={(row) => row.product_id}
            disableColumnSelector
            checkboxSelection={true}
            loading={loading}
            onSelectionModelChange={(params) => {
              let filteredData = getData.filter( (value) => {
                return params.includes(value.product_id);
              })
              handleRowDoubleClick(filteredData)
              if(params.length > searchSelectionModal.length) {
                setSearchSelectionModal(params);
              }
            }}
            selectionModel={searchSelectionModal}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={ handleCloseModal }>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDropdown;
