import { Link } from "react-router-dom";

export const requestDataColumn = [
  {
    field: "pr_no",
    headerName: "PR Number",
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link
          to={"/purchasing/purchaseRequest/show/" + row.pr_hdr_id}
          className="text-blue"
        >
          {row.pr_no}
        </Link>
      );
    },
  },
  {
    field: "pr_date",
    headerName: "PR Date",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "department",
    headerName: "Department",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) =>
      row.get_user?.get_department?.department_name ?? "",
  },
  {
    field: "employee",
    headerName: "Employee",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.get_user?.employee_name ?? "",
  },
  {
    field: "product_name",
    headerName: "Item Description",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) =>
      row.get_details.map((dat) => dat.product_details.product_name).join(", "),
  },
  {
    field: "estimated_delivery_date",
    headerName: "Date Needed",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "particulars",
    headerName: "Particulars",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "po_status",
    headerName: "PO Status",
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => {
      if (row.get_po_details) {
        let status = row.get_po_details?.approved_status;

        if (parseInt(status) === 0) {
          return <span className="badge badge-info">Officer Aproval</span>;
        }

        if (parseInt(status) === 1) {
          return <span className="badge badge-success">Approved</span>;
        }

        if (parseInt(status) === 2) {
          return <span className="badge badge-danger">Cancelled</span>;
        }

        if (parseInt(status) === 3) {
          return <span className="badge badge-default">Editing</span>;
        }

        if (parseInt(status) === 4) {
          return <span className="badge badge-info">For VP Approval</span>;
        }

        if (parseInt(status) === 5) {
          return <span className="badge badge-info">President Approval</span>;
        }

        if (parseInt(status) === 6) {
          return <span className="badge badge-info">Manager Approval</span>;
        }
      }

      return "";
    },
  },
];
