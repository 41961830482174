import { lazy } from "react";

const LiabilitySubledger = lazy(() =>
  import("../pages/LiabilitySubledger/index")
);

const liabilitySubledgerRoute = {
  auth: [
    {
      name: "liability_subledger",
      path: "/finance-and-accounting/current-liabilities",
      component: LiabilitySubledger,
    },
  ],
};

export default liabilitySubledgerRoute;
