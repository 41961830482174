import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import useNavActive from "../../../components/Navbar/useNavActive";
//screen
import SalesLedgerReportScreen from "../Screens/SalesLedgerReportScreen";

//api
import {
  requestGetByTransactionDate,
  requestSalesExport,
} from "../../../api/ledgers";
import { numberFormatCurrency } from "../../../utils/helper";

const today = new Date();
const prior = new Date().setDate(today.getDate() - 20);
const priorDate = new Date(prior);

const formDefault = {
  customer_id: null,
  id: "",
  date_from:
    priorDate.getFullYear() +
    "-" +
    (priorDate.getMonth() + 1) +
    "-" +
    priorDate.getDate(), //moment().format("YYYY-MM-DD"),
  date_to:
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate(), //moment().subtract(30, "days").format("YYYY-MM-DD"),

  page: 0,
  page_size: 50,
  order_by: null,
  direction: null,
};

const Sales = () => {
  useNavActive([
    "master_databases",
    "sub_ledger_database",
    "sales_special_journal",
  ]);
  //declaration
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  //functions
  useEffect(() => loadLedgerData(formData), []);

  const loadLedgerData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setSalesData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;

    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await requestGetByTransactionDate(form);

        const { code, result, total_rows } = res.data.response;

        if (code !== "00000") {
          toast.error(result);
          return;
        }

        setSalesData(
          result.map((row) => {
            return {
              id: row.id,
              transaction_date: row.transaction_date,
              si_number: row.si_number,
              digital_si: row.digital_si,
              transaction_type: row.transaction_type,
              customer_name: row.customer_name,
              customer_code: row.customer_code,
              gross: numberFormatCurrency(row.gross ?? 0),
              net: numberFormatCurrency(row.net ?? 0),
              sales_debit: numberFormatCurrency(row.sales_debit ?? 0),
              sales_credit: numberFormatCurrency(row.sales_credit ?? 0),
              sales_balance: numberFormatCurrency(row.balance ?? 0),
            };
          })
        );

        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        //console.log({ error });
        setLoading(false);
        toast.error("Error occured. Please try again later");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning("Please provide valid date!");
    }
  };

  const handleSelectedTab = (key) => {
    if (key === "sales_ledger") {
      history.push("/ledgers/sales");
    } else if (key === "receivables_ledger") {
      history.push("/ledgers/receivables");
    } else if (key === "inventories_ledger") {
      history.push("/ledgers/inventories");
    } else if (key === "sales_per_product_ledger") {
      history.push("/ledgers/sales-per-products");
    } else if (key === "vatable_sales_ledger") {
      history.push("/ledgers/vatable-sales");
    } else if (key === "product_ledger") {
      history.push("/ledgers/products");
    } else if (key === "ytd_sales_ledger") {
      history.push("/ledgers/ytd-sales");
    } else {
      history.push("/ledgers/sales");
    }
  };

  const getTransactionType = (e) => {
    if (e === null) {
      setFormData({ ...formData });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => loadLedgerData(formData);

  const handleDataTableChange = (params) => {
    //console.log(params);
    let temp = {
      ...formData,
      ...params,
    };

    //setFormData(temp);
    loadLedgerData(temp);
  };

  const OnHandleExport = () => {
    if (formData.date_from === "" || formData.date_to === "") {
      Swal.fire({
        title: "Warning",
        text: "Please select dates!",
        icon: "warning",
      });
    } else {
      let tmp = {
        id: formData.id,
        customer_id: formData.customer_id,
        date_from: formData.date_from,
        date_to: formData.date_to,
        tab: "sales",
      };
      //console.log(tmp);
      toast.loading("Exporting data in progress, Please wait...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      requestSalesExport(tmp)
        .then((res) => {
          //console.log({ res });
          if (res.data.response.code === "00000") {
            //console.log(res);
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: "Error",
              text: res,
              icon: "error",
            });
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <SalesLedgerReportScreen
      //throw parameter or props.
      formData={formData}
      handleChange={getTransactionType}
      dateChange={getDate}
      handleSearch={handleSearch}
      salesData={salesData}
      selectedTab={"sales_ledger"}
      handleSelectedTab={handleSelectedTab}
      loading={loading}
      totalRow={totalRow}
      handleDataTableChange={handleDataTableChange}
      reloadTable={reloadTable}
      handleExport={OnHandleExport}
    />
  );
};

export default Sales;
