import makeApiRequest from './makeApiCall';

export const getCustomerDetails = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getcustomerdeatils',
    'GET',
    null,
    data,
    files,
  );
};

export const getCustomersData = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/searchcustomer',
    'GET',
    null,
    data,
    files,
  );
};

export const getCustomersDataID = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getcustomer',
    'GET',
    null,
    data,
    files,
  );
};

export const getCustomersOrderData = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getcustomerorders',
    'GET',
    null,
    data,
    files,
  );
};

export const getCustomersOrderHistoryData = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getcustomerhistoricalorders',
    'GET',
    null,
    data,
    files,
  );
};

export const getCustomersAgingData = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getcustomeraging',
    'GET',
    null,
    data,
    files,
  );
};

export const createSRPF = async (data) => {
  return await makeApiRequest(
    '/collection/salesreturn/createreturn',
    'POST',
    null,
    data,
    true,
  );
};

export const updateSRPF = async (data) => {
  return await makeApiRequest(
    '/collection/salesreturn/update',
    'POST',
    null,
    data,
    true,
  );
};

export const getSRPFOnProcess = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/onprocesslist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSRPFApproval = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/approvallist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSRPFApproved = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/approvedlist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSRPFDeclinedList = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/declinedlist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSRPFDeclinedReason = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/getrejectedreason',
    'GET',
    null,
    data,
    files,
  );
};

export const getSRPF = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/approval/view',
    'GET',
    null,
    data,
    files,
  );
};

export const getApprovedSRPF = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/view',
    'GET',
    null,
    data,
    files,
  );
};

export const getDeclinedSRPF = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/approval/view',
    'GET',
    null,
    data,
    files,
  );
};

export const exportPDF = async (data, files = true) => {
  return await makeApiRequest(
    '/collection/salesreturn/export/srpf/pdf',
    'GET',
    null,
    data,
  );
};

export const approvalSRPF = async (data, files = true) => {
  return await makeApiRequest(
    '/collection/salesreturn/approval',
    'POST',
    null,
    data,
    files,
  );
};

export const approveSRPF = async (data, files = true) => {
  return await makeApiRequest(
    '/collection/salesreturn/approve',
    'POST',
    null,
    data,
    files,
  );
};

export const declineSRPF = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/reject',
    'POST',
    null,
    data,
    files,
  );
};

export const srpfExport = async (data = {}, files = null) => {
  return await makeApiRequest(
    `/collection/salesreturn/export/srpf`,
    `GET`,
    null,
    data,
    files,
  );
};

export const splitBatch = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/splitbatching',
    'POST',
    null,
    data,
    files,
  );
};

export const declined = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/declined',
    'POST',
    null,
    data,
    files,
  );
};

export const getSrpfReturnedList = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/returnedlist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSrpfApprovedByWarehouseList = async (data, files = null) => {
  return await makeApiRequest(
    '/collection/salesreturn/returnedlist',
    'GET',
    null,
    data,
    files,
  );
};

export const getSrpfNotYetReturnedLists = async (data) => {
  return await makeApiRequest(
    `/collection/salesreturn/not-yet-received`,
    'GET',
    null,
    data,
  );
};

export const srpfUploadAttachment = async (data, files = true) => {
  return await makeApiRequest(
    `/collection/salesreturn/additional-attachment`,
    'POST',
    null,
    data,
    files,
  );
};
