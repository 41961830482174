import { useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getForecastPurchaseOrderTrailLogs } from "../../../api/product";
import PrimaryDataTable from "../../../components/PrimaryDataTable";

const tableColumns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    minWidth: 250,
    flex: 1,
  },
  {
    field: "date_time",
    headerName: "Date",
    flex: 1,
    minWidth: 150,
    type: "date",
  },
];

const PoTrailLogsModal = ({ toggle, data, onClose }) => {
  const [tableData, setTableData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
  });

  const loadData = async () => {
    try {
      setTableData({ ...tableData, loading: true, data: [] });
      console.log({ loadData: 1, data });
      const res = await getForecastPurchaseOrderTrailLogs(data?.po_hdr_id);

      const { code, result } = res.data.response;

      if (code !== "00000") {
        setTableData({ ...tableData, loading: false });
        toast.error(Array.isArray(result) ? result.join("<br />") : result);
        return;
      }

      setTableData({
        ...tableData,
        loading: false,
        data: result.map((row) => ({
          id: row.log_trail_id,
          name: row.user_details.employee_name,
          remarks: row.remarks,
          date_time: row.date_time,
        })),
      });
    } catch (error) {
      setTableData({ ...tableData, loading: false });
      toast.error(error.message);
    }
  };

  const handleOnEntered = () => {
    loadData();
  };

  return (
    <Modal show={toggle} onHide={onClose} onEntered={handleOnEntered} size="md">
      <Modal.Header className="modal-draggable" closeButton>
        <Modal.Title>Purchase Order Trail Logs {data?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrimaryDataTable
          rows={tableData.data}
          columns={tableColumns}
          loading={tableData.loading}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose} style={{ width: "100px" }}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const usePoTrailLogsModal = () => {
  const [trailLogsModal, setPoTrailLogsModal] = useState({
    toggle: false,
    minimize: false,
    loading: false,
  });

  const setPoTrailLogsModalClose = useCallback(
    () =>
      setPoTrailLogsModal({
        ...trailLogsModal,
        toggle: false,
        data: null,
      }),
    []
  );

  const setPoTrailLogsModalToggle = useCallback(
    (data = null) =>
      setPoTrailLogsModal({
        ...trailLogsModal,
        toggle: true,
        data,
      }),
    []
  );

  return [trailLogsModal, setPoTrailLogsModalClose, setPoTrailLogsModalToggle];
};

export default PoTrailLogsModal;
