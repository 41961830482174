import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import axiosInstance from "../axiosInstance";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";

import PrimaryDataTable from "../PrimaryDataTable";
import DefaultLayout from "../Layouts/DefaultLayout";

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const InventoryReportSummary = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [storeData, setStoreData] = useState([]);
  const [pageSize, setPageSize] = useState(50); // for page purposes
  const history = useHistory();

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = storeData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };

  const location = useLocation();

  const productsData = location.state.data;

  const columns = [
    {
      field: "product_code",
      headerName: "Product Code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "generic_name",
      headerName: "Generic Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "batch_no",
      headerName: "Batch No",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "stock_in",
      headerName: "Stock In",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "stock_out",
      headerName: "Stock Out",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "zone",
      headerName: "Zone",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "pallet",
      headerName: "Pallet",
      flex: 1,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    if (productsData) {
      let tempstoredata = productsData.map((data) => ({
        key: data.key,
        product_code: data.product_code,
        product_name: data.product_name,
        generic_name: data.generic_name,
        batch_no: data.batch_no,
        expiry_date: data.expiry_date,
        stock_in: data.movements.movement_in,
        stock_out: data.movements.movement_out,
        balance:
          parseInt(data.movements.movement_beg_in) -
          parseInt(data.movements.movement_beg_out) +
          (parseInt(data.movements.movement_in) -
            parseInt(data.movements.movement_out)),
        status: data.shelf_life_status,
        zone: data.zone,
        pallet: data.pallet,
      }));

      setFilteredData(tempstoredata);
      setStoreData(tempstoredata);
    }
  }, [productsData]);

  const [prodNameHolder, setProdNameHolder] = useState([]);
  const [warehouseHolder, setWarehouseHolder] = useState([]);
  const [groupHolder, setGroupHolder] = useState([]);
  const [segmentHolder, setSegmentHolder] = useState([]);

  useEffect(() => {
    listProducts();
    listWarehouse();
    listGroup();
    listSegment();
  }, []);

  const listProducts = () => {
    axiosInstance.get(`/inventoryreport/dropdown/product`).then((response) => {
      setProdNameHolder(response.data.response.result);
    });
  };

  const listWarehouse = () => {
    axiosInstance
      .get(`/inventoryreport/dropdown/warehouse`)
      .then((response) => {
        setWarehouseHolder(response.data.response.result);
      });
  };

  const listGroup = () => {
    axiosInstance.get(`/products/group`).then((response) => {
      setGroupHolder(response.data.response.result);
    });
  };

  const listSegment = () => {
    axiosInstance.get(`/products/segment`).then((response) => {
      setSegmentHolder(response.data.response.result);
    });
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const wrapColumns = useRef(columns);

  return (
    <Fragment>
      <DefaultLayout title="Inventory List Summary">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <Link to="/inventoryreport">
              <button
                id="save_changes"
                className="btn btn-success"
                type="submit"
              >
                Change Table Columns
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <PrimaryDataTable
              autoHeight
              rows={filteredData}
              columns={wrapColumns.current}
              getRowId={(row) => row.key}
              disableSelectionOnClick
              density="compact"
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </div>
      </DefaultLayout>
    </Fragment>
  );
};

export default InventoryReportSummary;
