import React, { Fragment } from "react";

const CustomerDeliveryTime = ({ values, handleChange }) => {
  return (
    <Fragment>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>
                <strong>Day of Delivery</strong>
              </th>
              <th>
                <strong>From</strong>
              </th>
              <th>
                <strong>To</strong>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Monday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_mon_start"}
                  id={"shipping_mon_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_mon_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_mon_end"}
                  id={"shipping_mon_end"}
                  className="form-control form-control-sm ml-2"
                  value={values.shipping_mon_end}
                  min={values.shipping_mon_start}
                  max="24:00"
                  disabled={!values.shipping_mon_start}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_tue_start"}
                  id={"shipping_tue_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_tue_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_tue_end"}
                  id={"shipping_tue_end"}
                  className="form-control form-control-sm ml-2"
                  value={values.shipping_tue_end}
                  min={values.shipping_tue_start}
                  max="24:00"
                  disabled={!values.shipping_tue_start}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_wed_start"}
                  id={"shipping_wed_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_wed_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_wed_end"}
                  id={"shipping_wed_end"}
                  className="form-control form-control-sm ml-2"
                  min={values.shipping_wed_start}
                  max="24:00"
                  disabled={!values.shipping_wed_start}
                  value={values.shipping_wed_end}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_thur_start"}
                  id={"shipping_thur_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_thur_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_thur_end"}
                  id={"shipping_thur_end"}
                  className="form-control form-control-sm ml-2"
                  min={values.shipping_thur_start}
                  max="24:00"
                  disabled={!values.shipping_thur_start}
                  value={values.shipping_thur_end}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_fri_start"}
                  id={"shipping_fri_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_fri_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_fri_end"}
                  id={"shipping_fri_end"}
                  className="form-control form-control-sm ml-2"
                  min={values.shipping_fri_start}
                  max="24:00"
                  disabled={!values.shipping_fri_start}
                  value={values.shipping_fri_end}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_sat_start"}
                  id={"shipping_sat_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_sat_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_sat_end"}
                  id={"shipping_sat_end"}
                  className="form-control form-control-sm ml-2"
                  min={values.shipping_sat_start}
                  max="24:00"
                  disabled={!values.shipping_sat_start}
                  value={values.shipping_sat_end}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>
                <input
                  type="time"
                  name={"shipping_sun_start"}
                  id={"shipping_sun_start"}
                  className="form-control form-control-sm"
                  min="01:00"
                  max="24:00"
                  value={values.shipping_sun_start}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name={"shipping_sun_end"}
                  id={"shipping_sun_end"}
                  className="form-control form-control-sm ml-2"
                  min={values.shipping_sun_start}
                  max="24:00"
                  disabled={!values.shipping_sun_start}
                  value={values.shipping_sun_end}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default CustomerDeliveryTime;
