import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { Calendar3 } from 'react-bootstrap-icons';
import { parseISO } from 'date-fns';
const PrimaryDatePicker = ({
  name,
  value,
  onChange,
  format,
  formatString,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsEnd,
  selectsStart,
  readOnly = false,
  disabled = false,
  showMonthYearPicker,
  className,
  raw = false,
  placeholder = '',
}) => {
  const datePickerFormat =
    typeof format !== 'undefined' ? format : 'yyyy-MM-dd';
  const datePickerFormatString =
    typeof format !== 'undefined' ? formatString : 'YYYY-MM-DD';

  const PrimaryCoreInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="input-group">
        <input
          placeholder={placeholder}
          type="text"
          name={name ? name : 'primary-date-picker-default'}
          id={name ? `${name}--id` : 'primary-date-picker-default--id'}
          className={className ? `form-control ${className}` : `form-control`}
          defaultValue={value}
          onClick={onClick}
          ref={ref}
          readOnly
          style={
            readOnly
              ? {
                  backgroundColor: 'rgba(35, 46, 60, 0.1)',
                }
              : { backgroundColor: '#fff' }
          }
        />
        {!raw && (
          <div className={'input-group-append'}>
            <span className={'input-group-text'}>
              <Calendar3 />
            </span>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="primary-date-picker">
      <DatePicker
        selected={value !== '' ? new Date(value) : ''}
        dateFormat={datePickerFormat}
        name={name}
        minDate={!minDate ? null : parseISO(minDate)}
        maxDate={!maxDate ? null : parseISO(maxDate)}
        startDate={!startDate ? null : parseISO(startDate)}
        endDate={!endDate ? null : parseISO(endDate)}
        onChange={(e) => onChange(moment(e).format(datePickerFormatString))}
        readOnly={readOnly}
        customInput={<PrimaryCoreInput name={name} />}
        showMonthDropdown
        showYearDropdown
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        disabled={disabled}
        isEditable
        showMonthYearPicker={showMonthYearPicker}
      />
    </div>
  );
};

export default PrimaryDatePicker;
