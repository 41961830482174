import { lazy } from 'react';

const CoaTagging = lazy(() =>
  import('../pages/CoaTagging/index.js').then((module) => ({
    default: module.CoaTagging,
  })),
);

const coaTaggingRoute = {
  public: [],
  auth: [
    {
      name: 'coa_tagging',
      path: '/finance-and-accounting/coa-tagging',
      component: CoaTagging,
    },
  ],
};

export default coaTaggingRoute;
