import { IconButton } from '@material-ui/core';
import { numberFormatCurrency } from '../../utils/helper';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

export const srpfType = [
  { label: 'Return to Supplier', value: 'SRPF-RTS' },
  { label: 'Customer Returns', value: 'SRPF' },
];

export const tab_location = {
  add_new: '/collection/srpf',
  onprocess: '/collection/OnProcessSRPF',
  approval: '/collection/ForApproval',
  approved: '/collection/ApprovedSRPF',
  declined: '/collection/DeclinedSRPF',
  returned: '/collection/ReturnedSRPF',
  not_yet_received: '/collection/NotYetReceivedSRPF',
};

export const transactionForm = {
  customer_id: '',
  customer_code: '',
  customer_name: '',
  account_name: '',
  account_type: '',
  srpf_date: new Date().toISOString().slice(0, 10),
  srpf_type: '',
  remarks: '',
  signature: [],
  attachments: [],
  old_signature: [],
  old_attachments: [],
  address: '',
  disposition: '',
  disposition_reference: [],
  products: [],
  // Totals
  total_amount: 0,
  total_amount_near_ex: 0,
  applicable_amount: 0,
  total_applicable_amount: 0,
  total_total_applicable_amount: 0,
};

export const orderColumn = [
  {
    field: 'product_name',
    headerName: 'Product Name',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'batch_no',
    headerName: 'Batch No',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry Date',
    minWidth: 150,
    type: 'date',
    flex: 1,
    renderCell: ({ row }) =>
      row.expiry_date ? moment(row.expiry_date).format('YYYY-MM-DD') : '',
  },
  {
    field: 'dr_no',
    headerName: 'DR No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'si_number',
    headerName: 'SI No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'qty_ordered',
    headerName: 'Quantity',
    align: 'right',
    minWidth: 150,
    type: 'number',
    flex: 1,
  },
  {
    field: 'uom',
    headerName: 'UOM',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'selling_price',
    headerName: 'Unit Cost',
    minWidth: 150,
    type: 'number',
    flex: 1,
    // renderCell: ({ row }) => numberFormatCurrency(row.srp),
    renderCell: ({ row }) => numberFormatCurrency(row.selling_price),
  },
  {
    field: 'total_selling_price',
    headerName: 'Total Cost',
    minWidth: 150,
    type: 'number',
    flex: 1,
    // renderCell: ({ row }) => numberFormatCurrency(row.srp * row.qty_ordered),
    // valueGetter: ({ row }) => row.srp * row.qty_ordered,
    valueGetter: ({ row }) => row.selling_price * row.qty_ordered,
    renderCell: ({ row }) =>
      numberFormatCurrency(row.selling_price * row.qty_ordered),
  },
];

export const selectedOrderListColumn = ({
  showOUM,
  handleChangeQuantity,
  handleChangeReason,
  handleRemoveRow,
  handleChangeUOM,
  handleMultipleReason,
  handleSplitBatch,
}) => {
  return [
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'batch_no',
      headerName: 'Batch No',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) =>
        row.expiry_date ? moment(row.expiry_date).format('YYYY-MM-DD') : '',
    },
    {
      field: 'si_reference',
      headerName: 'DR/SI',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      align: 'right',
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <input
            type="number"
            className="form-control form-control-sm"
            onChange={(event) => {
              handleChangeQuantity(event, row);
            }}
            value={row.quantity}
          />
        );
      },
    },
    {
      field: 'uom',
      headerName: 'UOM',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        let options = showOUM.filter(
          (item) =>
            item.unit_id === row.unit_id && row.packaging_name !== item.value,
        );

        return (
          <>
            <select
              className="form-control form-control-sm"
              defaultValue={row.uom}
              onChange={(event) => {
                handleChangeUOM(event, row);
              }}
            >
              <option value={``} key={``}>
                -Select UOM-
              </option>
              <option
                key={row.packaging_name}
                data-type="packaging"
                value={row.packaging_name}
              >
                {row.packaging_name}
              </option>
              {options.map((opt) => (
                <option value={opt.value} data-type="unit" key={opt.value}>
                  {' '}
                  {opt.label}{' '}
                </option>
              ))}
            </select>
          </>
        );
      },
    },
    {
      field: 'unit_cost',
      headerName: 'Unit Cost',
      headerAlign: 'right',
      align: 'right',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => numberFormatCurrency(row.unit_cost),
    },
    {
      field: 'total_cost',
      headerName: 'Total Cost',
      headerAlign: 'right',
      align: 'right',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => numberFormatCurrency(row.total_cost),
    },
    {
      field: 'remarks',
      headerName: 'Reason',
      minWidth: 180,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        let remarks = row.remarks;
        let options = [
          { value: '', label: '-Select Reason-' },
          { value: 'Near Expiry', label: 'Near Expiry' },
          { value: '60-40 Near Expiry', label: '60-40 Near Expiry' },
          { value: 'Initial Stocking', label: 'Initial Stocking' },
          { value: 'Erroneous Delivery', label: 'Erroneous Delivery' },
          { value: 'Late arrival of item', label: 'Late arrival of item' },
          { value: 'Erroneous Order', label: 'Erroneous Order' },
          {
            value: 'Defective Item Received',
            label: 'Defective Item Received',
          },
          {
            value: 'Erroneous Discount Application',
            label: 'Erroneous Discount Application',
          },
          { value: 'Others', label: 'Others' },
        ];
        return (
          <>
            <input
              type="checkbox"
              onChange={handleMultipleReason}
              value={row.unique_id}
            />
            &nbsp;
            <select
              className="form-control form-control-sm"
              defaultValue={remarks}
              value={remarks}
              onChange={(event) => {
                handleChangeReason(event, row, row.unique_id);
              }}
            >
              {options.map((opt) => (
                <option value={opt.value} key={opt.value}>
                  {' '}
                  {opt.label}{' '}
                </option>
              ))}
            </select>
          </>
        );
      },
    },
    {
      field: '',
      headerName: ' ',
      align: 'center',
      minWidth: 110,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              aria-label="add"
              size="small"
              onClick={() => {
                handleRemoveRow(row.unique_id, row.od_id, row.transaction_type);
              }}
            >
              <ClearIcon color="danger" />
            </IconButton>
            {/* <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    handleRemoveRow(
                      row.unique_id,
                      row.od_id,
                      row.transaction_type,
                    );
                  }}
                >
                  X
                </button> */}
            {row.is_parent === 1 && (
              <>
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={() => {
                    handleSplitBatch(
                      row.unique_id,
                      row.od_id,
                      row.transaction_type,
                    );
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
                {/* <button
                  type="button"
                  className="btn btn-danger btn-xs ml-2"
                  onClick={() => {
                    handleSplitBatch(
                      row.unique_id,
                      row.od_id,
                      row.transaction_type,
                    );
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button> */}
              </>
            )}
          </>
        );
      },
    },
  ];
};

export const selectedAgingListColumn = ({
  numberFormatCurrency,
  handleChangeAgingAmount,
  handleRemoveAgingRow,
}) => {
  return [
    {
      field: 'disposition_date',
      headerName: 'Date',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'disposition_due_date',
      headerName: 'Due Date',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'disposition_dr',
      headerName: 'DR No.',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'disposition_si',
      headerName: 'SI No.',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'disposition_balance',
      headerName: 'Amount',
      align: 'right',
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => numberFormatCurrency(row.disposition_balance),
    },
    {
      field: 'disposition_amount',
      headerName: 'Applied Amt.',
      align: 'right',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <input
            type="number"
            className="form-control form-control-sm"
            onChange={(event) => {
              handleChangeAgingAmount(event, row);
            }}
            value={row.disposition_amount}
          />
        );
      },
    },
    {
      field: '',
      headerName: '',
      align: 'center',
      type: 'actions',
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => {
              handleRemoveAgingRow(row.disposition_id);
            }}
          >
            X
          </button>
        );
      },
    },
  ];
};

export const customerOptions = (showCustomers, data = []) => {
  return showCustomers.map((value) => ({
    ...data,
    value: value.customer_id,
    codes: value.customer_code,
    label: value.customer_code + ' - ' + value.customer_name,
  }));
};

export const dispositionOptions = [
  {
    id: 1,
    type: 'AR',
    value: 'apply_aging',
    label: 'Apply to Aging',
  },
  {
    id: 2,
    type: 'ADV',
    value: 'apply_advances',
    label: 'Apply to Advances',
  },
];

export const customStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    marginTop: '-4px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    top: '-2px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),

  menuList: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),

  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
    fontSize: '14px',
  }),
};

export const transactionDetails = {
  srpf_approval_id: '',
  srpf_no: '',
  account_name: '',
  account_type: '',
  address: '',
  customer_code: '',
  srpf_date: '',
  srpf_type: '',
  total_cost: '',
  disposition: '',
  total_amount: '',
  attachments: [],
  products: [],
  documents: [],
  documents_new: [],
  disposition_reference: [],
  get_customer_info: {},
  reject_reason: [],
  rr_date: '',
  rr_approved_date: '',
  reset_attachment: 0,
  has_new_attachments: false,
};

export const onProcessColumn = ({
  handleShowTransactionModal,
  handleShowReason,
}) => {
  return [
    {
      field: 'srpf_no',
      headerName: 'SRPF No',
      minWidth: 200,
      renderCell: (product) => {
        return product.row.viewSrpfPermission ? (
          <span
            onClick={() => {
              handleShowTransactionModal(product.id);
            }}
            style={{ color: 'blue', cursor: 'pointer' }}
          >
            {product.row.srpf_no}
          </span>
        ) : (
          product.row.srpf_no
        );
      },
    },
    {
      field: 'srpf_type',
      headerName: 'Type',
      minWidth: 200,
      renderCell: ({ row }) => {
        switch (row.srpf_type) {
          case 'SRPF-RTS':
            return (
              <span className="badge badge-success">Return to Supplier</span>
            );
          case 'SRPF':
            return (
              <span className="badge badge-success">Customer Returns</span>
            );
          default:
            return <></>;
        }
      },
    },
    {
      field: 'customer_id',
      headerName: 'Customer Code',
      minWidth: 200,
      renderCell: (product) => {
        let get_customer_info = product.row.get_customer_info;
        if (get_customer_info !== null) {
          return <>{get_customer_info.customer_code}</>;
        } else {
          return <span style={{ color: 'red' }}>No Customer Info!</span>;
        }
      },
    },
    {
      field: 'account_name',
      headerName: 'Account Name',
      minWidth: 200,
    },
    {
      field: 'srpf_date',
      headerName: 'Date',
      minWidth: 200,
    },
    {
      field: 'total_amount',
      headerName: 'Amount',
      minWidth: 200,
      type: 'number',
      renderCell: ({ row }) => numberFormatCurrency(row.total_amount),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      renderCell: (product) => {
        let status = product.row.status;
        if (status === '0') {
          return <>Pending</>;
        } else if (status === '1') {
          return <>Approved</>;
        } else if (status === '2') {
          return <>DECLINED</>;
        } else if (status === '3') {
          return <>FOR APPROVAL</>;
        } else if (status === '4') {
          return <>RETURNED TO MAKER</>;
        }
      },
    },
    {
      field: 'channel',
      headerName: 'Channel',
      minWidth: 200,
      renderCell: (product) => {
        let get_customer_info = product.row.get_customer_info;
        if (get_customer_info !== null) {
          return <>{get_customer_info.get_customer_type.channel}</>;
        } else {
          return <span style={{ color: 'red' }}>No Customer Info!</span>;
        }
      },
    },
    {
      field: 'kas',
      headerName: 'SAR/KASS',
      minWidth: 200,
      renderCell: (product) => {
        let get_customer_info = product.row.get_customer_info;
        if (get_customer_info !== null) {
          return <>{get_customer_info.kam_name}</>;
        } else {
          return <span style={{ color: 'red' }}>No Customer Info!</span>;
        }
      },
    },
    {
      field: 'sam',
      headerName: 'SAM/KASM',
      minWidth: 200,
      renderCell: (product) => {
        let get_customer_info = product.row.get_customer_info;
        if (get_customer_info !== null) {
          return <>{get_customer_info.kass_name}</>;
        } else {
          return <span style={{ color: 'red' }}>No Customer Info!</span>;
        }
      },
    },
    {
      field: 'generate_srpf',
      headerName: 'Generate SRPF',
      minWidth: 200,
    },
    {
      field: 'date_received',
      headerName: 'Date Received by WH',
      minWidth: 200,
    },
    {
      field: 'remarks',
      headerName: 'WH Remarks',
      minWidth: 200,
    },
    {
      field: 'reason',
      headerName: 'Declined Reason',
      minWidth: 200,
      renderCell: ({ row }) => {
        return (
          <span
            className="text-blue cursor-pointer"
            onClick={() => handleShowReason(row.srpf_no)}
          >
            View Reason
          </span>
        );
      },
    },
  ];
};

export const productColumn = ({ numberFormatCurrency }) => {
  return [
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 150,
      flex: 1,
      renderCell: (product) => product.row?.product_info?.product_name,
    },
    {
      field: 'batch_no',
      headerName: 'Batch No',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'dr_reference',
      headerName: 'DR No.',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'si_reference',
      headerName: 'SI No.',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (product) => product.row.quantity,
    },
    {
      field: 'unit',
      headerName: 'UOM',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'unit_cost',
      headerName: 'Unit Cost',
      headerAlign: 'right',
      align: 'right',
      minWidth: 100,
      flex: 1,
      renderCell: (product) => numberFormatCurrency(product.row.unit_cost),
    },
    {
      field: 'total_cost',
      headerName: 'Total Cost',
      headerAlign: 'right',
      align: 'right',
      minWidth: 100,
      flex: 1,
      renderCell: (product) => numberFormatCurrency(product.row.total_cost),
    },
    {
      field: 'discount',
      headerName: 'Discount',
      headerAlign: 'right',
      align: 'right',
      minWidth: 100,
      flex: 1,
      renderCell: (product) => numberFormatCurrency(product.row.discount),
    },
    {
      field: 'reason',
      headerName: 'Reason',
      minWidth: 150,
      flex: 1,
      renderCell: (product) => product.row.reason,
    },
  ];
};
