import { lazy } from 'react';

const StatementOfAccount = lazy(() => import('../pages/StatementOfAccount'));

const statementOfAccountRoute = {
  public: [],
  auth: [
    {
      name: 'cc_statement_of_account',
      path: '/credit-and-collections/management/statement-of-account/:tab?',
      component: StatementOfAccount,
    },
  ],
};

export default statementOfAccountRoute;
