import { Tabs, Tab } from 'react-bootstrap';
import '../../../globalStyles/css/bootstrap/bootstrap.min.css';
import '../ledger.css';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';
import PrimaryAsyncSelect from '../../../components/PrimaryAsyncSelect';
import { getCustomers } from '../../../api/ledgers';
import PrimaryDataTable from '../../../components/PrimaryDataTable';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';
import { receivablesCol } from '../const';

const ReceivablesLedgerReportScreen = ({
  // handleChange,
  formData,
  dateChange,
  onSearch,
  receivablesData,
  selectedTab,
  handleSearch,
  handleSelectedTab,
  handleChange,
  handleDataTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
  columnRunning,
  columnBalance,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            className="default-tab-content"
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          >
            <div className="col-md-12">
              <div className="row">
                <PermissionsGate scopes={['view_receivable_sub_ledger_list']}>
                  <div className="col-md-3">
                    <label>Select Customer</label>

                    <PrimaryAsyncSelect
                      onChange={(e) =>
                        handleChange({
                          target: { name: 'customer_id', value: e.value },
                        })
                      }
                      customFunction={getCustomers}
                      isClearable={false}
                      name={'customer_name'}
                      value={formData.customer_id}
                      placeholder="Select Customer - ALL Customer"
                      config={{
                        searchField: 'keyword',
                        valueField: 'customer_id',
                        labelField: 'customer_name',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>DR/SI Number</label>

                    <input
                      type="text"
                      class="form-control form-control"
                      placeholder="Search DR/SI No"
                      name="dr_si_number"
                      value={formData.dr_si_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date From</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_from'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_from}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_from', value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date To</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_to'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_to}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_to', value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-1">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <label>&nbsp;</label>
                    <div></div>
                  </div>
                </PermissionsGate>
                <div className="col-md-1 text-right">
                  <label>&nbsp;</label>
                  <div>
                    <PermissionsGate
                      scopes={['export_receivable_sub_ledger_list']}
                    >
                      <button
                        className=" btn btn-success"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
              <br />
              <PermissionsGate
                scopes={['view_receivable_sub_ledger_list']}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={receivablesData}
                  columns={receivablesCol}
                  columnVisibilityModel={{
                    running_balance: columnRunning,
                    receivable_balance: columnBalance,
                  }}
                  getRowId={(row) => row.id}
                  onChange={handleDataTableChange}
                  disableColumnFilter
                  rowCount={totalRow}
                  loading={loading}
                  mode="server"
                  reload={reloadTable}
                />
              </PermissionsGate>
            </div>
          </Tab>
          <Tab eventKey="inventories_ledger" title="Inventory Sub Ledger"></Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ReceivablesLedgerReportScreen;
