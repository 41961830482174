import { Fragment, useEffect, useState } from "react";

import PrimaryDataTable from "../../../../components/PrimaryDataTable";
// api
import { getCustomerSummaryTransactionHistory } from "../../../../api/collections";

// data and consts
import { transactionHistoryColumns } from "./const";
import CheckDetailModal from "./CheckDetailModal";

const TransactionHistory = ({ customer_code }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // detail
  const [detailModal, setDetailModal] = useState({ toggle: false, data: [] });

  useEffect(() => loadData(customer_code), [customer_code]);

  const loadData = (customerCode) => {
    if (customerCode === "") return;

    setLoading(true);
    setData([]);

    getCustomerSummaryTransactionHistory(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;

      if (code === "00000") {
        setData(
          result.sales_invoices.map((row, index) => ({
            ...row,
            id: customerCode + "_" + index,
          }))
        );
      }
    });
  };

  const handleShowCheckDetails = (row) =>
    setDetailModal({ toggle: true, data: row.check_details });

  const columns = transactionHistoryColumns({
    handleShowCheckDetails: handleShowCheckDetails,
  });

  return (
    <Fragment>
      <PrimaryDataTable
        key={customer_code}
        rows={data}
        columns={columns}
        disableColumnSelector={true}
        disableSelectionOnClick
        hasGridToolbar
        loading={loading}
      />

      <CheckDetailModal
        toggle={detailModal.toggle}
        closeModal={() => setDetailModal({ toggle: false, data: [] })}
        data={detailModal.data}
        type={"transaction_history"}
      />
    </Fragment>
  );
};

export default TransactionHistory;
