import React, { createElement, useState, Fragment, useEffect } from 'react';
import { Accordion, Alert, Col, Container, Row } from 'react-bootstrap';
import { getCustomerByCode } from '../../../../api/customer';
import {
  CHODDetails,
  OutstandingBalance,
  CheckDetails,
  Advances,
  StockReturns,
  OutstandingEWT,
  TransactionHistory,
} from '../../../../pages/CreditCollectionsManagement/components/CustomerCenter';
import CustomerTotal from '../../../../pages/CreditCollectionsManagement/components/CustomerTotal';
import FootprintTracing from '../../../../pages/CreditCollectionsManagement/components/FootprintTracing';
import { customerTotalDefault } from '../../../../pages/CreditCollectionsManagement/CustomerCenter/const';
import { hasPermission, PermissionsGate } from '../../../PermissionsGate';
import Swal from 'sweetalert2';
import { getCustomerSummaryTotals } from '../../../../api/collections';
import { toast } from 'react-toastify';
const customerCenterTabs = [
  {
    name: 'Outstanding Balance',
    slug: 'outstanding_balance',
    component: OutstandingBalance,
    permission: hasPermission(['get_customer_outstanding_balance']),
  },
  {
    name: 'CHOD Details',
    slug: 'chod_details',
    component: CHODDetails,
    permission: hasPermission(['get_list_of_chod']),
  },
  {
    name: 'Check Details',
    slug: 'check_details',
    component: CheckDetails,
    permission: hasPermission(['get_customer_check']),
  },
  {
    name: 'Advances',
    slug: 'advances',
    component: Advances,
    permission: hasPermission(['get_customer_advances']),
  },
  {
    name: 'Stock Returns',
    slug: 'stock_returns',
    component: StockReturns,
    permission: hasPermission(['get_customer_returns']),
  },
  {
    name: 'Outstanding EWT',
    slug: 'outstanding_ewt',
    component: OutstandingEWT,
    permission: hasPermission(['get_customer_ewt']),
  },
  {
    name: 'Transaction History',
    slug: 'transaction_history',
    component: TransactionHistory,
    permission: hasPermission(['get_customer_transactionhistory']),
  },
  //   {
  //     name: "Footprint Tracing",
  //     slug: "footprint_tracing_customer",
  //     component: FootprintTracing,
  //     permission: hasPermission(["get_customer_footprint"]),
  //   },
  //{ name: "Associate MD", slug: "associate_md", component: null },
];

const CustomerArInformation = ({ customerCode, customerData }) => {
  const [totals, setTotals] = useState(customerTotalDefault);
  const [loading, setLoading] = useState(false);

  useEffect(() => loadCustomerDetails(customerCode), [customerCode]);

  const loadCustomerDetails = async (customerCode) => {
    if (!customerCode) return;

    setLoading(true);
    const cInfoRes = await getCustomerByCode(customerCode);

    const { code: cInfoCode, result: cInfoResult } = cInfoRes.data.response;

    if (cInfoCode !== '00000') {
      setLoading(false);
      await Swal.fire({
        title: 'Customer Summary',
        html: 'Customer Information not found.',
        icon: 'error',
      });

      return;
    }

    setLoading(false);

    let cInfoTotalRes = await getCustomerSummaryTotals(customerCode);

    const { code: cInfoTotalCode, result: cInfoTotalResult } =
      cInfoTotalRes.data.response;

    if (cInfoTotalCode === '00000') {
      setTotals(cInfoTotalResult);
    } else {
      toast.error('Customer Totals not found.');
      setTotals(customerTotalDefault);
    }
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>AR Information</legend>

            <div className="col-md-12">
              <Accordion>
                {customerCenterTabs.map((row, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{row.name}</Accordion.Header>
                    <Accordion.Body>
                      {row.component &&
                        createElement(row.component, {
                          customer_code: customerData.customer_code,
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <PermissionsGate scopes={['get_collection_dashboard_totals']}>
              <div
                className="white-box mb-5"
                style={{ padding: '20px 10px 0' }}
              >
                <Container fluid>
                  <Row>
                    <Col lg="6">
                      {/* <CustomerDetails data={customerData} /> */}
                    </Col>
                    <Col lg="6">
                      <CustomerTotal data={totals} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </PermissionsGate>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerArInformation;
