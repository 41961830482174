import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useNavActive from "../../../components/Navbar/useNavActive";
import moment from "moment";
import _ from "lodash";
//api
import { requestYtdSalesData } from "../../../api/ledgers";
import YtdSalesLedgerReportScreen from "../Screens/YtdSalesLedgerReportScreen";
import defaultOptions from "../../../utils/defaultOptions";

const formDefault = {
  date_year: moment().format("YYYY"),
  type: "per_channel",
  page: 0,
  page_size: defaultOptions.pageSize,
  order_by: null,
  direction: null,
};

const YtdSales = () => {
  useNavActive(["master_databases", "sub_ledger_database", "ytd_sales_ledger"]);
  const history = useHistory();
  const handleSelectedTab = (key) => {
    if (key === "sales_ledger") {
      history.push("/ledgers/sales");
    } else if (key === "receivables_ledger") {
      history.push("/ledgers/receivables");
    } else if (key === "inventories_ledger") {
      history.push("/ledgers/inventories");
    } else if (key === "sales_per_product_ledger") {
      history.push("/ledgers/sales-per-products");
    } else if (key === "vatable_sales_ledger") {
      history.push("/ledgers/vatable-sales");
    } else if (key === "product_ledger") {
      history.push("/ledgers/products");
    } else if (key === "ytd_sales_ledger") {
      history.push("/ledgers/ytd-sales");
    } else {
      history.push("/ledgers/sales");
    }
  };
  const [formData, setFormData] = useState(formDefault);
  const [ytdSalesData, setYtdSalesData] = useState({
    loading: false,
    data: [],
    totalRow: 0,
  });

  const handleChange = (e) => {
    let tmpForm = { ...formData, type: "" };
    if (e !== null) {
      tmpForm = { ...tmpForm, type: e.value };
    }

    setFormData(tmpForm);
  };

  const handleYearChange = (value) => {
    setFormData({ ...formData, date_year: value });
  };
  const handleSearch = () => {
    loadYtdSalesData(formData);
  };

  const loadYtdSalesData = async (form) => {
    setYtdSalesData({
      loading: true,
      data: [],
      totalRow: 0,
    });
    try {
      let res = await requestYtdSalesData(form);
      const { code, result, total_rows } = res.data.response;
      if (code === "00000") {
        let flat_array = [];
        _.each(result, (row) => {
          let tmp = {
            type_name: row.type_name,
            id: row.id,
          };

          _.each(row.sales, function (month) {
            tmp[month.month] = month.total_sales;
          });
          flat_array.push(tmp);
        });
        setYtdSalesData({
          loading: false,
          data: flat_array,
          totalRow: total_rows,
        });
      }
    } catch (error) {
      toast.error("Error Loading Data");
    }
  };

  const reportType = [
    {
      label: "Sales Per Channel",
      value: "per_channel",
    },

    {
      label: "Sales Per Segment",
      value: "per_segment",
    },
  ];

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };

    loadYtdSalesData(temp);
  };

  return (
    <YtdSalesLedgerReportScreen
      formData={formData}
      handleChange={handleChange}
      handleYearChange={handleYearChange}
      handleSearch={handleSearch}
      ytdSalesData={ytdSalesData.data}
      loading={ytdSalesData.loading}
      totalRow={ytdSalesData.totalRow}
      selectedTab={"ytd_sales_ledger"}
      handleSelectedTab={handleSelectedTab}
      reportType={reportType}
      handleDataTableChange={handleDataTableChange}
    />
  );
};

export default YtdSales;
