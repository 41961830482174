import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";

$(function () {
  $(this).on("click", ".side-selector-arrow", function () {
    let sideSelector = $(this).closest(".side-selector");

    if (sideSelector.hasClass("open")) {
      sideSelector.removeClass("open");
      $(".side-selector-overlay").remove();
    } else {
      sideSelector.addClass("open");

      // add overlay on the body
      $("body").append(`<div class="side-selector-overlay"></div>`);
    }
  });

  $(this).on("click", ".side-selector-overlay", function () {
    $(".side-selector").removeClass("open");
    $(this).remove();
  });

  //accept only integer
  $(this).on("paste keypress", ".form-input-integer", function (evt) {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  });

  // $(".modal-content").resizable({
  //   //alsoResize: ".modal-dialog",
  //   minHeight: 3000,
  //   minWidth: 3000,
  // });

  // $("#myModal").on("show.bs.modal", function () {
  //   $(this).find(".modal-body").css({
  //     "max-height": "100%",
  //   });
  // });
});
