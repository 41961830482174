import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getRequestApproved } from "../../../api/purchaseRequest";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { requestDataColumn } from "../components/pendingDataColumn";
import SearchRequest from "../components/SearchProduct";

const ApprovedRequestScreen = () => {

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    loadData()
  }, []);
 
  const loadData = (data) => {

    setRows([]);
    setLoader(true);

    getRequestApproved({
      status: 1,
      search: data,
    }).then((res) => {
      let { code, result } = res.data.response;

      if(code === "00000") {
        setRows(result);
        setLoader(false);
      }
    })
  }

  const handleSearchSubmit = () => {
    loadData(search)
  }

  const handleSearchClear = () => {
    setSearch("");
    loadData()
  }

  return (
    <>
      <Row>
        <Col sm="12 mb-3">
          {/*  */}
          <SearchRequest
            search={search}
            handleSearch={(e) => setSearch(e.target.value)} 
            handleSearchSubmit={handleSearchSubmit}
            handleSearchClear={handleSearchClear}
            />
        </Col>
        {/*  */}
        <Col>
          <PrimaryDataTable
            loading={loader}
            disableSelectionOnClick
            getRowId={(row) => row.pr_hdr_id}
            rows={rows}
            columns={requestDataColumn}
          />
        </Col>
      </Row>
    </>
  )
}

export default ApprovedRequestScreen;