import { useEffect, useState } from 'react';
import { requestData } from './components/data';
import Swal from 'sweetalert2';
import {
  approvePurchaseRequest,
  cancelPurchaseRequest,
  createPurchaseRequest,
  declinePurchaseRequest,
  getRequestDetails,
  updatePurchaseRequest,
} from '../../api/purchaseRequest';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useNavActive from '../../components/Navbar/useNavActive';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const PurchaseRequestCreate = ({ id }) => {
  useNavActive(['purchasing', 'purchase_request']);
  const [data, setData] = useState(requestData);
  const history = useHistory();

  const { profileData } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (id) loadData(id);
  }, [id]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      prepared_by: profileData.username,
      department: profileData.department_name,
    }));
  }, [profileData]);

  const loadData = (id) => {
    getRequestDetails({
      pr_hdr_id: id,
    }).then((res) => {
      let { code, result } = res.data.response;

      if (code === '00000') {
        let collectData = {
          pr_hdr_id: result.pr_hdr_id,
          pr_no: result.pr_no,
          pr_date: result.pr_date,
          supplier: result?.get_vendor?.vendor_id || '',
          estimated_delivery_date: result.estimated_delivery_date,
          payment_term: result.payment_term,
          grand_total: result.grand_total,
          generate_type: result.generate_type || '',
          particulars: result.particulars || '',
          term_po: result.term_po,
          term_transit: result.term_transit,
          term_dr: result.term_dr,
          percent_down_payment: result.term_po,
          percent_intransit: result.term_transit,
          percent_upon_delivery: result.term_dr,
          pdc_days_remaining: result.pdc_days_remaining || '',
          status: result.status,
          department: result?.get_user?.get_department?.department_name || '',
          prepared_by: result?.get_user?.employee_name || '',
          prepared_date: result.created_at,
          approved_by: result?.get_user_approved?.employee_name,
          approved_date: result.approved_date || '',
          cancelled_by: result?.get_user_cancelled?.employee_name,
          cancelled_date: result.cancelled_date || '',
          declined_by: result?.get_user_declined?.employee_name,
          declined_date: result.declined_date || '',
          attachments: [],
          old_attachments: result.get_attachments.map((row) => ({
            path: row.document_info.raw_path,
            name: row.document_info.file_name,
          })),
          details: result.get_details.map((row) => ({
            ...row,
            id: row.product_id,
            pr_hdr_id: result.pr_hdr_id,
            product_id: row.product_id,
            product_code: row?.product_details.product_code,
            product_name: row?.product_details.product_name,
            quantity: row?.product_details.quantity,
            cost_vat: row?.product_details.cost_vat,
            cost: row?.product_details.cost,
            unit_price: row?.product_details.cost,
            requested_qty: row.requested_qty,
            stock_level: row?.product_details.stock_level,
            supplier_lead_time: row?.product_details.supplier_lead_time,
            unit: row?.product_details.unit,
            get_supplier: row?.product_details.get_supplier,
            product_segment: row?.product_details.product_segment,
            status: result.status,
            is_selected: 1,
          })),
        };

        console.log('collectData', collectData);

        setData({
          ...data,
          ...collectData,
        });
      }
    });
  };

  const handleChange = (e) => {
    let detailsList = data.details;

    if (e.target.name === 'supplier') {
      if (data.status === '') {
        // detailsList = [];
      }
    }

    console.log('handleCganeg', {
      ...data,
      [e.target.name]: e.target.value,
      details: detailsList,
    });

    setData({
      ...data,
      [e.target.name]: e.target.value,
      details: detailsList,
    });
  };

  const handleChangeDetails = (e, rowData) => {
    setData({
      ...data,
      details: data.details.map((row) => {
        if (rowData.id === row.id) {
          return {
            ...row,
            [e.target.name]: parseInt(e.target.value || 0),
          };
        }

        return row;
      }),
    });
  };

  const handleFileChange = (event) => {
    setData({ ...data, attachments: [...event.target.files] });
  };

  const handleProductDropdownDoubleClick = () => {};

  const handleShowRequestDetails = (id) => {
    getRequestDetails({
      pr_hdr_id: id,
    }).then((res) => {
      let { code, result } = res.data.response;

      if (code === '00000') {
        setData(result);
      }
    });
  };

  const handleSubmit = () => {
    // Validate Inoutted Requested Quantity
    let error = [];

    if (data.estimated_delivery_date === '') {
      error.push('Requested Delivery Date field is required.');
    }

    if (data.details.length === 0) {
      error.push('Details is required.');
    }

    let line = 1;

    data.details.forEach((row) => {
      if (parseFloat(row.requested_qty) < parseFloat(row.quantity)) {
        error.push(
          'Requested quantity should <b>NOT</b> be less than MOQ. Line: ' +
            line++,
        );
      }
    });

    if (error.length > 0) {
      Swal.fire({
        icon: 'error',
        html: error.join('<br />'),
        allowOutsideClick: false,
      });

      return false;
    }

    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to submit PR?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Please wait saving form ...');

        const detailsParse = data.details.map((row) => {
          let term_arr = [
            `${parseFloat(row.percent_down_payment) || 0}% Upon PO`,
            `${parseFloat(row.percent_upon_delivery) || 0}% Upon Delivery`,
            `${parseFloat(row.percent_intransit) || 0}% In Transit`,
          ];

          if (row?.product_pdc_payment_term.length) {
            _.each(row?.product_pdc_payment_term, (pt) => {
              term_arr.push(
                `${parseFloat(pt.percent_amount) || 0}% ${
                  pt.days_remaining
                } days PDC`,
              );
            });
          }

          return {
            product_id: row.product_id,
            requested_qty: row.requested_qty,
            unit_price: row.cost,
            total_amount: parseFloat(
              parseFloat(row.requested_qty) * parseFloat(row.cost),
            ).toFixed(2),
            lead_time: row.supplier_lead_time,
            payment_term: term_arr.join(' '),
          };
        });

        let reduceData = {
          ...data,
          status: 0,
          term_po: data.percent_down_payment,
          term_dr: data.percent_intransit,
          term_transit: data.percent_upon_delivery,
          grand_total: data.details.reduce((total, row) => {
            return total + parseFloat(row.requested_qty) * parseFloat(row.cost);
          }, 0),
          details: detailsParse,
          payment_term:
            (data.term_po || 0) +
            '% Upon PO ' +
            (data.term_dr || 0) +
            '% upon Delivery ' +
            (data.term_transit || 0) +
            '% Intransit',
        };

        createPurchaseRequest(reduceData).then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            Swal.fire({
              icon: 'success',
              title: result,
              allowOutsideClick: false,
            }).then((result) => {
              history.push('/purchasing/purchaseRequest/approval');
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result) ? result.join('<br />') : result,
            });
          }

          toast.dismiss();
        });
      }
    });
  };

  const handleUpdate = () => {
    // Validate Inoutted Requested Quantity
    let error = [];

    if (data.estimated_delivery_date === '') {
      error.push('Requested Delivery Date field is required.');
    }

    if (data.details.length === 0) {
      error.push('Details is required.');
    }

    let line = 1;

    data.details.forEach((row) => {
      if (parseInt(row.requested_qty) < parseInt(row.quantity)) {
        error.push(
          'Requested quantity should <b>NOT</b> be less than MOQ. Line: ' +
            line++,
        );
      }
    });

    if (error.length > 0) {
      Swal.fire({
        icon: 'error',
        html: error.join('<br />'),
        allowOutsideClick: false,
      });

      return false;
    }

    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to update PR?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Please wait saving form ...');

        updatePurchaseRequest({
          ...data,
          grand_total: data.details.reduce((total, row) => {
            return total + parseFloat(row.requested_qty) * parseFloat(row.cost);
          }, 0),
        }).then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            Swal.fire({
              icon: 'success',
              title: result,
              allowOutsideClick: false,
            }).then(() => {
              if (parseInt(data.status) === 2) {
                history.push('/purchasing/purchaseRequest/edit');
              } else {
                history.push('/purchasing/purchaseRequest/approval');
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result) ? result.join('<br />') : result,
            });
          }

          toast.dismiss();
        });
      }
    });
  };

  const handleApprove = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to approve PR?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Please wait saving form ...');

        approvePurchaseRequest({
          pr_hdr_id: id,
        }).then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            Swal.fire({
              icon: 'success',
              title: result,
              allowOutsideClick: false,
            }).then(() => {
              history.push('/purchasing/purchaseRequest/approved');
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result) ? result.join('<br />') : result,
            });
          }

          toast.dismiss();
        });
      }
    });
  };

  const handleDecline = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to decline PR?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Please wait saving form ...');

        declinePurchaseRequest({
          pr_hdr_id: id,
        }).then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            Swal.fire({
              icon: 'success',
              title: result,
              allowOutsideClick: false,
            }).then(() => {
              history.push('/purchasing/purchaseRequest/edit');
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result) ? result.join('<br />') : result,
            });
          }

          toast.dismiss();
        });
      }
    });
  };

  const handleCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to cancel PR?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Please wait saving form ...');

        cancelPurchaseRequest({
          pr_hdr_id: id,
        }).then((res) => {
          let { code, result } = res.data.response;

          if (code === '00000') {
            Swal.fire({
              icon: 'success',
              title: result,
              allowOutsideClick: false,
            }).then(() => {
              history.push('/purchasing/purchaseRequest/cancelled');
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: Array.isArray(result) ? result.join('<br />') : result,
            });
          }

          toast.dismiss();
        });
      }
    });
  };

  const handleFetchProductList = (products) => {
    // if (products.length > 0) {
    let productData = products[0];

    setData({
      ...data,
      details: products,
      percent_down_payment:
        productData?.percent_down_payment || data.percent_down_payment,
      percent_intransit:
        productData?.percent_upon_delivery || data.percent_upon_delivery,
      percent_upon_delivery:
        productData?.percent_intransit || data.percent_intransit,
      pdc_days_remaining:
        productData?.product_pdc_payment_term || data.product_pdc_payment_term,
    });
    // };

    console.log('fetchProduct', products);
  };

  return {
    data,
    handleChange,
    handleSubmit,
    handleProductDropdownDoubleClick,
    handleFileChange,
    handleUpdate,
    handleApprove,
    handleDecline,
    handleCancel,
    handleChangeDetails,
    handleShowRequestDetails,
    handleFetchProductList,
  };
};

export default PurchaseRequestCreate;
