import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import GeneralLedgerLists from '../../Ledgers/components/Lists/GeneralLedgerLists';

const AccountCodeModal = ({ show, handleClose, data }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>General Ledger Entries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GeneralLedgerLists form={data} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AccountCodeModal;
