import { useState } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { getTransactionType } from "../../../../api/ledgers";

const TransactionTypeAsyncSelect = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getTransactionType({ id: inputValue }).then((res) => {
        let { code, result } = res.data.response;
        if (code === "00000") {
          let data = result.map((row) => {
            console.log(row);
            return {
              value: row.id,
              label: row.type,
            };
          });

          data = _.uniqBy(data, function (e) {
            return e.value;
          });

          setOptions(data);
          resolve(data);
        } else {
          resolve([]);
        }
      });
    });

  return (
    <AsyncSelect
      classNamePrefix={"bk-select"}
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      onChange={onChange}
    />
  );
};

export default TransactionTypeAsyncSelect;
