import { lazy } from "react";

const DealsAndDiscountsRegular = lazy(() =>
  import("../pages/DealsAndDiscountsRegular")
);
const CreateDealsAndDiscountsRegular = lazy(() =>
  import("../pages/DealsAndDiscountsRegular/CreateDealsAndDiscounts")
);

const CustomerDealsAndDiscounts = lazy(() =>
  import("../pages/DealsAndDiscounts/CustomerDealsAndDiscounts")
);
const CreateDealsAndDiscounts = lazy(() =>
  import("../pages/DealsAndDiscounts/CreateDealsAndDiscounts")
);
const UpdateDealsAndDiscounts = lazy(() =>
  import("../pages/DealsAndDiscounts/UpdateDealsAndDiscounts")
);

const dealsAndDiscountRoute = {
  public: [],
  auth: [
    {
      name: "customers_deals_and_discounts_regular",
      path: "/customers/deals-and-discounts/regular",
      component: DealsAndDiscountsRegular,
    },
    {
      name: "customers_create_deals_and_discounts_regular",
      path: "/customers/create/deals-and-discounts/regular",
      component: CreateDealsAndDiscountsRegular,
    },
    {
      name: "customers_deals_and_discounts",
      path: "/customers/deals-and-discounts",
      component: CustomerDealsAndDiscounts,
    },
    {
      name: "customers_create_deals_and_discounts",
      path: "/customers/create/deals-and-discounts",
      component: CreateDealsAndDiscounts,
    },
    {
      name: "customers_update_deals_and_discounts",
      path: "/customers/update/deals-and-discounts/:customer_discount_batch_hdr_id",
      component: UpdateDealsAndDiscounts,
    },
  ],
};

export default dealsAndDiscountRoute;
