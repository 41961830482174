import { ProgressBar } from "react-bootstrap";

const UnderConstructionScreen = () => {
  document.body.classList.add("under-construction");

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "90vh" }}
    >
      <div className="uc-box d-flex align-items-center justify-content-center">
        <div>
          <img src="/Stacked Version.png" alt="Bell Kenz" />
          <div className="uc-title">New website coming soon</div>
          <ProgressBar animated now={80} />
        </div>
      </div>
    </div>
  );
};

export default UnderConstructionScreen;
