import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

//screen
import CrossLoginScreen from "./Screens/CrossLoginScreen";

//reducer
import { authUser } from "../../redux/modules/auth";

//api
import { crossLogin } from "../../api/login";

const CrossLogin = () => {
  const dispatch = useDispatch();

  const { euid, token } = useParams(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  console.log({ euid, token });

  useEffect(() => authenticateLogin(), []);

  const authenticateLogin = async () => {
    setLoading(true);
    try {
      let res = await crossLogin(euid, token);
      setLoading(false);

      const { code, result } = res.data.response;
      console.log({ code, result });

      if (code !== "00006") {
        setIsAuthenticated(false);
        redirectTo("/");
        return;
      }

      setIsAuthenticated(true);

      let tmpUser = result.user;
      let roles = result.user.roles.map(({ role }) => role);

      if (!result.permissions.length) roles.push("aaf1");

      dispatch(
        authUser({
          accessToken: result.access_token,
          user: { ...tmpUser, roles },
          permissions: [...result.permissions],
        })
      );

      redirectTo("/dashboard");
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const redirectTo = (url) => {
    console.log("wahei");
    setTimeout(() => (window.location.href = url), 3000);
  };

  return (
    <CrossLoginScreen loading={loading} isAuthenticated={isAuthenticated} />
  );
};

export default CrossLogin;
