import makeApiRequest from './makeApiCall';

export const getModeOfPayment = async () => {
  return await makeApiRequest(`/payments/mode`, 'GET', null);
};

export const getPaymentTerm = async () => {
  return makeApiRequest(`/payments/term`, 'GET', null);
};
export const getStockingTerm = async () => {
  return makeApiRequest(`/initial_stocking/term`, 'GET', null);
};
export const getCourier = async () => {
  return makeApiRequest(`/couriers`, 'GET', null);
};

export const getProductsData = async (data, files = null) => {
  return await makeApiRequest(`/products`, `GET`, null, data, files);
};

export const getCustomerData = async (data, files = null, customerCode) => {
  return await makeApiRequest(
    `/customer/${customerCode}`,
    `GET`,
    null,
    data,
    files,
  );
};

export const deleteDiscountProduct = async (data, files = null) => {
  return await makeApiRequest(
    `/customerdiscount/delete`,
    `DELETE`,
    null,
    data,
    files,
  );
};

export const updateCustomer = async (data, withFiles) => {
  return makeApiRequest(
    `customer/update?_method=put`,
    'POST',
    null,
    data,
    withFiles,
  );
};

export const getCustomersList = async (data) => {
  return await makeApiRequest(`/customers`, 'GET', null, data);
};

export const exportCustomersList = async (data) => {
  return await makeApiRequest(`/customers/list/export`, 'GET', null, data);
};

export const getCustomerByCode = async (code) => {
  return await makeApiRequest(`/customer/` + code, 'GET', null);
};

export const getCustomerCollections = async (id) => {
  return await makeApiRequest(
    `/collections/collection_details/` + id,
    'POST',
    null,
    id,
    false,
  );
};

export const getCustomerSoaReportPdf = async (data) => {
  return await makeApiRequest('/soa/generate/report', 'POST', null, data);
};

export const getInprogressCustomerAgingList = async (data) => {
  return await makeApiRequest('/soa/generated-report-lists', 'GET', null, data);
};

export const getSoaPreview = async (data) => {
  return await makeApiRequest(
    '/soa/generated-report-preview/' + data,
    'GET',
    null,
    null,
  );
};

export const getSubBranch = async (code) => {
  return await makeApiRequest(
    '/soa/customer-sub-branches/' + code,
    'GET',
    null,
    code,
  );
};

export const getCustomerConfig = async (code) => {
  return await makeApiRequest('/soa/customer-config', 'GET', null, code);
};

export const getHospitalUsers = async () => {
  return await makeApiRequest(`/hospital-users`, 'GET', null);
};

export const getCustomerByConfig = async (data) => {
  return await makeApiRequest(
    '/soa/filter-customer-by-config/' + data,
    'GET',
    null,
    null,
  );
};

export const getSoaInProgress = async (data) => {
  return await makeApiRequest('/soa/show-in-progress', 'GET', null, data);
};

export const getCustomerType = async () => {
  return await makeApiRequest(`/list/customer-type`, 'GET');
};
