import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import PrimaryDataTable from '../PrimaryDataTable';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { hasPermission, PermissionsGate } from '../PermissionsGate';

import { transactionDetails, onProcessColumn, tab_location } from './const';

import CustomerTransactionModal from './CustomerTransactionModal';
import CustomerTransactionReasonModal from './CustomerTransactionReasonModal';

import {
  getSrpfNotYetReturnedLists,
  srpfExport,
  getSRPFDeclinedReason,
  getSRPF,
} from '../../api/srpf';

const NotYetReceivedSRPF = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // Modal
  const [showTransaction, setShowTransaction] = useState(false);
  const [showTransactionReason, setShowTransactionReason] = useState(false);
  // Data
  const [transactionReasonData, setTransactionReasonData] = useState([]);
  const [onProcessSRPFDetails, setOnProcessSRPFDetails] =
    useState(transactionDetails);
  const [notYetReceivedData, setNotYetReceivedData] = useState([]);

  useEffect(() => {
    loadNotYerReceivedData();
  }, []);

  const handleCloseTransactionModal = () => setShowTransaction(false);

  const loadNotYerReceivedData = async (data = []) => {
    setLoading(true);
    getSrpfNotYetReturnedLists(data)
      .then((res) => {
        const { code, result } = res.data.response;

        if (code !== '00000') {
          return toast.error(result);
        }

        const viewSrpfPermission = hasPermission([
          'view_not_yet_returned_srpf_list',
        ]);

        let arr = result?.map((row) => ({ ...row, viewSrpfPermission }));

        setNotYetReceivedData(arr);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const handleShowTransactionModal = useCallback(
    (id) => {
      getSRPF({
        srpf_id: id,
      })
        .then((res) => {
          let { code, result } = res.data.response;
          if (code === '00000') {
            setOnProcessSRPFDetails({
              ...onProcessSRPFDetails,
              ...{ ...result, documents_new: [] },
            });
            setShowTransaction(true);
          }
        })
        .catch((err) => {});
    },
    [onProcessSRPFDetails],
  );

  const handleTabLocation = (key) => tab_location[key];

  const handleShowReason = useCallback((srpf_no) => {
    setShowTransactionReason(true);
    getSRPFDeclinedReason({
      srpf_no: srpf_no,
    }).then((res) => {
      let { code, result } = res.data.response;
      if (code === '00000') {
        setTransactionReasonData(result);
      }
    });
  }, []);

  const notYetReceivedSRPFDataCol = useMemo(
    () =>
      onProcessColumn({
        handleShowTransactionModal,
        handleShowReason,
      }),
    [handleShowTransactionModal, handleShowReason],
  );

  const handleExport = (e) => {
    toast.loading('Exporting Data...');
    srpfExport()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          window.open(result, '_blank').focus();
          toast.dismiss();
        }
      })
      .catch((err) => {
        toast.loading(
          'Something went wrong while exporting, Please try again.',
        );
      });
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
          >
            <Tabs
              className="default-tab"
              activeKey="not_yet_received"
              onSelect={(k, event) => {
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab eventKey="add_new" title="Add New"></Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab eventKey="onprocess" title="On-Process SRPF"></Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab eventKey="approval" title="For Approval"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="approved" title="Approved SRPF"></Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab eventKey="declined" title="Declined SRPF"></Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="not_yet_received"
                  title="Not yet Received"
                >
                  <div className="mt-2 mb-2">
                    <div className="col-md-12">
                      <div className="text-right d-none">
                        <Dropdown className="d-inline-block mb-3">
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            Export
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleExport}>
                              Download as XLSX
                            </Dropdown.Item>
                          </Dropdown.Menu>{' '}
                        </Dropdown>
                      </div>
                      <PrimaryDataTable
                        loading={loading}
                        rows={notYetReceivedData}
                        columns={notYetReceivedSRPFDataCol}
                        getRowId={(row) => row.srpf_id}
                      />
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>
      {/*  */}
      <CustomerTransactionModal
        srpfDetails={onProcessSRPFDetails}
        showTransactionModal={showTransaction}
        handleCloseTransactionModal={handleCloseTransactionModal}
        showApprovedButton={true}
        showDeclinedButton={false}
        showSplitButton={false}
        attachmentButton={true}
      />
    </Fragment>
  );
};

export default NotYetReceivedSRPF;
