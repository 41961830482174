import moment from "moment";

export const productLedgerColumn = [
  {
    field: "transaction_date",
    headerName: "Date",
    minWidth: 200,
    type: "date",
    groupable: false,
    headerClassName: "bg-blue-lt",
    hide: false,
    // sortable: false,
    renderCell: ({ row }) =>
      row.date_format
        ? moment(row.transaction_date).format("YYYY-MM-DD")
        : null,
  },

  {
    field: "batch_no",
    headerName: "Batch No.",
    minWidth: 230,
    headerClassName: "bg-blue-lt",
    hide: false,
  },
  {
    field: "references",
    headerName: "Reference No.",
    minWidth: 230,
    flex: 1,
    groupable: false,
    headerClassName: "bg-blue-lt",
    hide: false,
  },
  {
    field: "source",
    headerName: "Source",
    minWidth: 230,
    flex: 1,
    groupable: false,
    headerClassName: "bg-blue-lt",
    hide: false,
  },
  {
    field: "particulars",
    headerName: "Particulars",
    minWidth: 230,
    flex: 1,
    groupable: false,
    headerClassName: "bg-blue-lt",
    hide: false,
  },
  {
    field: "purchases_unit",
    headerName: "Purchase Unit",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.purchases_unit);
    // },
  },
  {
    field: "purchases_cost",
    headerName: "Purchase Cost",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.purchases_cost);
    // },
  },
  {
    field: "purchases_total",
    headerName: "Purchase Total",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.purchases_total);
    // },
  },

  {
    field: "sold_unit",
    headerName: "Sold Unit",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-red-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.purchases_total);
    // },
  },
  {
    field: "sold_cost",
    headerName: "Sold Cost",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-red-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.sold_cost);
    // },
  },

  {
    field: "sold_total",
    headerName: "Sold Total",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-red-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.sold_total);
    // },
  },

  {
    field: "inventory_transfer_unit",
    headerName: "Inventory Transfer Unit",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-yellow-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.inventory_transfer_unit);
    // },
  },

  {
    field: "inventory_transfer_cost",
    headerName: "Inventory Transfer Cost",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-yellow-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.inventory_transfer_cost);
    // },
  },

  {
    field: "inventory_transfer_total",
    headerName: "Inventory Transfer Total",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-yellow-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.inventory_transfer_total);
    // },
  },
  {
    field: "adjustment_unit",
    headerName: "Adjustment Unit",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-purple-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.adjustment_unit);
    // },
  },

  {
    field: "adjustment_cost",
    headerName: "Adjustment Cost",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-purple-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.adjustment_cost);
    // },
  },

  {
    field: "adjustment_total",
    headerName: "Adjustment Total",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-purple-lt",
    hide: false,
    // renderCell: (data) => {
    //   return numberFormatCurrency(data.row.adjustment_total);
    // },
  },

  {
    field: "beg_end_unit",
    headerName: "Unit",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
    hide: false,
  },
  {
    field: "beg_end_cost",
    headerName: "Cost",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
  },
  {
    field: "beg_end_total",
    headerName: "Total",
    minWidth: 230,
    type: "number",
    flex: 1,
    groupable: false,
    headerClassName: "bg-teal-lt",
    hide: false,
  },
];
