import React, { useState, useEffect, Fragment, useRef } from "react";
import useForm from "../../useForm";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import axiosInstance from "../axiosInstance";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";

/* For Data Grid */
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
/* End Of data grid */

/* Icons */
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrimaryDataTable from "../PrimaryDataTable";

/* End Of Icons */
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(0),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */

const DocumentManagement = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [documentsData, setDocumentsData] = useState([]);
  const [breadcrumbData, setBreadCrumb] = useState([
    <li className="breadcrumb-item">
      <a href="#">/</a>
    </li>,
  ]);

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = documentsData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  /* End of Search */

  const [folderData, setFolderData] = useState([]);

  const [folderdetails, setFolderChange] = useState({
    folder_name: "",
    parent_id: "",
  });

  const onInputChange = (e) => {
    setFolderChange({ ...folderdetails, [e.target.name]: e.target.value });
  };

  const handleEditDocumentButton = (data) => {
    const id = data.row.erp_uploads_id;
    history.push(`/preview/${id}`);
  };

  const columns = [
    {
      field: "file_name",
      headerName: "Filename",
      flex: 0,
      minWidth: 160,
      renderHeader: () => <label title="Filename">{"Filename"}</label>,
    },
    {
      field: "folder_name",
      headerName: "Folder",
      flex: 0,
      minWidth: 150,
      renderHeader: () => <label title="Folder">{"Folder"}</label>,
    },
    {
      field: "file_source",
      headerName: "Source",
      flex: 0,
      minWidth: 150,
      renderHeader: () => <label title="Source">{"Source"}</label>,
    },
    {
      field: "physical_filing",
      headerName: "Physical Filing",
      flex: 0,
      minWidth: 250,
      renderHeader: () => (
        <label title="Physical Filing">{"Physical Filing"}</label>
      ),
    },
    {
      field: "physically_received_by",
      headerName: "Physically Received By",
      flex: 0,
      minWidth: 250,
      renderHeader: () => (
        <label title="Physically Received By">{"Physically Received By"}</label>
      ),
    },
    {
      field: "last_released_to",
      headerName: "Last Released To",
      flex: 0,
      minWidth: 220,
      renderHeader: () => (
        <label title="Last Released To">{"Last Released To"}</label>
      ),
    },
    {
      field: "updated_at",
      headerName: "Latest Activity",
      flex: 0,
      minWidth: 190,
      renderHeader: () => (
        <label title="Latest Activity">{"Latest Activity"}</label>
      ),
    },
    {
      field: "Edit/View",
      headerName: "Actions",
      sortable: false,
      flex: 0.1,
      filterable: false,
      minWidth: 120,
      renderHeader: () => <label title="Action">{"Action"}</label>,
      renderCell: (documentsData) => {
        return (
          <>
            <Link
              className="usersAction"
              id={"Edit_" + documentsData.id}
              onClick={() => handleEditDocumentButton(documentsData)}
            >
              <Tooltip title="View/Edit">
                <EditIcon />
              </Tooltip>
            </Link>

            <Link
              className="usersAction"
              id={"DL_" + documentsData.id}
              onClick={() => handleEditDocumentButton(documentsData)}
            >
              <Tooltip title="Download">
                <GetAppIcon />
              </Tooltip>
            </Link>

            <Link
              className="usersAction"
              id={"DEL_" + documentsData.id}
              onClick={() => handleDeleteFile(documentsData.id)}
            >
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            </Link>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let rev = true;

    const getFoldersList = async () => {
      const res = await axiosInstance.get(`/file-system/custom/file-explorer`, {
        method: "GET",
      });
      if (rev) {
        //console.log(res.data.response.result[0]);
        setFolderData(res.data.response.result[0]);
      }
    };

    getFoldersList();
    return () => {
      rev = false;
    };
  }, []);

  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();

    const { nickname } = nodeData;

    setFolderChange({ parent_id: nickname });
    axios
      .all([
        axiosInstance.get(`/file-system/folder/` + nickname + `/documents`, {
          method: "GET",
        }),
        axiosInstance.get(`/file-system/folder/path/` + nickname, {
          method: "GET",
        }),
      ])
      .then(
        axios.spread((data1, data2) => {
          setDocumentsData(data1.data.response.result);
          setFilteredData(data1.data.response.result);

          setBreadCrumb([]);

          var links = data2.data.response.result.split("/");
          links.map((link) => {
            setBreadCrumb((breadcrumbData) => [
              ...breadcrumbData,
              <li className="breadcrumb-item">
                <a href="#">{link == "" ? "/" : link}</a>
              </li>,
            ]);
          });
        })
      );
  };

  const [targetfolderData, setTargetFolder] = useState([]);

  const onNameClickFileMove = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();

    const { nickname } = nodeData;
    const { name } = nodeData;

    setTargetFolder({ nickname: nickname, name: name });
  };

  const submitHandlerMovetoFolder = (e) => {
    e.preventDefault();
    selectedFilesData.map((fileid) => {
      axiosInstance
        .put("/file-system/document/move/folder", {
          document_id: fileid,
          folder_id: targetfolderData.nickname,
        })
        .then((res) =>
          res.data.response.code === "00004"
            ? Swal.fire({
                icon: "success",
                text: res.data.response.result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              })
            : Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.data.response.result,
              })
        );
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/file-system/folder/create", folderdetails, {
        method: "POST",
      })
      .then((res) =>
        res.data.response.code === "00003"
          ? Swal.fire({
              icon: "success",
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            })
      );
  };

  const [valuesfileupload, handleChangeFileUpload] = useForm({
    folder_id: "",
    attachment: "",
    file_name: "",
    physical_filing: "",
    physically_received_by: "",
    last_released_to: "",
    latest_activity: "",
  });

  const submitHandlerFileUpload = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    axiosInstance.post("/file-system/document/create", data).then((res) =>
      res.data.response.code === "00003"
        ? Swal.fire({
            icon: "success",
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        : Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.response.result,
          })
    );
  };

  const [selectedFilesData, setSelectedFiles] = useState([]);

  const handleDeleteFile = (ids) => {
    if (ids) {
      axiosInstance
        .delete("/file-system/document/delete", { data: { document_id: ids } })
        .then((res) =>
          res.data.response.code === "00006"
            ? Swal.fire({
                icon: "success",
                text: res.data.response.result,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              })
            : Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.data.response.result,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              })
        );
    } else {
      selectedFilesData.map((fileid) => {
        axiosInstance
          .delete("/file-system/document/delete", {
            data: { document_id: fileid },
          })
          .then((res) =>
            res.data.response.code === "00006"
              ? Swal.fire({
                  icon: "success",
                  text: res.data.response.result,
                  showConfirmButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                })
              : Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res.data.response.result,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                })
          );
      });
    }
  };

  const [selectedFilesDownloadData, setSelectedFilesDownload] = useState([]);

  const handleDownloadFile = (ids) => {
    const downloaddata = new FormData();

    selectedFilesData.map((fileid) => {
      downloaddata.append("document_id", fileid);
    });

    axiosInstance
      .post("/file-system/document/delete", downloaddata)
      .then((res) =>
        res.data.response.code === "00003"
          ? Swal.fire({
              icon: "success",
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
      );
  };

  const onTreeStateChange = (state, event) => {};

  //Add Folder
  const [showaddfolder, setShowAddFolder] = useState(false);
  const handleCloseAddFolder = () => setShowAddFolder(false);
  const handleShowAddFolder = () => setShowAddFolder(true);

  //Upload file
  const [showuploadfile, setShowUploadFile] = useState(false);
  const handleCloseUploadFile = () => setShowUploadFile(false);
  const handleShowUploadFile = () => setShowUploadFile(true);

  //Move to folder
  const [showmovetofolder, setShowMovetoFolder] = useState(false);
  const handleCloseMovetoFolder = () => setShowMovetoFolder(false);
  const handleShowMovetoFolder = () => setShowMovetoFolder(true);

  const [pageSize, setPageSize] = useState(50); // for page purposes
  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };
  const wrapColumns = useRef(columns);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "updated_at",
      sort: "asc",
    },
  ]);

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">Document Management System</h2>
              </div>
              <div className="col-auto ms-auto d-print-none"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-2">
          {/* <div className="row">
            <div className="col-md-6 mt-2 mb-2">
              <h1>Document Management System</h1>
            </div>
          </div> */}
          <div className="row row-cards">
            <div className="col-sm-12 col-lg-2">
              <FolderTree
                data={folderData}
                onChange={onTreeStateChange}
                showCheckbox={false}
                onNameClick={onNameClick}
                readOnly
              />
            </div>

            <div className="col-sm-12 col-lg-10">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ol
                    className="breadcrumb breadcrumb-arrows"
                    style={{ backgroundColor: "#FFF" }}
                    aria-label="breadcrumbs"
                  >
                    {breadcrumbData}
                  </ol>
                </div>
                <div className="text-right col-md-6 col-sm-12">
                  <div className="form-selectgroup form-selectgroup-pills">
                    <label className="form-selectgroup-item">
                      <input
                        type="radio"
                        name="name"
                        value="HTML"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon me-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                          <polyline points="7 11 12 16 17 11" />
                          <line x1="12" y1="4" x2="12" y2="16" />
                        </svg>
                        Download
                      </span>
                    </label>
                    <label
                      className="form-selectgroup-item"
                      onClick={handleDeleteFile}
                    >
                      <input
                        type="radio"
                        name="name"
                        value="CSS"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon me-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="4" y1="7" x2="20" y2="7" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                        Delete
                      </span>
                    </label>
                    <label
                      className="form-selectgroup-item"
                      onClick={handleShowMovetoFolder}
                    >
                      <input
                        type="radio"
                        name="name"
                        value="PHP"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon me-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="3" y="4" width="18" height="4" rx="2" />
                          <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                          <line x1="10" y1="12" x2="14" y2="12" />
                        </svg>
                        Move to Folder
                      </span>
                    </label>
                    <label
                      className="form-selectgroup-item"
                      onClick={handleShowAddFolder}
                    >
                      <input
                        type="radio"
                        name="name"
                        value="JavaScript"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon me-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                          <line x1="12" y1="10" x2="12" y2="16" />
                          <line x1="9" y1="13" x2="15" y2="13" />
                        </svg>
                        Add Folder
                      </span>
                    </label>

                    <label
                      className="form-selectgroup-item"
                      onClick={handleShowUploadFile}
                    >
                      <input
                        type="radio"
                        name="name"
                        value="JavaScript"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon me-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="12" y1="11" x2="12" y2="17" />
                          <line x1="9" y1="14" x2="15" y2="14" />
                        </svg>
                        Upload File
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <PrimaryDataTable
                rows={filteredData}
                columns={wrapColumns.current}
                getRowId={(row) => row.document_id}
                density="compact"
                disableColumnFilter
                rowCount={filteredData.length}
                mode="server"
                //reload={reloadTable}
                //onChange={handleOnDataTableChange}
                //loading={loading}
                components={{ Toolbar: QuickSearchToolbar }}
              />
              {/* <DataGrid
                autoHeight
                rows={filteredData}
                columns={wrapColumns.current}
                sortingOrder={["desc", "asc"]}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                getRowId={(row) => row.document_id}
                pageSize={pageSize}
                disableColumnResize={true}
                checkboxSelection
                disableSelectionOnClick
                hideFooterSelectedRowCount
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[25, 50, 75, 100]}
                density="compact"
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                  },
                }}
                onSelectionModelChange={(itm) => {
                  setSelectedFiles(itm);
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showaddfolder}
        onHide={handleCloseAddFolder}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Folder</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicFolderName">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                onChange={onInputChange}
                name="folder_name"
                placeholder="Enter new folder name"
                required
              />
              <Form.Control
                type="hidden"
                name="parent_id"
                placeholder="Parent ID"
                required
                value={folderdetails.parent_id}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddFolder}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create Folder
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showuploadfile}
        onHide={handleCloseUploadFile}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Form id="createfile" onSubmit={submitHandlerFileUpload}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formfile_name">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="file_name"
                placeholder="Enter file name"
                required
              />

              <Form.Control
                type="hidden"
                name="folder_id"
                placeholder="Parent ID"
                required
                value={folderdetails.parent_id}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formfile_source">
              <Form.Label>File Source</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="file_source"
                placeholder="Enter file source"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formphysical_filing">
              <Form.Label>Physical Filing</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="physical_filing"
                placeholder="Enter physical filing"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formphysically_received_by">
              <Form.Label>Physically Received by</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="physically_received_by"
                placeholder="Enter physically received by"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formlast_released_to">
              <Form.Label>Last Released to</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="last_released_to"
                placeholder="Enter last released to"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formlatest_activity">
              <Form.Label>Latest Activity</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeFileUpload}
                name="latest_activity"
                placeholder="Enter latest activity"
                required
              />
            </Form.Group>

            <Form.Group className="position-relative mb-3">
              <Form.Label>Attachment</Form.Label>
              <Form.Control type="file" required name="attachment" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUploadFile}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Upload File
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showmovetofolder}
        onHide={handleCloseMovetoFolder}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Move to Folder</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandlerMovetoFolder}>
          <Modal.Body>
            <FolderTree
              data={folderData}
              showCheckbox={false}
              onNameClick={onNameClickFileMove}
              readOnly
            />
            <br></br>
            <Form.Group className="mb-3" controlId="targetfolder_name">
              <Form.Label>Move to this folder</Form.Label>
              <Form.Control
                type="text"
                name="targetfoldername"
                placeholder="Target Folder"
                readOnly
                value={targetfolderData.name}
              />
              <Form.Control
                type="hidden"
                name="id"
                readOnly
                value={targetfolderData.nickname}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMovetoFolder}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Move File
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default DocumentManagement;
