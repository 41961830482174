import { lazy } from "react";

const CostCenter = lazy(() =>
  import("../pages/CostCenter/Screens/CostCenterScreen")
);

const costCenterRoute = {
  auth: [
    {
      name: "cost_center",
      path: "/finance-and-accounting/cost-center",
      component: CostCenter,
    },
  ],
};

export default costCenterRoute;
