const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
const CLEAR_MODAL_CONTENT = "CLEAR_MODAL_CONTENT";

export function setModalContent({ ...props }) {
  return {
    type: SET_MODAL_CONTENT,
    ...props,
  };
}

export function clearModalContent() {
  return {
    type: CLEAR_MODAL_CONTENT,
  };
}

const initialState = {
  title: null,
  modalContent: null,
  className: "",
  size: "md",
  onToggle: null,
  onSubmit: null,
};

export default function modalEvent(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_CONTENT:
      return {
        ...state,
        ...action,
      };
    case CLEAR_MODAL_CONTENT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
