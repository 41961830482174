import { Fragment, useState, useEffect } from "react";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import PrimaryDataTable from "../../../../components/PrimaryDataTable";

import { stockReturnsColumns } from "./const";

import { getCustomerStockReturn } from "../../../../api/collections";

const StockReturns = ({ customer_code }) => {
  const [srlFiles, setSrlFiles] = useState([]);

  const { openLightbox } = useLightbox();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(customer_code);
  }, [customer_code]);

  useEffect(() => {
    if (srlFiles.length) openLightbox(0);
  }, [srlFiles]);

  const loadData = (customerCode) => {
    setLoading(true);
    setData([]);
    getCustomerStockReturn(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;
      if (code === "00000")
        setData(
          result.map((row, index) => ({
            ...row,
            id: customerCode + "_" + index,
          }))
        );
    });

    return false;
  };

  const handleShowFile = (files) => {
    const sources = files.map((row) => ({
      src: row.document_info.processed_path,
    }));

    console.log({ sources });
    setSrlFiles(sources);

    return false;
  };

  const columns = stockReturnsColumns({ handleShowFile });

  return (
    <Fragment>
      <PrimaryDataTable
        key={customer_code}
        rows={data}
        columns={columns}
        disableColumnSelector={true}
        checkboxSelection
        disableSelectionOnClick
        hasGridToolbar
        loading={loading}
      />
      <SRLWrapper elements={srlFiles} key={0} />
    </Fragment>
  );
};

export default StockReturns;
