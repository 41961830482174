import IncomeStatement from "../pages/IncomeStatement/IncomeStatement";

const incomeStatementRoute = {
  public: [],
  auth: [
    {
      name: "income_statement",
      path: "/finance-and-accounting/income-statement",
      component: IncomeStatement,
    },
  ],
};

export default incomeStatementRoute;
