import { React, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  getApprovedData,
  getApprover,
  getDeclinedData,
  getListOfApprovals,
  setStatus,
} from "../../../api/treasury";
import ApproverScreen from "../Screens/ApproverScreen";
import useNavActive from "../../../components/Navbar/useNavActive";

const Approver = () => {
  useNavActive([
    "credit_and_collections",
    "cc_treasury",
    "treasury_approval_list",
  ]);

  const [forApprovalData, setApprovalData] = useState({
    data: [],
    loading: false,
  });

  const [approvedData, setApprovedData] = useState({
    data: [],
    loading: false,
  });

  const [declinedData, setDeclinedData] = useState({
    data: [],
    loading: false,
  });

  const [detailModal, setDetailModal] = useState({
    toggle: false,
    data: null,
    id: null,
    sending: false,
  });
  const [form, setForm] = useState({ id: 0, status: 0 });

  useEffect(() => {
    loadForApprovalData();
    loadApprovedData();
    loadDeclinedData();
  }, []);

  const loadForApprovalData = async (params) => {
    setApprovalData({ ...forApprovalData, loading: true });
    try {
      const res = await getApprover(params);
      const { code, result } = res.data.response;

      if (code !== "00000") {
        setApprovalData({ ...forApprovalData, loading: false });
        return;
      }

      setApprovalData({
        ...forApprovalData,
        loading: false,
        data: result,
      });
    } catch (error) {
      setApprovalData({ ...forApprovalData, loading: false });
      toast.error("Unable to load For Approval Data");
    }
  };

  const loadApprovedData = async (params) => {
    setApprovedData({ ...approvedData, loading: true });
    try {
      const res = await getApprovedData(params);
      const { code, result } = res.data.response;

      if (code !== "00000") {
        setApprovedData({ ...approvedData, loading: false });
        return;
      }
      setApprovedData({
        ...approvedData,
        loading: false,
        data: result,
      });
    } catch (error) {
      setApprovedData({ ...approvedData, loading: false });
      toast.error("Unable to load Approved Data");
    }
  };

  const loadDeclinedData = async (params) => {
    setDeclinedData({ ...declinedData, loading: true });
    try {
      const res = await getDeclinedData(params);
      const { code, result } = res.data.response;

      if (code !== "00000") {
        setDeclinedData({ ...declinedData, loading: false });
        return;
      }
      setDeclinedData({
        ...declinedData,
        loading: false,
        data: result,
      });
    } catch (error) {
      setDeclinedData({ ...declinedData, loading: false });
      toast.error("Unable to load Declined Data");
    }
  };

  const handleCloseModal = () => {
    setDetailModal({ toggle: false, data: null, id: null });
  };

  const handleDetailFormModal = ({ detail_id }) => {
    toast.loading("Please wait ...");
    getListOfApprovals(detail_id).then((res) => {
      toast.dismiss();
      const { code, result } = res.data.response;
      if (code === "00000")
        setDetailModal({ toggle: true, data: result, id: result.detail_id }); // show modal
      setForm({ ...form, id: result.detail_id });
    });

    return false;
  };

  const handleStatus = (status) => {
    let params = {
      id: form.id,
      status: status,
    };

    if (status === 1) {
      Swal.fire({
        title: "Are you sure, You want to approve this?",
        showDenyButton: true,
        confirmButtonText: "Approve",
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatus(params).then((res) => {
            toast.dismiss();
            const { code, result } = res.data.response;
            if (code === "00000")
              Swal.fire({ icon: "success", title: "Success", text: result });
            loadForApprovalData();
            setDetailModal({ toggle: false, data: null, id: null });
          });
        } else if (result.isDenied) {
          setDetailModal({ toggle: false, data: null, id: null });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure, You want to decline this?",
        showDenyButton: true,
        confirmButtonText: "Decline",
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatus(params).then((res) => {
            toast.dismiss();
            const { code, result } = res.data.response;
            if (code === "00000")
              Swal.fire({
                icon: "success",
                title: "Disapproved",
                text: result,
              });
            loadForApprovalData();
            setDetailModal({ toggle: false, data: null, id: null });
          });
        } else if (result.isDenied) {
          setDetailModal({ toggle: false, data: null, id: null });
        }
      });
    }
  };
  return (
    <ApproverScreen
      forApprovalData={forApprovalData.data}
      forApprovalLoading={forApprovalData.loading}
      approvedData={approvedData.data}
      approvedLoading={approvedData.loading}
      declinedData={declinedData.data}
      declinedLoading={declinedData.loading}
      detailModal={detailModal}
      handleCloseModal={handleCloseModal}
      handleDetailFormModal={handleDetailFormModal}
      handleStatus={handleStatus}
    />
  );
};

export default Approver;
