import React, { useState, useEffect, Fragment } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomerTransactionModal from '../Srpf/CustomerTransactionModal';
import CustomerDeclineReasonModal from '../Srpf/CustomerReturnToMakerModal';
import PrimaryDataTable from '../PrimaryDataTable';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { hasPermission, PermissionsGate } from '../PermissionsGate';

import { transactionDetails, onProcessColumn, tab_location } from './const';

import {
  getSRPFApproved,
  getApprovedSRPF,
  srpfExport,
  splitBatch,
  exportPDF,
} from '../../api/srpf';
import ExportModal from './ExportModal';

const ApprovedSRPF = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [approvedSRPFData, setApprovedSRPFData] = useState([]);
  const [approvedSRPFDetails, setApprovedSRPFDetails] =
    useState(transactionDetails);
  const [showExport, setShowExport] = useState({
    date_from: '',
    date_to: '',
    srpf_type: '',
    show: false,
    status: 1,
  });

  useEffect(() => {
    loadApprovedData();
  }, []);

  const loadApprovedData = () => {
    setLoading(true);
    getSRPFApproved()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          const viewSrpfPermission = hasPermission(['view_srpf']);
          setApprovedSRPFData(
            result.map((row) => ({ ...row, viewSrpfPermission })),
          );
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleShowTransactionModal = (id) => {
    getApprovedSRPF({
      srpf_id: id,
    })
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          setApprovedSRPFDetails({
            ...approvedSRPFDetails,
            ...{ ...result, documents_new: [] },
          });
          setShowTransaction(true);
        }
      })
      .catch((err) => {});
  };

  const handleTabLocation = (key) => tab_location[key];

  const handleCloseTransactionModal = () => setShowTransaction(false);

  const handleViewExportModal = () =>
    setShowExport({ ...showExport, show: true });

  const [modalShow, setModalShow] = useState(false);

  const [decline, setReturn] = useState({
    srpf_no: '',
    reason: '',
  });

  const handleExportPDFTransaction = (id, location) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to export this transaction?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        toast.loading('Processing request please wait');
        exportPDF({
          srpf_id: id,
          location: location,
        })
          .then((res) => {
            let { code, result } = res.data.response;
            if (code === '00000') {
              window.open(result, '_blank', 'noopener,noreferrer');

              toast.dismiss();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleSplitBatching = (id) => {
    Swal.fire({
      title: 'Return To Maker',
      text: 'Are you sure you want to return this transaction to maker?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalShow(true);
        setReturn({ ...decline, srpf_no: id });
      }
    });
  };

  const handleConfirmReturn = () => {
    splitBatch(decline)
      .then((res) => {
        if (res.data.response.code === '00000') {
          Swal.fire({
            icon: 'success',
            title: 'Submitted successfully!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops Something went Wrong!',
            text: res.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            // if (result.isConfirmed) {
            //   window.location.reload();
            // }
          });
        }
      })
      .catch((err) => {});
  };

  const handleChangeReason = (e) =>
    setReturn({ ...decline, reason: e.target.value });

  const handleExport = (e) => {
    toast.loading('Exporting Data...');
    srpfExport(showExport)
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          window.open(result, '_blank').focus();
          toast.dismiss();
        }
      })
      .catch((err) => {
        toast.loading(
          'Something went wrong while exporting, Please try again.',
        );
      });
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
          >
            <Tabs
              className="default-tab"
              activeKey="approved"
              onSelect={(k, event) => {
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab eventKey="add_new" title="Add New"></Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab eventKey="onprocess" title="On-Process SRPF"></Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab eventKey="approval" title="For Approval"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="approved"
                  title="Approved SRPF"
                >
                  <div className="mt-2 mb-2">
                    <div className="col-md-12">
                      <div className="text-right">
                        <Button
                          type="button"
                          variant="info"
                          onClick={handleViewExportModal}
                          className="mb-2"
                        >
                          Export
                        </Button>
                      </div>
                      <PrimaryDataTable
                        loading={loading}
                        rows={approvedSRPFData}
                        columns={onProcessColumn({
                          handleShowTransactionModal,
                        })}
                        getRowId={(row) => row.srpf_id}
                        columnVisibilityModel={{
                          reason: false,
                        }}
                      />
                    </div>
                  </div>
                </Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab eventKey="declined" title="Declined SRPF"></Tab>
              )}
              {hasPermission(['srpf_returned_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab eventKey="not_yet_received" title="Not yet Received"></Tab>
              )}
              {/* {hasPermission(["srpf_declined_list"]) && (
                <Tab eventKey="declined" title="Approved RTS(WAREHOUSE)"></Tab>
              )} */}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>

      <CustomerTransactionModal
        srpfDetails={approvedSRPFDetails}
        showTransactionModal={showTransaction}
        handleCloseTransactionModal={handleCloseTransactionModal}
        showApprovedButton={false}
        handleApproveTransaction={false}
        showSplitButton={true}
        handleSplitBatching={handleSplitBatching}
        handleExportPDFTransaction={handleExportPDFTransaction}
        showExportPDF={{ status: true, location: 'approved' }}
      />
      {/*  */}
      <CustomerDeclineReasonModal
        show={modalShow}
        setModalShow={setModalShow}
        handleConfirmReturn={handleConfirmReturn}
        handleChange={handleChangeReason}
      />
      {/*  */}
      <ExportModal
        show={showExport}
        setModalShow={() =>
          setShowExport((prevState) => ({ ...prevState, show: false }))
        }
        handleChange={(e) =>
          setShowExport((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
        }
        handleConfirmReturn={handleExport}
      />
    </Fragment>
  );
};

export default ApprovedSRPF;
