import { useState } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";

const PrimaryAsyncSelect = ({
  name,
  value,
  onChange,
  placeholder = "- Select Option -",
  customFunction,
  config = {
    searchField: "id",
    valueField: "id",
    labelField: "name",
  },
  isClearable = true,
  isMulti,
  isDisabled = false,
}) => {
  // console.log({ PrimaryAsyncSelect: 1, rerender: 1 });
  const [options, setOptions] = useState([]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      customFunction({ [config.searchField]: inputValue }).then((res) => {
        let { code, result } = res.data.response;

        if (code === "00000") {
          let data = result.map((row) => {
            return {
              value:
                config.valueField === "object" ? row : row[config.valueField],
              label: row[config.labelField],
            };
          });

          data = _.uniqBy(data, (e) => e.value);

          setOptions(data);
          resolve(data);
        } else {
          resolve([]);
        }
      });
    });

  const renderValue = () => {
    if (value) {
      if (typeof value === "object") {
        return value;
      }

      const tmp = options.find((row) => {
        if (config.valueField === "object") {
          return row.value[name] === value;
        }

        return row.value === value;
      });
      return tmp;
    }
    return placeholder;
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      classNamePrefix={"bk-select"}
      //defaultValue ={{label:value ? value: placeholder}}
      placeholder={placeholder}
      loadOptions={promiseOptions}
      value={renderValue()}
      onChange={(e) => onChange(e === null ? { value: "" } : e)}
      isClearable={isClearable}
      width="100%"
      isMulti={isMulti}
    />
  );
};

export default PrimaryAsyncSelect;
