import { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import Swal from 'sweetalert2';
import UserForm from './UserForm';

import { getUser, updateUser } from '../../../api/users';

const UserEditNewPage = () => {
  const { id } = useParams();

  const history = useHistory();

  const [form, setForm] = useState({
    user_id: '',
    username: '',
    employee_no: '',
    last_name: '',
    first_name: '',
    email: '',
    password: '',
    department_id: '',
    job_id: '',
    contact_no: '',
    signature: '',
    signature_file_path: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files });
  };

  useEffect(() => {
    getUser(id).then((res) => {
      let { code, result } = res.data.response;
      if (code === '00000') {
        setForm({
          ...form,
          user_id: result.user_id,
          username: result.username,
          employee_no: result.employee_no,
          last_name: result.last_name,
          first_name: result.first_name,
          email: result.email,
          department_id: result.department.department_id,
          job_id: result.job.job_id,
          contact_no: result.contact_no,
          signature_file_path: result.signature_file_path,
        });
      }
    });

    // let componentMounted = true;

    // const loadUser = async () => {
    //   const res = await axiosInstance.get(`/users/${id}`, {
    //     method: "GET",
    //   });
    //   if (componentMounted) {
    //     const getData = {
    //       user_id: res.data.response.result.user_id,
    //       username: res.data.response.result.username,
    //       employee_no: res.data.response.result.employee_no,
    //       last_name: res.data.response.result.last_name,
    //       first_name: res.data.response.result.first_name,
    //       email: res.data.response.result.email,
    //       department_id: res.data.response.result.department.department_id,
    //       job_id: res.data.response.result.job.job_id,
    //       contact_no: res.data.response.result.contact_no,
    //     };

    //     setUser(getData);
    //     /* console.log(res.data.response.result.user_id); */
    //   }
    // };

    // const loadDepartments = async () => {
    //   const res = await axiosInstance.get(`/departments`, {
    //     method: "GET",
    //   });
    //   if (componentMounted) {
    //     setDeptHolder(res.data.response.result);
    //   }
    // };

    // const loadJobs = async () => {
    //   const res = await axiosInstance.get(`/jobs`, {
    //     method: "GET",
    //   });
    //   if (componentMounted) {
    //     setJobHolder(res.data.response.result);
    //   }
    // };

    // loadDepartments();
    // loadJobs();
    // loadUser();
    // return () => {
    //   componentMounted = false;
    // };
  }, [id]);

  const submitHandler = (e) => {
    Swal.fire({
      icon: 'info',
      title: 'Are you sure you want to update user info?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#dc3545',
    }).then((result) => {
      if (result.isConfirmed) {
        if (checkEmptyFields()) {
          Swal.fire({
            icon: 'warning',
            title: 'Please fill up required fields.',
          });
        } else {
          updateUser(form)
            .then((res) => {
              let { code, result } = res.data.response;
              if (code !== '00004') {
                Swal.fire({
                  icon: 'error',
                  text: result,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: 'success',
                  text: result,
                  showConfirmButton: true,
                });
                history.push('/user-control');
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.data.response.result,
              });
            });
        }
      }
    });
  };

  const checkEmptyFields = () => {
    let hasEmptyFields = false;

    let excludedFields = ['signature', 'signature_file_path', 'password'];
    Object.keys(form).every((field) => {
      if (form[field] === '' && excludedFields.indexOf(field) === -1) {
        hasEmptyFields = true;
        return false;
      }

      return true;
    });

    return hasEmptyFields;
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <h3>Edit User Page</h3>
            </div>

            <UserForm
              form={form}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              showPassword={false}
            />

            {/* <div className="card-body">
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label>Username</label>

                  <input
                    className="form-control"
                    name="username"
                    id="username"
                    type="text"
                    value={values.username}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>

                <div className="form-group">
                  <label>Employee No.</label>

                  <input
                    className="form-control"
                    name="employee_no"
                    id="employee_no"
                    type="text"
                    value={values.employee_no}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Last Name</label>

                  <input
                    className="form-control"
                    name="last_name"
                    id="last_name"
                    type="text"
                    value={values.last_name}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>First Name</label>

                  <input
                    className="form-control"
                    name="first_name"
                    id="first_name"
                    type="text"
                    value={values.first_name}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>

                  <input
                    className="form-control"
                    name="email"
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Department</label>
                  <select
                    className="form-control"
                    name="department_id"
                    id="department_id"
                    defaultValue={values.department_id}
                    onChange={(e) => onInputChange(e)}
                  >
                    {deptHolder.map((dept) => (
                      <option
                        key={dept.department_id}
                        value={dept.department_id}
                        selected={
                          dept.department_id === values.department_id
                            ? true
                            : false
                        }
                      >
                        {dept.department_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Job</label>
                  <select
                    className="form-control"
                    name="job_id"
                    id="job_id"
                    defaultValue={values.job_id}
                    onChange={(e) => onInputChange(e)}
                  >
                    {jobHolder.map((job) => (
                      <option
                        key={job.job_id}
                        value={job.job_id}
                        selected={job.job_id === values.job_id ? true : false}
                      >
                        {job.job_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Contact Number</label>

                  <input
                    className="form-control"
                    name="contact_no"
                    id="contact_no"
                    type="text"
                    value={values.contact_no}
                    onChange={(e) => onInputChange(e)}
                    maxLength={20}
                    required
                  />
                </div>

                <div className="btn-group mt-2" style={{ float: "right" }}>
                  <button
                    id="save_changes"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Save Changes
                  </button>
                  <Link
                    id="cancel"
                    to="/user-control"
                    className="ml-2 btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>

            <div className="card-footer">
              <div className="text-right">
                <Button 
                  id="add" 
                  variant="success" 
                  type="button"
                  onClick={() => {
                    submitHandler()
                  }}>
                  Save
                </Button>
                <Link
                  id="cancel"
                  to="/user-control"
                  className="ml-2 btn btn-danger"
                >
                  Cancel
                </Link>
              </div>
            </div> */}
            <div className="card-footer">
              <div className="text-right">
                <Button
                  id="add"
                  variant="success"
                  type="button"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Save
                </Button>
                <Link
                  id="cancel"
                  to="/user-control"
                  className="ml-2 btn btn-danger"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserEditNewPage;
