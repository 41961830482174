import React, { useState, useEffect, Fragment } from 'react';
import axiosInstance from '../../../../axiosInstance';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../../PermissionsGate';
import PrimaryInputCurrency from '../../../../PrimaryInputCurrency';
import DropdownCustom from '../billing-shipping/DropdownCustom';
import PrimaryToolTip from '../PrimaryToolTip';
import Select from 'react-select';
import AccountOfficerDropdown from '../../../components/AccountOfficerDropdown';

const agreedChargesCheckBoxes = [
  'Senior Citizen Discount',
  'Distribution Allowance',
  'Store Opening Support',
  'Scanned Data Fee',
];

const taggedOwnedCheckBoxes = [
  'Collection c/o Account',
  'Collection c/o Associate MD',
];

function CustomerInfo({
  customer,
  form,
  handleChange,
  handleChecked,
  handleCheckboxCollections,
  handleFileUpload,
}) {
  let chargesSeniorCitizenDisc = false;
  let chargesDistributionAllowance = false;
  let chargesStoreOpeningSupport = false;
  let chargesScannedDataFee = false;

  let taggedOwnedAccount = false;
  let taggedOwnedAccountMD = false;

  if (Array.isArray(customer.charges_collections)) {
    if (customer.charges_collections.length > 0) {
      for (let charges of customer.charges_collections) {
        if (charges.description.toLowerCase() == 'senior citizen discount')
          // eslint-disable-next-line no-unused-vars
          chargesSeniorCitizenDisc = true;
        if (charges.description.toLowerCase() == 'distribution allowance')
          // eslint-disable-next-line no-unused-vars
          chargesDistributionAllowance = true;
        if (charges.description.toLowerCase() == 'store opening support')
          // eslint-disable-next-line no-unused-vars
          chargesStoreOpeningSupport = true;
        if (charges.description.toLowerCase() == 'scanned data fee')
          // eslint-disable-next-line no-unused-vars
          chargesScannedDataFee = true;
      }
    }

    if (customer.tagged_or_owned_collections.length > 0) {
      for (let taggedOwned of customer.tagged_or_owned_collections) {
        if (taggedOwned.description.toLowerCase() == 'collection c/o account')
          // eslint-disable-next-line no-unused-vars
          taggedOwnedAccount = true;
        if (
          taggedOwned.description.toLowerCase() == 'collection c/o associate md'
        )
          // eslint-disable-next-line no-unused-vars
          taggedOwnedAccountMD = true;
      }
    }
  }
  const [customerTypeHolder, setCustomerTypeHolder] = useState([]);
  const [customerChannelListHolder, setCustomerChannelListHolder] = useState(
    [],
  );
  const [regularNonRegularListHolder, setRegularNonRegularListHolder] =
    useState([]);
  const [vatTypeHolder, setVatTypeHolder] = useState([]);
  const [returnPolicyHolder, setReturnPolicyHolder] = useState([]);
  const [businessTypeHolder, setBusinessTypeHolder] = useState([]);
  const [aoDataHolder, setAODataHolder] = useState([]);

  useEffect(() => {
    let componentMounted = true;

    const loadCustomerType = async () => {
      const res = await axiosInstance.get(`/list/customer-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setCustomerTypeHolder(res.data.response.result);
      }
    };

    const loadCustomerChannelList = async () => {
      const res = await axiosInstance.get(`/list/customer-channel`, {
        method: 'GET',
      });
      if (componentMounted) {
        setCustomerChannelListHolder(res.data.response.result);
      }
    };

    const loadRegularNonRegularList = async () => {
      const res = await axiosInstance.get(
        `/list/customer-regular-non-regular`,
        {
          method: 'GET',
        },
      );
      if (componentMounted) {
        setRegularNonRegularListHolder(res.data.response.result);
      }
    };

    const loadVatType = async () => {
      const res = await axiosInstance.get(`/list/vat-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setVatTypeHolder(res.data.response.result);
      }
    };

    const loadReturnPolicy = async () => {
      const res = await axiosInstance.get(`/list/customer-return-policy`, {
        method: 'GET',
      });
      if (componentMounted) {
        setReturnPolicyHolder(res.data.response.result);
      }
    };

    const loadBusinessType = async () => {
      const res = await axiosInstance.get(`/list/customer-business-type`, {
        method: 'GET',
      });
      if (componentMounted) {
        setBusinessTypeHolder(res.data.response.result);
      }
    };

    const loadAOData = async () => {
      let tmp = [];

      const res = await axiosInstance.get(`/customer/ao/all`, {
        method: 'GET',
      });
      if (componentMounted) {
        const { result, code } = res.data.response;

        if (code === '00000') {
          result.map((row) => {
            tmp.push({
              value: row.ao_id,
              label: row.ao_name,
            });
          });
          setAODataHolder(tmp);
        }
      }
    };

    loadCustomerType();
    loadCustomerChannelList();
    loadRegularNonRegularList();
    loadVatType();
    loadReturnPolicy();
    loadBusinessType();

    loadAOData();
    return () => {
      componentMounted = false;
    };
  }, []);

  const checkCollection = (value, field) => {
    const data = form[field].find(
      (row) => row.description.toLowerCase() === value.toLowerCase(),
    );
    return data ? true : false;
  };

  return (
    <Fragment>
      <div className="col-md-12" id="customer_information">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Customer Account Information</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Customer Account Name
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="customer_name"
                        id="customer_name"
                        value={form.customer_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Customer Account Code
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="customer_account_code"
                        id="customer_account_code"
                        value={form.customer_code}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Customer Type
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={customerTypeHolder}
                        valueField={'customer_type_id'}
                        nameField={'customer_type_desc'}
                        selected={parseInt(form.customer_type) || 0}
                        placeholder={'- Select Customer Type -'}
                        name={'customer_type'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Customer Channel
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={customerChannelListHolder}
                        valueField={'customer_channel_id'}
                        nameField={'channel'}
                        selected={parseInt(form.customer_channel) || 0}
                        placeholder={'- Select Customer Channel -'}
                        name={'customer_channel'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        (Non) / Regular Customer
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={regularNonRegularListHolder}
                        valueField={'customer_regular_nonregular_id'}
                        nameField={'description'}
                        selected={parseInt(form.regular_or_nonregular) || 0}
                        placeholder={'- Select Status of Customer -'}
                        name={'regular_or_nonregular'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm"></label>
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="template"
                        name="si_attachment"
                        onChange={handleFileUpload}
                      />
                      {form.si_attachment !== '' ? (
                        <a
                          href={form.si_attachment}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {form.si_filename !== '' ? (
                            <span style={{ fontSize: '13px', color: 'blue' }}>
                              View File: {form.si_filename}
                            </span>
                          ) : null}
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Tagged / Owned
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="tagged_or_owned"
                          id="tagged_or_owned"
                          checked={form.tagged_or_owned}
                          onChange={handleChecked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm"></label>
                    </div>

                    <div className="col-lg-6">
                      {form.tagged_or_owned === 1 ? (
                        <fieldset className="details-field-set">
                          <legend>
                            <label className="col-form-label col-form-label-sm">
                              {' '}
                              Collection for Tagged/Owned
                            </label>
                          </legend>

                          <div className="form-check">
                            {taggedOwnedCheckBoxes.map((value) => (
                              <Fragment>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="tagged_or_owned_collections"
                                  value={value}
                                  onChange={(e) =>
                                    handleCheckboxCollections({
                                      e,
                                      fieldId: 'customer_tagged_owned_id',
                                    })
                                  }
                                  checked={checkCollection(
                                    value,
                                    'tagged_or_owned_collections',
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  {value}
                                </label>
                              </Fragment>
                            ))}
                          </div>
                        </fieldset>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        AO
                        <span className="requiredField"> *</span>
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <AccountOfficerDropdown
                        selected={form.AO}
                        onChange={handleChange}
                      />
                      {/* <Select
                        options={aoDataHolder}
                        placeholder="- List Of AO -"
                        name="AO"
                        value={
                          form.AO !== ""
                            ? aoDataHolder.find(
                                (row) => row.value === form.AO?.ao_id
                              )
                            : ""
                        }
                        onChange={(e) =>
                          handleChange({
                            target: { name: "AO", value: e.value },
                          })
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm"></label>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="ewt"
                              id="ewt"
                              checked={form.ewt}
                              onChange={handleChecked}
                            />
                            <label className="form-check-label">EWT</label>
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="sc_discount"
                              id="sc_discount"
                              checked={form.sc_discount}
                              onChange={() => console.log('')}
                            />
                            <label className="form-check-label">
                              Senior Citizen Discount
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Vat Type
                      </label>
                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={vatTypeHolder}
                        valueField={'vat_type_id'}
                        nameField={'type'}
                        selected={parseInt(form.vat_type) || 0}
                        placeholder={'- Select Vat Type -'}
                        name={'vat_type'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        PO-Based
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="po_based"
                          id="po_based"
                          checked={form.po_based}
                          onChange={handleChecked}
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                  </div>

                  {form.po_based === 1 ? (
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="col-form-label col-form-label-sm"></label>
                      </div>
                      <div className="col-sm-6 mt-1">
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="po_template"
                          name="po_attachment"
                          onChange={handleFileUpload}
                        />
                        {form.po_attachment !== '' ? (
                          <a
                            href={form.po_attachment}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {form.po_filename !== '' ? (
                              <span style={{ fontSize: '13px', color: 'blue' }}>
                                View File: {form.po_filename}
                              </span>
                            ) : null}
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Credit Limit
                      </label>
                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <PrimaryInputCurrency
                        name="credit_limit"
                        value={form.credit_limit}
                        onValueChange={handleChange}
                        style={{ minWidth: '50%' }}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Minimum Order Required
                      </label>
                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <PrimaryInputCurrency
                        name="min_order_required"
                        value={form.min_order_required}
                        onValueChange={handleChange}
                        style={{ minWidth: '50%' }}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Return Policy
                      </label>
                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={returnPolicyHolder}
                        valueField={'customer_return_policy_id'}
                        nameField={'policy'}
                        selected={parseInt(form.return_policy) || 0}
                        placeholder={'- Select Policy -'}
                        name={'return_policy'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        With Agreed Charges
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="with_agreed_charges"
                          id="with_agreed_charges"
                          checked={form.with_agreed_charges}
                          onChange={handleChecked}
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm"></label>
                    </div>
                    <div className="col-lg-6">
                      {form.with_agreed_charges === 1 ? (
                        <fieldset className="details-field-set">
                          <legend>
                            <label className="col-form-label col-form-label-sm">
                              Charges
                            </label>
                          </legend>
                          <div className="form-check ml-2 mt-3">
                            {agreedChargesCheckBoxes.map((value) => (
                              <Fragment>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="charges_collections"
                                  custom_data="wahehi"
                                  value={value}
                                  onChange={(e) =>
                                    handleCheckboxCollections({
                                      e,
                                      fieldId: 'customer_charges_id',
                                    })
                                  }
                                  checked={checkCollection(
                                    value,
                                    'charges_collections',
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  {value}
                                </label>
                              </Fragment>
                            ))}
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="charges_others_checkbox"
                              id="charges_others_checkbox"
                              checked={form.charges_others_checkbox}
                              onChange={handleChecked}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              Others
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="charges_others_text_field"
                              id="charges_others_text_field"
                              onChange={handleChange}
                              value={form.charges_others_text_field}
                              disabled={!form.charges_others_checkbox}
                            />
                          </div>
                        </fieldset>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="col-md-12" id="customer_information">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Business Information</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        TIN Number
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="tin_no"
                        id="tin_no"
                        value={form.tin_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Business / Trade Name
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="business_trade_name"
                        id="business_trade_name"
                        value={form.business_trade_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Registered Name
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="registered_name"
                        id="registered_name"
                        value={form.registered_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Business Style
                      </label>
                      {form.customer_type != 4 ? (
                        <span className="requiredField"> *</span>
                      ) : null}
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="business_style"
                        id="business_style"
                        value={form.business_style}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Business Type
                      </label>
                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <DropdownCustom
                        data={businessTypeHolder}
                        valueField={'customer_business_type_id'}
                        nameField={'business_type'}
                        selected={parseInt(form.business_type) || 0}
                        placeholder={'- Select Business Type -'}
                        name={'business_type'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Owner Name
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="owner_name"
                        id="owner_name"
                        value={form.owner_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Owner Email
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        name="owner_email"
                        id="owner_email"
                        value={form.owner_email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Owner Contact Number
                      </label>

                      <span className="requiredField"> *</span>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="owner_contact_no"
                        id="owner_contact_no"
                        value={form.owner_contact_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="col-md-12" id="customer_information">
        <div className="row">
          <fieldset className="details-field-set mb-4 mt-0">
            <legend>Booking Configuration</legend>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Allow FG/SC Booking
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sc_booking"
                          id="sc_booking"
                          checked={form.sc_booking}
                          onChange={handleChecked}
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="col-form-label col-form-label-sm">
                        Allow Water for Injection
                      </label>
                      <PrimaryToolTip />
                    </div>

                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="allow_water_injection"
                          id="allow_water_injection"
                          checked={form.allow_water_injection}
                          onChange={handleChecked}
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomerInfo;
