import React from "react";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { zoneCol } from "./ZoneType/const";

const ZoneTypeList = ({ data, handleEdit }) => {
  const columns = zoneCol({
    handleEdit: handleEdit,
  });
  return (
    <div>
      <PrimaryDataTable
        rows={data}
        columns={columns}
        getRowId={(row) => row.zone_id}
        // onChange={handlePerChannelDataTableChange}
        // disableColumnFilter
        // rowCount={totalRow}
        // loading={loading}
        // mode="server"
        // reload={reloadTable}
      />
    </div>
  );
};

export default ZoneTypeList;
