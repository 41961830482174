import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { QueryClientProvider } from 'react-query';
import { LicenseInfo } from '@mui/x-license-pro';

import { useSelector } from 'react-redux';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import PrimaryLoader from './components/PrimaryLoader';

import routeList from './routes';

import { ToastContainer } from 'react-toastify';

import queryClient from './hooks/queryClient';
// component
import HeaderEnvironmentLabel from './components/HeaderEnvironmentLabel';
import ModalHtpass from './components/ModalHtpass';
import MoveToNewWebsiteScreen from './pages/UnderConstruction/Screens/MoveToNewWebsiteScreen';
import NotFoundPage from './pages/Login/NotFoundPage';

const App = () => {
  const { isAuthed } = useSelector(({ auth }) => auth);

  useEffect(() => {
    moment.tz.setDefault('Asia/Manila'); // set timezone used by the server
  }, []);

  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE);

  const renderApp = () => {
    let location_origin = window.location.origin;
    let hostname = 'bellkenzerp.com';
    let port = '4444';

    const url = new URL(location_origin);
    if (url.hostname === hostname && url.port === port) {
      return <MoveToNewWebsiteScreen />;
    }

    return (
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<PrimaryLoader visible="true" />}>
          <Router>
            <HeaderEnvironmentLabel />
            <ModalHtpass />
            <Switch>
              {routeList.public.map((route) => (
                <Route
                  key={route.name}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}

              <SimpleReactLightbox>
                <div className="App">
                  <Navbar />
                  <div className="children" style={{ minHeight: '83vh' }}>
                    <Switch>
                      {routeList.auth.map((route) => (
                        <Route
                          key={route.name}
                          exact
                          path={route.path}
                          render={() => {
                            return isAuthed ? (
                              <route.component />
                            ) : (
                              <Redirect from="*" to="/" />
                            );
                          }}
                        />
                      ))}

                      <Route
                        path="*"
                        exact={true}
                        component={() =>
                          isAuthed ? (
                            <NotFoundPage />
                          ) : (
                            <Redirect from="*" to="/" />
                          )
                        }
                      />
                    </Switch>
                  </div>

                  <Footer />
                  <ToastContainer position="bottom-right" />
                </div>
              </SimpleReactLightbox>

              <Route path="*" exact={true} component={NotFoundPage} />
            </Switch>
          </Router>
        </React.Suspense>
      </QueryClientProvider>
    );
  };

  return renderApp();
};

export default App;
