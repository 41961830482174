import { toast } from 'react-toastify';

export const isValidDate = ({ form }) => {
  if (form === null || form === undefined) return;

  let dateFrom = new Date(form.date_from);
  let dateTo = new Date(form.date_to);

  if (dateFrom.getTime() === dateTo.getTime()) {
    return true;
  } else if (dateFrom.getTime() < dateTo.getTime()) {
    return true;
  } else {
    throw new Error('Please provide a valid date!');
  }
};

export const isColumnRunning = ({ form }) => {
  if (form === null || form === undefined) return;

  return form.account_code ? false : true;
};

export const transformData = ({ result }) => {
  return result.map((row) => {
    return {
      id: row.id,
      journal_no: row.journal_entry,
      transaction_date: row.transaction_date,
      account_code: row.account_code,
      account_name: row.account_name,
      debit: row.debit,
      credit: row.credit,
      running_balance: row.running_balance,
      module: row.module,
      reference: row.reference,
      payee: row.payee,
      md: row.md,
      particulars: row.particulars,
      remarks: row.remarks,
      details: row.details,
      program: row.program,
      cost_center: row.cost_center,
    };
  });
};

export const isValidCode = (code) => {
  if (code !== '00000') {
    throw new Error(
      'Something went wrong while processing your general ledger request!',
    );
  }
};

export const isProcessing = ({ isLoading }) => {
  if (isLoading) {
    return toast.load('Processing your request please wait...');
  }

  return toast.dismiss();
};
