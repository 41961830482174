import Srpf from '../components/Srpf/Srpf';
import OnProcessSRPF from '../components/Srpf/OnProcessSRPF';
import ApprovedSRPF from '../components/Srpf/ApprovedSRPF';
import DeclinedSRPF from '../components/Srpf/DeclinedSRPF';
import ReturnedSRPF from '../components/Srpf/ReturnedSRPF';
import EditSrpf from '../components/Srpf/EditSrpf';
import NotYetReceivedSRPF from '../components/Srpf/NotYetReceivedSRPF';
import ForApproval from '../components/Srpf/ForApproval';

const srpfRoute = {
  public: [],
  auth: [
    {
      name: 'srpf',
      path: '/collection/srpf',
      component: Srpf,
    },
    {
      name: 'srpf',
      path: '/collection/srpf/:customer_code?',
      component: Srpf,
    },
    {
      name: 'srpf',
      path: '/collection/edit/srpf/:srpf_no',
      component: EditSrpf,
    },
    {
      name: 'srpf',
      path: '/collection/OnProcessSRPF',
      component: OnProcessSRPF,
    },
    {
      name: 'srpf',
      path: '/collection/ForApproval',
      component: ForApproval,
    },
    {
      name: 'srpf',
      path: '/collection/ApprovedSRPF',
      component: ApprovedSRPF,
    },
    {
      name: 'srpf',
      path: '/collection/DeclinedSRPF',
      component: DeclinedSRPF,
    },
    {
      name: 'srpf',
      path: '/collection/ReturnedSRPF',
      component: ReturnedSRPF,
    },
    {
      name: 'srpf',
      path: '/collection/NotYetReceivedSRPF',
      component: NotYetReceivedSRPF,
    },
  ],
};

export default srpfRoute;
