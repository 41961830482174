import React, { useState, useEffect, Fragment } from "react";
import useForm from "../../useForm";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import axiosInstance from "../axiosInstance";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

const UpdateProducts = () => {
  const history = useHistory();
  const { product_code } = useParams();
  const [supplierHolder, setSupplierHolder] = useState([]);
  const [prodSegmentHolder, setProdSegmentHolder] = useState([]);
  const [prodGroupHolder, setProdGroupHolder] = useState([]);
  const [checkboxVal, setCheckboxData] = useState([]);
  const [image, setImage] = useState([
    {
      erp_uploads_draft_id: "",
      processed_path: "",
    },
    {
      erp_uploads_draft_id: "",
      processed_path: "",
    },
  ]);
  const [values, setProducts] = useState({
    product_id: "",
    supplier_id: "",
    product_segment_id: "",
    product_sub_segment_id: "",
    product_group_id: "",
    product_sub_group_id: "",
    inventory_id: 1,
    product_name: "",
    brand_name: "",
    packaging: "",
    qty_per_pack: "",
    stock_level: 20000,
    shelf_life: "",
    quantity: "",
    safety_stock_level: "",
    forecast: "",
    supplier_lead_time: "",
    active: "",
    vat_exemption: 0,
    cost: "",
    cost_vat: 0,
    selling_price: "",
    selling_price_vat: 0,
    cpr_no: "",
    cpr_expiry: "",
    temperature: "",
    humidity: "",
    small_size: "",
    small_size_image: "",
    big_size: "",
    big_size_image: "",
    weight_net: "",
    weight_gross: "",
    is_submit: 0,
  });

  const onInputChange = (e) => {
    setProducts({ ...values, [e.target.name]: e.target.value });
  };

  const appendDraftValue = (e) => {
    const is_submit = e.target.getAttribute("data-submit");
    values.is_submit = Number(is_submit);
  };
  const appendSubmitValue = (e) => {
    const is_submit = e.target.getAttribute("data-submit");
    values.is_submit = Number(is_submit);
  };

  /* Use hooks to load the specific roles */
  useEffect(() => {
    let componentMounted = true;

    const loadUserRole = async () => {
      const res = await axiosInstance.get(`/products/code/${product_code}`, {
        method: "GET",
      });

      if (componentMounted) {
        setProducts(res.data.response.result);
        setImage(res.data.response.result.with_image);
      }
    };

    loadUserRole();
    loadSupplier();
    loadProdSegment();
    loadProdGroup();

    return () => {
      componentMounted = false;
    };
  }, [product_code]);

  const submitHandler = (e) => {
    e.preventDefault();
    values.vat_exemption = checkboxVal == 1 ? 1 : 0;
    const data = new FormData(e.target);
    data.append("product_id", values.product_id);
    data.append("is_submit", values.is_submit);
    data.append("vat_exemption", values.vat_exemption);
    data.append("product_code", product_code);
    data.append("category", "UPDATED");

    axiosInstance.post(`/products/submit/updated`, data).then((response) =>
      response.data.response.code == "00013" ||
      response.data.response.code == "00012"
        ? Swal.fire({
            icon: "success",
            title: "Sucess",
            text: response.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/products`);
            }
          })
        : Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.response.result,
          })
    );
  };

  const loadSupplier = () => {
    let supplier = [
      {
        supplier_id: 1,
        supplier_name: "TEST SUPPLIER",
      },
      {
        supplier_id: 2,
        supplier_name: "SAMPLE SUPPLIER",
      },
    ];

    setSupplierHolder(supplier);
  };

  const loadProdSegment = () => {
    let product_segment = [
      {
        segment_id: 1,
        segment_name: "ORAL",
      },
      {
        segment_id: 2,
        segment_name: "ORAL 1",
      },
    ];

    setProdSegmentHolder(product_segment);
  };

  const activeVatExemption = (e) => {
    values.vat_exemption = e.target.checked == true ? 1 : 0;
    setCheckboxData(values.vat_exemption);

    if (e.target.checked == false) {
      values.cost_vat = 0;
      values.selling_price_vat = 0;
    }

    if (e.target.checked == true) {
      const costVat = values.cost / (values.cost + values.cost * 0.12);
      values.cost_vat = costVat;

      const sellingVat =
        values.selling_price /
        (values.selling_price + values.selling_price * 0.12);
      values.selling_price_vat = sellingVat;
    }
  };

  const loadProdGroup = () => {
    let product_group = [
      {
        group_id: 1,
        group_name: "GROUP 1",
      },
      {
        group_id: 2,
        group_name: "GROUP 2",
      },
    ];

    setProdGroupHolder(product_group);
  };

  const computeCostVat = () => {
    const costVat = values.cost / (values.cost + values.cost * 0.12);
    values.cost_vat = checkboxVal == 1 ? costVat : 0;
  };

  const computeSellingVat = () => {
    const sellingVat =
      values.selling_price /
      (values.selling_price + values.selling_price * 0.12);
    values.selling_price_vat = checkboxVal == 1 ? sellingVat : 0;
  };

  return (
    <Fragment>
      <div className="col-md-8 offset-1">
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
          <div className="card" style={{ width: "1000px" }}>
            <div className="card-header">
              <h1>Update Products</h1>
            </div>
            <div className="card-body">
              <form onSubmit={submitHandler}>
                <div className="row col-md-12">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Products Name: </label>
                      <input
                        className="form-control"
                        name="product_name"
                        type="text"
                        placeholder="Product Name"
                        value={values.product_name}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Brand Name: </label>
                      <input
                        className="form-control"
                        name="brand_name"
                        type="text"
                        placeholder="Brand Name"
                        value={values.brand_name}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Supplier: </label>
                      <select
                        className="form-control"
                        name="supplier_id"
                        defaultValue={values.supplier_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option className="form-control">
                          CHOOSE SUPPLIER
                        </option>
                        {supplierHolder.map((supplier) => (
                          <option
                            value={supplier.supplier_id}
                            key={supplier.supplier_id}
                            selected={
                              supplier.supplier_id == values.supplier_id
                                ? "selected"
                                : ""
                            }
                          >
                            {supplier.supplier_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Product Segment: </label>
                      <select
                        className="form-control"
                        name="product_segment_id"
                        defaultValue={values.product_segment_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option className="form-control">
                          CHOOSE PRODUCT SEGMENT
                        </option>
                        {prodSegmentHolder.map((prodsegment) => (
                          <option
                            value={prodsegment.segment_id}
                            key={prodsegment.segment_id}
                            selected={
                              prodsegment.segment_id ==
                              values.product_segment_id
                                ? "selected"
                                : ""
                            }
                          >
                            {prodsegment.segment_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Product Sub Segment: </label>
                      <select
                        className="form-control"
                        name="product_sub_segment_id"
                        defaultValue={values.product_sub_segment_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option className="form-control">
                          CHOOSE PRODUCT SUB SEGMENT
                        </option>
                        {prodSegmentHolder.map((prodsegment) => (
                          <option
                            value={prodsegment.segment_id}
                            key={prodsegment.segment_id}
                            selected={
                              prodsegment.segment_id ==
                              values.product_sub_segment_id
                                ? "selected"
                                : ""
                            }
                          >
                            {prodsegment.segment_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Product Group: </label>
                      <select
                        className="form-control"
                        name="product_group_id"
                        defaultValue={values.product_group_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option className="form-control">
                          CHOOSE PRODUCT GROUP
                        </option>
                        {prodGroupHolder.map((prodgroup) => (
                          <option
                            value={prodgroup.group_id}
                            key={prodgroup.group_id}
                            selected={
                              prodgroup.group_id == values.product_group_id
                                ? "selected"
                                : ""
                            }
                          >
                            {prodgroup.group_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Product Sub Group: </label>
                      <select
                        className="form-control"
                        name="product_sub_group_id"
                        defaultValue={values.product_sub_group_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option className="form-control">
                          CHOOSE PRODUCT SUB GROUP
                        </option>
                        {prodGroupHolder.map((prodgroup) => (
                          <option
                            value={prodgroup.group_id}
                            key={prodgroup.group_id}
                            selected={
                              prodgroup.group_id == values.product_sub_group_id
                                ? "selected"
                                : ""
                            }
                          >
                            {prodgroup.group_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Packaging : </label>
                      <input
                        className="form-control"
                        name="packaging"
                        type="text"
                        placeholder="Packaging"
                        value={values.packaging}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Qty per Pack: </label>
                      <input
                        className="form-control"
                        name="qty_per_pack"
                        type="text"
                        placeholder="Qty per Pack"
                        value={values.qty_per_pack}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="text-bold">Stock level: </label>
                      <input
                        className="form-control"
                        name="stock_level"
                        type="text"
                        placeholder="Stock level"
                        value={values.stock_level}
                        onChange={(e) => onInputChange(e)}
                        style={{ backgroundColor: "#e0e0e0" }}
                      />
                    </div>

                    <div className="form-group">
                      <label className="text-bold">Shelf Life: </label>
                      <input
                        className="form-control"
                        name="shelf_life"
                        type="text"
                        placeholder="Shelf Life"
                        value={values.shelf_life}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-bold">MOQ: : </label>
                    </div>
                    <div className="form-group">
                      <label className="text-bold">Quantity: </label>
                      <input
                        className="form-control"
                        name="quantity"
                        type="text"
                        placeholder="Quantity"
                        value={values.quantity}
                        onChange={(e) => onInputChange(e)}
                        style={{ backgroundColor: "#e0e0e0" }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-bold">Safety Stock level: </label>
                      <input
                        className="form-control"
                        name="safety_stock_level"
                        type="text"
                        placeholder="Safety Stock level"
                        value={values.safety_stock_level}
                        onChange={(e) => onInputChange(e)}
                        style={{ backgroundColor: "#e0e0e0" }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-bold">Forecast (Required): </label>
                      <input
                        className="form-control"
                        name="forecast"
                        type="text"
                        placeholder="Forecast"
                        value={values.forecast}
                        onChange={(e) => onInputChange(e)}
                        style={{ backgroundColor: "#e0e0e0" }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-bold">Supplier Lead Time: </label>
                      <input
                        className="form-control"
                        name="supplier_lead_time"
                        type="text"
                        placeholder="Supplier Lead Time"
                        value={values.supplier_lead_time}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <div className="form-group">
                                                <input name="active" type="radio" value={values.active}  onChange={(e) => onInputChange(e)}/> Active
                                            </div>
                                            <div className="form-group">
                                                <input name="active" type="radio" value={values.inactive}  onChange={(e) => onInputChange(e)}/> Inactive
                                            </div> */}
                    <div className="form-group">
                      VAT Exemption:{" "}
                      <input
                        name="vat_exemption"
                        type="checkbox"
                        value={values.vat_exemption}
                        onClick={(e) => activeVatExemption(e)}
                        onChange={(e) => onInputChange(e)}
                        defaultChecked={false}
                      />
                    </div>
                    <div className="row col-md-12">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-bold">Cost: </label>
                          <input
                            className="form-control"
                            name="cost"
                            type="text"
                            placeholder="Cost"
                            value={values.cost}
                            onKeyUp={(e) => computeCostVat(e)}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="text-bold">Selling Price: </label>
                          <input
                            className="form-control"
                            name="selling_price"
                            type="text"
                            placeholder="Selling Price"
                            onKeyUp={(e) => computeSellingVat(e)}
                            value={values.selling_price}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="text-bold">CPR No: </label>
                          <input
                            className="form-control"
                            name="cpr_no"
                            type="text"
                            placeholder="CPR No"
                            value={values.cpr_no}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-bold">Cost (VAT Ex): </label>
                          <input
                            className="form-control"
                            name="cost_vat"
                            type="text"
                            placeholder="Cost (VAT Ex)"
                            value={values.cost_vat}
                            onChange={(e) => onInputChange(e)}
                            style={{ backgroundColor: "#e0e0e0" }}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label className="text-bold">
                            Selling Price (VAT Ex):{" "}
                          </label>
                          <input
                            className="form-control"
                            name="selling_price_vat"
                            type="text"
                            placeholder="Selling Price (VAT Ex)"
                            value={values.selling_price_vat}
                            onChange={(e) => onInputChange(e)}
                            style={{ backgroundColor: "#e0e0e0" }}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label className="text-bold">CPR Expiry: </label>
                          <input
                            className="form-control"
                            name="cpr_expiry"
                            type="date"
                            placeholder="CPR Expiry"
                            value={values.cpr_expiry}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12 mt-5">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-bold">Temperature: </label>
                        </div>
                        <div className="form-group">
                          <label className="text-bold">Humidity: </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="temperature"
                            type="text"
                            placeholder="Temperature"
                            value={values.temperature}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="humidity"
                            type="text"
                            placeholder="Humidity"
                            value={values.humidity}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12 mt-2">
                      <label className="text-bold">Dimension: </label>
                    </div>
                    <div className="row col-md-12 mt-5">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-bold">Small: </label>
                          <input
                            className="form-control"
                            name="small_size"
                            type="text"
                            placeholder="eg. 12x12x12"
                            value={values.small_size}
                            onChange={(e) => onInputChange(e)}
                          />
                          <input
                            className="form-control"
                            name="small_size_image"
                            type="file"
                            value={values.small_size_image}
                            onChange={(e) => onInputChange(e)}
                          />
                          {/* <img src={image[0].processed_path} height="200" width="200"></img> */}
                        </div>
                        <div className="form-group">
                          <label className="text-bold">Big: </label>
                          <input
                            className="form-control"
                            name="big_size"
                            type="text"
                            placeholder="eg. 12x12x12"
                            value={values.big_size}
                            onChange={(e) => onInputChange(e)}
                          />
                          <input
                            className="form-control"
                            name="big_size_image"
                            type="file"
                            value={values.big_size_image}
                            onChange={(e) => onInputChange(e)}
                          />
                          {/* <img src={image[1].processed_path} height="200" width="200"></img> */}
                        </div>
                        <div className="form-group">
                          <label className="text-bold">Weight: </label>
                          <input
                            className="form-control"
                            name="weight_net"
                            type="text"
                            placeholder="Net"
                            value={values.weight_net}
                            onChange={(e) => onInputChange(e)}
                          />
                          <input
                            className="form-control"
                            name="weight_gross"
                            type="text"
                            placeholder="Gross"
                            value={values.weight_gross}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row col-md-12 mt-4">
                      <div className="col-md-6 pt-0 pb-0 pl-0 pr-2">
                        <button
                          type="submit"
                          className="btn btn-warning"
                          style={{ width: "100%" }}
                          data-submit="0"
                          onClick={appendDraftValue}
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div className="col-md-3 pt-0 pb-0 pl-0 pr-2">
                        <button
                          type="submit"
                          className="btn btn-success"
                          style={{ width: "100%" }}
                          data-submit="1"
                          onClick={appendSubmitValue}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-3 p-0">
                        <button
                          type="submit"
                          className="btn btn-danger"
                          style={{ width: "100%" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateProducts;
