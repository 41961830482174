import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import axiosInstance from "../axiosInstance";
import useForm from "../../useForm";
import Swal from "sweetalert2";
import { Tooltip } from "@material-ui/core";
import "../Products/modal.css";
import { toast } from "react-toastify";
import { Button, Table } from "react-bootstrap";
import { Trash, Percent } from "react-bootstrap-icons";
import { Action } from "history";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AddProducts = () => {
  const [showTab, setTab] = useState("tab1");
  const [showTab1, setShowTab1] = useState(true);
  const [showTab2, setShowTab2] = useState(false);
  const [smallImageAdd, setsmallImageAdd] = useState("");
  const [bigImageAdd, setbigImageAdd] = useState("");
  const [supplierHolder, setSupplierHolder] = useState([]);
  const [prodSegmentHolder, setProdSegmentHolder] = useState([]);
  const [prodGroupHolder, setProdGroupHolder] = useState([]);
  const [unitMeasureHolder, setUnitMeasureHolder] = useState([]);
  const [packagingHolder, setPackagingeHolder] = useState([]);
  const [productSubGroup, setProductSubGroup] = useState([]);
  const [productSubSegment, setProductSubSegment] = useState([]);

  const [costVatValue, setCostVatValue] = useState("0.00");
  const [costUnitVatValue, setCostUnitVatValue] = useState("0.00");
  const [sellingVatValue, setSellingVatValue] = useState("0.00");
  const [sellingUnitVatValue, setSellingUnitVatValue] = useState("0.00");

  const [vatIsCheck, setVatIsCheck] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [costBox, setCostBox] = useState(0);
  const [costUnit, setCostUnit] = useState("0.00");
  const [sellingBox, setSellingBox] = useState(0);
  const [sellingUnit, setSellingUnit] = useState("0.00");
  const [qtyPerPack, setQtyperpac] = useState(0);

  const defaultPaymentsField = {
    percent_amount: "",
    days_remaining: "",
  };

  const [showSpecifyOthers, setSpecifyOthers] = useState(false);
  const [values, setValues] = useState({
    supplier_id: "",
    product_segment_id: "",
    product_sub_segment_id: "",
    product_group_id: "",
    product_sub_group_id: "",
    inventory_id: 1,
    product_name: "",
    generic_name: "",
    packaging: "",
    packaging_description: "",
    unit_measure: "",
    qty_per_pack: "",
    stock_level: 0,
    shelf_life: "",
    quantity: "",
    safety_stock_level: "",
    forecast: "",
    supplier_lead_time: "",
    active: 1,
    vat_exemption: 0,
    cost: "0.00",
    cost_vat: "0.00",
    cost_unit: "0.00",
    cost_unit_vat: "0.00",
    selling_price: "0.00",
    selling_price_unit: "0.00",
    selling_price_vat: "0.00",
    selling_price_unit_vat: "0.00",
    cpr_no: "",
    cpr_expiry: "",
    cpr_document: "",
    moq: "",
    min_temperature: "",
    max_temperature: "",
    storage_requirement: "",
    humidity: "",
    small_size: "",
    small_size_image: "",
    big_size: "",
    big_size_image: "",
    weight_net: "",
    weight_gross: "",
    is_submit: "",
    specify_others: "",
    percent_down_payment: "",
    percent_intransit: "",
    percent_upon_delivery: "",
    payments: [],
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [paymentsField, setPaymentsField] = useState([defaultPaymentsField]);

  const handleAddPaymentTerms = () => {
    //console.log(paymentsField);
    setPaymentsField([...paymentsField, defaultPaymentsField]);
  };

  const handleRemovePaymentTerms = (selectedIndex) => {
    let tmp = [
      ...paymentsField.filter((row, index) => {
        return selectedIndex !== index;
      }),
    ];
    setPaymentsField(tmp);
  };

  const handleChangePaymentTerms = (selectedIndex, name, value) => {
    const newPaymentTerms = paymentsField.map((terms, index) => {
      if (index === selectedIndex) {
        return { ...terms, [name]: value };
      }
      return terms;
    });
    setPaymentsField(newPaymentTerms);

    setValues({ ...values, payments: newPaymentTerms });
  };

  const styles = {
    border: {
      border: "1px solid #e7eaef",
    },
    divPadding: {
      padding: "5px",
    },
    fieldset: {
      border: "1px solid #e7eaef",
      padding: "-4px",
    },
    legend: {
      position: "static",
      marginTop: "-20px",
      top: 1,
      fontSize: "13px",
      lineHeight: 1,
      margin: "-9px 0 0" /* half of font-size */,
      background: "#fff",
      color: "#000",
    },

    formGroup: {
      margin: 0,
      paddingTop: "0px",
      paddingLeft: "20px",
      paddingBottom: "0px",
      paddingRight: "0",
    },
  };

  const appendSubmitValue = (e) => {
    const is_submit = e.target.getAttribute("data-submit");
    values.is_submit = is_submit;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    toast.loading("Processing request please wait", {
      autoClose: false,
    });

    console.log(values);
    const data = new FormData(e.target);
    data.append("is_submit", values.is_submit);
    data.append("vat_exemption", values.vat_exemption);
    data.append("category", "CREATED");

    axiosInstance.post(`/products/add`, data, {}).then((response) => {
      toast.dismiss();
      setDisableSubmit(false);
      response.data.response.code === "00013" ||
      response.data.response.code === "00012"
        ? Swal.fire({
            icon: "success",
            title: "Sucess",
            text: response.data.response.result,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        : response.data.response.code === "00001"
        ? Swal.fire({
            icon: "error",
            title: "Invalid Request",
            html: response.data.response.result.join("<br/>"),
          })
        : Swal.fire({
            icon: "error",
            title: "Someting went wrong...",
            text: response.data.response.result,
          });
    });
  };

  useEffect(() => {
    loadSupplier();
    loadProdSegment();
    loadProdGroup();
    loadUnitMasure();
    loadPackaging();
  }, []);

  const loadSupplier = () => {
    axiosInstance.get(`/vendor-db/vendor-dropdown`).then((response) => {
      setSupplierHolder(response.data.response.result);
    });
  };

  const loadProdSegment = () => {
    axiosInstance.get(`/products/segment`).then((response) => {
      setProdSegmentHolder(response.data.response.result);
    });
  };

  const loadProdGroup = () => {
    axiosInstance.get(`/products/group`).then((response) => {
      setProdGroupHolder(response.data.response.result);
    });
  };

  const loadUnitMasure = () => {
    axiosInstance.get(`/products/unit`).then((response) => {
      setUnitMeasureHolder(response.data.response.result);
    });
  };

  const loadPackaging = () => {
    axiosInstance.get(`/products/packaging`).then((response) => {
      setPackagingeHolder(response.data.response.result);
    });
  };

  const smallUplaodImage = (e) => {
    const file = document.getElementById("small_size_image_add").files[0];

    if (file) {
      setsmallImageAdd(URL.createObjectURL(file));
    } else {
      setsmallImageAdd("");
    }
  };

  const bigUplaodImage = (e) => {
    const file = document.getElementById("big_size_image_add").files[0];

    if (file) {
      setbigImageAdd(URL.createObjectURL(file));
    } else {
      setbigImageAdd("");
    }
  };

  const vatCheck = (e) => {
    const isCheck = e.target.checked;

    if (isCheck) {
      values.vat_exemption = 1;
      setVatIsCheck(true);

      const cost_vat_rate =
        parseFloat(values.cost.replace(/,/g, "")).toFixed(2) / 100;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      const cost_unit_vat_rate =
        parseFloat(values.cost_unit.replace(/,/g, "")).toFixed(2) / 100;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      const selling_vat_rate =
        parseFloat(values.selling_price.replace(/,/g, "")).toFixed(2) / 100;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      const selling_unit_vat_rate =
        parseFloat(values.selling_price_unit.replace(/,/g, "")).toFixed(2) /
        100;
      const selling_price_unit_vat = selling_unit_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
      setCostUnitVatValue(values.cost_unit_vat);
      setSellingVatValue(values.selling_price_vat);
      setSellingUnitVatValue(values.selling_price_unit_vat);
    } else {
      values.vat_exemption = 0;
      setVatIsCheck(false);

      const cost_vat_rate =
        parseFloat(values.cost.replace(/,/g, "")).toFixed(2) / 112;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      const cost_unit_vat_rate =
        parseFloat(values.cost_unit.replace(/,/g, "")).toFixed(2) / 112;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      const selling_vat_rate =
        parseFloat(values.selling_price.replace(/,/g, "")).toFixed(2) / 112;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      const selling_unit_vat_rate =
        parseFloat(values.selling_price_unit.replace(/,/g, "")).toFixed(2) /
        112;
      const selling_price_unit_vat = selling_unit_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
      setCostUnitVatValue(values.cost_unit_vat);
      setSellingVatValue(values.selling_price_vat);
      setSellingUnitVatValue(values.selling_price_unit_vat);
    }
  };

  const computeVatCost = (e) => {
    const costPriceFormat = parseFloat(
      e.target.value.replace(/,/g, "")
    ).toFixed(2);

    const pack = qtyPerPack;

    if (vatIsCheck) {
      const cost_vat_rate = costPriceFormat / 100;
      const cost_vat = cost_vat_rate * 100;

      values.cost_vat = cost_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
    } else {
      const cost_vat_rate = costPriceFormat / 112;
      const cost_vat = cost_vat_rate * 100;
      values.cost_vat = cost_vat.toFixed(2);

      setCostVatValue(values.cost_vat);
    }

    if (parseInt(pack) !== 0) {
      let costUnitValue = costPriceFormat / pack;
      values.cost_unit = costUnitValue.toFixed(2);
      setCostUnit(costUnitValue.toFixed(2));
      computeVatCostUnit(costUnitValue.toFixed(2));
    }
    setCostBox(e.target.value);
  };

  const computeUnit = (e) => {
    let cost_unit = parseFloat(costBox);
    let selling_unit = parseFloat(sellingBox);
    let pack = e.target.value;

    let costUnitValue = cost_unit / pack;
    values.cost_unit = costUnitValue.toFixed(2);
    setCostUnit(costUnitValue.toFixed(2));
    computeVatCostUnit(costUnitValue.toFixed(2));

    let sellingUnitValue = selling_unit / pack;
    values.selling_price_unit = sellingUnitValue.toFixed(2);
    setSellingUnit(sellingUnitValue.toFixed(2));
    computeVatSellingUnit(sellingUnitValue.toFixed(2));

    setQtyperpac(e.target.value);
  };

  const computeVatCostUnit = (costUnitValue) => {
    const costPriceFormat = parseFloat(costUnitValue.replace(/,/g, "")).toFixed(
      2
    );

    if (vatIsCheck) {
      const cost_unit_vat_rate = costPriceFormat / 100;
      const cost_unit_vat = cost_unit_vat_rate * 100;

      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      setCostUnitVatValue(values.cost_unit_vat);
    } else {
      const cost_unit_vat_rate = costPriceFormat / 112;
      const cost_unit_vat = cost_unit_vat_rate * 100;
      values.cost_unit_vat = cost_unit_vat.toFixed(2);

      setCostUnitVatValue(values.cost_unit_vat);
    }
  };

  const computeVatSelling = (e) => {
    const sellingPriceFormat = parseFloat(
      e.target.value.replace(/,/g, "")
    ).toFixed(2);
    const pack = qtyPerPack;

    if (vatIsCheck) {
      const selling_vat_rate = sellingPriceFormat / 100;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      setSellingVatValue(values.selling_price_vat);
    } else {
      const selling_vat_rate = sellingPriceFormat / 112;
      const selling_price_vat = selling_vat_rate * 100;

      values.selling_price_vat = selling_price_vat.toFixed(2);

      setSellingVatValue(values.selling_price_vat);
    }

    if (parseInt(pack) !== 0) {
      let sellingUnitValue = sellingPriceFormat / pack;
      values.selling_price_unit = sellingUnitValue.toFixed(2);
      setSellingUnit(sellingUnitValue.toFixed(2));
      computeVatSellingUnit(sellingUnitValue.toFixed(2));
    }

    setSellingBox(sellingPriceFormat);
  };

  const computeVatSellingUnit = (sellingUnitValue) => {
    const sellingUnitPriceFormat = parseFloat(
      sellingUnitValue.replace(/,/g, "")
    ).toFixed(2);
    console.log(vatIsCheck);
    if (vatIsCheck) {
      const selling_vat_rate = sellingUnitPriceFormat / 100;
      const selling_price_unit_vat = selling_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setSellingUnitVatValue(values.selling_price_unit_vat);
    } else {
      const selling_vat_rate = sellingUnitPriceFormat / 112;
      const selling_price_unit_vat = selling_vat_rate * 100;

      values.selling_price_unit_vat = selling_price_unit_vat.toFixed(2);

      setSellingUnitVatValue(values.selling_price_unit_vat);
    }
  };

  const populateSubGroup = (e) => {
    let subgroup = [
      {
        sub_group_id: 1,
        group_name: "sub-group 1",
      },
      {
        sub_group_id: 2,
        group_name: "sub-group 2",
      },
    ];

    setProductSubGroup(subgroup);
  };

  const populateSubSegment = (e) => {
    let subsegment = [
      {
        sub_segment_id: 1,
        segment_name: "sub-segment 1",
      },
      {
        sub_segment_id: 2,
        segment_name: "sub-segment 2",
      },
    ];

    setProductSubSegment(subsegment);
  };

  const specifyOthers = (e) => {
    const packaging = e.target.value;

    if (packaging === "Others") {
      setSpecifyOthers(true);
    } else {
      setSpecifyOthers(false);
    }
  };

  const rowPaymentTerms = (rowData, index) => {
    return (
      <div className="row">
        <div className="col-md-1">
          {index ? (
            <Button
              variant="link"
              onClick={() => handleRemovePaymentTerms(index)}
            >
              <Tooltip title="Delete">
                <Trash />
              </Tooltip>
            </Button>
          ) : null}
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control form-control-sm text-dark"
            name={"payments" + "[" + [index] + "]" + "[percent_amount]"}
            id="percentage_downpayment"
            value={rowData.percent_amount}
            style={{ marginTop: "6px" }}
            onKeyPress={(event) => {
              if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleChangePaymentTerms(index, "percent_amount", e.target.value);
            }}
            placeholder="Enter % PDC"
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control form-control-sm text-dark"
            name={"payments" + "[" + [index] + "]" + "[days_remaining]"}
            id="no_of_days"
            value={rowData.days_remaining}
            style={{ marginTop: "6px" }}
            onKeyPress={(event) => {
              if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleChangePaymentTerms(index, "days_remaining", e.target.value);
            }}
            placeholder="# of Days"
          />
        </div>
        <div className="col-md-3">
          <label
            className="col-sm-12 col-form-label col-form-label-sm"
            style={{ marginTop: "6px" }}
          >
            After Delivery
          </label>
        </div>
      </div>
    );
  };

  const renderPaymentTerms = () => {
    return paymentsField.map((value, index) => rowPaymentTerms(value, index));
  };

  return (
    <Fragment>
      <form onSubmit={submitHandler}>
        <div className="container-fluid">
          <div className="card-tabs mt-2">
            <ul className="nav nav-tabs bg-header">
              <li className="nav-item">
                <a
                  href="#tab-top-1"
                  className={
                    showTab === "tab1" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab1");
                    setShowTab1(true);
                    setShowTab2(false);
                  }}
                >
                  Product Information
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-2"
                  className={
                    showTab === "tab2" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="tab"
                  onClick={() => {
                    setTab("tab2");
                    setShowTab1(false);
                    setShowTab2(true);
                  }}
                >
                  Packaging
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="tab-top-1"
                className={
                  showTab === "tab1"
                    ? "card tab-pane active show"
                    : "card tab-pane"
                }
              >
                <div className="card-body">
                  <div className="col-md-12 row" id="product_information">
                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>
                          Product Information
                        </legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="product_name"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Product Name
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="product_name"
                                id="product_name"
                                defaultValue={values.product_name}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Product Name"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="generic_name"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Generic Name
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="generic_name"
                                id="generic_name"
                                defaultValue={values.generic_name}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Generic Name"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="product_segment"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Product Segment
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <select
                                className="form-control form-control-sm"
                                name="product_segment_id"
                                defaultValue={values.product_segment_id}
                                style={{ marginTop: "6px" }}
                                onChange={(handleChange, populateSubSegment)}
                                novalidate
                              >
                                <option disabled selected>
                                  CHOOSE PRODUCT SEGMENT
                                </option>
                                {prodSegmentHolder.map((prodsegment) => (
                                  <option
                                    value={prodsegment.segment_id}
                                    key={prodsegment.segment_id}
                                  >
                                    {prodsegment.segment_name}
                                  </option>
                                ))}
                              </select>
                              <select
                                className="form-control form-control-sm"
                                name="product_sub_segment_id"
                                defaultValue={values.product_sub_segment_id}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                              >
                                <option disabled selected>
                                  CHOOSE PRODUCT SUB SEGMENT
                                </option>
                                {productSubSegment.map((prodsubsegment) => (
                                  <option
                                    value={prodsubsegment.sub_segment_id}
                                    key={prodsubsegment.sub_segment_id}
                                  >
                                    {prodsubsegment.segment_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="product_segment"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Product Group
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <select
                                className="form-control form-control-sm"
                                name="product_group_id"
                                defaultValue={values.product_group_id}
                                style={{ marginTop: "6px" }}
                                onChange={(handleChange, populateSubGroup)}
                                novalidate
                              >
                                <option disabled selected>
                                  CHOOSE PRODUCT GROUP
                                </option>
                                {prodGroupHolder.map((prodgroup) => (
                                  <option
                                    value={prodgroup.group_id}
                                    key={prodgroup.group_id}
                                  >
                                    {prodgroup.group_name}
                                  </option>
                                ))}
                              </select>
                              <select
                                className="form-control form-control-sm"
                                name="product_sub_group_id"
                                defaultValue={values.product_sub_group_id}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                              >
                                <option disabled selected>
                                  CHOOSE PRODUCT SUB GROUP
                                </option>
                                {productSubGroup.map((prodsubgroup) => (
                                  <option
                                    value={prodsubgroup.sub_group_id}
                                    key={prodsubgroup.sub_group_id}
                                  >
                                    {prodsubgroup.group_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="supplier"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Supplier
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <select
                                className="form-control form-control-sm"
                                name="supplier_id"
                                defaultValue={values.supplier_id}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                              >
                                <option disabled selected>
                                  CHOOSE SUPPLIER
                                </option>
                                {supplierHolder.map((supplier) => (
                                  <option
                                    value={supplier.vendor_id}
                                    key={supplier.vendor_id}
                                  >
                                    {supplier.vendor_code} -{" "}
                                    {supplier.company_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div> */}

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="manufacturer"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Manufacturer
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="manufacturer"
                                id="manufacturer"
                                defaultValue={values.manufacturer}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Manufacturer"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cpr_no"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            CPR Number
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="cpr_no"
                                id="cpr_no"
                                defaultValue={values.cpr_no}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="XXXX-XXXX-XXXX"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cpr_expiry"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            CPR Expiry
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                name="cpr_expiry"
                                id="cpr_expiry"
                                defaultValue={values.cpr_expiry}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cpr_expiry"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            CPR Document
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="file"
                                className="form-control form-control-sm"
                                name="cpr_document"
                                id="cpr_document"
                                defaultValue={values.cpr_document}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </section>

                      <br />
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>
                          Product Payment Terms
                        </legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <div
                            className="col-sm-6 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-12 row">
                              <input
                                type="text"
                                className="form-control form-control-sm text-dark"
                                name="percent_down_payment"
                                id="percent_down_payment"
                                defaultValue={values.percent_down_payment}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                onKeyPress={(event) => {
                                  if (
                                    !/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                placeholder="Enter % Down Payment"
                              />
                            </div>
                          </div>

                          <label className="col-sm-6 col-form-label col-form-label-sm">
                            % Down Payment
                          </label>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <div
                            className="col-sm-6 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-12 row">
                              <input
                                type="text"
                                className="form-control form-control-sm text-dark"
                                name="percent_intransit"
                                id="percent_intransit"
                                defaultValue={values.percent_intransit}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                onKeyPress={(event) => {
                                  if (
                                    !/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                placeholder="Enter % In Transit"
                              />
                            </div>
                          </div>

                          <label className="col-sm-6 col-form-label col-form-label-sm">
                            % In Transit
                          </label>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <div
                            className="col-sm-6 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-12 row">
                              <input
                                type="text"
                                className="form-control form-control-sm text-dark"
                                name="percent_upon_delivery"
                                id="percent_upon_delivery"
                                defaultValue={values.percent_upon_delivery}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                onKeyPress={(event) => {
                                  if (
                                    !/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                placeholder="Enter % Upon Delivery"
                              />
                            </div>
                          </div>

                          <label className="col-sm-6 col-form-label col-form-label-sm">
                            % Upon Delivery
                          </label>
                        </div>
                        <br />
                        {renderPaymentTerms()}
                        <br />
                        <div className="col-sm-12 d-flex justify-content-center mb-2">
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={handleAddPaymentTerms}
                          >
                            Add Another Payment Terms
                          </button>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>Inventory</legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="stock_level"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Stock Level
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="stock_level"
                                id="stock_level"
                                defaultValue={values.stock_level}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="shelf_life"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Shelf Life
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="shelf_life"
                                id="shelf_life"
                                defaultValue={values.shelf_life}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Shelf Life"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-3">Months</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="quantity"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Minimum Order Quantity
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="quantity"
                                id="quantity"
                                defaultValue={values.quantity}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="safety_stock_level"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Safety Stock Level
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="safety_stock_level"
                                d="safety_stock_level"
                                defaultValue={values.safety_stock_level}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="forecast"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Forecast (Required)
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="forecast"
                                id="forecast"
                                defaultValue={values.forecast}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="supplier_lead_time"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Supplier Lead Time
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="supplier_lead_time"
                                id="supplier_lead_time"
                                defaultValue={values.supplier_lead_time}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Supplier Lead Time"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-3">Days</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </section>
                      <br />
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>Price & Cost</legend>
                        <br />

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <p class="text-danger">
                            Note: Fill out{" "}
                            <span className="font-weight-bold">
                              Cost Per Box and Selling Price Per Box
                            </span>{" "}
                            First and{" "}
                            <span className="font-weight-bold">
                              Quantity Per Pack
                            </span>{" "}
                            to compute Per Unit and VAT Ex.
                          </p>
                        </div>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="vat_exemption"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          ></label>
                          <div
                            className="col-sm-8"
                            style={{ marginLeft: "-15px" }}
                          >
                            <input
                              id="vat_exemption"
                              type="checkbox"
                              name="vat_exemption"
                              defaultValue={1}
                              style={{ marginTop: "6px" }}
                              onChange={handleChange}
                              onClick={vatCheck}
                            />{" "}
                            Vat Exemption
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cost"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Cost Per Box
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="cost"
                                id="cost"
                                defaultValue={values.cost}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                onKeyUp={computeVatCost}
                                onKeyPress={(event) => {
                                  if (
                                    !/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                novalidate
                                autoComplete="off"
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cost_vat"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Cost Per Box (Vat Ex){" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="cost_vat"
                                id="cost_vat"
                                value={
                                  isNaN(costVatValue) ? "0.00" : costVatValue
                                }
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cost_unit"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Cost Per Unit
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="cost_unit"
                                id="cost_unit"
                                value={isNaN(costUnit) ? "0.00" : costUnit}
                                style={{ marginTop: "6px" }}
                                novalidate
                                autoComplete="off"
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="cost_vat_unit"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Cost Per Unit (Vat Ex){" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="cost_vat_unit"
                                id="cost_vat_unit"
                                value={
                                  isNaN(costUnitVatValue)
                                    ? "0.00"
                                    : costUnitVatValue
                                }
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="selling_price"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Selling Price Per Box{" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="selling_price"
                                id="selling_price"
                                defaultValue={values.selling_price}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                onKeyUp={computeVatSelling}
                                onKeyPress={(event) => {
                                  if (
                                    !/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                novalidate
                                autoComplete="off"
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="selling_price_vat"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Selling Price Per Box (Vat Ex){" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="selling_price_vat"
                                id="selling_price_vat"
                                value={
                                  isNaN(sellingVatValue)
                                    ? "0.00"
                                    : sellingVatValue
                                }
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="selling_price_unit"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Selling Price Per Unit{" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="selling_price_unit"
                                id="selling_price_unit"
                                value={sellingUnit}
                                style={{ marginTop: "6px" }}
                                novalidate
                                autoComplete="off"
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="selling_price_unit_vat"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Selling Price Per Unit (Vat Ex){" "}
                            <span className="float-right">&#8369;</span>
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="selling_price_unit_vat"
                                id="selling_price_unit_vat"
                                value={
                                  isNaN(sellingUnitVatValue)
                                    ? "0.00"
                                    : sellingUnitVatValue
                                }
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                                readOnly
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tab-top-2"
                className={
                  showTab === "tab2"
                    ? "card tab-pane active show"
                    : "card tab-pane"
                }
              >
                <div className="card-body">
                  <div className="col-md-12 row" id="packaging_information">
                    <div className="text-right">
                      <button
                        type="submit"
                        className="btn btn-success btn-sm"
                        style={{ width: "20%" }}
                        data-submit="2"
                        onClick={appendSubmitValue}
                        disabled={disableSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>Packaging</legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="packaging"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Packaging
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <select
                                className="form-control form-control-sm"
                                name="packaging"
                                defaultValue={values.packaging}
                                style={{ marginTop: "6px" }}
                                onChange={(handleChange, specifyOthers)}
                                novalidate
                              >
                                <option disabled selected>
                                  CHOOSE PACKAGING
                                </option>
                                {packagingHolder.map((packaging) => (
                                  <option
                                    value={packaging.packaging_id}
                                    key={packaging.packaging_id}
                                  >
                                    {packaging.packaging_name}
                                  </option>
                                ))}
                                {/* <option value="Others" key="4">
                                  Others
                                </option> */}
                              </select>
                              {showSpecifyOthers ? (
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="specify_others"
                                  id="specify_others"
                                  defaultValue={values.specify_others}
                                  style={{ marginTop: "6px" }}
                                  onChange={handleChange}
                                  placeholder="Specify if Others"
                                  novalidate
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="packaging_description"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Packaging Description
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="packaging_description"
                                id="packaging_description"
                                defaultValue={values.packaging_description}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Packaging Description"
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="supplier"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Unit of Measure
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-11 row">
                              <select
                                className="form-control form-control-sm"
                                name="unit_measure"
                                defaultValue={values.unit_measure}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                novalidate
                              >
                                <option disabled selected>
                                  CHOOSE UNIT OF MEASURE
                                </option>
                                {unitMeasureHolder.map((unit) => (
                                  <option
                                    value={unit.unit_id}
                                    key={unit.unit_id}
                                  >
                                    {unit.unit_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="qty_per_pack"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Qty per Pack
                          </label>
                          <div
                            className="col-sm-8 row pr-0"
                            style={{ marginTop: "6px" }}
                          >
                            <div className="col-sm-11 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="qty_per_pack"
                                id="qty_per_pack"
                                defaultValue={values.qty_per_pack}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Quantity Per Pack"
                                onKeyUp={computeUnit}
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="small_size"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Size (small)
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="small_size"
                                id="small_size"
                                defaultValue={values.small_size}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Small Size"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-3">mm</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="big_size"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Size (big)
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="big_size"
                                id="big_size"
                                defaultValue={values.big_size}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Big Size"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-3">cm</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="weight_net"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Weight Net
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="weight_net"
                                id="weight_net"
                                defaultValue={values.weight_net}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Weight Net"
                              />
                            </div>
                            <div className="col-sm-3">grams</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="weight_gross"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Weight Gross
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="weight_gross"
                                id="weight_gross"
                                defaultValue={values.weight_gross}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Weight Gross"
                              />
                            </div>
                            <div className="col-sm-3">grams</div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>Handling</legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="temperature"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Temperature
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-4 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="min_temperature"
                                id="min_temperature"
                                defaultValue={values.min_temperature}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Min"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-4 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="max_temperature"
                                id="max_temperature"
                                defaultValue={values.max_temperature}
                                style={{
                                  marginTop: "6px",
                                  marginLeft: "30px",
                                }}
                                onChange={handleChange}
                                placeholder="Max"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-4">
                              <span style={{ paddingLeft: "30px" }}>
                                &#8451;
                              </span>
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="humidity"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Humidity
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="humidity"
                                id="humidity"
                                defaultValue={values.humidity}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Humidity"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-3">
                              <span>%</span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <label
                            for="storage_requirement"
                            className="col-sm-4 col-form-label col-form-label-sm"
                          >
                            Storage Requirement
                          </label>
                          <div className="col-sm-8 row pr-0">
                            <div className="col-sm-8 row">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="storage_requirement"
                                id="storage_requirement"
                                defaultValue={values.storage_requirement}
                                style={{ marginTop: "6px" }}
                                onChange={handleChange}
                                placeholder="Storage Requirement"
                                novalidate
                              />
                            </div>
                            <div className="col-sm-1">
                              <Tooltip
                                title={
                                  <span
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    Indicated in 2303 BIR Certificate of
                                    Registration
                                  </span>
                                }
                              >
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="material-icons"
                                >
                                  help_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <br />
                    <br />
                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>
                          Small Size Image Upload
                        </legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <div className="col-sm-12 row">
                            <input
                              type="file"
                              className="form-control form-control-sm"
                              name="small_size_image"
                              id="small_size_image_add"
                              defaultValue={values.small_size_image}
                              style={{ marginTop: "6px" }}
                              onChange={smallUplaodImage}
                            />
                          </div>
                        </div>
                        {smallImageAdd.length > 0 ? (
                          <center>
                            <img
                              src={smallImageAdd}
                              style={{ padding: "20px" }}
                              alt="small"
                            />
                          </center>
                        ) : (
                          ""
                        )}
                      </section>
                    </div>

                    <div className="col-md-6" style={styles.divPadding}>
                      <section style={styles.fieldset}>
                        <legend style={styles.legend}>
                          Big Size Image Upload
                        </legend>
                        <br />
                        <div
                          className="form-group row"
                          style={styles.formGroup}
                        >
                          <div className="col-sm-12 row">
                            <input
                              type="file"
                              className="form-control form-control-sm"
                              name="big_size_image"
                              id="big_size_image_add"
                              defaultValue={values.big_size_image}
                              style={{ marginTop: "6px" }}
                              onChange={bigUplaodImage}
                            />
                          </div>
                        </div>
                        {bigImageAdd.length > 0 ? (
                          <center>
                            <img
                              src={bigImageAdd}
                              style={{ padding: "20px" }}
                              alt="big"
                            />
                          </center>
                        ) : (
                          ""
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AddProducts;
