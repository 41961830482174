export const customerDataDefault = {
  customer_code: '',
  customer_name: '',
  owner_contact_no: '',
  owner_email: '',
  billing_address: '',
  shipping_address: '',
  business_type: '',
  status: '',
  account_type: '',
};

export const customerTotalDefault = {
  total_outstanding: 0,
  total_pdc: 0,
  total_advances: 0,
  total_returns: 0,
  total_due: 0,
};
