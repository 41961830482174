import { useEffect } from "react";
import { setActiveMenu } from "../../redux/modules/menu";
import { useDispatch } from "react-redux";

const useNavActive = (activeMenus = []) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveMenu(activeMenus));
  }, []);

  return activeMenus;
};

export default useNavActive;
