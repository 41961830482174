import { useEffect, useState } from "react";

import PrimaryDataTable from "../../../../components/PrimaryDataTable";

import { getCustomerSummaryAdvances } from "../../../../api/collections";

// data and consts
import { advancesColumns } from "./const";

const Advances = ({ customer_code }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(customer_code);
  }, [customer_code]);

  const loadData = (customerCode) => {
    if (customerCode === "") return;

    setLoading(true);
    setData([]);
    getCustomerSummaryAdvances(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;
      if (code === "00000") setData(result);
    });

    return false;
  };

  return (
    <PrimaryDataTable
      key={customer_code}
      rows={data}
      columns={advancesColumns}
      disableColumnSelector={true}
      getRowId={(row) => row.advances_id}
      disableSelectionOnClick
      hasGridToolbar
      loading={loading}
    />
  );
};

export default Advances;
