import ViewCustomerCreditAssessmentPublic from "../pages/Guest/ViewCustomerCreditAssessmentPublic";
import UnderConstruction from "../pages/UnderConstruction";

const publicRoute = {
  public: [
    {
      name: "credit_assessment_public",
      path: "/public/credit-assessment/:customer_code",
      component: ViewCustomerCreditAssessmentPublic,
    },
    {
      name: "under_construction",
      path: "/under-construction",
      component: UnderConstruction,
    },
  ],
  auth: [],
};

export default publicRoute;
