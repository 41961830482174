import { Alert } from "react-bootstrap";

const NotFoundPage = () => {
  return (
    <Alert variant="warning" className="m-3">
      Page not found.
    </Alert>
  );
};

export default NotFoundPage;
