import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";

const PrimaryToolTip = ({ message = "Sample Message" }) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip"> {message}</Tooltip>}
    >
      <QuestionCircle style={{ marginLeft: "10px", fontSize: "12px" }} />
    </OverlayTrigger>
  );
};

export default PrimaryToolTip;
