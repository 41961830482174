import React, { Fragment } from "react";
import PrimaryDataTable from "../../../../components/PrimaryDataTable";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { revisionHistoryColumn } from "../RevisionHistoryList/const";

const RevisionHistoryList = ({
  data,
  total,
  formData,
  loading,
  reloadTable,
  handleClearFilterSearch,
  handleFilterChange,
  onChange,
}) => {
  const columns = revisionHistoryColumn;

  return (
    <Fragment>
      <PrimaryDataTable
        rows={data}
        columns={columns}
        getRowId={(row) => row.revision_history_id}
        onChange={onChange}
        loading={loading}
        mode="server"
        reload={reloadTable}
        rowCount={total}
        hideFooterSelectedRowCount
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            value: formData.reference_code,
            onChange: handleFilterChange,
            clearSearch: handleClearFilterSearch,
          },
        }}
      />
    </Fragment>
  );
};

export default RevisionHistoryList;
