import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import WithFunction from './WithFunction';

const DefaultLayout = (props) => {
  return (
    <Container fluid>
      <div className="default-layout-container">
        <div
          className={
            props?.classHeader ? props.classHeader : 'dlc-header-block mt-4'
          }
        >
          <Row className="dlc-header">
            <Col lg="6">
              <h3> {props.title} </h3>{' '}
            </Col>{' '}
            {props.hasOwnProperty('customAddBtn') ||
            props.hasOwnProperty('customDropDown') ? (
              <Col className="text-right">
                <WithFunction
                  customAddBtn={props?.customAddBtn}
                  customDropDown={props?.customDropDown}
                  loading={props?.loading}
                />{' '}
              </Col>
            ) : (
              ''
            )}{' '}
          </Row>{' '}
        </div>{' '}
        <div className="dlc-main-content"> {props.children} </div>{' '}
      </div>{' '}
    </Container>
  );
};

DefaultLayout.propTypes = {
  title: PropTypes.string.isRequired,
  withFunction: PropTypes.bool,
};
export default DefaultLayout;
