import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';
//screen
import IncomeStatementSheetReportScreen from '../Screens/IncomeStatementSheetReportScreen';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';

//api
import {
  getAllIncomeStatement,
  getAccountList,
} from '../../../api/incomeStatement';
import {
  incomeStatementDataCol_month,
  incomeStatementDataCol_quarter,
} from '../const';
import useNavActive from '../../../components/Navbar/useNavActive';
import { numberFormatCurrency } from '../../../utils/helper';

const today = new Date();
const prior = new Date().setDate(today.getDate() - 20);

const currentDate = moment().format('YYYY-MM-DD');

const formDefault = {
  date_from: currentDate,
  date_to: currentDate,
  year: today.getFullYear(),
  period_breakdown: '',
  breakdown: '',
  accounts_from: '0',
  accounts_to: '0',
};

const colsDefault = {
  jan: false,
  feb: false,
  mar: false,
  apr: false,
  may: false,
  jun: false,
  jul: false,
  aug: false,
  sep: false,
  oct: false,
  nov: false,
  dec: false,
  q1: false,
  q2: false,
  q3: false,
  q4: false,
};

const BalanceSheet = () => {
  useNavActive([
    'finance_and_accounting',
    'fna_financial_reports',
    'income_statement',
  ]);
  //declaration
  const [formData, setFormData] = useState(formDefault);
  const [incomeStatementData, setIncomeStatementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [periodBreakdown, setPeriodBreakdown] = useState(
    incomeStatementDataCol_month,
  );
  const [storePeriodType, setStorePeriodType] = useState(
    incomeStatementDataCol_month,
  );
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState(colsDefault);

  //functions
  useEffect(() => loadIncomeStatementData(formData), []);

  const loadIncomeStatementData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setIncomeStatementData([]);
    try {
      let res = await getAllIncomeStatement(form);
      const { code, result } = res.data;

      if (code !== '00000') {
        toast.error(result);
        return;
      }
      let tmp = [];
      let accounts_counter = 1;
      let lineitem_counter = 1;
      let group_counter = 1;
      let subgroup_counter = 1;
      let type_counter = 1;

      setColumnVisibilityModel(colsDefault);

      result.map((res) => {
        res.cols_ret.map((cols) => {
          setColumnVisibilityModel((prevState) => ({
            ...prevState,
            [cols]: true,
          }));
        });

        res.type.map((type) => {
          type.group.map((group) => {
            group.subgroup.map((subgroup) => {
              subgroup.line_item.map((line_item) => {
                line_item.accounts.map((accounts) => {
                  if (formData.breakdown.indexOf('accounts') > -1) {
                    let tmpval = [];
                    // let tmpvalstore = [];
                    accounts.date.map((date) => {
                      tmpval = {
                        ...tmpval,
                        [date.words]: numberFormatCurrency(date.total),
                      };
                    });

                    tmpval = {
                      ...tmpval,
                      id: accounts_counter + '_accounts',
                      breakdown_type: 'ACCOUNTS',
                      account_code: accounts.account_code,
                      account_name: accounts.account_name,
                    };
                    tmp.push(tmpval);
                    accounts_counter++;
                  }
                });
                if (formData.breakdown.indexOf('line_item') > -1) {
                  let tmpval = [];
                  line_item.date.map((date) => {
                    tmpval = {
                      ...tmpval,
                      [date.words]: numberFormatCurrency(date.total),
                    };
                  });

                  tmpval = {
                    ...tmpval,
                    id: lineitem_counter + '_line_item',
                    breakdown_type: 'LINE ITEM',
                    account_code: '-',
                    account_name: line_item.line_item,
                  };
                  tmp.push(tmpval);
                  lineitem_counter++;
                }
              });
              if (formData.breakdown.indexOf('subgroup') > -1) {
                let tmpval = [];
                subgroup.date.map((date) => {
                  tmpval = {
                    ...tmpval,
                    [date.words]: numberFormatCurrency(date.total),
                  };
                });

                tmpval = {
                  ...tmpval,
                  id: subgroup_counter + '_subgroup',
                  breakdown_type: 'SUB-GROUP',
                  account_code: '-',
                  account_name: subgroup.subgroup,
                };
                tmp.push(tmpval);
                subgroup_counter++;
              }
            });
            if (formData.breakdown.indexOf('group') > -1) {
              let tmpval = [];
              group.date.map((date) => {
                tmpval = {
                  ...tmpval,
                  [date.words]: numberFormatCurrency(date.total),
                };
              });

              tmpval = {
                ...tmpval,
                id: group_counter + '_group',
                breakdown_type: 'GROUP',
                account_code: '-',
                account_name: group.group,
              };
              tmp.push(tmpval);
              group_counter++;
            }
          });
          if (formData.breakdown.indexOf('type') > -1) {
            let tmpval = [];
            type.date.map((date) => {
              tmpval = {
                ...tmpval,
                [date.words]: numberFormatCurrency(date.total),
              };
            });

            tmpval = {
              ...tmpval,
              id: type_counter + '_type',
              breakdown_type: 'TYPE',
              account_code: '-',
              account_name: type.type,
            };
            tmp.push(tmpval);
            type_counter++;
          }
        });
      });
      setIncomeStatementData(tmp);

      setLoading(false);
    } catch (error) {
      //console.log({ error });
      setLoading(false);
      toast.error('Error occured. Please try again later');
    } finally {
      setLoading(false);
    }
  };

  const getDate = (e) => {
    if (formData.period_breakdown === 'year') {
      const convyear = moment(formData.date_from).format('YYYY');

      const yearcol = [
        {
          field: 'breakdown_type',
          headerName: 'Breakdown Type',
          width: 150,
          sortable: false,
          hideable: false,
        },
        {
          field: 'account_code',
          headerName: 'Account Code',
          width: 150,
          sortable: false,
          hideable: false,
        },
        {
          field: 'account_name',
          headerName: 'Account Description',
          width: 200,
          sortable: false,
          hideable: false,
        },
        {
          field: convyear,
          headerName: convyear,
          width: 150,
          sortable: false,
          hideable: false,
        },
      ];
      setStorePeriodType(yearcol);
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    if (e.target.name === 'period_breakdown') {
      if (e.target.value === 'quarter') {
        setStorePeriodType(incomeStatementDataCol_quarter);
      } else if (e.target.value === 'month') {
        setStorePeriodType(incomeStatementDataCol_month);
      } else {
        const convyear = moment(formData.date_from).format('YYYY');

        const yearcol = [
          {
            field: 'breakdown_type',
            headerName: 'Breakdown Type',
            width: 150,
            sortable: false,
            hideable: false,
          },
          {
            field: 'account_code',
            headerName: 'Account Code',
            width: 150,
            sortable: false,
            hideable: false,
          },
          {
            field: 'account_name',
            headerName: 'Account Description',
            width: 200,
            sortable: false,
            hideable: false,
          },
          {
            field: convyear,
            headerName: convyear,
            width: 150,
            sortable: false,
            hideable: false,
          },
        ];

        setStorePeriodType(yearcol);
      }
    }

    let newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleChangeBreakdown = (e) => {
    let array = e.target.value.map((e) => e.value);

    setFormData({
      ...formData,
      [e.target.name]: array,
    });
  };

  const handleSearch = () => {
    loadIncomeStatementData(formData);
    setPeriodBreakdown(storePeriodType);
  };

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };

    loadIncomeStatementData(temp);
  };

  function customToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <IncomeStatementSheetReportScreen
      //throw parameter or props.
      incomeStatementData={incomeStatementData}
      formData={formData}
      dateChange={getDate}
      handleChange={handleChange}
      handleChangeBreakdown={handleChangeBreakdown}
      handleSearch={handleSearch}
      periodBreakdown={periodBreakdown}
      loading={loading}
      handleDataTableChange={handleDataTableChange}
      reloadTable={reloadTable}
      getAccountList={getAccountList}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
      customToolbar={customToolbar}
      // handleExport={OnHandleExport}
    />
  );
};

export default BalanceSheet;
