import { numberFormatCurrency } from "../../../utils/helper";

export const ytdColumns = [
  {
    field: "type_name",
    headerName: "Customer Type",
    minWidth: 250,
    sortable: false,
  },
  {
    field: "January",
    headerName: "January",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.January === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.January);
      }
    },
  },
  {
    field: "February",
    headerName: "February",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.February === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.February);
      }
    },
  },
  {
    field: "March",
    headerName: "March",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.March === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.March);
      }
    },
  },
  {
    field: "April",
    headerName: "April",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.April === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.April);
      }
    },
  },
  {
    field: "May",
    headerName: "May",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.May === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.May);
      }
    },
  },
  {
    field: "June",
    headerName: "June",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.June === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.June);
      }
    },
  },
  {
    field: "July",
    headerName: "July",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.July === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.July);
      }
    },
  },
  {
    field: "August",
    headerName: "August",
    minWidth: 250,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.August === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.August);
      }
    },
  },
  {
    field: "September",
    headerName: "September",
    minWidth: 300,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.September === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.September);
      }
    },
  },
  {
    field: "October",
    headerName: "October",
    minWidth: 300,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.October === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.October);
      }
    },
  },
  {
    field: "November",
    headerName: "November",
    minWidth: 300,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.November === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.November);
      }
    },
  },
  {
    field: "December",
    headerName: "December",
    minWidth: 300,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.December === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.December);
      }
    },
  },
  {
    field: "Grand_Total",
    headerName: "Grand Total",
    minWidth: 300,
    sortable: false,
    type: "number",
    renderCell: ({ row }) => {
      if (row.Grand_Total === 0) {
        return "-";
      } else {
        return numberFormatCurrency(row.Grand_Total);
      }
    },
  },
];
