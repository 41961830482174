import { React } from 'react';
import PrimaryToolTip from '../PrimaryToolTip.js';

function Accounting({ form, handleChange }) {
  /* let accounting = customer.business_contacts.accounting[0];
   */
  return (
    <fieldset className="details-field-set mb-4 mt-2">
      <legend>Account Contact Information</legend>
      {/*  */}
      <div className="row pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm"></label>
        </div>
        <div className="col-lg-6"></div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Contact Person
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="accounting_contact_person"
            id="accounting_contact_person"
            value={form.accounting_contact_person}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Contact Number
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="accounting_contact_no"
            id="accounting_contact_no"
            value={form.accounting_contact_no}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/* <div className="row mt-2 pl-3">
                              <div className="col-lg-4">
                                <label className="col-form-label col-form-label-sm"></label>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="accounting_contact_no_1"
                                  id="accounting_contact_no_1"
                                  value={values.accounting_contact_no_1}
                                  onChange={handleChange}
                                />
                              </div>
                            </div> */}
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">
            Designation
          </label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="accounting_designation"
            id="accounting_designation"
            value={form.accounting_designation}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="row mt-2 pl-3">
        <div className="col-lg-4">
          <label className="col-form-label col-form-label-sm">Email</label>
          <span className="requiredField"> *</span>
          <PrimaryToolTip />
        </div>
        <div className="col-lg-6">
          <input
            type="email"
            className="form-control form-control-sm"
            name="accounting_email"
            id="accounting_email"
            value={form.accounting_email}
            onChange={handleChange}
          />
        </div>
      </div>
      {/*  */}
    </fieldset>
  );
}

export default Accounting;
