import { useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Swal from "sweetalert2";
import { GridPagination } from "@mui/x-data-grid-pro";

import { numberFormatCurrency } from "../../../utils/helper";
import { requestColumn } from "../components/requestColumn";

import PrimaryDatePicker from "../../../components/PrimaryDatePicker";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import MultipleFileUploadPreview from "../../../components/MultipleFileUploadPreview";

import PurchaseRequestCreate from "../PurchaseRequestCreate";
import DefaultLayout from "../../../components/Layouts/DefaultLayout";
import ProductSelectionModal from "../components/productSelectionModal";

import { getProducts, getSupplier } from "../../../api/purchaseRequest";
import { hasPermission } from "../../../components/PermissionsGate";
import { statusText } from "../components/data";
import moment from "moment";

const CustomFooterStatusComponent = ({ totalText, totalAmount }) => {
  return (
    <>
      <Box
        sx={{
          padding: "15px",
        }}
        className="text-right text-dark"
      >
        <strong>
          {totalText}: {numberFormatCurrency(totalAmount)}
        </strong>
      </Box>
      <Box className="text-right">
        <GridPagination />
      </Box>
    </>
  );
};

const FormRequestScreen = () => {
  const { id } = useParams();

  const history = useHistory();

  const {
    data,
    handleChange,
    handleSubmit,
    handleUpdate,
    handleApprove,
    handleDecline,
    handleCancel,
    handleProductDropdownDoubleClick,
    handleFileChange,
    handleChangeDetails,
    handleFetchProductList,
  } = PurchaseRequestCreate({ id });

  const [showProduct, setShowProduct] = useState(false);

  const handleButtonAddProduct = () => {
    if (data.supplier === "") {
      Swal.fire({
        icon: "warning",
        title: "Please select a supplier",
        allowOutsideClick: false,
      });
    } else {
      setShowProduct(true);
    }
  };

  const parseRequestColumn = useMemo(
    () =>
      requestColumn({
        handleChangeDetails,
      }),
    [handleChangeDetails]
  );

  return (
    <DefaultLayout
      title={statusText(data.status) + " Purchase Request (Stocks)"}
    >
      <Box
        key="create"
        eventkey="create"
        title="Create Purchase Request"
        className="default-tab-content"
      >
        {[0, 1, 2, 3].includes(parseInt(data.status)) && (
          <Row>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  PR No.
                </Form.Label>
                <Col lg="9">
                  <Form.Control name="pr_no" value={data.pr_no} readOnly />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          {/*  */}

          {/* <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                PR Date
              </Form.Label>
              <Col lg="9">
                <PrimaryDatePicker
                  name="pr_date"
                  datePickerFormat="yyyy-MM-dd"
                  datePickerFormatString="YYYY-MM-DD"
                  value={data.pr_date}
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "pr_date",
                        value: date,
                      },
                    })
                  }
                />
              </Col>
            </Form.Group>
          </Col> */}

          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3">
                Supplier
              </Form.Label>
              <Col lg="9">
                <PrimaryAsyncSelect
                  placeholder="- Select Supplier -"
                  className="form-control"
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "supplier",
                        value: e.value,
                      },
                    })
                  }
                  customFunction={getSupplier}
                  isClearable={true}
                  name="supplier"
                  value={data.supplier}
                  config={{
                    searchField: "company_name",
                    valueField: "vendor_id",
                    labelField: "company_name",
                  }}
                  isDisabled={
                    data.status !== "" &&
                    [0, 1, 2, 3].includes(parseInt(data.status))
                  }
                />
              </Col>
            </Form.Group>
          </Col>

          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3">
                Requested Delivery Date
              </Form.Label>
              <Col lg="9">
                <PrimaryDatePicker
                  name="estimated_delivery_date"
                  datePickerFormat="yyyy-MM-dd"
                  datePickerFormatString="YYYY-MM-DD"
                  value={data.estimated_delivery_date}
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "estimated_delivery_date",
                        value: date,
                      },
                    })
                  }
                  disabled={
                    data.status !== "" && [1, 3].includes(parseInt(data.status))
                  }
                />
              </Col>
            </Form.Group>
          </Col>

          <Col sm="4 text-right">
            {data.status === "" && (
              <Button variant="success" onClick={handleButtonAddProduct}>
                Add Product
              </Button>
            )}
            {/* <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3">
                Products
              </Form.Label>
              <Col lg="9 w-100">
                <div className="d-flex">
                  <div className="w-100">
                    <PrimaryAsyncSelectMulti
                      placeholder="- Select Product -"
                      className="form-control"
                      onChange={(e) => handleChange({
                        target: { name: "products", value: e },
                      })}
                      customFunction={getProducts}
                      isClearable={true}
                      isMulti={true}
                      name="products"
                      value={data.products}
                      config={{
                        searchField: "search",
                        valueField: "object",
                        labelField: "product_name",
                      }}
                    />
                  </div>
                  <div>
                    <Tooltip title="Search Product">
                      <Button 
                        variant="success" 
                        onClick={() => setShowProduct(true)}
                        >
                        <SearchIcon style={{ height: 20 }}/>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </Form.Group> */}
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                Prepared By.
              </Form.Label>
              <Col lg="9">
                <Form.Control value={data.prepared_by} readOnly />
              </Col>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                Department
              </Form.Label>
              <Col lg="9">
                <Form.Control type="text" value={data.department} readOnly />
              </Col>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                PR Date.
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  type="text"
                  value={
                    data.pr_date
                      ? moment(data.pr_date).format("MM/DD/YYYY")
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        {/* <Row>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg="3">
                Department
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  type="text"
                  value={
                    parseInt(data.percent_down_payment || 0) +
                    "% Upon PO " +
                    parseInt(data.percent_upon_delivery || 0) +
                    "% upon Delivery " +
                    parseInt(data.percent_intransit || 0) +
                    "% Intransit"
                  }
                  readOnly
                />
              </Col>
            </Form.Group>
          </Col>
        </Row> */}

        {/* <Row>
          <Col sm="12 mb-3 text-right">
            <Button
              variant="success"
              onClick={handleSearchProduct}
              >
                Add Product
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12 mb-3">
            <PrimaryDataTable
              autoHeight
              disableSelectionOnClick
              rows={data.details}
              columns={parseRequestColumn}
              components={{
                Footer: CustomFooterStatusComponent,
              }}
              componentsProps={{
                footer: {
                  totalText: "Grand Total",
                  totalAmount: data.details.reduce((total, row) => {
                    return (
                      total +
                      parseFloat(row.requested_qty || 0) * parseFloat(row.cost)
                    );
                  }, 0),
                },
              }}
            />
          </Col>
        </Row>

        <Row>
          <div className="col-sm-12 col-md-4 mb-3">
            <Form.Group className="mb-3">
              <Form.Label lg="2">Additional Attachments</Form.Label>
              <MultipleFileUploadPreview
                newAttachments={data.attachments}
                oldAttachments={data.old_attachments}
                handleRemoveFile={[]}
                handleFileUpload={handleFileChange}
                disabled={
                  data.status !== "" && [1, 3].includes(parseInt(data.status))
                }
              />
            </Form.Group>
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <Form.Label lg="2">Particulars/Special instructions</Form.Label>
            <Form.Control
              as="textarea"
              style={{ minHeight: "190px" }}
              name="particulars"
              value={data.particulars || ""}
              onChange={handleChange}
              readOnly={
                data.status !== "" && [1, 3].includes(parseInt(data.status))
              }
            />
          </div>
        </Row>

        {[1].includes(parseInt(data.status)) && (
          <Row>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Approved By.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    name="pr_no"
                    value={data.approved_by}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Approved Date.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    type="text"
                    value={
                      data.approved_date
                        ? moment(data.approved_date).format("MM/DD/YYYY")
                        : ""
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}

        {[3].includes(parseInt(data.status)) && (
          <Row>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Cancelled By.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    name="pr_no"
                    value={data.cancelled_by}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Cancelled Date.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    type="text"
                    value={
                      data.cancelled_date
                        ? moment(data.cancelled_date).format("MM/DD/YYYY")
                        : ""
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}

        {[2].includes(parseInt(data.status)) && (
          <Row>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Declined By.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    name="pr_no"
                    value={data.declined_by}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg="3" style={{ whiteSpace: "nowrap" }}>
                  Declined Date.
                </Form.Label>
                <Col lg="9">
                  <Form.Control
                    type="text"
                    value={
                      data.declined_date
                        ? moment(data.declined_date).format("MM/DD/YYYY")
                        : ""
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <div className="col-md-12 mb-3 text-right">
            {data.status === "" &&
              hasPermission(["can_create_purchase_request"]) && (
                <Button
                  variant="success"
                  onClick={handleSubmit}
                  className="ml-2"
                >
                  Save
                </Button>
              )}

            {[0, 2].includes(parseInt(data.status)) &&
              hasPermission(["can_approve_purchase_request"]) && (
                <Button
                  variant="success"
                  onClick={handleApprove}
                  className="ml-2"
                >
                  Approve
                </Button>
              )}

            {[0, 2].includes(parseInt(data.status)) &&
              hasPermission(["can_update_purchase_request"]) && (
                <Button
                  variant="success"
                  onClick={handleUpdate}
                  className="ml-2"
                >
                  Update
                </Button>
              )}

            {/* Decline is for editing */}

            {[0, 3].includes(parseInt(data.status)) &&
              hasPermission(["can_decline_purchase_request"]) && (
                <Button
                  variant="danger"
                  onClick={handleDecline}
                  className="ml-2"
                >
                  Decline
                </Button>
              )}

            {/* Cancel is for cancellation and cannot be return for edit */}

            {[0].includes(parseInt(data.status)) &&
              hasPermission(["can_cancel_purchase_request"]) && (
                <Button
                  variant="danger"
                  onClick={handleCancel}
                  className="ml-2"
                >
                  Cancel
                </Button>
              )}

            <Tooltip title="Back to list of requests" placement="top">
              <Button
                variant="default"
                onClick={() => history.goBack()}
                className="ml-2 border"
              >
                Back to List
              </Button>
            </Tooltip>
          </div>
        </Row>

        <ProductSelectionModal
          data={data}
          toggle={showProduct}
          supplierID={data.supplier}
          handleCloseModal={() => setShowProduct(false)}
          handleRowDoubleClick={handleProductDropdownDoubleClick}
          getData={getProducts} // API ENDPOINT FOR PRODUCTS LIST
          rowKey="product_id"
          handleProductList={(products) => handleFetchProductList(products)}
        />
      </Box>
    </DefaultLayout>
  );
};

export default FormRequestScreen;
