import { Fragment, React, useEffect, useState } from "react";
import { GridToolbar } from "@mui/x-data-grid-pro";

import PrimaryDataTable from "../../../../components/PrimaryDataTable";
import { listOfDeclinedColumns } from "../../Approver/const";
import defaultOptions from "../../../../utils/defaultOptions";

const Declined = ({ data, loading }) => {
  const [pageSize, setPageSize] = useState(defaultOptions.pageSize);
  const columns = listOfDeclinedColumns();

  return (
    <Fragment>
      <PrimaryDataTable
        rows={data}
        columns={columns}
        disableColumnSelector={true}
        getRowId={(row) => row.detail_id}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={defaultOptions.rowsPerPageOptions}
        hideFooterSelectedRowCount
        components={{
          Toolbar: GridToolbar,
        }}
        loading={loading}
      />
    </Fragment>
  );
};

export default Declined;
