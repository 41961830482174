import React from "react";
import { Tooltip } from "@material-ui/core";

function ProductInformation({
  styles,
  values,
  onInputChange,
  updateForm,
  divHidden,
  populateSubSegment,
  prodSegmentHolder,
  productSubSegment,
  populateSubGroup,
  prodGroupHolder,
  productSubGroup,
  supplierHolder,
  cprDocument,
  vatIsCheck,
  vatCheck,
  computeVatCost,
  costVatValue,
  costUnit,
  costUnitVatValue,
  computeVatSelling,
  sellingVatValue,
  sellingUnit,
  sellingUnitVatValue,
  handleAddPaymentTerms,
  renderPaymentTerms,
}) {
  return (
    <div className="col-md-12 row" id="product_information">
      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Product Information</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <label
              for="product_name"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Product Name
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="product_name"
                  id="product_name"
                  value={values.product_name}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Product Name"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="generic_name"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Generic Name
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="generic_name"
                  id="generic_name"
                  value={values.generic_name}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Generic Name"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="product_segment"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Product Segment
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <select
                  className="form-control form-control-sm text-dark"
                  name="product_segment_id"
                  value={values.product_segment_id}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => {
                    onInputChange(e);
                    populateSubSegment();
                  }}
                  disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE PRODUCT SEGMENT
                  </option>
                  {prodSegmentHolder.map((prodsegment) => (
                    <option
                      value={prodsegment.segment_id}
                      key={prodsegment.segment_id}
                      selected={
                        prodsegment.segment_id == values.product_segment_id
                          ? "selected"
                          : ""
                      }
                    >
                      {prodsegment.segment_name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control form-control-sm text-dark"
                  name="product_sub_segment_id"
                  value={values.product_sub_segment_id}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE PRODUCT SUB SEGMENT
                  </option>
                  {productSubSegment.map((prodsubsegment) => (
                    <option
                      value={prodsubsegment.sub_segment_id}
                      key={prodsubsegment.sub_segment_id}
                      selected={
                        prodsubsegment.sub_segment_id ==
                        values.product_sub_segment_id
                          ? "selected"
                          : ""
                      }
                    >
                      {prodsubsegment.segment_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="product_segment"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Product Group
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <select
                  className="form-control form-control-sm text-dark"
                  name="product_group_id"
                  value={values.product_group_id}
                  style={{ marginTop: "3px" }}
                  onChange={(e) => {
                    onInputChange(e);
                    populateSubGroup();
                  }}
                  disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE PRODUCT GROUP
                  </option>
                  {prodGroupHolder.map((prodgroup) => (
                    <option
                      value={prodgroup.group_id}
                      key={prodgroup.group_id}
                      selected={
                        prodgroup.group_id == values.product_group_id
                          ? "selected"
                          : ""
                      }
                    >
                      {prodgroup.group_name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control form-control-sm text-dark"
                  name="product_sub_group_id"
                  value={values.product_sub_group_id}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE PRODUCT SUB GROUP
                  </option>
                  {productSubGroup.map((prodsubgroup) => (
                    <option
                      value={prodsubgroup.sub_group_id}
                      key={prodsubgroup.sub_group_id}
                      selected={
                        prodsubgroup.sub_group_id == values.product_sub_group_id
                          ? "selected"
                          : ""
                      }
                    >
                      {prodsubgroup.group_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="supplier"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Supplier
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <select
                  className="form-control form-control-sm text-dark"
                  name="supplier_id"
                  value={values.supplier_id}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={true}
                  // disabled={!updateForm}
                >
                  <option disabled selected>
                    CHOOSE SUPPLIER
                  </option>
                  {supplierHolder.map((supplier) => (
                    <option
                      value={supplier.vendor_id}
                      key={supplier.vendor_id}
                      selected={
                        supplier.vendor_id === values.supplier_id
                          ? "selected"
                          : ""
                      }
                    >
                      {supplier.vendor_code} - {supplier.company_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="manufacturer"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Manufacturer
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="manufacturer"
                  id="manufacturer"
                  value={values.manufacturer}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Manufacturer"
                  disabled={!updateForm}
                  novalidate
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cpr_no"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              CPR Number
            </label>
            <div className="col-sm-8 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="cpr_no"
                  id="cpr_no"
                  value={values.cpr_no}
                  style={{ marginTop: "3px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="XXXX-XXXX-XXXX"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cpr_expiry"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              CPR Expiry
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="date"
                  className="form-control form-control-sm text-dark"
                  name="cpr_expiry"
                  id="cpr_expiry"
                  value={values.cpr_expiry}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cpr_expiry"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              CPR Document
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="file"
                  className="form-control form-control-sm text-dark"
                  name="cpr_document"
                  id="cpr_document"
                  value={values.cpr_document}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
                <br />
                {cprDocument !== "" ? (
                  <label className="font-weight-bold">
                    File:
                    <a
                      className="link-color ml-1"
                      href={cprDocument.file_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cprDocument.file_name}
                    </a>
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>

        <br />
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Product Payment Terms</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <div className="col-sm-6 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-12 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="percent_down_payment"
                  id="percent_down_payment"
                  value={values.percent_down_payment}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={(event) => {
                    if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter % Down Payment"
                  disabled={!updateForm}
                />
              </div>
            </div>

            <label className="col-sm-6 col-form-label col-form-label-sm">
              % Down Payment
            </label>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <div className="col-sm-6 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-12 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="percent_intransit"
                  id="percent_intransit"
                  value={values.percent_intransit}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={(event) => {
                    if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter % In Transit"
                  disabled={!updateForm}
                />
              </div>
            </div>

            <label className="col-sm-6 col-form-label col-form-label-sm">
              % In Transit
            </label>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <div className="col-sm-6 row pr-0" style={{ marginTop: "6px" }}>
              <div className="col-sm-12 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="percent_upon_delivery"
                  id="percent_upon_delivery"
                  value={values.percent_upon_delivery}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={(event) => {
                    if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter % Upon Delivery"
                  disabled={!updateForm}
                />
              </div>
            </div>

            <label className="col-sm-6 col-form-label col-form-label-sm">
              % Upon Delivery
            </label>
          </div>
          <br />
          {renderPaymentTerms()}
          <br />
          <div className="col-sm-12 d-flex justify-content-center mb-2">
            <button
              type="button"
              className={"btn " + divHidden + " btn-info "}
              onClick={handleAddPaymentTerms}
            >
              Add Another Payment Terms
            </button>
          </div>
        </section>
      </div>
      <div className="col-md-6" style={styles.divPadding}>
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Inventory</legend>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <label
              for="stock_level"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Stock Level
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="stock_level"
                  id="stock_level"
                  value={values.stock_level}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  readOnly
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="shelf_life"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Shelf Life
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="shelf_life"
                  id="shelf_life"
                  value={values.shelf_life}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Shelf Life"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">Months</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="quantity"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Minimum Order quantity
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="quantity"
                  id="quantity"
                  value={values.quantity}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="safety_stock_level"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Safety Stock Level
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="safety_stock_level"
                  d="safety_stock_level"
                  value={values.safety_stock_level}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="forecast"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Forecast (Required)
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="forecast"
                  id="forecast"
                  value={values.forecast}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="supplier_lead_time"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Supplier Lead Time
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-8 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="supplier_lead_time"
                  id="supplier_lead_time"
                  value={values.supplier_lead_time}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Supplier Lead Time"
                  disabled={!updateForm}
                />
              </div>
              <div className="col-sm-3">Days</div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section style={styles.fieldset}>
          <legend style={styles.legend}>Price & Cost</legend>
          <br />

          <div className="form-group row" style={styles.formGroup}>
            <p class="text-danger">
              Note: Fill out{" "}
              <span className="font-weight-bold">
                Cost Per Box and Selling Price Per Box
              </span>{" "}
              First and{" "}
              <span className="font-weight-bold">Quantity Per Pack</span> to
              compute Per Unit and VAT Ex.
            </p>
          </div>
          <br />
          <div className="form-group row" style={styles.formGroup}>
            <label
              for="vat_exemption"
              className="col-sm-4 col-form-label col-form-label-sm"
            ></label>
            <div className="col-sm-8" style={{ marginLeft: "-15px" }}>
              <input
                name="vat_exemption"
                type="checkbox"
                id="vat_exemption"
                value={values.vat_exemption}
                style={{ marginTop: "6px" }}
                onChange={(e) => onInputChange(e)}
                checked={vatIsCheck}
                onClick={vatCheck}
                disabled={!updateForm}
              />{" "}
              Vat Exemption
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cost"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Cost Per Box <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="cost"
                  id="cost"
                  value={values.cost}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  onKeyUp={computeVatCost}
                  onKeyPress={(event) => {
                    if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={!updateForm}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cost_vat"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Cost Per Box (Vat Ex) <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="cost_vat"
                  id="cost_vat"
                  value={isNaN(costVatValue) ? "0.00" : costVatValue}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cost_unit"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Cost Per Unit
              <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="cost_unit"
                  id="cost_unit"
                  value={isNaN(costUnit) ? "0.00" : costUnit}
                  style={{ marginTop: "6px" }}
                  novalidate
                  autoComplete="off"
                  disabled={!updateForm}
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="cost_vat_unit"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Cost Per Unit (Vat Ex){" "}
              <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="cost_vat_unit"
                  id="cost_vat_unit"
                  value={isNaN(costUnitVatValue) ? "0.00" : costUnitVatValue}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  novalidate
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="selling_price"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Selling Price Per Box <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="selling_price"
                  id="selling_price"
                  value={
                    isNaN(values.selling_price) ? "0.00" : values.selling_price
                  }
                  style={{ marginTop: "6px" }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[^a-zA-Z!@#$%^&*)\/(+=_-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyUp={computeVatSelling}
                  disabled={!updateForm}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="selling_price_vat"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Selling Price Per Box(Vat Ex){" "}
              <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="selling_price_vat"
                  id="selling_price_vat"
                  value={isNaN(sellingVatValue) ? "0.00" : sellingVatValue}
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="selling_price_unit"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Selling Price Per Unit{" "}
              <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm text-dark"
                  name="selling_price_unit"
                  id="selling_price_unit"
                  value={isNaN(sellingUnit) ? "0.00" : sellingUnit}
                  style={{ marginTop: "6px" }}
                  disabled={!updateForm}
                  autoComplete="off"
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form-group row" style={styles.formGroup}>
            <label
              for="selling_price_unit_vat"
              className="col-sm-4 col-form-label col-form-label-sm"
            >
              Selling Price Per Unit (Vat Ex){" "}
              <span className="float-right">&#8369;</span>
            </label>
            <div className="col-sm-8 row pr-0">
              <div className="col-sm-11 row">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="selling_price_unit_vat"
                  id="selling_price_unit_vat"
                  value={
                    isNaN(sellingUnitVatValue) ? "0.00" : sellingUnitVatValue
                  }
                  style={{ marginTop: "6px" }}
                  onChange={(e) => onInputChange(e)}
                  novalidate
                  readOnly
                />
              </div>
              <div className="col-sm-1">
                <Tooltip
                  title={
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Indicated in 2303 BIR Certificate of Registration
                    </span>
                  }
                >
                  <span style={{ fontSize: "12px" }} className="material-icons">
                    help_outline
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProductInformation;
