import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';

import PrimaryDataTable from '../PrimaryDataTable';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { hasPermission, PermissionsGate } from '../PermissionsGate';

import { transactionDetails, onProcessColumn, tab_location } from './const';

import CustomerTransactionModal from './CustomerTransactionModal';
import CustomerTransactionReasonModal from './CustomerTransactionReasonModal';

import {
  getSRPFDeclinedList,
  getSRPFDeclinedReason,
  srpfExport,
} from '../../api/srpf';
import ExportModal from './ExportModal';

const DeclinedSRPF = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // Modal
  const [showTransaction, setShowTransaction] = useState(false);
  const [showTransactionReason, setShowTransactionReason] = useState(false);
  // Data
  const [transactionReasonData, setTransactionReasonData] = useState([]);
  const [declinedSRPFData, setDeclinedSRPFData] = useState([]);
  // const [declinedSRPFDetails, setDeclinedSRPFDetails] = useState(transactionDetails);
  const [showExport, setShowExport] = useState({
    date_from: '',
    date_to: '',
    srpf_type: '',
    show: false,
    status: 2,
  });

  useEffect(() => {
    loadDeclinedData();
  }, []);

  const handleCloseTransactionModal = () => setShowTransaction(false);
  const handleCloseTransactionReasonModal = () =>
    setShowTransactionReason(false);

  const loadDeclinedData = () => {
    setLoading(true);
    getSRPFDeclinedList()
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          const viewSrpfPermission = hasPermission(['view_declined_srpf']);

          let unique = 1;

          setDeclinedSRPFData(
            result.map((row) => ({ ...row, id: unique++, viewSrpfPermission })),
          );
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleShowTransactionModal = useCallback((id) => {
    history.push('/collection/edit/srpf/' + id);
    // getDeclinedSRPF({
    //   srpf_id: id,
    // })
    //   .then((res) => {
    //     let { code, result } = res.data.response;
    //     if (code === "00000") {
    //       setDeclinedSRPFDetails(result);
    //       setShowTransaction(true);
    //     }
    //   })
    //   .catch((err) => {});
  }, []);

  const handleTabLocation = (key) => tab_location[key];

  const handleShowReason = useCallback((srpf_no) => {
    setShowTransactionReason(true);
    getSRPFDeclinedReason({
      srpf_no: srpf_no,
    }).then((res) => {
      let { code, result } = res.data.response;
      if (code === '00000') {
        setTransactionReasonData(result);
      }
    });
  }, []);

  const declinedSRPFDataCol = useMemo(
    () =>
      onProcessColumn({
        handleShowTransactionModal,
        handleShowReason,
      }),
    [handleShowTransactionModal, handleShowReason],
  );

  const handleViewExportModal = () =>
    setShowExport({ ...showExport, show: true });

  const handleExport = (e) => {
    toast.loading('Exporting Data...');
    srpfExport(showExport)
      .then((res) => {
        let { code, result } = res.data.response;
        if (code === '00000') {
          window.open(result, '_blank').focus();
          toast.dismiss();
        }
      })
      .catch((err) => {
        toast.loading(
          'Something went wrong while exporting, Please try again.',
        );
      });
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"> Stock Returns Pull-Out </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <PermissionsGate
            scopes={[
              'create_srpf',
              'srpf_approved_list',
              'srpf_onprocess_list',
              'srpf_approval_list',
              'srpf_declined_list',
              'srpf_returned_list',
              'view_not_yet_returned_srpf_list',
            ]}
          >
            <Tabs
              className="default-tab"
              activeKey="declined"
              onSelect={(k, event) => {
                history.push(handleTabLocation(k));
              }}
            >
              {hasPermission(['create_srpf']) && (
                <Tab eventKey="add_new" title="Add New"></Tab>
              )}
              {hasPermission(['srpf_onprocess_list']) && (
                <Tab eventKey="onprocess" title="On-Process SRPF"></Tab>
              )}
              {hasPermission(['srpf_approval_list']) && (
                <Tab eventKey="approval" title="For Approval"></Tab>
              )}
              {hasPermission(['srpf_approved_list']) && (
                <Tab eventKey="approved" title="Approved SRPF"></Tab>
              )}
              {hasPermission(['srpf_declined_list']) && (
                <Tab
                  className="default-tab-content"
                  eventKey="declined"
                  title="Declined SRPF"
                >
                  <div className="mt-2 mb-2">
                    <div className="col-md-12">
                      <div className="text-right">
                        <Button
                          type="button"
                          variant="info"
                          onClick={handleViewExportModal}
                          className="mb-2"
                        >
                          Export
                        </Button>
                      </div>
                      <PrimaryDataTable
                        loading={loading}
                        rows={declinedSRPFData}
                        columns={declinedSRPFDataCol}
                        getRowId={(row) => row.srpf_approval_id}
                      />
                    </div>
                  </div>
                </Tab>
              )}

              {hasPermission(['srpf_returned_list']) && (
                <Tab eventKey="returned" title="Returned to maker"></Tab>
              )}
              {hasPermission(['view_not_yet_returned_srpf_list']) && (
                <Tab eventKey="not_yet_received" title="Not yet Received"></Tab>
              )}
              {/* {hasPermission(["srpf_declined_list"]) && (
                <Tab
                  eventKey="approvedrts"
                  title="Approved RTS(WAREHOUSE)"
                ></Tab>
              )} */}
            </Tabs>
          </PermissionsGate>
        </div>
      </div>
      {/*  */}
      <CustomerTransactionModal
        srpfDetails={transactionDetails}
        showTransactionModal={showTransaction}
        handleCloseTransactionModal={handleCloseTransactionModal}
        showApprovedButton={true}
        showDeclinedButton={false}
        showSplitButton={false}
        handleApproveTransaction={false}
      />
      {/*  */}
      <CustomerTransactionReasonModal
        srpfDetails={transactionReasonData}
        showTransactionModal={showTransactionReason}
        handleCloseTransactionModal={handleCloseTransactionReasonModal}
        showApprovedButton={true}
        showDeclinedButton={false}
        handleApproveTransaction={false}
      />
      {/*  */}
      <ExportModal
        show={showExport}
        setModalShow={() =>
          setShowExport((prevState) => ({ ...prevState, show: false }))
        }
        handleChange={(e) =>
          setShowExport((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
        }
        handleConfirmReturn={handleExport}
      />
    </Fragment>
  );
};

export default DeclinedSRPF;
