import { useEffect, useState, Fragment } from 'react';

// components
import {
  DropdownProvince,
  DropdownCities,
  DropdownBarangays,
  DropdownZipCodes,
} from '../../../../Addresses';
import DropdownCustom from './DropdownCustom';

// api
import {
  getModeOfPayment,
  getPaymentTerm,
  getStockingTerm,
} from '../../../../../api/customer';

import PrimaryToolTip from '../PrimaryToolTip';

const Billing = ({ form, handleChange }) => {
  const [modeOfPayment, setModeOfPayment] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [stockingTerm, setStockingTerm] = useState([]);

  // get mode of payment

  useEffect(() => {
    loadModeOfPayment();
    loadPaymentTerm();
    loadStockingTerm();
  }, []);

  const loadModeOfPayment = () => {
    getModeOfPayment().then((res) => {
      if (res.data.response.code === '00000') {
        setModeOfPayment(res.data.response.result);
      }
    });
  };

  const loadPaymentTerm = () => {
    getPaymentTerm().then((res) => {
      const { code, result } = res.data.response;
      if (code === '00000') {
        setPaymentTerm(result);
      }
    });
  };

  const loadStockingTerm = () => {
    getStockingTerm().then((res) => {
      const { code, result } = res.data.response;
      if (code === '00000') {
        setStockingTerm(result);
      }
    });
  };

  return (
    <Fragment>
      <div id="billing_and_shipping">
        <input
          type="hidden"
          name="customer_billing_address_id"
          value={form.customer_billing_address_id}
          readOnly
        />
        <fieldset className="details-field-set mb-4 mt-0">
          <legend>Billing</legend>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                {/*  */}
                <div className="row">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Mode Of Payment
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownCustom
                      data={modeOfPayment}
                      valueField={'payment_mode_id'}
                      nameField={'payment_mode'}
                      selected={form.billing_mode_of_payment}
                      placeholder={'- Select Mode of Payment -'}
                      name={'billing_mode_of_payment'}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
              </div>

              <div className="col-md-6">
                {/*  */}
                <div className="row">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Payment Terms
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownCustom
                      data={paymentTerm}
                      valueField={'payment_terms_id'}
                      nameField={'payment_terms'}
                      selected={parseInt(form.payment_terms) || 0}
                      placeholder={'- Select Mode of Payment -'}
                      name={'payment_terms'}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Initial Stocking
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownCustom
                      data={stockingTerm}
                      valueField={'payment_terms_id'}
                      nameField={'payment_terms'}
                      selected={parseInt(form.initial_stocking_terms) || 0}
                      placeholder={'- Select Initial Stocking -'}
                      name={'initial_stocking_terms'}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className="details-field-set mb-4 mt-0">
          <legend>Billing Address</legend>
          <div className="col-md-12">
            <div className="row">
              {/*  */}
              <div className="col-lg-2">
                <label className="col-form-label col-form-label-sm">
                  Bldg & Street Address
                  <span className="requiredField"> *</span>
                </label>
                <PrimaryToolTip />
              </div>
              <div className="col-lg-9">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="billing_bldg_street_address"
                  id="billing_bldg_street_address"
                  value={form.billing_bldg_street_address}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/*  */}
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Province
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownProvince
                      classWrapper="form-control form-control-sm"
                      name={'billing_province'}
                      value={form.billing_province}
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'billing_province', value },
                        })
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      City
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownCities
                      classWrapper={'form-control form-control-sm'}
                      provinceId={form.billing_province}
                      value={form.billing_city}
                      name="billing_city"
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'billing_city', value },
                        })
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Barangay
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownBarangays
                      classWrapper={'form-control form-control-sm'}
                      cityId={form.billing_city}
                      name="billing_barangay"
                      value={form.billing_barangay}
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'billing_barangay', value },
                        })
                      }
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      Zip Code
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <DropdownZipCodes
                      classWrapper={'form-control form-control-sm'}
                      cityId={form.billing_city}
                      name="billing_zip_code"
                      value={form.billing_zip_code}
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'billing_zip_code', value },
                        })
                      }
                    />
                  </div>
                </div>
                {/*  */}
              </div>

              <div className="col-md-6">
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      LBA Code
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="billing_lba_code"
                      id="billing_lba_code"
                      value={form.billing_lba_code}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      LBA Name
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="billing_lba_name"
                      id="billing_lba_name"
                      value={form.billing_lba_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label className="col-form-label col-form-label-sm">
                      District
                      <span className="requiredField"> *</span>
                    </label>
                    <PrimaryToolTip />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="billing_district"
                      id="billing_district"
                      value={form.billing_district}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </Fragment>
  );
};

export default Billing;
