import { Tabs, Tab } from 'react-bootstrap';
import '../../../globalStyles/css/bootstrap/bootstrap.min.css';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';

import PrimaryDataTable from '../../../components/PrimaryDataTable';

import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';
import { trialBalanceDataCol } from '../const';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';

function TrialBalanceCustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

const minDate = '2022-11-30';

const TrialBalanceReportScreen = ({
  handleChange,
  formData,
  dateChange,
  selectedTab,
  handleSearch,
  trialBalanceData,
  handleDataTableChange,
  handleTableFilter,
  loading,
  totalRow,
  reloadTable,
  handleExport,
  handleClickAccountCode,
}) => {
  const columns = trialBalanceDataCol({
    onClickAccountCode: handleClickAccountCode,
  });
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <h4 className="headline">Trial Balance</h4>
      <br />
      <Tabs
        id="controlled-tab-example"
        activeKey={selectedTab}
        className="default-tab"
      >
        <Tab
          className="default-tab-content"
          eventKey="trial_balance"
          title="Trial Balance"
        >
          <div className="row">
            <PermissionsGate scopes={['get_trial_balance']}>
              <div className="col-md-2">
                <label>Date From</label>
                <label className="req">*</label>
                {/* <input type="date" className="form-control form-control-sm" name="date_from" id="date-from" onChange={dateChange}/>
                 */}

                <PrimaryDatePicker
                  name={'date_from'}
                  datePickerFormat={'yyyy-MM-dd'}
                  datePickerFormatString={'YYYY-MM-DD'}
                  value={formData.date_from}
                  onChange={(date) =>
                    dateChange({
                      target: { name: 'date_from', value: date },
                    })
                  }
                  minDate={minDate}
                />
              </div>
              <div className="col-md-2">
                <label>Date To</label>
                <label className="req">*</label>
                {/* <input type="date" className="form-control form-control-sm" name="date_to" id="date-to" onChange={dateChange}/> */}
                <PrimaryDatePicker
                  name={'date_to'}
                  datePickerFormat={'yyyy-MM-dd'}
                  datePickerFormatString={'YYYY-MM-DD'}
                  value={formData.date_to}
                  onChange={(date) =>
                    dateChange({
                      target: { name: 'date_to', value: date },
                    })
                  }
                  minDate={minDate}
                />
              </div>
              <div className="col-md-2">
                <label>&nbsp;</label>
                <div>
                  <button className="btn btn-primary" onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
            </PermissionsGate>
            <div className="text-right mb-3" style={{ marginTop: '-80px' }}>
              <label>&nbsp;</label>
              <div>
                <PermissionsGate scopes={['export_trial_balance']}>
                  <button className=" btn btn-info" onClick={handleExport}>
                    Export
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>

          <br />
          <PermissionsGate
            scopes={['get_trial_balance']}
            RenderError={AlertDefaultError}
          >
            <PrimaryDataTable
              rows={trialBalanceData}
              columns={columns}
              rowCount={totalRow}
              onChange={handleDataTableChange}
              getRowId={(row) => row.id}
              loading={loading}
              mode="server"
              filterMode="server"
              onFilterModelChange={handleTableFilter}
              components={{
                Toolbar: TrialBalanceCustomGridToolbar,
              }}
            />
          </PermissionsGate>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TrialBalanceReportScreen;
