import { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import Select from "react-select";
import { toast } from "react-toastify";
import { getAccountOfficers } from "../../../../api/customerAccountOfficer";
import AccountOfficerFormModal from "../AccountOfficerFormModal.js";
import "./styles.css";

const AccountOfficerDropdown = ({ selected, onChange }) => {
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [toggleAccountOfficerModal, setToggleAccountOfficerModal] =
    useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedId === "" && selected) {
      setSelectedId(selected);
    }
  }, [selected]);

  const loadData = async () => {
    try {
      const res = await getAccountOfficers();

      const { code, result } = res.data.response;

      if (code !== "00000") {
        setData([]);
        toast.error(Array.isArray(result) ? result.join("<br />") : result);
        return;
      }

      setData(
        result.map((row) => ({
          value: row.ao_id,
          label: row.ao_name,
        }))
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    setSelectedId(e.value);
    onChange({
      target: { name: "AO", value: e.value },
    });
  };

  const handleAccountOfficerFormToggle = () =>
    setToggleAccountOfficerModal(!toggleAccountOfficerModal);

  const handleAccountOfficerFormSubmitted = async (data) => {
    if (data.success) {
      loadData();
      handleAccountOfficerFormToggle();

      setSelectedId(data.result?.ao_id);
      onChange({
        target: { name: "AO", value: data.result?.ao_id },
      });
    }
  };

  return (
    <div>
      <div className="dropdown-account-officer-container">
        <InputGroup className="mb-3">
          <Button
            variant="primary"
            id="button-dropdown-account-officer"
            className="dropdown-account-officer-add-button"
            onClick={handleAccountOfficerFormToggle}
          >
            <PlusCircleFill className="dropdown-account-officer-add-icon" />
          </Button>
          <Select
            options={data}
            value={
              selectedId ? data.find((row) => row.value === selectedId) : ""
            }
            className="form-control padding-0 dropdown-account-officer-select"
            classNamePrefix="dropdown-account-officer-select_"
            onChange={handleChange}
          />
        </InputGroup>
      </div>
      <AccountOfficerFormModal
        toggle={toggleAccountOfficerModal}
        onClose={handleAccountOfficerFormToggle}
        onSubmitted={handleAccountOfficerFormSubmitted}
      />
    </div>
  );
};

export default AccountOfficerDropdown;
