import React, { Fragment, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { productLedgerColumn } from "./const";
import moment from "moment";

import "./productFormModal.css";
import _ from "lodash";
import Select from "react-select";
import { getBreakDownComputationPerModule } from "../../../api/ledgers";
import { toast } from "react-toastify";

const formDefault = {
  product_id: "",
  date_from: moment().subtract(20, "days").format("YYYY-MM-DD"),
  date_to: moment().format("YYYY-MM-DD"),
  page: 0,
  page_size: 50,
  order_by: "",
  direction: "",
  batch_no: "",
};
const ProductLedgerFormModal = ({ moduleData, toggle, closeModal }) => {
  const [form, setForm] = useState(formDefault);
  const [batchDropdown, setBatchDropdown] = useState([]);

  const [productListings, setProductListings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState(productLedgerColumn);
  const loadData = (formData) => {
    getBreakDownComputationPerModule(formData)
      .then((res) => {
        const { code, result } = res.data.response;

        if (code === "00000") {
          setProductListings(result);
        }
      })
      .catch(() => {
        toast.error("Unable to load Data");
      });
  };

  const loadModuleFilterData = (formData) => {
    getBreakDownComputationPerModule(formData)
      .then((res) => {
        const { code, result } = res.data.response;
        if (code === "00000") {
          let newForm = _.uniqBy(result, "batch_no");

          setBatchDropdown([
            ...newForm.map((form) => ({
              label: form.batch_no,
              value: form.batch_no,
            })),
          ]);
        }
      })
      .catch(() => {
        toast.error("Test");
      });
  };

  const handleOnEnter = () => {
    let tmpForm = { ...formDefault, ...moduleData };
    setForm(tmpForm);

    loadData(tmpForm);
    loadModuleFilterData(tmpForm);
  };

  const handleChange = (e) => {
    let tmpForm = { ...formDefault, ...moduleData, batch_no: "" };
    if (e !== null) {
      tmpForm = { ...tmpForm, batch_no: e.value };
    }

    setForm(tmpForm);
  };

  const handleSearch = () => {
    loadData(form);
  };

  const indexHolder = [
    0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ];

  if (moduleData) {
    if (moduleData.module === "Beg Balance") {
      indexHolder.map((index) => {
        if (index === 17 || index === 18 || index === 19) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }

    if (moduleData.module === "RR") {
      indexHolder.map((index) => {
        if (
          index === 0 ||
          index === 2 ||
          index === 3 ||
          index === 4 ||
          index === 5 ||
          index === 6 ||
          index === 7
        ) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }

    if (moduleData.module === "SO") {
      indexHolder.map((index) => {
        if (
          index === 0 ||
          index === 2 ||
          index === 3 ||
          index === 4 ||
          index === 8 ||
          index === 9 ||
          index === 10
        ) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }

    if (moduleData.module === "IA") {
      indexHolder.map((index) => {
        if (
          index === 0 ||
          index === 2 ||
          index === 3 ||
          index === 4 ||
          index === 14 ||
          index === 15 ||
          index === 16
        ) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }

    if (moduleData.module === "IT") {
      indexHolder.map((index) => {
        if (
          index === 0 ||
          index === 2 ||
          index === 3 ||
          index === 4 ||
          index === 11 ||
          index === 12 ||
          index === 13
        ) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }

    if (moduleData.module === "Ending Balance") {
      indexHolder.map((index) => {
        if (index === 17 || index === 18 || index === 19) {
          productLedgerColumn[index].hide = false;
        } else {
          productLedgerColumn[index].hide = true;
        }
      });
    }
  }

  return (
    <Fragment>
      <Modal
        show={toggle}
        onHide={closeModal}
        size="xl"
        onEnter={handleOnEnter}
        backdrop="static"
        dialogClassName="modal-width-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Inventory Per Batch Number</Modal.Title>
        </Modal.Header>

        <Modal.Body className="disable-check-all-on-customer-aging-per-si">
          <Row>
            <Col lg={12}>
              <fieldset className="details-field-set mb-4 mt-0">
                <legend>Batch Information</legend>
                <Row>
                  <Col lg="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Module Name<span className="req">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        name="module_name"
                        value={
                          moduleData === undefined
                            ? " - "
                            : moduleData.module_name
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Date Range <span className="req">*</span>
                        <Form.Control
                          type="text"
                          readOnly
                          name="date_range"
                          value={
                            moduleData === undefined
                              ? " - "
                              : moment(moduleData.date_from).format("ll") +
                                " - " +
                                moment(moduleData.date_to).format("ll")
                          }
                        />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <fieldset className="details-field-set mb-4 mt-0">
                <legend>Batch Details</legend>
                <Row className="d-flex flex-row">
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label>Select Batch</Form.Label>

                      <Select
                        options={batchDropdown}
                        placeholder={"- Select Batch No. -"}
                        name="batch_no"
                        onChange={handleChange}
                        isClearable={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1"></Form.Label>
                      <br />

                      <Button variant="success" onClick={handleSearch}>
                        Search
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" className="table-responsive">
                    <Row>
                      <PrimaryDataTable
                        rows={productListings}
                        getRowId={(row) => row.id}
                        columns={columns}
                        experimentalFeatures={{ rowGrouping: true }}
                        loading={loading}
                        hideFooterSelectedRowCount
                        getRowClassName={(params) => {
                          if (params.row.status === 0) {
                            return "pending-status-text";
                          }
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="warning" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ProductLedgerFormModal;
