import { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { postAddPurchaseLogs } from '../../../api/product';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { numberFormatCurrency } from '../../../utils/helper';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';
import Swal from 'sweetalert2';

const AddLog = ({ product, visible, productCode }) => {
  const emptyValues = {
    product_id: product.product_id,
    batch: '',
    quantity_ordered: 0,
    received_quantity: 0,
    unit_price: 0,
    unit_vat_inclusive: 0,
    unit_vat_exclusive: 0,
    free_goods: 0,
    diluted_cost: 0,
    expiry_date: '',
    manufacturing_date: '',
  };
  const [form, setForm] = useState(emptyValues);

  const submitHandler = async () => {
    toast.loading('Processing request please wait', {
      autoClose: false,
    });

    const res = await postAddPurchaseLogs(form);
    const { code, result } = res.data.response;

    toast.dismiss();
    code === '00000'
      ? Swal.fire({
          icon: 'success',
          title: 'Sucess',
          text: result,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      : code === '00001'
      ? Swal.fire({
          icon: 'error',
          title: 'Invalid Request',
          html: result.join('<br/>'),
        })
      : Swal.fire({
          icon: 'error',
          title: 'Someting went wrong...',
          text: result,
        });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleExpiryDateChange = (date) => {
    setForm({ ...form, expiry_date: date });
  };

  const handleManufacturingDateChange = (date) => {
    setForm({ ...form, manufacturing_date: date });
  };

  return (
    <div>
      <Fragment>
        <div className="container-fluid">
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Batch No
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name="batch"
                  id="batch"
                  defaultValue={form.batch}
                  style={{ marginTop: '6px' }}
                  onChange={handleChange}
                  placeholder="Batch No"
                  novalidate
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Ordered Quantity
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  name="quantity_ordered"
                  id="quantity_ordered"
                  defaultValue={form.quantity_ordered}
                  style={{ marginTop: '6px' }}
                  onChange={handleChange}
                  placeholder="Ordered Quantity"
                  novalidate
                  min={0}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Received Quantity
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  name="received_quantity"
                  id="received_quantity"
                  defaultValue={form.received_quantity}
                  style={{ marginTop: '6px' }}
                  onChange={handleChange}
                  placeholder="Received Quantity"
                  novalidate
                  min={0}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Unit Price
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1-php">PHP</InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control form-control-sm"
                    name="unit_price"
                    id="unit_price"
                    value={form.unit_price}
                    style={{ marginTop: '6px' }}
                    onChange={handleChange}
                    placeholder="Unit Price"
                    novalidate
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Unit Vat Inclusive
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1-php">PHP</InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control form-control-sm"
                    name="unit_vat_inclusive"
                    id="unit_vat_inclusive"
                    value={form.unit_vat_inclusive}
                    style={{ marginTop: '6px' }}
                    onChange={handleChange}
                    placeholder="Unit Vat Inclusive"
                    novalidate
                    min={0}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Unit Vat Exclusive
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1-php">PHP</InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control form-control-sm"
                    name="unit_vat_exclusive"
                    id="unit_vat_exclusive"
                    value={form.unit_vat_exclusive}
                    style={{ marginTop: '6px' }}
                    onChange={handleChange}
                    placeholder="Unit Vat Exclusive"
                    novalidate
                    min={0}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Free Goods
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  name="free_goods"
                  id="free_goods"
                  defaultValue={form.free_goods}
                  style={{ marginTop: '6px' }}
                  onChange={handleChange}
                  placeholder="Free Goods"
                  novalidate
                  min={0}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Deluted Cost
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1-php">PHP</InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="form-control form-control-sm"
                    name="diluted_cost"
                    id="diluted_cost"
                    value={form.diluted_cost}
                    style={{ marginTop: '6px' }}
                    onChange={handleChange}
                    placeholder="Deluted Cost"
                    novalidate
                    min={0}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Expiry Date
              </Form.Label>
              <Col sm="8">
                <PrimaryDatePicker
                  name="expiry_date"
                  value={form.expiry_date}
                  onChange={handleExpiryDateChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Manufacturing Date
              </Form.Label>
              <Col sm="8">
                <PrimaryDatePicker
                  name="manufacturing_date"
                  value={form.manufacturing_date}
                  onChange={handleManufacturingDateChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <div className="text-right">
              <Button
                variant="success"
                onClick={submitHandler}
                style={{ width: '100px' }}
              >
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </Fragment>
    </div>
  );
};

export default AddLog;
