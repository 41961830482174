import { Fragment } from "react";

const RolesForm = ({
  form,
  handleChange
}) => {
  return (<Fragment>
    <div className="card-body">
      <div className="form-group">
        <label>Role</label>
        <input
          className="form-control"
          name="role"
          type="text"
          value={form.role}
          onChange={(e) => handleChange(e) }
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input
          className="form-control"
          name="description"
          type="text"
          value={form.description}
          onChange={(e) => handleChange(e) }
          required
        />
      </div>
    </div>
  </Fragment>)
}

export default RolesForm;