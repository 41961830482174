import { Checkbox } from "@mui/material";
import { Link } from "react-router-dom";

export const productDataColumn = ({
  selectionModel,
  handleOnSelectProduct,
}) => [
  {
    field: "actions",
    type: "actions",
    width: 70,
    headerClassName: "p-1",
    renderHeader: () => <Checkbox label="" color="primary" disabled />,
    renderCell: ({ row }) => {
      return (
        <Checkbox
          label=""
          color="primary"
          checked={selectionModel.includes(row.id)}
          value={row.is_selected}
          name="customer_selected"
          onChange={(e) =>
            handleOnSelectProduct(
              {
                target: {
                  name: "is_selected",
                  value: parseInt(row.is_selected) === 1 ? 0 : 1,
                },
              },
              row
            )
          }
        />
      );
    },
  },
  {
    field: "product_code",
    headerName: "Product Code",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "product_name",
    headerName: "Product Name",
    minWidth: 280,
    flex: 1,
  },
  {
    field: "product_segment",
    headerName: "Product Segment",
    minWidth: 180,
    flex: 1,
    valueGetter: ({ row }) => row.product_segment?.segment_name,
  },
  {
    field: "supplier",
    headerName: "Supplier",
    minWidth: 180,
    flex: 1,
    valueGetter: ({ row }) => row.get_supplier?.company_name,
  },
  {
    field: "stock_level",
    headerName: "Current Level",
    minWidth: 180,
    type: "number",
    flex: 1,
  },
  {
    field: "supplier_lead_time",
    headerName: "Lead Time",
    minWidth: 180,
    type: "number",
    flex: 1,
    renderCell: ({ row }) => (
      <Link to={"/view/products/list/" + row.product_code}>
        {row.supplier_lead_time}
      </Link>
    ),
  },
];
