import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { orderColumn } from './const';
import PrimaryDataTable from '../PrimaryDataTable';
import debounce from 'lodash.debounce';

const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const CustomerOrderModal = ({
  selectionModel,
  setSelectionModel,

  handleModalCustomerOrder,
  handleModalCustomerOrderSet,

  customerOrderData,
  customerOrderLoading,
  handleSelectedOrderProduct,
}) => {
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  useEffect(() => setLoader(customerOrderLoading), [customerOrderLoading]);

  useEffect(() => {
    setFilteredData(customerOrderData);
  }, [customerOrderData]);

  const filterProducts = (event) => {
    const searchRegex = new RegExp(escapeRegExp(event.target.value), 'i');
    const filteredRows = customerOrderData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] !== null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setLoader(false);
    setFilteredData(filteredRows);
  };

  const filterProductsDebounce = debounce(
    (event) => filterProducts(event),
    800,
  );

  const handleFilterProducts = (event) => {
    setSearchText(event.target.value);
    setLoader(true);
    setFilteredData([]);
    filterProductsDebounce(event);
  };

  const handleFilterModelChange = (filterModel) => {
    if (Array.isArray(filterModel.items)) {
      const searchValue = filterModel.items[0]?.value || '';

      filterProducts({
        target: {
          value: searchValue,
        },
      });

      setFilterModel(filterModel);
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        show={handleModalCustomerOrder}
        onHide={() => handleModalCustomerOrderSet(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-width"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Select Products </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <input
                type="text"
                className="form-control"
                onChange={handleFilterProducts}
                value={searchText}
                placeholder="Search here..."
              />
            </div>
            <div className="col-md-12">
              <PrimaryDataTable
                disableSelectionOnClick={true}
                rows={filteredData}
                columns={orderColumn}
                getRowId={(row) => row.transaction_id}
                checkboxSelection={true}
                loading={loader}
                onSelectionModelChange={(param) => {
                  setSelectionModel(param);
                  handleSelectedOrderProduct(
                    filteredData.filter((value) => {
                      return param.includes(value.transaction_id);
                    }),
                  );
                }}
                selectionModel={selectionModel}
                filterMode="server"
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-success"
            onClick={() => handleModalCustomerOrderSet(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerOrderModal;
