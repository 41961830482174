import { Link } from "react-router-dom";

export const requestColumn = ({ handleChangeDetails }) => [
  {
    field: "product_code",
    headerName: "Product Code",
    minWidth: 150,
    width: 180,
  },
  {
    field: "product_name",
    headerName: "Product Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "unit",
    headerName: "UOM",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.unit?.unit_name,
  },
  {
    field: "product_segment",
    headerName: "Product Segment",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.product_segment?.segment_name,
  },
  {
    field: "supplier",
    headerName: "Supplier",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.get_supplier?.company_name,
  },
  {
    field: "stock_level",
    headerName: "Current Level",
    minWidth: 180,
    type: "number",
    flex: 1,
  },
  {
    field: "supplier_lead_time",
    headerName: "Lead Time",
    minWidth: 180,
    type: "number",
    flex: 1,
    renderCell: ({ row }) => (
      <Link to={"/view/products/list/" + row.product_code}>
        {row.supplier_lead_time}
      </Link>
    ),
  },
  {
    field: "quantity",
    headerName: "MOQ",
    minWidth: 180,
    type: "number",
    flex: 1,
  },
  {
    field: "requested_qty",
    headerName: "Quantity",
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <input
        type="number"
        className="form-control form-control-sm"
        value={row.requested_qty}
        min={row.quantity}
        step={row.quantity}
        onChange={(e) =>
          handleChangeDetails(
            {
              target: {
                name: "requested_qty",
                value: e.target.value,
              },
            },
            row
          )
        }
        readOnly={row.status !== "" && [1, 3].includes(parseInt(row.status))}
        onKeyDown={(e) => e.stopPropagation()}
      />
    ),
  },
  {
    field: "total_amount",
    headerName: "Total",
    minWidth: 180,
    type: "number",
    flex: 1,
    valueGetter: ({ row }) =>
      parseFloat(row.requested_qty) * parseFloat(row.cost),
  },
];
