import { Form, InputGroup } from "react-bootstrap";
import { numberFormatCurrency } from "../../../utils/helper";

//CustomerTotals
const CustomerTotal = ({ data }) => {
  return (
    <dl class="row mt-3">
      <dt class="col-sm-6">Total Outstanding Balance</dt>
      <dd class="col-sm-6" data-test={data.total_outstanding}>
        <InputGroup className="">
          <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>
          <Form.Control
            type="text"
            className="text-right"
            placeholder="0.00"
            name="amount_applied"
            value={numberFormatCurrency(data.total_outstanding)}
            readOnly
          />
        </InputGroup>
      </dd>

      <dt class="col-sm-6">Total PDC</dt>
      <dd class="col-sm-6 text-right">
        <InputGroup className="">
          <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>
          <Form.Control
            type="text"
            className="text-right"
            placeholder="0.00"
            name="amount_applied"
            value={numberFormatCurrency(data.total_pdc)}
            readOnly
          />
        </InputGroup>
      </dd>
      <br />
      <dt class="col-sm-6">Total Unapplied Advances</dt>
      <dd class="col-sm-6 text-right">
        <InputGroup className="">
          <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>
          <Form.Control
            type="text"
            className="text-right"
            placeholder="0.00"
            name="amount_applied"
            value={numberFormatCurrency(data.total_advances)}
            readOnly
          />
        </InputGroup>
      </dd>

      <dt class="col-sm-6">Total Returns on-process</dt>
      <dd class="col-sm-6 text-right">
        <InputGroup className="">
          <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>
          <Form.Control
            type="text"
            className="text-right"
            placeholder="0.00"
            name="amount_applied"
            value={numberFormatCurrency(data.total_returns)}
            readOnly
          />
        </InputGroup>
      </dd>
      {/* <div>
        <hr />
      </div> */}
      <dt class="col-sm-6">Total Amount Due</dt>
      <dd class="col-sm-6 text-right">
        <InputGroup className="">
          <InputGroup.Text id="basic-addon2">PHP</InputGroup.Text>
          <Form.Control
            type="text"
            className="text-right"
            placeholder="0.00"
            name="amount_applied"
            value={numberFormatCurrency(data.total_due)}
            readOnly
          />
        </InputGroup>
      </dd>
    </dl>
  );
};

export default CustomerTotal;
