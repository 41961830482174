import { lazy } from "react";

const SaleInvoiceManualEntry = lazy(() =>
  import("../pages/SalesInvoiceManualEntry")
);

const salesInvoiceRoute = {
  public: [],
  auth: [
    {
      name: "sale_invoice_manual_entry",
      path: "/sales-invoice/manual/entry",
      component: SaleInvoiceManualEntry,
    },
  ]
}

export default salesInvoiceRoute;