import { lazy } from "react";

const DebitMemoLogic = lazy(() => import("../pages/DebitMemo/DebitMemoLogic"));

const DebitMemoRoute = {
  public: [],
  auth: [
    {
      name: "debit_memo",
      path: "/debit-memo/:status?",
      component: DebitMemoLogic,
    },
  ],
};

export default DebitMemoRoute;
