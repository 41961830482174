import { useState } from "react";

import { InputGroup } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const PrimaryInputPassword = ({
  autocomplete,
  onChange,
  required,
  tabIndex,
  placeholder,
  id,
  name,
}) => {
  const [showPassword, setPassword] = useState(false);

  const handleClickShowPassword = () => {
    setPassword(!showPassword);
  };

  return (
    <InputGroup className="input-group-flat">
      <input
        type={showPassword ? "text" : "password"}
        className="form-control"
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        autoComplete={autocomplete}
        tabIndex={tabIndex}
      />
      <span
        className="input-group-text"
        style={{ cursor: "pointer " }}
        title="Show Password"
        data-toggle-bs="tooltip"
        onClick={() => handleClickShowPassword(!showPassword)}
      >
        {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
      </span>
    </InputGroup>
  );
};

export default PrimaryInputPassword;
