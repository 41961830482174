import { NavLink } from "react-router-dom";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";

import PrimaryInputPassword from "../../../components/PrimaryInputPassword";

const LoginScreen = ({ form, onHandleChange, onSubmit }) => {
  return (
    //renaming foldername
    <div data-dev="1">
      <div className="page page-center">
        <div className="container-tight py-4">
          <div className="text-center mb-4">
            <span>
              <img
                src="/Stacked Version.png"
                height="120px !important"
                alt=""
              />
            </span>
          </div>
          <form
            className="card card-md"
            method="get"
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  id="username"
                  name="username"
                  value={form.username}
                  onChange={onHandleChange}
                  tabIndex={1}
                />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Password{" "}
                  <span className="form-label-description">
                    <NavLink to="/provide-email">Forgot password</NavLink>
                  </span>
                </label>
                <PrimaryInputPassword
                  placeholder="Password"
                  value={form.password}
                  onChange={onHandleChange}
                  id="password"
                  name="password"
                  required={true}
                  autocomplete="off"
                  tabIndex={2}
                />
              </div>
              <div className="form-footer">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  value="Log In"
                  tabIndex={3}
                >
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
