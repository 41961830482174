import { Tabs, Tab } from "react-bootstrap";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";
import "../ledger.css";
import Select from "react-select";
import { GridToolbarExport } from "@mui/x-data-grid-pro";
import {
  AlertDefaultError,
  PermissionsGate,
} from "../../../components/PermissionsGate";
import YearDatePicker from "../../../components/YearDatePicker";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { ytdColumns } from "../YtdSales/const";
import "../ledger.css";

const SalesLedgerReportScreen = ({
  formData,
  handleChange,
  handleYearChange,
  handleSearch,
  ytdSalesData,
  loading,
  totalRow,
  selectedTab,
  handleSelectedTab,
  handleDataTableChange,
  reportType,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab eventKey="inventories_ledger" title="Inventory Sub Ledger"></Tab>
          <Tab
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          ></Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab
            className="default-tab-content"
            eventKey="ytd_sales_ledger"
            title="YTD Sales Ledger"
          >
            <div className="col-md-12">
              <div className="row">
                <PermissionsGate scopes={["view_ytd_sales_ledger_list"]}>
                  <div className="col-md-5">
                    <label>Report Type</label>
                    <Select
                      options={reportType}
                      placeholder={"- Select Type of Report -"}
                      name="type"
                      onChange={handleChange}
                      isClearable={true}
                    />
                  </div>

                  <div className="col-md-3">
                    <label>Year</label>
                    <label className="req">*</label>
                    <YearDatePicker
                      name={"date_year"}
                      datePickerFormat={"yyyy"}
                      datePickerFormatString={"yyyy"}
                      value={formData.date_year}
                      showYearPicker
                      onChange={handleYearChange}
                      showFourColumnYearPicker
                      yearItemNumber={10}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </PermissionsGate>
                {/* <div className="col-md-2 text-right">
                  <label>&nbsp;</label>
                  <div>
                    <PermissionsGate
                      scopes={["export_sales_special_journal_list"]}
                    >
                      <button
                        className=" btn btn-success"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </PermissionsGate>
                  </div>
                </div> */}
              </div>
              <br />
              <PermissionsGate
                scopes={["view_ytd_sales_ledger_list"]}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={ytdSalesData}
                  columns={ytdColumns}
                  getRowId={(row) => row.id}
                  disableColumnFilter
                  rowCount={totalRow}
                  loading={loading}
                  hideFooterSelectedRowCount
                  onChange={handleDataTableChange}
                  getRowClassName={(params) => {
                    if (params.row.type_name === "Grand Total") {
                      return "grand-total-text";
                    }
                  }}
                  components={{
                    Toolbar: GridToolbarExport,
                  }}
                />
              </PermissionsGate>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default SalesLedgerReportScreen;
