import { Tabs, Tab, InputGroup, FormControl } from "react-bootstrap";
import "../../../globalStyles/css/bootstrap/bootstrap.min.css";
import PrimaryAsyncSelect from "../../../components/PrimaryAsyncSelect";
import PrimaryDatePicker from "../../../components/PrimaryDatePicker";
import PrimaryDataTable from "../../../components/PrimaryDataTable";
import { getCustomers } from "../../../api/ledgers";
import "../ledger.css";
import Select from "react-select";

import {
  AlertDefaultError,
  PermissionsGate,
} from "../../../components/PermissionsGate";
import { salesPerProductDataCol } from "../const";

const SalesPerProductReportScreen = ({
  formData,
  salesPerProductData,
  loading,
  totalRow,
  reloadTable,
  selectedTab,
  handleSelectedTab,
  handleChange,
  handleDataTableChange,
  dateChange,
  handleSearch,
  handleExport,
  productsOption,
  handleChangeProducts,
  overallNetTotal,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <br />
      <div className="col-md-12">
        <h4 className="headline">SUB LEDGERS</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          className="default-tab"
        >
          <Tab eventKey="sales_ledger" title="Sales Special Journal"></Tab>
          <Tab
            eventKey="receivables_ledger"
            title="Receivable Sub Ledger"
          ></Tab>
          <Tab eventKey="inventories_ledger" title="Inventory Sub Ledger"></Tab>
          <Tab
            className="default-tab-content"
            eventKey="sales_per_product_ledger"
            title="Sales Per Product Ledger"
          >
            <div className="col-md-12">
              <div className="row">
                <PermissionsGate
                  scopes={["view_sales_per_product_ledger_list"]}
                >
                  <div className="col-md-3">
                    <label>Select Customer</label>
                    <PrimaryAsyncSelect
                      onChange={(e) =>
                        handleChange({
                          target: { name: "customer_name", value: e.value },
                        })
                      }
                      customFunction={getCustomers}
                      isClearable={false}
                      name={"customer_name"}
                      value={formData.customer_id}
                      config={{
                        searchField: "keyword",
                        valueField: "customer_id",
                        labelField: "customer_name",
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <label>Select Products</label>
                    <Select
                      isMulti
                      options={productsOption}
                      onChange={(event) =>
                        handleChangeProducts({
                          target: {
                            name: "products",
                            value: event,
                          },
                        })
                      }
                      // value={productsOption.find(
                      //   (row) => row.value === paginate.product
                      // )}
                      placeholder="-Select Products-"
                      isClearable={true}
                    />
                  </div>

                  <div className="col-md-2">
                    <label>Date From</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={"date_from"}
                      datePickerFormat={"yyyy-MM-dd"}
                      datePickerFormatString={"YYYY-MM-DD"}
                      value={formData.date_from}
                      onChange={(date) =>
                        dateChange({
                          target: { name: "date_from", value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date To</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={"date_to"}
                      datePickerFormat={"yyyy-MM-dd"}
                      datePickerFormatString={"YYYY-MM-DD"}
                      value={formData.date_to}
                      onChange={(date) =>
                        dateChange({
                          target: { name: "date_to", value: date },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-1">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </PermissionsGate>

                <div className="col-md-1 text-right">
                  <label>&nbsp;</label>
                  <div>
                    <PermissionsGate
                      scopes={["export_sales_per_product_ledger_list"]}
                    >
                      <button
                        className=" btn btn-success"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
              <br />
              <PermissionsGate
                scopes={["view_sales_per_product_ledger_list"]}
                RenderError={AlertDefaultError}
              >
                <PrimaryDataTable
                  rows={salesPerProductData}
                  columns={salesPerProductDataCol}
                  getRowId={(row) => row.id}
                  onChange={handleDataTableChange}
                  disableColumnFilter
                  rowCount={totalRow}
                  loading={loading}
                  mode="server"
                  reload={reloadTable}
                  getRowClassName={(params) => {
                    if (params.row.year === "Total") {
                      return "ending_balance";
                    }
                  }}
                />
                <br />
              </PermissionsGate>
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">Overall Net Total:</div>
                <div className="col-md-9"></div>
                <InputGroup className="col-md-3 text-right">
                  <InputGroup.Text id="basic-addon1">Php</InputGroup.Text>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="overall"
                    id="overall"
                    value={overallNetTotal}
                    readOnly
                  />
                </InputGroup>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="vatable_sales_ledger"
            title="Vatable Sales Ledger"
          ></Tab>
          <Tab eventKey="product_ledger" title="Product Ledger"></Tab>
          <Tab eventKey="ytd_sales_ledger" title="YTD Sales Ledger"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default SalesPerProductReportScreen;
