import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const tabIndex = [
  // {
  //   key: "product_pending",
  //   title: "Transfer Request List",
  //   status: 0,
  //   active: "list",
  //   can: ["view_inventory_transfer_list"],
  // },
  {
    key: 'product_pending_confirmation',
    title: 'Stock Allocation',
    status: 0,
    active: 'list-confirmation',
    can: ['view_inventory_transfer_confirmation'],
  },
  // {
  //   key: "product_confirmed",
  //   title: "Allocation Approval",
  //   status: 2,
  //   active: "list-confirmed",
  //   can: ["view_inventory_transfer_confirmed"],
  // },
  {
    key: 'product_approved',
    title: 'Transfer Request (In Transit)',
    status: 1,
    active: 'list-approved',
    can: ['view_inventory_transfer_in_transit'],
  },
  {
    key: 'product_received',
    title: 'Received & Completed',
    status: 6,
    active: 'list-received',
    can: ['view_inventory_transfer_received'],
  },
  // {
  //   key: "product_posted",
  //   title: "Completed Transfers",
  //   status: 3,
  //   active: "list-posted",
  //   can: ["view_inventory_transfer_posted"],
  // },
  {
    key: 'product_cancelled',
    title: 'Cancelled Transfers',
    status: 4,
    active: 'list-cancelled',
    can: ['view_inventory_transfer_cancelled'],
  },
  // {
  //   key: "product_closed",
  //   title: "Transfer Request (Closed)",
  //   status: 5,
  //   active: "list-closed",
  //   can: ["view_inventory_transfer_closed"],
  // },
];

export const statusRequest = (status) => {
  switch (parseInt(status)) {
    case 0:
      return 'Pending';
    case 1:
      return 'Approved';
    case 2:
      return 'Confirmed';
    case 3:
      return 'Posted';
    case 4:
      return 'Cancelled';
    case 5:
      return 'Closed';
    default:
      return '';
  }
};

// Product Requset List
export const productRequestListColumn = ({
  handleViewRequestDetails,
  handleShowApprovedModal,
  handleShowPostedModal,
  handleReceiveRequestDetails,
  tabActiveIndex,
}) => {
  return [
    {
      field: 'transfer_code',
      headerName: 'Transfer Code',
      minWidth: 170,
      flex: 1,
      renderCell: (productsData) => {
        if (parseInt(tabActiveIndex.status) === 6) {
          return (
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={(e) =>
                handleReceiveRequestDetails(productsData.row.transfer_code)
              }
            >
              {productsData.row.transfer_code}
            </span>
          );
        } else {
          if (
            parseInt(productsData.row.status) === 0 ||
            parseInt(productsData.row.status) === 4 ||
            parseInt(productsData.row.status) === 2
          ) {
            return (
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={(e) =>
                  handleViewRequestDetails(productsData.row.transfer_code)
                }
              >
                {productsData.row.transfer_code}
              </span>
            );
          }

          if (parseInt(productsData.row.status) === 1) {
            return (
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={(e) =>
                  handleShowApprovedModal(productsData.row.transfer_code)
                }
              >
                {productsData.row.transfer_code}
              </span>
            );
          }

          if (
            parseInt(productsData.row.status) === 3 ||
            parseInt(productsData.row.status) === 5
          ) {
            return (
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={(e) =>
                  handleShowPostedModal(productsData.row.transfer_code)
                }
              >
                {productsData.row.transfer_code}
              </span>
            );
          }
        }
      },
    },
    {
      field: 'products',
      headerName: 'Products Transfer',
      minWidth: 170,
      sortable: false,
      flex: 3,
    },
    {
      field: 'transfer_type',
      headerName: 'Transfer Type',
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.transfer_version === 'v1') {
          return <span className="badge badge-danger">Floor to Floor</span>;
        }
        if (row.transfer_version === 'v2') {
          return <span className="badge badge-success">Replenishment</span>;
        }
      },
    },
    {
      field: 'posting_date',
      headerName: 'Requested Date',
      minWidth: 170,
      type: 'date',
      flex: 1,
    },
    {
      field: 'requested_by',
      headerName: 'Requested By',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => {
        if (parseInt(row.status) === 0) {
          return <span className="badge bg-warning">PENDING</span>;
        }
        if (parseInt(row.status) === 1) {
          return <span className="badge bg-green">PENDING</span>;
        }
        if (parseInt(row.status) === 2) {
          return <span className="badge bg-green">CONFIRMED</span>;
        }
        if (parseInt(row.status) === 3) {
          return <span className="badge bg-green">POSTED</span>;
        }
        if (parseInt(row.status) === 4) {
          return <span className="badge bg-red">CANCELLED</span>;
        }
        if (parseInt(row.status) === 5) {
          return <span className="badge bg-red">CLOSED</span>;
        }
      },
    },
  ];
};

export const productsColumnModalApproved = ({
  handleRemoveRow,
  handleChangeCell,
}) => {
  return [
    {
      field: 'remove',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      width: 50,
      renderCell: (data) => {
        if (data.row.isDuplicate) {
          return (
            <span
              className="badge bg-red cursor-pointer"
              onClick={() => handleRemoveRow(data.row.id)}
            >
              X
            </span>
          );
        }
      },
    },
    // {
    //   field: "isParent",
    //   headerName: "Product Name",
    //   minWidth: 170,
    //   flex: 1,
    //   hide: true,
    // },
    // {
    //   field: "isDuplicate",
    //   headerName: "Product Name",
    //   minWidth: 170,
    //   flex: 1,
    //   hide: true,
    // },
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'batch_no',
      headerName: 'Batch No.',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'unit',
      headerName: 'UOM',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      minWidth: 120,
      flex: 1,
    },

    {
      field: 'pallet_origin',
      headerName: 'Pallet Origin',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Qty. Transfer',
      minWidth: 150,
      type: 'number',
      renderCell: (data) => {
        if (data.row.isDuplicate) {
          return (
            <input
              type="number"
              className="form-control form-control-sm border-radius-0"
              value={data.row.quantity}
              onChange={(e) =>
                handleChangeCell({
                  ...data,
                  value: e.target.value,
                })
              }
              onWheel={(e) => e.target.blur()}
            />
          );
        } else {
          return <>{data.row.quantity}</>;
        }
      },
    },
    {
      field: 'pallet_destination',
      headerName: 'Pallet Address (Destination)',
      minWidth: 170,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'cursor-pointer text-center',
      hide: false,
      renderCell: (data) => {
        if (!data.row.isParent) {
          return (
            <span className="text-blue">
              {data.row.pallet_destination_name}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
};

export const productsColumnModal = ({ handleRemoveRow, handleChangeCell }) => {
  return [
    {
      field: 'remove',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      width: 50,
      renderCell: (data) => {
        if (data.row.isDuplicate) {
          return (
            <span
              className="badge bg-red cursor-pointer"
              onClick={() => handleRemoveRow(data.row.id)}
            >
              X
            </span>
          );
        }
      },
    },
    // {
    //   field: "isParent",
    //   headerName: "Product Name",
    //   minWidth: 170,
    //   flex: 1,
    //   hide: true,
    // },
    // {
    //   field: "isDuplicate",
    //   headerName: "Product Name",
    //   minWidth: 170,
    //   flex: 1,
    //   hide: true,
    // },
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'batch_no',
      headerName: 'Batch No.',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'SLS',
      headerName: 'SLS',
      minWidth: 150,
      flex: 1,
      hide: true,
    },
    {
      field: 'shelf_life',
      headerName: 'Shelf Life',
      minWidth: 110,
      flex: 1,
      renderCell: ({ row }) => {
        if (parseInt(row.shelf_life) < 8) {
          return <span className="badge bg-red">{row.shelf_life}</span>;
        } else if (
          parseInt(row.shelf_life) >= 8 &&
          parseInt(row.shelf_life) <= 12
        ) {
          return <span className="badge bg-yellow">{row.shelf_life}</span>;
        } else if (parseInt(row.shelf_life) > 12) {
          return (
            <span className="badge bg-green" size="20">
              {row.shelf_life}
            </span>
          );
        }
      },
    },
    {
      field: 'floor',
      headerName: 'Origin Floor',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Origin Pallet',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      minWidth: 150,
      type: 'number',
      renderCell: (data) => {
        if (data.row.isDuplicate) {
          return (
            <input
              type="number"
              className="form-control form-control-sm border-radius-0 text-right"
              value={data.row.quantity}
              onChange={(e) =>
                handleChangeCell({
                  ...data,
                  value: e.target.value,
                })
              }
              onWheel={(e) => e.target.blur()}
            />
          );
        } else {
          return <>{data.row.quantity}</>;
        }
      },
    },
    {
      field: 'floor_destination',
      headerName: 'Floor (Destination)',
      minWidth: 120,
      flex: 1,
      hide: false,
    },
    {
      field: 'pallet_destination',
      headerName: 'Pallet Address (Destination)',
      minWidth: 170,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'cursor-pointer text-center',
      hide: false,
      renderCell: (data) => {
        if (!data.row.isParent) {
          return (
            <span
              className={parseInt(data.row.status) !== 3 ? 'text-blue' : ''}
            >
              {data.row.pallet_destination_name}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
};

export const productsColumnModalView = [
  {
    field: 'product_name',
    headerName: 'Product Name',
    minWidth: 170,
    flex: 1,
  },
  {
    field: 'batch_no',
    headerName: 'Batch No.',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry Date',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    minWidth: 150,
    type: 'number',
  },
  {
    field: 'floor_destination',
    headerName: 'Floor (Destination)',
    minWidth: 120,
    flex: 1,
    hide: false,
  },
  {
    field: 'pallet_destination_name',
    headerName: 'Pallet Address (Destination)',
    minWidth: 170,
    flex: 1,
  },
];

// List of Products
export const productColumnPendingRequest = [
  {
    field: 'product_code',
    headerName: 'Product Code',
    sortable: true,
    filterable: false,
    minWidth: 170,
    flex: 1,
    renderCell: (productsData) => {
      return (
        <Link
          className="text-blue"
          to={`/view/products/list/${productsData.row.product_code}`}
        >
          {productsData.row.product_code}
        </Link>
      );
    },
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    minWidth: 170,
    flex: 1,
    filterable: false,
  },
  {
    field: 'request_transfer',
    headerName: 'Requested Quantity',
    minWidth: 170,
    flex: 1,
    filterable: false,
  },
  {
    field: 'untracked_quantity',
    headerName: 'Untracked Quantity',
    minWidth: 170,
    flex: 1,
    filterable: false,
  },
];

export const productsColumn = ({ handleChangeRequesCreate }) => {
  return [
    {
      field: 'product_code',
      headerName: 'Product Code',
      sortable: true,
      filterable: false,
      minWidth: 170,
      flex: 1,
      renderCell: (productsData) => {
        return (
          <Link
            className="text-blue"
            to={`/view/products/list/${productsData.row.product_code}`}
          >
            {productsData.row.product_code}
          </Link>
        );
      },
    },
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 170,
      flex: 1,
      filterable: false,
    },
    {
      field: 'main',
      headerName: 'Main/Central',
      minWidth: 170,
      flex: 1,
      type: 'number',
    },
    {
      field: 'picking',
      headerName: 'Picking Area',
      minWidth: 170,
      flex: 1,
      type: 'number',
    },
    {
      field: 'available_stock',
      headerName: 'Available Quantity',
      minWidth: 170,
      flex: 1,
      type: 'number',
    },
    {
      field: 'transfer_quantity',
      headerName: 'Requested Quantity',
      minWidth: 170,
      type: 'number',
      flex: 1,
      renderCell: (productsData) => {
        return (
          <input
            type="number"
            name="requested_quantity"
            className="form-control form-control-sm border-radius-0"
            value={productsData.row.transfer_quantity}
            onChange={(e) => handleChangeRequesCreate(e, productsData.row)}
            onWheel={(e) => e.target.blur()}
          />
        );
      },
    },
  ];
};

export const productsBatchColumn = ({
  handleChangeRequestUpdate,
  tabActiveIndex,
}) => {
  return [
    {
      field: 'product_code',
      headerName: 'Product Code',
      sortable: true,
      filterable: false,
      minWidth: 170,
      flex: 1,
      renderCell: (productsData) => {
        return (
          <Link
            className="text-blue"
            to={`/view/products/list/${productsData.row.product_code}`}
          >
            {productsData.row.product_code}
          </Link>
        );
      },
    },
    {
      field: 'product_name',
      headerName: 'Product Name',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'batch_no',
      headerName: 'Batch No.',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'unit',
      headerName: 'UOM',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'floor',
      headerName: 'Floor (Origin)',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'pallet_address',
      headerName: 'Pallet Address (Origin)',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'available_stock',
      headerName: 'Available Quantity',
      flex: 1,
      minWidth: 170,
      type: 'number',
    },
    {
      field: 'requested_quantity',
      headerName: 'Request Quantity',
      flex: 1,
      minWidth: 170,
      type: 'number',
      renderCell: (productsData) => {
        if (parseInt(tabActiveIndex.status) === 6) {
          return <>{productsData.row.requested_quantity}</>;
        } else {
          return (
            <input
              type="number"
              name="requested_quantity"
              className="form-control form-control-sm border-radius-0"
              value={productsData.row.requested_quantity}
              onChange={(e) => handleChangeRequestUpdate(e, productsData.row)}
              onWheel={(e) => e.target.blur()}
            />
          );
        }
      },
    },
    {
      field: 'floor_destination',
      headerName: 'Floor (Destination)',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'pallet_destination',
      headerName: 'Pallet Address (Destination)',
      flex: 1,
      minWidth: 170,
    },
  ];
};

export const searchableColumnsTransfer = productRequestListColumn({
  handleViewRequestDetails: null,
  handleShowApprovedModal: null,
  handleShowPostedModal: null,
  handleReceiveRequestDetails: null,
  tabActiveIndex: null,
})
  .filter((row) => {
    let reject = ['actions', 'status', 'transfer_type'];
    return !reject.includes(row.field);
  })
  .map((row) => ({
    value: row.field,
    label: row.headerName,
  }));

// Select Warehouse Pallet Modal
export const warehousePalletAssignColumnModal = ({
  handleChangeWarehousePallet,
}) => {
  return [
    {
      field: 'floor_id',
      headerName: 'Floor',
      width: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.floor ? row.floor.floor : ''}</>;
      },
    },
    {
      field: 'zone_id',
      headerName: 'Zone',
      width: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.zone ? row.zone.zone_name : 'Main Inventory'}</>;
      },
    },
    // { field: "column", headerName: "Column", width: 100, flex : 1 },
    // { field: "row", headerName: "Row", width: 100, flex : 1 },
    // { field: "shelf", headerName: "Shelf", width: 100, flex : 1 },
    {
      field: 'pallet_code',
      headerName: 'Pallet',
      width: 100,
      flex: 1,
    },
    {
      field: 'button_select_pallet',
      headerName: 'Action',
      headerAlign: 'center',
      width: 100,
      align: 'center',
      flex: 1,
      type: 'action',
      renderCell: ({ row }) => {
        return (
          <Button
            variant="link"
            className="btn btn-sm text-blue"
            onClick={(event) => handleChangeWarehousePallet(row)}
          >
            Select
          </Button>
        );
      },
    },
  ];
};
