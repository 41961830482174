import React, { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { getRevisionHistory } from "../../api/history";
import defaultOptions from "../../utils/defaultOptions";
import RevisionHistoryScreen from "./Screens/RevisionHistoryScreen";

const RevisionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    page: 0,
    page_size: defaultOptions.pageSize,
    order_by: "revision_history_id",
    direction: "asc",
    revision_history_id: "",
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({ rows: 0 });
  const [tableForm, setTableForm] = useState({});
  const [reloadTable, setReloadTable] = useState(1);

  useEffect(() => {
    setTotal({ rows: 0 });
    //loadData(form);
  }, []);

  const loadData = async (form) => {
    setLoading(false);
    setData([]);

    try {
      let res = await getRevisionHistory(form);

      const { code, result, total_rows } = res.data.response;

      if (code !== "00000") {
        return;
      }
      setData(result);
      setTotal({ rows: total_rows });
    } catch (error) {
      toast.error("Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleOnDataTableChange = (data) => {
    setTableForm(data);
    loadData({ ...form, ...data });
  };

  const handleFilterChange = (e) => {
    let tmpObj = {
      ...form,
      revision_history_id: e.target.value,
    };
    setReloadTable(Math.floor(Math.random() * 101));

    setForm(tmpObj);
    loadData(tmpObj);
  };

  const handleClearFilterSearch = () => {
    let newFormData = { ...form, revision_history_id: "" };
    setReloadTable(Math.floor(Math.random() * 101));
    loadData(newFormData);
    setForm(newFormData);
  };

  return (
    <Fragment>
      <RevisionHistoryScreen
        data={data}
        total={total.rows}
        formData={form}
        loading={loading}
        reloadTable={reloadTable}
        handleFilterChange={handleFilterChange}
        handleClearFilterSearch={handleClearFilterSearch}
        onChange={handleOnDataTableChange}
      />
    </Fragment>
  );
};

export default RevisionHistory;
