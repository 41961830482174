import { Alert } from "react-bootstrap";
import Loader from "react-loader-spinner";

const CrossLoginScreen = ({ loading, isAuthenticated }) => {
  const _renderLoading = () => {
    return (
      <div className="text-center">
        <Loader type="ThreeDots" color="#38d39f" height={50} width={50} />
        <div>Please wait verifying user.</div>
      </div>
    );
  };

  const _renderMessage = () => {
    if (!isAuthenticated) {
      return (
        <div className="text-center">
          <Alert variant="danger">
            <strong>Authentication Failed.</strong>
            <br /> You'll be redirect to Login Page in while.
          </Alert>
        </div>
      );
    }

    return (
      <div className="text-center">
        <Alert variant="success">
          <strong>Authentication Success.</strong>
          <br /> You'll be redirect to Dashboard Page in while.
        </Alert>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: "rgba(255,255,255,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? _renderLoading() : _renderMessage()}
    </div>
  );
};

export default CrossLoginScreen;
