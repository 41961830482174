import React, { Fragment } from 'react';
import moment from 'moment';

import '../views/styles/creditAssessment.css';
import { Col, Form, Row } from 'react-bootstrap';

const ViewCustomerCreditAssessment = ({ customer, form, setForm }) => {
  const criteria_1 = customer[0].criteria_1;
  const criteria_2 = customer[1].criteria_2;
  const criteria_3 = customer[2].criteria_3;
  const criteria_4 = customer[3].criteria_4;
  const criteria_5 = customer[4].criteria_5;
  const final_assessment = customer[5].final_assessment;
  const basis = customer[5].breakdown;
  const ytd_date = moment().subtract(1, 'year').format('MMM DD, YYYY');
  const today_date = moment().format('MMM DD, YYYY');

  const colorCode = (color) => {
    if (color <= 1.5) {
      return 'rateRed';
    } else if (color <= 2.49) {
      return 'rateOrange';
    } else if (color <= 3.49) {
      return 'rateYellow';
    } else if (color <= 4.49) {
      return 'rateGreen';
    } else if (color <= 5) {
      return 'rateSuperGreen';
    }
  };

  const rateCoding = (color) => {
    if (color === 1) {
      return 'rateRed';
    } else if (color === 2) {
      return 'rateOrange';
    } else if (color === 3) {
      return 'rateYellow';
    } else if (color === 4) {
      return 'rateGreen';
    } else if (color === 5) {
      return 'rateSuperGreen';
    }
  };

  const handleSelectedAssessmentFlag = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      const removed =
        value !== 'po_based'
          ? ['red', 'green', 'follow_assessment'].filter((row) => row !== value)
          : [];

      setForm({
        ...form,
        ca_flag: [
          ...form.ca_flag.filter((row) => removed.indexOf(row) === -1),
          value,
        ],
      });
    } else {
      setForm({
        ...form,
        ca_flag: form.ca_flag.filter((row) => row !== value),
      });
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h5 className="mb-3">Allowable Credit</h5>
        <div className="row">
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-lg-6 text-white stretch-content-1024 mb-3">
                <div className="card bg-success rounded mb-4">
                  <div className="card-body d-flex">
                    <div className="icon">
                      <i className="material-icons">credit_card_off</i>
                    </div>
                    <div className="content w-100">
                      <div className="text-right">
                        <div className="currencies">
                          {'₱ ' + basis.allowable_credit_limit}
                        </div>
                        <div className="currencies-label">Credit Limit</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card bg-info rounded  mb-4">
                  <div className="card-body d-flex">
                    <div className="">
                      <i className="material-icons">credit_card</i>
                    </div>
                    <div className="content w-100">
                      <div className="text-right">
                        <div className="currencies">
                          {'₱ ' + basis.ending_balance || 0}
                        </div>
                        <div className="currencies-label">
                          Total Account Receivable
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 stretch-content-1024 mb-3">
                <div className="card rounded">
                  <div
                    className={
                      'card-body text-center text-white p-2 ' +
                      colorCode(final_assessment.rating.rating)
                    }
                  >
                    <div className="ratingText">
                      {final_assessment.rating.rating}
                    </div>
                  </div>
                  <div className="text-center">
                    <h5 className="mt-4">
                      <strong>Final Assessment</strong>
                    </h5>
                    <div className="assessment-text">
                      {final_assessment.rating.remarks}
                    </div>
                  </div>
                </div>

                {/* <div className="card bg-info rounded  mb-4">
                  <div className="card-body d-flex">
                    <div className="">
                      <i className="material-icons">credit_card</i>
                    </div>
                    <div className="content w-100">
                      <div className="text-right">
                        <div className="currencies">
                          {"₱ " + basis.ending_balance || 0}
                        </div>
                        <div className="currencies-label">
                          Total Account Receivable
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="mb-3">Initial Assessment</h5>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered mb-3">
                    <thead className="bg-success">
                      <tr>
                        <th>Criteria</th>
                        <th className="text-center">Result</th>
                        <th className="text-center">Rating</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td width="40%">DSO vs. Given Credit Terms</td>
                        <td className="text-center">
                          {criteria_1.rating.days} days
                        </td>

                        <td
                          className={
                            rateCoding(criteria_1.rating.rating) +
                            ' text-center'
                          }
                        >
                          {criteria_1.rating.rating}
                        </td>
                      </tr>
                      <tr>
                        <td width="40%">Past Due Rate (PDR)</td>
                        <td className="text-center">
                          {criteria_2.rating.past_due_rate}%
                        </td>
                        <td
                          className={
                            rateCoding(criteria_2.rating.rating) +
                            ' text-center'
                          }
                        >
                          {criteria_2.rating.rating}
                        </td>
                      </tr>
                      <tr>
                        <td width="40%">Outstanding AR vs Credit Limit (CL)</td>
                        <td className="text-center">
                          {criteria_3.rating.ar_vs_cl}%
                        </td>
                        <td
                          className={
                            rateCoding(criteria_3.rating.rating) +
                            ' text-center'
                          }
                        >
                          {criteria_3.rating.rating}
                        </td>
                      </tr>
                      <tr>
                        <td width="40%">Payment to Net Credit Sales Ratio</td>
                        <td className="text-center">
                          {criteria_4.rating.payment_ratio}%
                        </td>
                        <td
                          className={
                            rateCoding(criteria_4.rating.rating) +
                            ' text-center'
                          }
                        >
                          {criteria_4.rating.rating}
                        </td>
                      </tr>
                      <tr>
                        <td width="40%">Promise-to-Pay Conversion Rate</td>
                        <td className="text-center">
                          {criteria_5.rating.ptp_conversion_rate}%
                        </td>
                        <td
                          className={
                            rateCoding(criteria_5.rating.rating) +
                            ' text-center'
                          }
                        >
                          {criteria_5.rating.rating}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="card card-body bg-light"
              style={{ borderRadius: '0.25rem', minHeight: '610px' }}
            >
              <h4 className="mb-4">Breakdown</h4>
              {basis ? (
                <dl className="row">
                  <dt className="col-sm-7 font-weight-normal">
                    Beginning Balance
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.beginning_balance}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal mb-4">
                    Ending Balance
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.ending_balance}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">Average AR</dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.average_ar}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    YTD Sales{' '}
                    <span className="small font-weight-bold">
                      (from {ytd_date} to {today_date})
                    </span>
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.ytd_sales}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    YTD Collection{' '}
                    <span className="small font-weight-bold">
                      (from {ytd_date} to {today_date})
                    </span>
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.ytd_collection}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">DSO</dt>
                  <dd className="col-sm-5 text-right">{basis.dso}</dd>

                  <dt className="col-sm-7 font-weight-normal">DSO vs CT</dt>
                  <dd className="col-sm-5 text-right">{basis.dso_vs_ct}</dd>

                  <dt className="col-sm-7 font-weight-normal">
                    Average Collection
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.average_collection}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">Past Due</dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.past_due_90days_and_above_credit_term}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    % of Past Due Rate
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {basis.percent_past_due_rate + '%'}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    Credit Limit Performance
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.credit_limit_performance}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    Credit Limit 25% gross up
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {'₱ ' + basis.credit_limit_25percent_gross_up}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    % of AR vs Credit limit
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {basis.percent_of_ar_vs_credit_limit + '%'}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">Payment Ratio</dt>
                  <dd className="col-sm-5 text-right">
                    {basis.payment_ratio + '%'}
                  </dd>

                  <dt className="col-sm-7 font-weight-normal">
                    PTP Conversation Rate
                  </dt>
                  <dd className="col-sm-5 text-right">
                    {basis.ptp_conversion_rate + '%'}
                  </dd>
                </dl>
              ) : (
                <div className="alert alert-warning">No Data.</div>
              )}
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <fieldset className="details-field-set mb-3 mt-0">
              <legend>SO Approval</legend>
              <div className="col-md-12">
                <div className="row pl-2 pr-2 pt-4">
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="assesment_po_based"
                  >
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="PO Based"
                        name="assesment_po_based"
                        value={'po_based'}
                        onChange={handleSelectedAssessmentFlag}
                        checked={form.ca_flag.indexOf('po_based') !== -1}
                      />
                    </Col>
                    <Col sm="8">
                      Any customer where ticked must proceed to C&C assessment
                      regardless of credit score
                    </Col>
                  </Form.Group>
                  <hr />
                  <h6>Credit Assessment Verification</h6>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="assesment_red_flag"
                  >
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="Red Flag"
                        name="assesment_red_flag"
                        value={'red'}
                        onChange={handleSelectedAssessmentFlag}
                        checked={form.ca_flag.indexOf('red') !== -1}
                      />
                    </Col>
                    <Col sm="8">
                      SO will always go to C&C Assessment regardless of credit
                      score
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="assesment_green_flag"
                  >
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="Green Flag"
                        name="assesment_green_flag"
                        value={'green'}
                        onChange={handleSelectedAssessmentFlag}
                        checked={form.ca_flag.indexOf('green') !== -1}
                      />
                    </Col>
                    <Col sm="8">
                      SO will always proceed to picking regardless of credit
                      score
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="checkbox3">
                    <Col sm="4">
                      <Form.Check
                        type="checkbox"
                        label="Follow C&C Assessment"
                        name="assesment_follow_flag"
                        value={'follow_assessment'}
                        onChange={handleSelectedAssessmentFlag}
                        checked={
                          form.ca_flag.indexOf('follow_assessment') !== -1
                        }
                      />
                    </Col>
                    <Col sm="8">
                      SO will follow credit score condition{' '}
                      <div className="font-weight-bold text-success">
                        AA+ and A+ will automatically proceed to picking
                      </div>
                      <div className="font-weight-bold text-danger">
                        A, B and B- will automatically go through C&C Assessment
                      </div>
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </fieldset>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ViewCustomerCreditAssessment;
