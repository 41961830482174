import CurrencyInput from "react-currency-input-field";
import { InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

const PrimaryInputCurrency = (props) => {
  const renderField = () => {
    const { raw, size, ...propInput } = props;

    if (raw) {
      return (
        <CurrencyInput
          {...propInput}
          onValueChange={(value, name) =>
            props.onValueChange({ target: { name, value } })
          }
        />
      );
    }

    return (
      <InputGroup size={size}>
        <InputGroup.Text id="primary-input-currency-addon">PHP</InputGroup.Text>
        <CurrencyInput
          {...propInput}
          onValueChange={(value, name) =>
            props.onValueChange({ target: { name, value } })
          }
        />
      </InputGroup>
    );
  };
  return renderField();
};

PrimaryInputCurrency.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  decimalsLimit: PropTypes.number,
  decimalScale: PropTypes.number,
  defaultValue: PropTypes.number,
  value: PropTypes.any,
  step: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  raw: PropTypes.bool,
};

PrimaryInputCurrency.defaultProps = {
  name: "primary-input-currency-default",
  decimalsLimit: 2,
  decimalScale: 2,
  step: 1,
  prefix: "",
  className: "form-control",
  size: "md",
  raw: false,
};

export default PrimaryInputCurrency;
