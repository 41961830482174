import { lazy } from 'react';

const BalanceSheet = lazy(() => import('../pages/BalanceSheet/BalanceSheet'));
const BalanceSheetV3 = lazy(() => import('../pages/BalanceSheetV3'));

const balanceSheetRoute = {
  public: [],
  auth: [
    {
      name: 'balance_sheet',
      path: '/finance-and-accounting/balance-sheet/table',
      component: BalanceSheet,
    },
    {
      name: 'balance_sheet_v3',
      path: '/finance-and-accounting/balance-sheet',
      component: BalanceSheetV3,
    },
  ],
};

export default balanceSheetRoute;
