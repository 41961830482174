import { notificationBellIcon } from "./const";

const notificationItems = [
  {
    label: "Show Notification",
    icon: notificationBellIcon,
    notification_data: [
      {
        header: "Sample Data 1",
        update: "Sample Update 1",
        time_stamp: "Dec 03, 1994",
        mark_as_read: 0,
      },
      {
        header: "Sample Data 2",
        update: "Sample Update 2",
        time_stamp: "Dec 03, 1994",
        mark_as_read: 0,
      },
      {
        header: "Sample Data 3",
        update: "Sample Update 3",
        time_stamp: "Dec 03, 1994",
        mark_as_read: 0,
      },
      {
        header: "Sample Data 4",
        update: "Sample Update 4",
        time_stamp: "Dec 03, 1994",
        mark_as_read: 0,
      },
      {
        header: "Sample Data 5",
        update: "Sample Update 5",
        time_stamp: "Dec 03, 1994",
        mark_as_read: 0,
      },
    ],
  },
];

export default notificationItems;
