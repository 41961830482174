import React, { useState, useEffect, Fragment } from "react";
import axiosInstance from "../axiosInstance";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { Form, Button, Accordion } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { capitalize } from "@material-ui/core";

import useFormSelect2 from "../../useFormSelect2";
import PrimaryDataTable from "../PrimaryDataTable";

import Swal from "sweetalert2";

const PermissionPage = () => {
  const history = useHistory();

  //for getting of header
  const [module, setModule] = useState([]);

  const [rowsData, setRowsData] = useState([]);
  const [headerListData, setHeaderList] = useState([]);
  const [roleHolder, setRoleHolder] = useState([]);
  /* Search */

  const { id } = useParams();
  const addPermission = () => {
    history.push("add/permission");
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showPermission, setShowPermission] = useState(false);

  const [values, handleChange] = useFormSelect2({
    function_id: "",
    role_id: "",
  });

  useEffect(() => {
    axios
      .all([
        axiosInstance.get("/modules", {
          method: "GET",
        }),
        axiosInstance.get("/permission/module/" + id + "/all-permissions", {
          method: "GET",
        }),
        axiosInstance.get("/role", {
          method: "GET",
        }),
      ])
      .then(
        axios.spread((data1, data2, data3) => {
          setModule(data1.data.response.result);
          setRoleHolder(data3.data.response.result);

          let roleListDashboard = data2.data.response.result.role;
          let funclist = data2.data.response.result.functions;

          roleListDashboard.map((role, i) => {
            columns_header_holder.push({
              headerName: role.role,
              field: JSON.stringify(role.role_id),
              minWidth: 180,
            });
          });
          columns_header_holder.push({
            headerName: "Add Role",
            field: "add_role",
            minWidth: 180,
            sortable: false,
            flex: 0.1,
            filterable: false,
            renderHeader: () => (
              <label
                title="Add Role"
                onClick={handleShow}
                style={{ color: "black", textDecoration: "underline" }}
              >
                Add Role
              </label>
            ),
          });

          setHeaderList(columns_header_holder);
          var store_details = {};

          funclist.map((func) => {
            var function_roles = func.role_has_permission_in_function;
            store_details = {
              functions: func.function_name,
              function_id: func.function_id,
            };

            function_roles.map((details) => {
              store_details = {
                ...store_details,
                [details.role_id]: details.function_has_permission,
              };

              setRowsData((rowsData) => [...rowsData, store_details]);
            });
          });
        })
      );
  }, []);

  const handleTabClick = (tabID) => {
    setRowsData([]);

    axiosInstance
      .get("/permission/module/" + tabID + "/all-permissions", {
        method: "GET",
      })
      .then((res) => {
        let function_list = res.data.response.result.functions;
        let roleListDashboard = res.data.response.result.role;

        roleListDashboard.map((role) => {
          columns_header_holder.push({
            headerName: role.role,
            field: JSON.stringify(role.role_id),
            minWidth: 180,
          });
        });
        columns_header_holder.push({
          headerName: "Add Role",
          field: "add_role",
          minWidth: 180,
          sortable: false,
          flex: 0.1,
          filterable: false,
          renderHeader: () => (
            <label
              title="Add Role"
              onClick={handleShow}
              style={{ color: "black", textDecoration: "underline" }}
            >
              Add Role
            </label>
          ),
        });
        setHeaderList(columns_header_holder);
        var store_details = {};

        function_list.map((func) => {
          var function_roles = func.role_has_permission_in_function;
          store_details = {
            functions: func.function_name,
            function_id: func.function_id,
          };

          function_roles.map((details) => {
            store_details = {
              ...store_details,
              [details.role_id]: details.function_has_permission,
            };

            setRowsData((rowsData) => [...rowsData, store_details]);
          });
        });
      });
  };
  const columns_header_holder = [
    {
      headerName: "Functionalities",
      field: "functions",
      minWidth: 180,
    },
  ];

  const newData = {
    function_id: values.function_id.value,
    role_id: values.role_id.value,
    function_has_permission: 1,
    label: "N/A",
    tag: "N/A",
    permitted_by: 1,
  };

  const submitHandler = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/permission/create", newData, {
        method: "POST",
      })
      .then((res) => {
        res.data.response.code === "00003"
          ? Swal.fire({
              icon: "success",
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/user-control");
              }
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            });
      });
    /* axiosInstance
      .post("/permission/create", values, {
        method: "POST",
      })
      .then((res) =>
        res.data.response.code === "00003"
          ? Swal.fire({
              icon: "success",
              text: res.data.response.result,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/user-control");
              }
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.response.result,
            })
      ); */
  };
  const roleAssignmentHolder = [];
  const rowsAssignmentHolder = [];

  rowsData.map((row) => {
    rowsAssignmentHolder.push({
      value: row.function_id,
      label: row.functions,
    });
  });

  roleHolder.map((role) => {
    roleAssignmentHolder.push({
      value: role.role_id,
      label: role.role,
    });
  });

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="col">
              {!showPermission ? (
                <Button
                  //to={"/user-control#tab-top/" + module[0].module_id}
                  className="btn btn-success"
                  onClick={() => setShowPermission(true)}
                >
                  <strong>Show {module.length} Pages </strong>
                </Button>
              ) : (
                <Button
                  className="btn btn-primary"
                  onClick={() => setShowPermission(false)}
                >
                  <strong>Hide Pages. </strong>
                </Button>
              )}
            </div>
            {showPermission ? (
              <div className="row align-items-center">
                <div className="col">
                  <h2 className="page-title">Permissions List</h2>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <a
                    id="add_permission"
                    onClick={addPermission}
                    className="btn btn-light btn-pill bg-header"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    Add Permission
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {showPermission ? (
          <div className="container-fluid">
            <div className="card-tabs mt-2">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <ul className="nav nav-tabs bg-header">
                {module.map((value) => (
                  <li className="nav-item">
                    <Link
                      key={value.module_id}
                      style={{
                        backgroundColor: "rgb(249 249 250) !important",
                        backgroundImage:
                          "linear-gradient(-180deg, rgba(53, 64, 82, 0), rgba(53, 64, 82, 0.04) 100% ) !important",
                        fontWeight: "600",
                      }}
                      to={"/user-control#tab-top/" + value.module_id}
                      className={
                        value.module_id === id
                          ? "nav-link active card tab-pane active show"
                          : "nav-link card tab-pane"
                      }
                      data-toggle="tab"
                      onClick={() => handleTabClick(value.module_id)}
                    >
                      {capitalize(value.module)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="tab-content">
                <div style={{ marginTop: "10px" }}>
                  <PrimaryDataTable
                    autoHeight
                    rows={rowsData}
                    columns={headerListData}
                    getRowId={(row) => row.function_id}
                    checkboxSelection
                    disableSelectionOnClick
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>

        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Functionalities</Form.Label>
              <Select
                name="function_id"
                isClearable
                placeholder="Functionalities"
                value={values.function_id}
                onChange={handleChange}
                options={rowsAssignmentHolder}
              />
              <Form.Label>Add Role</Form.Label>
              <Select
                name="role_id"
                isClearable
                placeholder="Roles"
                value={values.role_id}
                onChange={handleChange}
                options={roleAssignmentHolder}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Add
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default PermissionPage;

/* Additional Note:
New API to list all functions per module */
