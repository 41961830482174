import makeApiRequest from "./makeApiCall";

export const createBranch = async(data) => {
  return await makeApiRequest("/customer/branches/store", "POST", null, data);
};

// export const updateBranch = async(data) => {
//   return await makeApiRequest("/customer/branches/update", "POST", null, data);
// };

export const removeBranch = async(data) => {
  return await makeApiRequest("/customers/branches/remove", "DELETE", null, data);
};

export const updateBranchesConfig = async(data) => {
  return await makeApiRequest("/customers/branches/config/update", "POST", null, data);
};


export const getMainOfficeBranches = async(data) => {
  return await makeApiRequest("/customer/branches", "POST", null, data);
};

export const getUnassociateBranches = async(data) => {
  return await makeApiRequest("/customers/branches/unassociate", "POST", null, data);
};

export const getMainBranches = async(data) => {
  return await makeApiRequest("/customers/branches/main", "POST", null, data);
};

export const createBranchesConfig = async(data) => {
  return await makeApiRequest("/customers/branches/config", "POST", null, data);
};

export const createBranchesConfigShow = async(data) => {
  return await makeApiRequest("/customers/branches/config/show", "POST", null, data);
};


