import { lazy } from "react";

const Divisions = lazy(() => import("../pages/Divisions/index"));

const divisionRoute = {
  auth: [
    {
      name: "divisions",
      path: "/finance-and-accounting/divisions",
      component: Divisions,
    },
  ],
};

export default divisionRoute;
