import { Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import '../../../globalStyles/css/bootstrap/bootstrap.min.css';
import PrimaryAsyncSelect from '../../../components/PrimaryAsyncSelect';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';

import PrimaryDataTable from '../../../components/PrimaryDataTable';
import './incomestatement.css';

import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';
import { dropdownPeriodBreakdown, dropdownBreakdown } from '../const';
const minDate = '2022-11-30';

const IncomeStatementSheetReportScreen = ({
  handleChange,
  handleChangeBreakdown,
  selectedTab,
  formData,
  incomeStatementData,
  dateChange,
  handleSearch,
  periodBreakdown,
  handleDataTableChange,
  loading,
  totalRow,
  reloadTable,
  handleExport,
  dropdownAccounts,
  getAccountList,
  columnVisibilityModel,
  customToolbar,
}) => {
  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <div className="col-md-12">
        <h4 className="headline">Income Statement</h4>
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedTab}
          className="default-tab"
        >
          <Tab
            className="default-tab-content"
            eventKey="income_statement"
            title="Income Statement"
          >
            <br />
            <div className="col-md-12">
              <PermissionsGate
                scopes={['get_income_statement']}
                RenderError={AlertDefaultError}
              >
                <div className="row">
                  <div className="col-md-4">
                    <label>Period Breakdown</label>

                    <Select
                      name="period_breakdown"
                      options={dropdownPeriodBreakdown}
                      placeholder="Select Period Breakdown"
                      value={dropdownPeriodBreakdown.find(
                        (doc_source) =>
                          doc_source.value === formData.period_breakdown,
                      )}
                      onChange={(value, e) =>
                        handleChange({
                          target: { name: e.name, value: value.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Select Breakdown</label>

                    <Select
                      name="breakdown"
                      multiple
                      options={dropdownBreakdown}
                      placeholder="Select Breakdown"
                      value={dropdownBreakdown.find(
                        (doc_source) => doc_source.value === formData.breakdown,
                      )}
                      onChange={(e) =>
                        handleChangeBreakdown({
                          target: { name: 'breakdown', value: e },
                        })
                      }
                      isMulti="true"
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Select Account Code</label>
                    <div className="row">
                      <div className="col-md-6">
                        <PrimaryAsyncSelect
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'accounts_from',
                                value: e.value,
                              },
                            })
                          }
                          customFunction={getAccountList}
                          isClearable={true}
                          name={'accounts_from'}
                          value={formData.accounts_from}
                          placeholder="From"
                          config={{
                            searchField: 'keyword',
                            valueField: 'account_code',
                            labelField: 'account_code',
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <PrimaryAsyncSelect
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'accounts_to',
                                value: e.value,
                              },
                            })
                          }
                          customFunction={getAccountList}
                          isClearable={true}
                          name={'accounts_to'}
                          value={formData.accounts_to}
                          placeholder="To"
                          config={{
                            searchField: 'keyword',
                            valueField: 'account_code',
                            labelField: 'account_code',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-md-2">
                    <label>Date From</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_from'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_from}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_from', value: date },
                        })
                      }
                      minDate={minDate}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Date To</label>
                    <label className="req">*</label>
                    <PrimaryDatePicker
                      name={'date_to'}
                      datePickerFormat={'yyyy-MM-dd'}
                      datePickerFormatString={'YYYY-MM-DD'}
                      value={formData.date_to}
                      onChange={(date) =>
                        dateChange({
                          target: { name: 'date_to', value: date },
                        })
                      }
                      minDate={minDate}
                    />
                  </div>
                  <div className="col-md-1">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <label>&nbsp;</label>
                    <div></div>
                  </div>
                </div>
                <br />
                <PrimaryDataTable
                  rows={incomeStatementData}
                  columns={periodBreakdown}
                  getRowId={(row) => row.id}
                  onChange={handleDataTableChange}
                  disableColumnFilter
                  loading={loading}
                  reload={reloadTable}
                  columnVisibilityModel={columnVisibilityModel}
                  components={{ Toolbar: customToolbar }}
                  disableSelectionOnClick
                  getRowClassName={(params) => {
                    if (params.row.breakdown_type === 'ACCOUNTS') {
                      return 'accounts-row';
                    } else if (params.row.breakdown_type === 'LINE ITEM') {
                      return 'lineitem-row';
                    } else if (params.row.breakdown_type === 'SUBLINE ITEM') {
                      return 'sublineitem-row';
                    } else if (params.row.breakdown_type === 'SUB-GROUP') {
                      return 'subgroup-row';
                    } else if (params.row.breakdown_type === 'GROUP') {
                      return 'group-row';
                    } else if (params.row.breakdown_type === 'TYPE') {
                      return 'type-row';
                    }
                  }}
                />
              </PermissionsGate>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default IncomeStatementSheetReportScreen;
