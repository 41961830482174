import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";
import PrimaryAsyncSelect from "../../PrimaryAsyncSelect";
import { getRoles, createUsersRole } from "../../../api/users";

const UserRolesAdd = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = useState({ role_id: "" });

  useEffect(() => {
    // let componentMounted = true;
    // const loadAllRoles = async () => {
    //   const res = await axiosInstance.get(`/role`, {
    //     method: "GET",
    //   });
    //   if (componentMounted) {
    //     setRolesHolder(res.data.response.result);
    //   }
    // };
    // loadAllRoles();
    // return () => {
    //   componentMounted = false;
    // };
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    Swal.fire({
      icon: "info",
      title: "Are you sure you want to role to this user?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "#dc3545",
    }).then((result) => {
      if (result.isConfirmed) {
        createUsersRole(id, form)
          .then((res) => {
            let { code, result } = res.data.response;
            if (code !== "00003") {
              Swal.fire({
                icon: "error",
                text: result,
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: result,
                showConfirmButton: true,
              });
              history.push(`/user/${id}/role`);
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.data.response.result,
            });
          });
      }
    });

    // e.preventDefault();
    //
    // axiosInstance
    //   .post(`/user/${id}/role/create`, values, {
    //     method: "POST",
    //   })
    //   .then((res) =>
    //     res.data.response.code === "00003"
    //       ? Swal.fire({
    //           icon: "success",
    //           text: res.data.response.result,
    //           showConfirmButton: true,
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             history.push(`/user/${id}/role`);
    //           }
    //         })
    //       : Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: res.data.response.result,
    //         })
    //   );
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div className="card mb-3 mt-3">
            <div className="card-header">
              <h3>Add User Role</h3>
            </div>
            <form onSubmit={submitHandler}>
              <div className="card-body">
                <div className="form-group">
                  <label>Role ID</label>

                  <PrimaryAsyncSelect
                    onChange={(e) =>
                      handleChange({
                        target: { name: "role_id", value: e.value },
                      })
                    }
                    customFunction={getRoles}
                    name="role_id"
                    value={form.role_id}
                    isClearable={true}
                    config={{
                      searchField: "role",
                      valueField: "role_id",
                      labelField: "role",
                    }}
                  />
                </div>
              </div>
              <div className="card-footer">
                <div className="text-right">
                  <Button id="add" variant="success" type="submit">
                    Save
                  </Button>
                  <Link
                    id="cancel"
                    to={`/user/${id}/role`}
                    className="ml-2 btn btn-danger"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      {/* <div className="col-md-10 offset-1">
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
          <div className="card">
            <div className="card-header">
              <h1>Add Role to this User</h1>
            </div>
            <div className="card-body">
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label>Role ID</label>
                  <select
                    className="form-control"
                    name="role_id"
                    id="role_id"
                    defaultValue={values.role_id}
                    onChange={handleChange}
                  >
                    <option className="form-control">Choose</option>
                    {rolesHolder.map((roles) => (
                      <option value={roles.role_id} key={roles.role_id}>
                        {roles.role}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="btn-group mt-2" style={{ float: "right" }}>
                  <button id="add" className="btn btn-primary" type="submit">
                    Add
                  </button>
                  <Link
                    id="cancel"
                    to={`/user/${id}/role`}
                    className="ml-2 btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </Container>
  );
};

export default UserRolesAdd;
