import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import axiosInstance from "../axiosInstance";
import Swal from "sweetalert2";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import PrimaryDataTable from "../PrimaryDataTable";

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);
function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(0),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const ProductsApprovalList = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [productsData, setProductsData] = useState([]);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  let url = "http://localhost/erp_api_v1/erp/public/api";

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = productsData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };

  /* Continuation of Search */
  useEffect(() => {
    let componentMounted = true;

    const loadProducts = async () => {
      const res = await axiosInstance.get(`/products/approvals/list`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      });
      if (componentMounted) {
        setProductsData(res.data.response.result);
        setFilteredData(res.data.response.result);
      }
    };

    loadProducts();
    return () => {
      componentMounted = false;
    };
  }, []);

  const handleApprovedAddProductButton = (data) => {

    const approval_code = data.approval_code;

    Swal.fire({
      title: `Do you want to approved created ${approval_code}`,
      showDenyButton: true,
      confirmButtonText: `Approved`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post(`/products/add/approved/${approval_code}`)
          .then((res) =>
            res.data.response.code === "00003"
              ? Swal.fire({
                  icon: "success",
                  text: res.data.response.result,
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push(`/products`);
                  }
                })
              : null
          );
      }
    });
  };

  const handleApprovedUpdatedProductButton = (data) => {
    const product_code = data.product_code;

    Swal.fire({
      title: `Do you want to approve changes on ${product_code}?`,
      showDenyButton: true,
      confirmButtonText: `Approve`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post(`/products/update/approved/${product_code}`)
          .then((res) =>
            res.data.response.code === "00004"
              ? Swal.fire({
                  icon: "success",
                  text: res.data.response.result,
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push(`/products`);
                  }
                })
              : null
          );
      }
    });
  };

  const columns = [
    { field: "product_code", headerName: "Product Code", width: 200 },
    { field: "product_name", headerName: "Product Name", width: 200 },
    { field: "brand_name", headerName: "Brand Name", width: 200 },
    { field: "supplier_id", headerName: "Supplier", width: 200 },
    { field: "product_segment_id", headerName: "Product Segment", width: 200 },
    { field: "product_group_id", headerName: "Product Group", width: 200 },
    { field: "packaging", headerName: "Packaging", width: 200 },
    { field: "qty_per_pack", headerName: "Qty Per Pack", width: 200 },
    { field: "inventory_id", headerName: "Stock Level", width: 200 },
    { field: "cpr_no", headerName: "CPR No.", width: 200 },
    { field: "cpr_expiry", headerName: "CPR Expiry", width: 200 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 0,
      minWidth: 400,
      renderCell: (productsData) => {
        return (
          <>
            <Link
              style={{
                textDecoration: "underline blue",
                color: "blue",
              }}
              onClick={() =>
                productsData.row.product_code == null
                  ? handleApprovedAddProductButton(productsData.row)
                  : handleApprovedUpdatedProductButton(productsData.row)
              }
            >
              Approved Product
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">Products Approval Masterlist</h2>
              </div>
              <div className="col-auto ms-auto d-print-none"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-md-6 mt-2 mb-2">
              <h1>Roles Masterist</h1>
            </div>

            <div className="col-md-6 mt-1 mb-2">
              <Tooltip title="Add" aria-label="add">
                <Fab
                  color="primary"
                  className={classes.fab}
                  style={{ float: "right" }}
                >
                  <Link
                    to="/add/roles"
                    style={{
                      textDecoration: "underline white",
                      color: "white",
                    }}
                  >
                    <AddIcon />
                  </Link>
                </Fab>
              </Tooltip>
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col-md-12">
              <PrimaryDataTable
                rows={filteredData}
                columns={columns}
                getRowId={(row) => row.product_id}
                disableColumnFilter
                rowCount={filteredData.length}
                mode="server"
                components={{ Toolbar: QuickSearchToolbar }}
              />
              {/* <Link to="/">
                <button className="btn btn-primary mt-2">Back</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsApprovalList;
