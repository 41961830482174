import { lazy } from "react";

const PayableManagementLogic = lazy(() =>
  import("../pages/PayableManagement/PayableManagementLogic")
);

const payableManagementRoute = {
  public: [],
  auth: [
    {
      name: "payable_management",
      path: "/payable-management/:status?",
      component: PayableManagementLogic,
    },
  ],
};

export default payableManagementRoute;
