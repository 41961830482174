import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
//screen
import TrialBalanceReportScreen from '../Screens/TrialBalanceReportScreen';

//api
import {
  getTrialBalance,
  getTrialBalanceExport,
} from '../../../api/trialBalance';
import { numberFormatCurrency } from '../../../utils/helper';
import useNavActive from '../../../components/Navbar/useNavActive';
import moment from 'moment';
import AccountCodeModal from '../Modal/AccountCodeModal';

const currentDate = moment().format('YYYY-MM-DD');

const formDefault = {
  date_from: currentDate,
  date_to: currentDate,
  filter: { items: [] },
};

const TrialBalance = () => {
  useNavActive([
    'finance_and_accounting',
    'fna_financial_reports',
    'trial_balance',
  ]);

  //declaration
  const [formData, setFormData] = useState(formDefault);
  const [trialBalanceData, setTrialBalanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [accountCodeModal, setAccountCodeModal] = useState({
    toggle: false,
    data: null,
  });

  //functions
  //useEffect(() => loadTrialBalanceData(formData), []);

  const loadTrialBalanceData = async (form) => {
    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setTrialBalanceData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;

    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await getTrialBalance(form);
        const { code, result, total_rows } = res.data.response;

        if (code !== '00000') {
          toast.error(result);
          return;
        }
        setTrialBalanceData(
          result.map((row) => {
            return {
              id: row.id,
              account_code: row.account_code,
              account_name: row.account_name,
              account_fs_group: row.account_fs_group,
              account_group: row.account_group,
              account_sub_group: row.account_sub_group,
              account_line_item: row.account_line_item,
              account_sub_line_item: row.account_sub_line_item,
              beginning_balance: row.beginning_balance,
              debit: row.debit,
              credit: row.credit,
              balance: row.balance,
            };
          }),
        );

        setTotalRow(total_rows);
        console.log(trialBalanceData);
        setLoading(false);
      } catch (error) {
        //console.log({ error });
        setLoading(false);
        toast.error('Error occured. Please try again later');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning('Please provide valid date!');
    }
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => loadTrialBalanceData(formData);

  const handleDataTableChange = (params) => {
    //console.log(params);
    let newFormData = {
      ...formData,
      ...params,
    };

    setFormData(newFormData);
    loadTrialBalanceData(newFormData);
  };

  const handleTableFilter = (filterModel) => {
    let newFormData = {
      ...formData,
      filter: filterModel,
    };

    setFormData(newFormData);
    loadTrialBalanceData(newFormData);
  };

  const OnHandleExport = () => {
    if (formData.date_from === '' || formData.date_to === '') {
      Swal.fire({
        title: 'Warning',
        text: 'Please select dates!',
        icon: 'warning',
      });
    } else {
      //console.log(tmp);
      toast.loading('Exporting data in progress, Please wait...', {
        position: toast.POSITION.TOP_RIGHT,
      });
      getTrialBalanceExport(formData)
        .then((res) => {
          //console.log({ res });
          if (res.data.response.code === '00000') {
            //console.log(res);
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handleClickAccountCode = (row) => {
    const data = {
      account_code: row.account_code,
      date_from: formData.date_from,
      date_to: formData.date_to,
    };
    setAccountCodeModal({ toggle: true, data: data });
  };

  return (
    <>
      <TrialBalanceReportScreen
        //throw parameter or props.
        formData={formData}
        dateChange={getDate}
        handleSearch={handleSearch}
        trialBalanceData={trialBalanceData}
        loading={loading}
        totalRow={totalRow}
        handleDataTableChange={handleDataTableChange}
        handleTableFilter={handleTableFilter}
        reloadTable={reloadTable}
        handleExport={OnHandleExport}
        handleClickAccountCode={handleClickAccountCode}
      />

      <AccountCodeModal
        show={accountCodeModal.toggle}
        data={accountCodeModal.data}
        handleClose={() => setAccountCodeModal({ toggle: false, data: null })}
      />
    </>
  );
};

export default TrialBalance;
