import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { numberFormatCurrency } from '../../utils/helper';
import PrimaryDataTable from '../PrimaryDataTable';

const CustomerAgingModal = ({
  showAgingTransaction,
  handleSelectedAging,
  // Selection Model
  selectionAgingModel,
  setSelectionAgingModel,
  // Modal
  showAging,
  setShowAging,
  agingLoader,
}) => {
  const showAgingTransactionColumn = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
    },
    {
      field: 'dr_no',
      headerName: 'DR No.',
      flex: 1,
    },
    {
      field: 'si_number',
      headerName: 'SI No.',
      flex: 1,
    },
    {
      field: 'in_transit',
      headerName: 'In Transit Returns',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      renderCell: ({ row }) => numberFormatCurrency(row.amount),
    },
  ];

  return (
    <Fragment>
      <Modal
        show={showAging}
        onHide={() => setShowAging(false)}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Customer Aging </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrimaryDataTable
            rows={showAgingTransaction}
            columns={showAgingTransactionColumn}
            getRowId={(row) => row.unique_id}
            loading={agingLoader}
            checkboxSelection={true}
            selectionModel={selectionAgingModel}
            onSelectionModelChange={(param) => {
              setSelectionAgingModel(param);
              handleSelectedAging(
                showAgingTransaction
                  .filter((value) => {
                    return param.includes(value.unique_id);
                  })
                  .map((value) => {
                    return { ...value, applied_amt: 0 };
                  }),
              );
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => setShowAging(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CustomerAgingModal;
