import { numberFormatCurrency } from "../../../../utils/helper";

export const exportColumnModal = () => [
  {
    field: "customer_code",
    headerName: "Customer Code",
    width: 250,
  },
  {
    field: "customer_id",
    headerName: "Customer ID",
    width: 250,
  },
  {
    field: "customer_name",
    headerName: "Customer Name",
    flex: 1,
  },
  // {
  //   field: "outstanding_balance",
  //   headerName: "Outstanding Balance",
  //   width: 250,
  //   type: "number",
  //   renderCell: (data) => {
  //     return numberFormatCurrency(data.row.outstanding_balance);
  //   },
  // },
];
