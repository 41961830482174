import React, { Fragment, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
//import { toast } from "react-toastify";
import { createPermissionInSideModule } from "../../../api/permission";
import Swal from "sweetalert2";
import PrimaryAsyncSelect from "../../PrimaryAsyncSelect";
import { getRoles } from "../../../api/users";

const PermissionAssignmentForm = ({
  toggle,
  closeModal,
  formData,
  submitHandler,
  handleChange,
}) => {
  return (
    <Fragment>
      <Modal show={toggle} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>

        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Add Role</Form.Label>
              <PrimaryAsyncSelect
                onChange={(e) =>
                  handleChange({
                    target: { name: "role_id", value: e.value },
                  })
                }
                customFunction={getRoles}
                name="role_id"
                value={formData.role_id}
                isClearable={true}
                config={{
                  searchField: "role",
                  valueField: "role_id",
                  labelField: "role",
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Add
            </Button>

            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default PermissionAssignmentForm;
