import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
export const footprintTracingColumns = ({ handleViewDetail }) => [
  {
    field: "date_created",
    headerName: "Date",
    type: "date",
    width: 180,
  },
  {
    field: "note",
    headerName: "Note / Remarks",
    flex: 1,
  },
  {
    field: "sss",
    headerName: "Actions",
    flex: 1,
    renderCell: ({ row }) =>
      // <span
      //   to={"/#"}
      //   onClick={() => handleViewDetail(row)}
      //   style={{ color: "blue", cursor: "pointer" }}
      // >
      //   View
      // </span>

      row.editCustomerFootPrintPermission && (
        <Button variant="info" size="sm" onClick={() => handleViewDetail(row)}>
          <Search />
        </Button>
      ),
  },
];

export const formDefault = {
  note: "",
  signature: "",
};
