import SmppProductMaintenance from "../pages/SmppProductMaintenance";

const smppProductMaintenanceRoute = {
  public: [],
  auth: [
    {
      name: "product_maintenance",
      path: "/customers/smpp-product-maintenance",
      component: SmppProductMaintenance,
    },
  ],
};

export default smppProductMaintenanceRoute;
