import React, { useEffect, useState, Fragment } from 'react';
import {
  PencilSquare,
  Lock,
  People,
  PlusCircleFill,
} from 'react-bootstrap-icons';
import { Button, Row, Col } from 'react-bootstrap';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

/* For Data Grid */
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles';
/* End Of data grid */

/* For Icons */
import Tooltip from '@material-ui/core/Tooltip';
/* end of Icons */

/* For Switch */
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Switch } from '@material-ui/core';
import PrimaryDataTable from '../PrimaryDataTable';

import { activateUser, deactivateUser, getUsers } from '../../api/users';
import { toast } from 'react-toastify';
import { resetActiveMenu } from '../../redux/modules/menu';

/* EndFor Switch */

/* Switch */
const GreenSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);
/* End Switch */

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();
const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div></div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const User = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); //need to set on filter and pass the data to datagrid
  const [loading, setLoading] = useState(false);

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = tableData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };
  /* Continuation of Search */

  const handleEditUserButton = ({ id }) => history.push(`/edit/user/${id}`);

  const handleEditRolesButton = ({ id }) => history.push(`/user/${id}/role`);

  const handleChangeActiveSwitch = async (user_id, toggle) => {
    try {
      toast.loading('Please wait ...');
      let res = null;
      if (toggle) {
        res = await activateUser(user_id);
      } else {
        res = await deactivateUser(user_id);
      }

      toast.dismiss();

      if (!res) {
        toast.error('Unable to change active column.');
        return;
      }

      const { code, result } = res.data.response;

      if (code !== '00004') {
        toast.error(result);
        return;
      }

      setFilteredData(
        filteredData.map((row) => {
          if (row.user_id === user_id) {
            return { ...row, is_active: toggle ? 1 : 0 };
          }

          return row;
        }),
      );
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const handleUserChangePassword = ({ id }) =>
    history.push(`/user/${id}/change-password`);

  const checkBoxHandler = ({ user_id, is_active }) => {
    return (
      <GreenSwitch
        checked={is_active}
        onChange={(e, toggle) => handleChangeActiveSwitch(user_id, toggle)}
      />
    );
  };

  const columns = [
    {
      field: 'is_active',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <div>{checkBoxHandler(row)}</div>;
      },
      renderHeader: () => <label title="Active">{'Active '}</label>,
    },
    {
      field: 'employee_no',
      headerName: 'Employee No.',
      flex: 0,
      minWidth: 180,
      renderHeader: () => <label title="Employee No.">{'Employee No. '}</label>,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 0,
      minWidth: 160,
      renderHeader: () => <label title="First Name">{'First Name '}</label>,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 0,
      minWidth: 160,
      renderHeader: () => <label title="Last Name">{'Last Name '}</label>,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0,
      minWidth: 300,
      renderHeader: () => <label title="Email">{'Email'}</label>,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 0,
      minWidth: 160,
      renderHeader: () => <label title="Username">{'Username'}</label>,
    },
    {
      field: 'department_name',
      headerName: 'Department',
      flex: 0,
      minWidth: 165,
      renderHeader: () => <label title="Department">{'Department'}</label>,
    },
    {
      field: 'job_name',
      headerName: 'Job Title',
      flex: 0,
      minWidth: 150,
      renderHeader: () => <label title="Job Title">{'Job Title'}</label>,
    },
    {
      field: 'roles_text_format',

      headerName: 'Roles',
      flex: 0,
      minWidth: 270,
      renderHeader: () => <label title="Roles">{'Roles'}</label>,
    },
    {
      field: 'action',
      sortable: false,
      flex: 0.1,
      filterable: false,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <label title="Action">{'Action'}</label>,
      renderCell: (tableData) => {
        return (
          <div className="btn-action-buttons">
            <Link
              className="usersAction btn btn-link btn-sm btn-action"
              id={'Edit_' + tableData.id}
              to={'/edit/user/' + tableData.id}
              onClick={() => handleEditUserButton(tableData)}
            >
              <Tooltip title="Edit">
                <PencilSquare />
              </Tooltip>
            </Link>

            <Link
              className="usersAction btn btn-link btn-sm btn-action"
              to={`/user/${tableData.id}/change-password`}
              id={'CP_' + tableData.id}
              onClick={() => handleUserChangePassword(tableData)}
            >
              <Tooltip title="Change Password">
                <Lock />
              </Tooltip>
            </Link>

            <Link
              className="usersAction btn btn-link btn-sm btn-action"
              to={`/user/${tableData.id}/role`}
              id={'ER_' + tableData.id}
              onClick={() => handleEditRolesButton(tableData)}
            >
              <Tooltip title="Edit Roles">
                <People />
              </Tooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setTableData([]);
    setFilteredData([]);
    setLoading(true);

    try {
      let res = await getUsers();

      const { code, result } = res.data.response;

      setLoading(false);

      if (code !== '00000') {
        toast.error(result);
        return;
      }

      setTableData(result);
      setFilteredData(result);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addUser = () => history.push('/add/user');

  return (
    <Fragment>
      <Row className="mb-3">
        <Col lg="6">
          <h2 className="page-title">User List</h2>
        </Col>
        <Col lg="6">
          <div className="text-right">
            <Button
              id="add_user"
              onClick={addUser}
              variant="success"
              className="btn-add-create btn-md"
            >
              <PlusCircleFill /> Add User
            </Button>
          </div>
        </Col>
      </Row>

      <PrimaryDataTable
        rows={filteredData}
        columns={columns}
        getRowId={(row) => row.user_id}
        components={{ Toolbar: QuickSearchToolbar }}
        loading={loading}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
      />
    </Fragment>
  );
};

export default User;
