import { Fragment } from "react";
import { ListGroup, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";

const SideBarSearch = ({
  title,
  data,
  onSearch,
  labelField,
  sideBarLoader,
  oldData,
  values,
  moduleName,
}) => {
  const history = useHistory();

  const discardChanges = (e) => {
    e.preventDefault();
    let result = _.isEqual(oldData, values);
    if (moduleName === "products") {
      if (result) {
        history.push(e.target.getAttribute("data-link"));
      }

      if (!result) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to discard changes?",
          type: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: true,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(e.target.getAttribute("data-link"));
          }
        });
      }
    } else {
      history.push(e.target.getAttribute("data-link"));
    }
  };

  const renderListItem = (value, index) => (
    <ListGroup.Item key={index} className="border">
      <Link
        to={labelField.link + value[labelField.code]}
        data-link={labelField.link + value[labelField.code]}
        onClick={discardChanges}
      >
        {value[labelField.code]} - {value[labelField.name]}
      </Link>
    </ListGroup.Item>
  );

  return (
    <Fragment>
      <div className="">
        <h2 className="page-title">{title}</h2>
      </div>
      <div className="">
        <ListGroup className="mt-2">
          <ListGroup.Item className="p-2">
            <Form.Control
              type="text"
              placeholder="Search here..."
              onChange={onSearch}
            />
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div
        style={{
          overflow: "auto",
          height: "calc(100vh - 310px)",
        }}
      >
        <ListGroup>
          {data.length > 0 ? (
            data.map((value, index) => renderListItem(value, index))
          ) : (
            <ListGroup.Item disabled>
              {sideBarLoader
                ? "Loading Data..."
                : "No result found for such keyword"}
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
    </Fragment>
  );
};

export default SideBarSearch;
