import { useState, useCallback } from "react";
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { WindowFullscreen, Calendar3 } from "react-bootstrap-icons";

import ModalDraggable from "../../ModalDraggable";
import Swal from "sweetalert2";
import { saveProductForecast } from "../../../api/product";

const defaultForm = {
  forecast_year: "",
  product_code: "",
  january: 0,
  february: 0,
  march: 0,
  april: 0,
  may: 0,
  june: 0,
  july: 0,
  august: 0,
  september: 0,
  october: 0,
  november: 0,
  december: 0,
  forecast_date: "",
};

const monthNameObj = {
  january: "jan",
  february: "feb",
  march: "mar",
  april: "apr",
  may: "may",
  june: "jun",
  july: "jul",
  august: "aug",
  september: "sep",
  october: "oct",
  november: "nov",
  december: "dec",
};

const currentYear = parseInt(moment().format("YYYY")) || 0;
const currentMonth = parseInt(moment().format("M")) || 0;
const currentMonthYear = moment().format("YYYY-MM-01");

const ForecastFormModal = ({
  productCode,
  toggle,
  minimize,
  onClose,
  onMinimize,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [yearDropdown, setYearDropdown] = useState([]);

  const handleOnExited = () => {
    if (!minimize) {
      setForm(defaultForm);
    }
  };

  const handleSubmit = async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Product Forecast",
        text: "Are you sure you want to save this forecast?",
        icon: "warning",
        showCancelButton: true,
      });

      if (isConfirmed) {
        setLoading(true);
        const res = await saveProductForecast({
          ...form,
          product_code: productCode,
          forecast_date: moment().format("YYYY-MM-DD"),
        });
        const { code, result } = res.data;

        if (code !== "00003") {
          throw Object.assign(new Error(result), {
            code: 402,
          });
        }

        setLoading(false);
        toast.success(result);
        onSubmitted({
          success: 1,
          message: "Success",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      onSubmitted({
        success: 0,
        message: error.message,
      });
    }
  };

  const handleOnEnter = () => {
    // set year dropdown 10 years from current year

    let cYear = currentYear;

    if (currentMonth === 12) {
      // december exclude year and start next year
      cYear += 1;
    }

    if (!minimize) {
      setForm({
        ...form,
        year: cYear,
      });
    }

    let tmpDropdownYears = [];
    for (let i = cYear; i < cYear + 5; i++) {
      tmpDropdownYears.push({ value: i, label: i });
    }

    setYearDropdown(tmpDropdownYears);
  };

  const handleChangeYear = (e) => {
    let year = e.value;
    let tmpForm = { forecast_year: year };

    Object.keys(monthNameObj).forEach((month) => {
      const isAfterCurrentMonth = moment(`${month} 01, ${year}`).isAfter(
        currentMonthYear
      );

      if (!isAfterCurrentMonth) tmpForm[month] = 0;
    });

    setForm({
      ...form,
      ...tmpForm,
    });
  };

  const handleChangeMonth = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const renderMonthInput = (month, index) => {
    const isAfterCurrentMonth = moment(
      `${month} 01, ${form.forecast_year}`
    ).isAfter(currentMonthYear);

    if (!isAfterCurrentMonth) return null;

    return (
      <Col sm="2" key={index}>
        <InputGroup className="mb-3 input-group-forecast-stack">
          <InputGroup.Text
            id="basic-addon1"
            className="text-center text-uppercase"
          >
            <Calendar3 style={{ marginTop: "-3px" }} />
            &nbsp;{monthNameObj[month]}
          </InputGroup.Text>
          <Form.Control
            type="number"
            value={form[month]}
            name={month}
            placeholder={`Forecast`}
            aria-label={month}
            aria-describedby={`${month}-addon`}
            onChange={handleChangeMonth}
            className="form-input-integer"
          />
        </InputGroup>
      </Col>
    );
  };

  const renderMonthInputs = () => {
    const { year, product_code, ...months } = defaultForm;

    return (
      <div style={{ minHeight: "185px" }}>
        <Row>
          {Object.keys(months).map((month, index) =>
            renderMonthInput(month, index)
          )}
        </Row>
      </div>
    );
  };

  const renderYear = () => {
    return yearDropdown.map((row) => (
      <Button
        variant={form.forecast_year === row.value ? `info` : `secondary`}
        className="ml-3 year-choice"
        style={{ width: "105px" }}
        onClick={() => handleChangeYear(row)}
      >
        {row.value}
      </Button>
    ));
  };

  return (
    <Modal
      show={toggle}
      onHide={onClose}
      onEntered={handleOnEnter}
      backdrop={false}
      centered
      size={"lg"}
      onExited={handleOnExited}
      dialogAs={ModalDraggable}
    >
      <Modal.Header className="modal-draggable" closeButton>
        <Modal.Title>Forecast Form</Modal.Title>
        <Button variant="minimize" onClick={onMinimize}>
          &nbsp;
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Year
          </Form.Label>
          <Col sm={10}>
            {renderYear()}
            {/* <Select
              options={yearDropdown}
              onChange={handleChangeYear}
              value={
                form.forecast_year
                  ? yearDropdown.find((row) => row.value === form.forecast_year)
                  : ""
              }
            /> */}
          </Col>
        </Form.Group>
        {renderMonthInputs()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={handleSubmit}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Save
        </Button>
        <Button
          variant="danger"
          onClick={onClose}
          style={{ width: "100px" }}
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const useForecastFormModal = () => {
  const [forecastFormModal, setForecastFormModal] = useState({
    toggle: false,
    minimize: false,
    loading: false,
  });

  const setForecastFormModalClose = useCallback(() => {
    toast.dismiss("ff_toast");
    setForecastFormModal({
      ...forecastFormModal,
      toggle: false,
      minimize: false,
      loading: false,
    });
  }, []);

  const setForecastFormModalMinimize = useCallback(() => {
    setForecastFormModal({
      ...forecastFormModal,
      toggle: false,
      minimize: true,
      loading: false,
    });

    toast.info("Forecast Form", {
      autoClose: false,
      closeButton: false,
      className: "toast-modal-minimize",
      toastId: "ff_toast",
      icon: <WindowFullscreen />,
      onClick: () =>
        setForecastFormModal({
          ...forecastFormModal,
          toggle: true,
          minimize: false,
          loading: false,
        }),
    });
  }, []);

  const setForecastFormModalToggle = useCallback(
    (data) =>
      setForecastFormModal({
        ...forecastFormModal,
        toggle: true,
        minimize: false,
        loading: false,
      }),
    []
  );

  return [
    forecastFormModal,
    setForecastFormModalClose,
    setForecastFormModalMinimize,
    setForecastFormModalToggle,
  ];
};

export default ForecastFormModal;
