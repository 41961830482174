import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import PrimaryDataTable from "../PrimaryDataTable";
import DefaultLayout from "../Layouts/DefaultLayout";

import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

/* SEARCH */
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();

const newStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = newStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* END SEARCH */
const InventoryReportMovementResult = () => {

  const [searchText, setSearchText] = useState("");

  const [storeData, setStoreData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  /* Search */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = storeData.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setFilteredData(filteredRows);
  };

  const location = useLocation();

  const productsData = location.state;

  useEffect(() => {
    if (productsData) {
      let count = 0;
      setStoreData(
        productsData.map(e => ({...e,id: count++}))
      );
    }
  }, [productsData]);

  useEffect(() => {
    setFilteredData(storeData);
  }, [storeData])

  // const [prodNameHolder, setProdNameHolder] = useState([]);
  // const [warehouseHolder, setWarehouseHolder] = useState([]);
  // const [groupHolder, setGroupHolder] = useState([]);
  // const [segmentHolder, setSegmentHolder] = useState([]);

  // useEffect(() => {
  //   listProducts();
  //   listWarehouse();
  //   listGroup();
  //   listSegment();
  // }, []);

  // const listProducts = () => {
  //   axiosInstance.get(`/inventoryreport/dropdown/product`).then((response) => {
  //     setProdNameHolder(response.data.response.result);
  //   });
  // };

  // const listWarehouse = () => {
  //   axiosInstance
  //     .get(`/inventoryreport/dropdown/warehouse`)
  //     .then((response) => {
  //       setWarehouseHolder(response.data.response.result);
  //     });
  // };

  // const listGroup = () => {
  //   axiosInstance.get(`/products/group`).then((response) => {
  //     setGroupHolder(response.data.response.result);
  //   });
  // };

  // const listSegment = () => {
  //   axiosInstance.get(`/products/segment`).then((response) => {
  //     setSegmentHolder(response.data.response.result);
  //   });
  // };

  const columns = [
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "batch_no",
      headerName: "Batch No.",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "central_balance",
      headerName: "Main/Central",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "picking_balance",
      headerName: "Picking Area",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "total_inventory",
      headerName: "Total Inventory",
      flex: 1,
      type: "number",
      minWidth: 200,
      className: "font-weight-bold"
    },
    {
      field: "_blank",
      headerName: " ",
      width: 30,
    },
    {
      field: "ar_stage",
      headerName: "AR Assessment Stage",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "picking_stage",
      headerName: "Picking Area Stage",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "marking_stage",
      headerName: "Marking Stage",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "dr_confirmed",
      headerName: "DR Confirmed",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "cancelled",
      headerName: "Cancelled",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "_blank_",
      headerName: " ",
      width: 30,
    },
    {
      field: "available_for_booking",
      headerName: "Booking (SO PORTAL)",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "ending_inventory_ledger",
      headerName: "Ending Inventory (LEDGER)",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "_blank_1",
      headerName: " ",
      width: 30,
    },
    {
      field: "ending_inventory_ledger_2",
      headerName: "Ending Inventory (LEDGER)",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
    {
      field: "actual_inventory",
      headerName: "Actual Inventory",
      flex: 1,
      type: "number",
      minWidth: 200,
    },
  ];

  return (
    <Fragment>
      <DefaultLayout title="Inventory Movement">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <Link to="/inventoryreport">
              <button
                id="save_changes"
                className="btn btn-success"
                type="submit"
              >
                Change Table Columns
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <PrimaryDataTable
              rows={filteredData}
              columns={columns}
              disableSelectionOnClick
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </div>
      </DefaultLayout>
    </Fragment>
  );
};

export default InventoryReportMovementResult;
