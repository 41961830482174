import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

export const approverColumns = ({ handleDetailsModal }) => [
  {
    field: 'requestor_name',
    headerName: 'Requestor',
    minWidth: 200,
  },
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    minWidth: 150,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    minWidth: 150,
  },
  {
    field: 'bank',
    headerName: 'Bank',
    minWidth: 150,
  },
  {
    field: 'branch',
    headerName: 'Branch',
    minWidth: 150,
  },
  {
    field: 'check_no',
    headerName: 'Check No.',
    minWidth: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
  },
  {
    field: 'cr_no',
    headerName: 'CR No.',
    minWidth: 200,
  },
  {
    field: 'pr_no',
    headerName: 'PR No.',
    minWidth: 200,
  },
  {
    field: 'date_requested',
    headerName: 'Date Of Request',
    minWidth: 200,
  },
  {
    field: 'change_bank_reason',
    headerName: 'Change Bank Reason',
    minWidth: 400,
  },
  {
    field: 'change_deposited_date_reason',
    headerName: 'Deposit Date Reason',
    minWidth: 400,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    minWidth: 400,
  },
  {
    field: 'select',
    headerName: 'Action',
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return (
        <Link
          className="btn btn-link btn-sm text-blue"
          to="/treasury/list-of-approvals"
          onClick={() => handleDetailsModal(row)}
        >
          <Tooltip title="View Details">
            <Search size="20" />
          </Tooltip>
        </Link>
      );
    },
  },
];

export const listOfApprovedColumns = () => [
  {
    field: 'requestor_name',
    headerName: 'Requestor',
    minWidth: 150,
  },
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    minWidth: 150,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    minWidth: 150,
  },
  {
    field: 'bank',
    headerName: 'Bank',
    minWidth: 150,
  },
  {
    field: 'branch',
    headerName: 'Branch',
    minWidth: 150,
  },
  {
    field: 'check_no',
    headerName: 'Check No.',
    minWidth: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
  },
  {
    field: 'cr_no',
    headerName: 'CR No.',
    minWidth: 150,
  },
  {
    field: 'pr_no',
    headerName: 'PR No.',
    minWidth: 150,
  },
  {
    field: 'date_requested',
    headerName: 'Date Of Request',
    minWidth: 200,
  },
  {
    field: 'change_bank_reason',
    headerName: 'Change Bank Reason',
    minWidth: 400,
  },
  {
    field: 'change_deposited_date_reason',
    headerName: 'Deposit Date Reason',
    minWidth: 400,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    minWidth: 400,
  },
  {
    field: 'date_approved',
    headerName: 'Date Approved',
    minWidth: 200,
  },
  {
    field: 'approved_by',
    headerName: 'Approved By',
    minWidth: 200,
  },
];

export const listOfDeclinedColumns = () => [
  {
    field: 'requestor_name',
    headerName: 'Requestor',
    minWidth: 200,
  },
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    minWidth: 150,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    minWidth: 150,
  },
  {
    field: 'bank',
    headerName: 'Bank',
    minWidth: 150,
  },
  {
    field: 'branch',
    headerName: 'Branch',
    minWidth: 150,
  },
  {
    field: 'check_no',
    headerName: 'Check No.',
    minWidth: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
  },
  {
    field: 'cr_no',
    headerName: 'CR No.',
    minWidth: 150,
  },
  {
    field: 'pr_no',
    headerName: 'PR No.',
    minWidth: 150,
  },
  {
    field: 'date_requested',
    headerName: 'Date Of Request',
    minWidth: 200,
  },
  {
    field: 'change_bank_reason',
    headerName: 'Change Bank Reason',
    minWidth: 400,
  },
  {
    field: 'change_deposited_date_reason',
    headerName: 'Deposit Date Reason',
    minWidth: 400,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    minWidth: 400,
  },
  {
    field: 'date_disapproved',
    headerName: 'Date Declined',
    minWidth: 200,
  },
  {
    field: 'disapproved_by',
    headerName: 'Declined By',
    minWidth: 200,
  },
];
